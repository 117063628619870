import React from "react"

import { withFooterSocial } from "./withFooterSocial"
import { Icon } from "../../Icon/Icon"
import { Nav, Items, Item, Link } from "./styledFooterSocial"

export const FooterSocial = withFooterSocial(({ items }) => (
  <Nav>
    <Items>
      {items?.map(({ id, title, url }) => (
        <Item key={id}>
          <Link as={`a`} href={url} rel={`noopener`} title={title} target={`_blank`}>
            <Icon name={`social-${id}`} title={title} width={32} />
          </Link>
        </Item>
      ))}
    </Items>
  </Nav>
))
