import { useStaticQuery, graphql } from "gatsby"

export const useAcknowledgement = () => {
  const { acknowledgement } = useStaticQuery(graphql`
    query {
      acknowledgement: sanitySettingAcknowledgement {
        enabled
        content: _rawContent(resolveReferences: { maxDepth: 2 })
        alternativeItems: _rawAlternativeItems(resolveReferences: { maxDepth: 6 })
      }
    }
  `)

  return {
    ...acknowledgement,
  }
}
