import React from "react"

import { GlobalProvider } from "./global"
import { LocationProvider } from "./location"
import { ShopifyProvider } from "./shopify"
import { AppProvider } from "./app"
import { InstagramProvider } from "./instagram"
import { TrackingProvider } from "./tracking"
import { CustomerProvider } from "./customer"
import { CheckoutProvider } from "./checkout"
import { WishlistProvider } from "./wishlist"
import { LoyaltyProvider } from "./loyalty"
import { ShopProvider } from "./shop"
import config from "@root/config.js"

export const Providers = ({ children }) => (
  <GlobalProvider>
    <LocationProvider config={config}>
      <ShopifyProvider
        config={{
          shopName: config.store.shopName,
          apiVersion: config.store.shopifyApiVersion,
          accessToken: config.store.accessToken,
        }}
        allStores={config.stores}
      >
        <AppProvider config={config}>
          <InstagramProvider>
            <ShopProvider>
              <CustomerProvider>
                <LoyaltyProvider>
                  <CheckoutProvider>
                    <WishlistProvider>
                      <TrackingProvider>{children}</TrackingProvider>
                    </WishlistProvider>
                  </CheckoutProvider>
                </LoyaltyProvider>
              </CustomerProvider>
            </ShopProvider>
          </InstagramProvider>
        </AppProvider>
      </ShopifyProvider>
    </LocationProvider>
  </GlobalProvider>
)
