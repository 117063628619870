import React, { memo, useMemo, useEffect } from "react"
import { useTransition, TransitionFn } from "react-spring"

import * as Components from "../../types/components"
import * as Shopify from "../../types/shopify"
import { UseAnimation } from "../../types/hooks"
import { useApp } from "../../hooks/useApp"
import { useImage } from "../../hooks/useImage"
import { useAnimation } from "../../hooks/useAnimation"

export const withAnimationPopup = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "AnimationPopup" }: Components.ComponentProps) => {
    const { activeAnimationPopup = [], headerBounds, setActiveAnimationPopup } = useApp()
    const { baseImageTrans, getSpringConfig } = useAnimation()
    const { convertSwatchImageUrl } = useImage()

    const active = useMemo(() => activeAnimationPopup.length, [activeAnimationPopup.length])
    const bagImage = useMemo(() => activeAnimationPopup[0], [activeAnimationPopup])
    const productImages = useMemo(() => activeAnimationPopup.slice(1, activeAnimationPopup.length), [activeAnimationPopup])
    const imageTrans = useMemo(
      () =>
        baseImageTrans.map((tran, index) => ({
          ...tran,
          src: productImages?.[index]?.src ? convertSwatchImageUrl(productImages?.[index]?.src) : "",
        })),
      [baseImageTrans, convertSwatchImageUrl, productImages]
    )

    const transitions = useTransition(imageTrans, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      delay: 10,
      pause: !active,
      config: { mess: 1, tension: 280, friction: 320 },
    })

    useEffect(() => {
      if (active) {
        setTimeout(() => {
          setActiveAnimationPopup([])
        }, 4000)
      }
    }, [active, setActiveAnimationPopup])

    const props = useMemo(
      () => ({
        active,
        bounds: headerBounds,
        bagImage,
        transitions,
        getSpringConfig,
      }),
      [active, bagImage, headerBounds, getSpringConfig, transitions]
    )

    Component.displayName = name
    return useMemo(() => <Component {...(props as P)} />, [props])
  })

type InjectedProps = {
  active: boolean
  bounds: any
  bagImage: Shopify.ShopifyImage
  transitions: TransitionFn<
    {
      src: any
      fig: number
      op: UseAnimation.TransConfig
      trans: UseAnimation.TransConfig
    },
    {
      opacity: number
    }
  >
  getSpringConfig: (input: UseAnimation.GetSpringConfigInput) => UseAnimation.GetSpringConfigResponse
}
