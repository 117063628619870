import React, { useState, useRef, useEffect, useMemo, useContext } from "react"

import { useApp } from "../hooks/useApp"
import { useCore } from "../hooks/useCore"
import { useGlobal } from "../hooks/useGlobal"
import { useAnalytics } from "../hooks/useAnalytics"
import { useSettings } from "../hooks/useSettings"
import { useRelated } from "@app/hooks/useRelated"

export const CheckoutContext: any = React.createContext({})

export const CheckoutProvider = ({ children }): JSX.Element => {
  const {
    config: {
      settings: { keys },
      store,
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const { retrieveClientID, retrieveReferralCandyReferrer } = useAnalytics()
  const { tracking } = useSettings()
  const { handleGlobalGroupReady } = useGlobal()
  const [checkout, setCheckout] = useState<any>(false)
  const [clientId, setClientId] = useState(null)
  const [sessionID, setSessionID] = useState(null)
  const [related, setRelated] = useState({})
  const [candyReferrer, setCandyReferrer] = useState("")
  const [productRelatedHandles, setProductRelatedHandles] = useState({})
  const [currentUpsellHandles, setCurrentUpsellHandles] = useState([])

  const prevCheckout = useRef(checkout)

  const countryCode = useMemo(
    () => checkout?.buyerIdentity?.countryCode || storage.get(keys.market) || store.siteLocation || "AU",
    [checkout?.buyerIdentity?.countryCode, keys.market, storage, store.siteLocation]
  )

  //Retrieve GA client ID for LittleData
  useEffect(() => {
    const retrievedID = retrieveClientID()
    if (retrievedID) {
      setClientId(retrievedID)
    }
  })

  //Retrieve GA4 Session ID for LittleData
  useEffect(() => {
    if (window?.gtag && !sessionID && tracking.gaFourId) {
      gtag("get", `${tracking.gaFourId}`, "session_id", field => {
        if (field) {
          setSessionID(field)
        }
      })
    }
  }, [sessionID])

  //Retrieve Referral Candy Referrer ID from URL params
  useEffect(() => {
    const id = retrieveReferralCandyReferrer()
    window.localStorage.setItem(keys?.refcandy, id)
    setCandyReferrer(id)
  }, [sessionID])

  // save previous checkout in the prevCheckout ref
  useEffect(() => {
    if (!prevCheckout.current) {
      prevCheckout.current = checkout
    }
  }, [checkout])

  // compare previous checkout and current checkout, if previous is false and current is not false, then set the checkout ready to true
  useEffect(() => {
    if (prevCheckout.current === false && checkout) {
      handleGlobalGroupReady("checkout")
    }
  }, [handleGlobalGroupReady, checkout])

  const relatedHandleData = useRelated()

  //retrieve related handles for all products
  useEffect(() => {
    const data = relatedHandleData?.products?.edges
    if (Object.keys(productRelatedHandles).length === 0) {
      const formattedData = {}
      setProductRelatedHandles(relatedHandleData)
      data?.forEach(product => {
        const id = product?.node?.id
        const related = product?.node?.content?.related?.map(relatedProduct => {
          return relatedProduct?.shopify?.shopifyHandle ? relatedProduct?.shopify?.shopifyHandle : null
        })
        if (!formattedData?.[id] && id && related) {
          formattedData[id] = related
        }
      })
      setRelated(formattedData)
    }
  }, [productRelatedHandles, checkout])

  //Set related for current products in cart and sort them
  useEffect(() => {
    const currentItemIdsInCart =
      checkout?.lineItems?.map(lineItem => {
        const extractIdFromGid = (gid: string) => gid.match(/[^/]+$/i)[0]
        return extractIdFromGid(lineItem?.product?.id)
      }) || []

    const currentHandlesInCart =
      checkout?.lineItems?.map(lineItem => {
        return lineItem?.product?.handle
      }) || []

    const currentRelatedHandles = currentItemIdsInCart
      ?.map(id => {
        return related?.[+id] && related?.[+id]
      })
      ?.filter(items => items)

    const sortedCurrentRelatedHandles = []
    if (currentRelatedHandles.length) {
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < currentRelatedHandles.length; j++) {
          if (
            currentRelatedHandles[j][i] &&
            !currentHandlesInCart.includes(currentRelatedHandles[j][i]) &&
            !sortedCurrentRelatedHandles.includes(currentRelatedHandles[j][i])
          ) {
            sortedCurrentRelatedHandles.push(currentRelatedHandles[j][i])
          }
        }
      }
    }
    setCurrentUpsellHandles(sortedCurrentRelatedHandles || [])
  }, [related, checkout])
  const values = {
    checkout,
    countryCode,
    setCheckout,
    clientId,
    sessionID,
    currentUpsellHandles,
    candyReferrer,
  }

  return <CheckoutContext.Provider value={{ ...values }}>{children}</CheckoutContext.Provider>
}
