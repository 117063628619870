import React, { memo, useMemo, useEffect, useState } from "react"
import { useTransition, TransitionFn } from "react-spring"

import * as Components from "../../types/components"
import * as Shopify from "../../types/shopify"
import { UseAnimation } from "../../types/hooks"
import { useApp } from "../../hooks/useApp"
import { useImage } from "../../hooks/useImage"
import { useAnimationBoxCard } from "../../hooks/useAnimationBoxCard"

export const withBoxCardAnimationPopup = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "BoxCardAnimationPopup" }: Components.ComponentProps) => {
    const { activeBoxCardAnimationPopup = [], headerBounds, setActiveBoxCardAnimationPopup } = useApp()
    const { baseImageTrans, getSpringConfig } = useAnimationBoxCard()
    const { convertSwatchImageUrl } = useImage()
    const boxImage = useMemo(() => activeBoxCardAnimationPopup[0]?.img, [activeBoxCardAnimationPopup])

    // eslint-disable-next-line
    let [openedBox, setOpenedBox] = useState(true)

    const active = useMemo(() => activeBoxCardAnimationPopup.length, [activeBoxCardAnimationPopup.length])

    // Makes sure that the bag always shows first in the second animation
    // eslint-disable-next-line
    let productImages = []
    // eslint-disable-next-line
    let boxImageOpen = []
    for (let index = 0; index < activeBoxCardAnimationPopup?.length; index++) {
      if (activeBoxCardAnimationPopup[index]?.itemIdentifier === "bag") {
        productImages[0] = activeBoxCardAnimationPopup[index]?.img
      } else if (activeBoxCardAnimationPopup[index]?.itemIdentifier === "card") {
        productImages[1] = activeBoxCardAnimationPopup[index]?.img
      } else {
        boxImageOpen = activeBoxCardAnimationPopup[index]?.img
      }
    }

    const imageTrans = useMemo(
      () =>
        baseImageTrans.map((tran, index) => ({
          ...tran,
          src: productImages?.[index]?.src ? convertSwatchImageUrl(productImages?.[index]?.src) : "",
        })),
      [baseImageTrans, convertSwatchImageUrl, productImages]
    )

    const [animationImages, setAnimationImages] = useState([])

    const transitions = useTransition(animationImages, {
      from: { opacity: 1 },
      enter: { opacity: 1 },
      delay: 10,
      pause: !active,
      config: { mess: 1, tension: 280, friction: 320 },
    })

    useEffect(() => {
      if (active) {
        setTimeout(() => {
          setAnimationImages([imageTrans[0]])
        }, 1000)

        setTimeout(() => {
          if (imageTrans[1]?.src != "") {
            setAnimationImages([imageTrans[0], imageTrans[1]])
          }
        }, 2000)

        setTimeout(() => {
          setOpenedBox(false)
          setAnimationImages([])
        }, 3000)

        setTimeout(() => {
          setActiveBoxCardAnimationPopup([])
        }, 4500)

        setTimeout(() => {
          setOpenedBox(true)
        }, 6000)
      }
      // eslint-disable-next-line
    }, [active, setActiveBoxCardAnimationPopup])

    const props = useMemo(
      () => ({
        active,
        bounds: headerBounds,
        boxImage,
        openedBox,
        boxImageOpen,
        transitions,
        getSpringConfig,
      }),
      [active, boxImage, openedBox, headerBounds, getSpringConfig, transitions, boxImageOpen]
    )

    Component.displayName = name
    return useMemo(() => <Component {...(props as P)} />, [props])
  })

type InjectedProps = {
  active: boolean
  bounds: any
  openedBox: any
  boxImageOpen: any
  boxImage: Shopify.ShopifyImage
  transitions: TransitionFn<
    {
      src: any
      fig: number
      op: UseAnimation.TransConfig
      trans: UseAnimation.TransConfig
    },
    {
      opacity: number
    }
  >
  getSpringConfig: (input: UseAnimation.GetSpringConfigInput) => UseAnimation.GetSpringConfigResponse
}
