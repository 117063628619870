import React from "react"
import { animated } from "react-spring"

import { withBoxCardAnimationPopup } from "./withBoxCardAnimationPopup"
import { Notify, Inner, Popup, Wrapper, Content, ItemGroup, ImageContent, ImageWrapper, Image } from "./styledBoxCardAnimation"

export const BoxCardAnimationPopup = withBoxCardAnimationPopup(
  ({ active, bounds, boxImage, boxImageOpen, openedBox, transitions, getSpringConfig }): JSX.Element => {
    return (
      <Notify active={active} top={bounds?.height}>
        <Inner>
          <Popup active={active}>
            <Wrapper>
              <Content>
                <ItemGroup>
                  {transitions(({ opacity }, item) => {
                    return <animated.img style={{ ...(getSpringConfig({ opacity, item }) as unknown as any) }} src={item.src}></animated.img>
                  })}
                </ItemGroup>
              </Content>
              <ImageContent>
                <ImageWrapper>
                  {openedBox ? (
                    <Image alt={"bag"} fluid={{ ...boxImage, aspectRatio: 4 / 3 }} />
                  ) : (
                    <Image alt={"bag"} fluid={{ ...boxImageOpen, aspectRatio: 4 / 3 }} />
                  )}
                </ImageWrapper>
              </ImageContent>
            </Wrapper>
          </Popup>
        </Inner>
      </Notify>
    )
  }
)
