import tw, { css, styled } from "twin.macro"

import { StyledLink, StyledButton } from "../Styled/Button"
import { StyledImage } from "../Styled/Image"
import { H4, RichText } from "../Styled/Text"

const styles = {
  outer: tw`fixed inset-0 w-full h-full flex flex-col items-center justify-end md:justify-center z-50 invisible opacity-0 transition-all duration-200`,
  inner: tw`relative md:rounded-full overflow-hidden w-full md:w-158 max-w-full invisible transform scale-90 translate-y-6 transition-all duration-200`,
  content: tw`relative md:absolute md:inset-0 flex flex-col justify-center px-6 pb-7 pt-10 md:p-28`,
  overlay: tw`absolute inset-0 bg-white bg-opacity-70 cursor-pointer`,
}

export const Popup = styled.section`
  ${styles.outer}
  ${({ active }) => active && tw`visible opacity-100`}
`
export const Overlay = styled.div`
  ${styles.overlay}
`
export const Inner = styled.div`
  ${styles.inner}
  ${({ active }) => active && tw`visible scale-100 translate-y-0`}

  ${css`
    @media (min-width: 768px) {
      min-height: 39.5rem;
    }
  `}
`
export const Content = styled.div`
  ${styles.content}
`
export const Title = tw(H4)`md:text-4.5xl`
export const Success = styled(RichText)`
  ${tw`text-xl md:text-2xl leading-tight md:leading-snug font-bold`}

  strong {
    ${tw`text-purple-dark`}
  }
`
export const Image = styled(StyledImage)`
  ${tw`inset-0 md:relative md:inset-auto md:w-full`}

  @media (max-width: 767px) {
    position: absolute !important;
  }
`
export const Button = tw(StyledButton)`self-center md:px-14`
export const Close = tw(StyledLink)`self-end mb-6`
