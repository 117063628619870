import React from "react"

import { withLayout } from "./withLayout"
import { Header } from "../Header/Header"
import { Footer } from "../Footer/Footer"
import { Newsletter } from "../Newsletter/Newsletter"
import { InstagramPopup } from "../Sections/Instagram/Popup/InstagramPopup"
import { ProductNotify } from "../Product/Notify/ProductNotify"
import { QuizShare } from "../QuizV2/Share/QuizShare"
import { QuizInfo } from "../QuizV2/Info/QuizInfo"
import { QuizSubscribePopup } from "../QuizV2/Subscribe/QuizSubscribePopup"
import { AnimationPopup } from "../Animation/AnimationPopup"
import { AnimationPopupLoyalty } from "../AnimationLoyalty/AnimationPopupLoyalty"
import { AnimationPopupPickGift } from "../AnimationPickGift/AnimationPopupPickGift"
import { BoxCardAnimationPopup } from "../BoxCardAnimation/BoxCardAnimationPopup"
import { PickMixPopup } from "../PickMix/Select/Popup/PickMixPopup"
import { PickGiftPopup } from "../PickGift/Select/Popup/PickGiftPopup"

import { StyledLayoutContent, StyledLayoutFooter, StyledLayoutMain } from "./styledLayout"

export const Layout = withLayout(
  ({ children, location, title }): JSX.Element => (
    <>
      <StyledLayoutContent>
        <Header location={location} title={title} />
        <StyledLayoutMain>{children}</StyledLayoutMain>
        <Newsletter location={location} />
        <ProductNotify />
        <InstagramPopup />
        <QuizShare />
        <QuizInfo />
        <QuizSubscribePopup />
        <AnimationPopup />
        <AnimationPopupLoyalty />
        <AnimationPopupPickGift />
        <BoxCardAnimationPopup />
        <PickMixPopup />
        <PickGiftPopup />
      </StyledLayoutContent>
      <StyledLayoutFooter>
        <Footer title={title} />
      </StyledLayoutFooter>
    </>
  )
)
