import { useCallback, useContext } from "react"
import { navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useCustomerAccessToken, useCustomerContext, useCustomerLogout } from "./useCustomer"
import { useLocation } from "./useLocation"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"
import { useShop, useShopContext } from "./useShop"
import { CheckoutContext } from "../providers/checkout"

export const useCheckoutContext = () => {
  const { shopifyStore, shopifyStoreDomain } = useLocation()

  const checkoutData: any = useContext(CheckoutContext)

  const checkoutUrl = checkoutData?.checkout?.webUrl
    ? checkoutData?.checkout?.webUrl.replace(`${shopifyStore}.myshopify.com`, shopifyStoreDomain)
    : ""

  return { ...checkoutData, checkoutUrl }
}

export const useCheckoutSession = () => {
  const {
    config: {
      settings: { keys, params },
      store,
    },
  } = useApp()
  const {
    helpers: { decodeBase64, encodeBase64, storage },
  } = useCore()
  const { getCustomer } = useCustomerAccessToken()
  const { customer } = useCustomerContext()
  const { createCheckout, migrateCheckout } = useCheckout()
  const { checkout } = useCheckoutContext()
  const { location: shopLocation } = useLocation()
  const { getUrlParameter, setUrlParameter } = useRoutes()
  const { shop } = useShopContext()

  const exportSession = useCallback(
    (url: string) => {
      const checkout = storage.get(keys?.checkout) || null
      const customer = storage.get(keys?.customer) || null

      const session = encodeBase64(
        JSON.stringify({
          checkout,
          customer,
        })
      )

      return `${url}?${params?.session}=${session}`
    },
    [encodeBase64, keys, params, storage]
  )

  const importSession = useCallback(
    (location: any) => {
      const rawSession = getUrlParameter(params?.session, location)
      const url = setUrlParameter(params?.session, null, location)

      if (rawSession) {
        try {
          const session = rawSession && JSON.parse(decodeBase64(rawSession))

          if (session?.checkout) {
            createCheckout(session.checkout)
          }
          if (session?.customer) {
            getCustomer(session.customer)
          }

          navigate(url, { replace: true })
        } catch (error) {
          navigate(url, { replace: true })
          console.error(error)
        }
      } else {
        if (!checkout) createCheckout(null, store.siteLocation)
        if (shop && shop?.paymentSettings?.countryCode !== shopLocation) migrateCheckout(shop.paymentSettings.countryCode)
        if (!customer?.id) getCustomer()
      }
    },
    [
      checkout,
      createCheckout,
      customer,
      decodeBase64,
      getCustomer,
      getUrlParameter,
      migrateCheckout,
      params,
      setUrlParameter,
      store.siteLocation,
      shop,
      shopLocation,
    ]
  )

  return { exportSession, importSession }
}

export const useCheckout = () => {
  const {
    config: {
      settings: { keys },
      store,
    },
  } = useApp()
  const {
    helpers: { storage },
    graphql: {
      mutations: {
        CHECKOUT_CREATE,
        CHECKOUT_ATTRIBUTES_UPDATE,
        CHECKOUT_SHIPPING_ADDRESS_UPDATE,
        CHECKOUT_EMAIL_UPDATE,
        CHECKOUT_DISCOUNT_APPLY,
        CHECKOUT_GIFTCARDS_APPEND,
        CHECKOUT_CUSTOMER_ASSOCIATE,
      },
      queries: { GET_CHECKOUT, GET_SHIPPING_RATES, GET_PRODUCTS_BY_HANDLE },
    },
  } = useCore()
  const { checkout, clientId, setCheckout: saveCheckout, countryCode } = useCheckoutContext()
  const { checkoutNormaliser, useMutation, useQuery, client } = useShopify()
  const { handleLogout } = useCustomerLogout()
  const { getShop } = useShop()
  const checkoutId = storage.get(keys?.checkout)

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)
  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE)
  const [checkoutShippingAddressUpdate] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE)
  const [checkoutEmailUpdate] = useMutation(CHECKOUT_EMAIL_UPDATE)
  const [checkoutDiscountApply] = useMutation(CHECKOUT_DISCOUNT_APPLY)
  const [checkoutGiftcardAppend] = useMutation(CHECKOUT_GIFTCARDS_APPEND)

  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const { refetch: getShippingRatesQuery } = useQuery(GET_SHIPPING_RATES, { fetchPolicy: "no-cache", skip: true })

  const getCheckout = useCallback(
    async (checkoutId: string) => {
      try {
        if (checkoutId) {
          const {
            data: { node: checkout },
          } = await getCheckoutQuery({ countryCode, checkoutId })

          return checkout
        }

        return false
      } catch (error) {
        console.error(error)
      }
    },
    [countryCode, getCheckoutQuery]
  )

  const setCheckout = useCallback(
    checkout => {
      try {
        saveCheckout(checkoutNormaliser(checkout))
        storage.set(keys?.checkout, checkout?.id)
      } catch (error) {
        console.error(error)
      }
    },
    [saveCheckout, checkoutNormaliser, keys, storage]
  )

  const createCheckout = useCallback(
    async (id = null, countryCode = "AU", forceNew = false) => {
      try {
        const checkoutToken = id || checkoutId
        const existingCheckout = !forceNew && (await getCheckout(checkoutToken))

        if (forceNew || !existingCheckout?.id || existingCheckout?.completedAt !== null || Object.keys(existingCheckout).length < 1) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate(
            {
              variables: {
                countryCode,
                input: {
                  buyerIdentity: {
                    countryCode,
                  },
                },
              },
            },
            countryCode
          )
          if (checkout) setCheckout(checkout)
        } else {
          setCheckout(existingCheckout)
        }
        getShop()
      } catch (error) {
        storage.remove(keys?.checkout)
        console.error(error)
      }
    },
    [checkoutCreate, checkoutId, getCheckout, getShop, keys, setCheckout, storage]
  )

  const updateAttributes = useCallback(
    async input => {
      const {
        data: { checkoutAttributesUpdateV2: data },
      } = await checkoutAttributeUpdate({
        variables: { checkoutId, countryCode, input },
      })
      setCheckout(checkoutNormaliser(data?.checkout))
    },
    [checkoutAttributeUpdate, countryCode, checkoutNormaliser, setCheckout, checkoutId]
  )

  const updateCustomer = useCallback(
    async customerAccessToken => {
      const {
        data: { checkoutCustomerAssociateV2: data },
      } = await checkoutCustomerAssociate({
        variables: { checkoutId, countryCode, customerAccessToken },
      })
      setCheckout(checkoutNormaliser(data?.checkout))
    },
    [checkoutCustomerAssociate, checkoutNormaliser, countryCode, setCheckout, checkoutId]
  )

  const updateShippingAddress = useCallback(
    async input => {
      const {
        data: { checkoutShippingAddressUpdateV2: data },
      } = await checkoutShippingAddressUpdate({
        variables: {
          checkoutId,
          countryCode,
          shippingAddress: {
            firstName: input?.firstName,
            lastName: input?.lastName,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            country: input?.country,
            phone: input?.phone,
            province: input?.province,
            zip: input?.zip,
          },
        },
      })
      setCheckout(checkoutNormaliser(data?.checkout))
    },
    [checkoutShippingAddressUpdate, countryCode, checkoutNormaliser, setCheckout, checkoutId]
  )

  const updateEmail = useCallback(
    async email => {
      const {
        data: { checkoutEmailUpdateV2: data },
      } = await checkoutEmailUpdate({
        variables: { checkoutId, countryCode, email },
      })
      setCheckout(checkoutNormaliser(data?.checkout))
    },
    [checkoutEmailUpdate, checkoutNormaliser, countryCode, setCheckout, checkoutId]
  )

  const applyDiscountCode = useCallback(
    async discountCode => {
      const {
        data: { checkoutDiscountCodeApplyV2: data },
      } = await checkoutDiscountApply({
        variables: { checkoutId, countryCode, discountCode },
      })

      if (!data.checkoutUserErrors.length) {
        const checkout = checkoutNormaliser(data?.checkout)
        storage.set(
          keys?.discounts,
          [...(storage.get(keys?.discounts) || []), discountCode].filter((value, index, self) => self.indexOf(value) === index)
        )
        setCheckout(checkout)

        return checkout
      } else {
        return data
      }
    },
    [checkoutDiscountApply, setCheckout, checkoutId, checkoutNormaliser, keys, storage, countryCode]
  )

  const applyGiftCardCode = useCallback(
    async giftCardCode => {
      const {
        data: { checkoutGiftCardsAppend: data },
      } = await checkoutGiftcardAppend({
        variables: { checkoutId, countryCode, giftCardCodes: [giftCardCode] },
      })

      if (!data.checkoutUserErrors.length) {
        const checkout = checkoutNormaliser(data?.checkout)
        setCheckout(checkout)

        return checkout
      } else {
        return data
      }
    },
    [checkoutGiftcardAppend, setCheckout, countryCode, checkoutId, checkoutNormaliser]
  )

  const getShippingRates = useCallback(async () => {
    const {
      data: { node: checkout },
    } = await getShippingRatesQuery({ countryCode, id: checkoutId })

    setCheckout(checkoutNormaliser(checkout))
    return checkout
  }, [getShippingRatesQuery, setCheckout, countryCode, checkoutId, checkoutNormaliser])

  const applyDiscounts = useCallback(async () => {
    const discountCodes = storage.get(keys?.discounts) || []
    for (const discountCode of discountCodes) {
      await applyDiscountCode(discountCode)
    }
  }, [applyDiscountCode, keys, storage])

  const duplicateCheckout = useCallback(
    async (countryCode: string) => {
      const { customAttributes, lineItems, note, email, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      const {
        data: { checkoutCreate: data },
      } = await checkoutCreate({
        variables: {
          countryCode,
          input: {
            ...(email && { email }),
            ...(note && { note }),
            ...(customAttributes && {
              customAttributes:
                customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })) || [],
            }),
            buyerIdentity: {
              countryCode,
            },
            lineItems:
              lineItems?.map(item => ({
                variantId: item?.variant.id,
                quantity: item?.quantity || 1,
                customAttributes:
                  item?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              })) || [],
            shippingAddress: shippingAddress
              ? {
                  address1: shippingAddress.address1,
                  address2: shippingAddress.address2,
                  city: shippingAddress.city,
                  company: shippingAddress.company,
                  country: shippingAddress.country,
                  firstName: shippingAddress.firstName,
                  lastName: shippingAddress.lastName,
                  phone: shippingAddress.phone,
                  province: shippingAddress.province,
                  zip: shippingAddress.zip,
                }
              : undefined,
          },
        },
      })
      if (!discounts.length) setCheckout(checkoutNormaliser(data?.checkout))
      if (discounts.length) applyDiscounts()
    },
    [checkout, checkoutCreate, checkoutNormaliser, setCheckout, applyDiscounts, keys, storage]
  )

  const migrateCheckout = useCallback(
    async (countryCode: string) => {
      const { lineItems, note, email, customAttributes, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      if (lineItems?.length) {
        const mappedLineItems =
          lineItems?.map(item => ({
            handle: item?.variant?.product?.handle,
            sku: item?.variant?.sku,
            quantity: item?.quantity,
            customAttributes: item?.customAttributes,
          })) || []

        const { data: matchedProducts } = await client.query({
          query: GET_PRODUCTS_BY_HANDLE(mappedLineItems.map(product => product?.handle)),
          variables: {
            firstCollections: 0,
            firstImages: 0,
            firstMedia: 0,
            metafieldIdentifiers: { namespace: "", key: "" },
            firstVariants: 100,
          },
        })

        const migratedLineItems =
          mappedLineItems.map(lineItem => ({
            variantId: matchedProducts[`product${lineItem?.handle?.replace(/-/g, "")}`]?.variants?.edges
              ?.filter(({ node }) => node?.sku === lineItem?.sku)
              .map(({ node }) => node?.id)[0],
            quantity: lineItem?.quantity,
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
          })) || []

        const {
          data: { checkoutCreate: data },
        } = await checkoutCreate({
          variables: {
            countryCode,
            input: {
              ...(email && { email }),
              ...(note && { note }),
              ...(customAttributes && {
                customAttributes:
                  customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              }),
              buyerIdentity: {
                countryCode,
              },
              lineItems: migratedLineItems,
              shippingAddress: shippingAddress || undefined,
            },
          },
        })
        if (!discounts.length) setCheckout(checkoutNormaliser(data?.checkout))
        if (discounts.length) applyDiscounts()
        getShop()
        handleLogout()
      } else {
        createCheckout(null, store.siteLocation, true)
      }
    },
    [
      checkout,
      createCheckout,
      checkoutCreate,
      checkoutNormaliser,
      setCheckout,
      applyDiscounts,
      handleLogout,
      getShop,
      client,
      GET_PRODUCTS_BY_HANDLE,
      keys,
      store,
      storage,
    ]
  )

  const updateCurrency = useCallback(
    async (countryCode: string) => {
      storage.set(keys.market, countryCode)
      await duplicateCheckout(countryCode)
      getShop()
      window.location.reload()
    },
    [duplicateCheckout, getShop, keys.market, storage]
  )

  const count = checkout?.lineItems?.reduce((count, lineItem, i) => (i ? count + parseInt(lineItem.quantity) : parseInt(lineItem.quantity)), 0) || 0
  const empty = checkout?.lineItems && checkout?.lineItems?.length === 0

  return {
    checkout,
    clientId,
    createCheckout,
    getCheckout,
    setCheckout,
    migrateCheckout,
    updateCurrency,
    updateAttributes,
    updateShippingAddress,
    updateEmail,
    updateCustomer,
    applyDiscountCode,
    applyGiftCardCode,
    getShippingRates,
    count,
    empty,
  }
}
