import React, { memo, useCallback, useMemo } from "react"
import { useApp } from "../../../../../hooks/useApp"

export const withSubMenuNavigation = Component =>
  memo(({ name = "SubMenuNavigation", primary, bounds, multiColourText, title, menuKey, isMobile, handleMobileMenu, isLast }: any) => {
    const { globalState } = useApp()
    const [state, dispatch] = globalState

    const handleSubnav = useCallback(() => {
      dispatch({ type: "setActiveMenu", payload: menuKey !== state?.activeMenu ? menuKey : false })
    }, [menuKey, state, dispatch])

    const handleClose = useCallback(() => {
      state?.activeSearch && dispatch({ type: "setActiveSearch", payload: false })
      state?.activeMenu && dispatch({ type: "setActiveMenu", payload: false })
      handleMobileMenu(false)
    }, [state, dispatch, handleMobileMenu])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          primary={primary}
          bounds={bounds}
          showSubMenu={state?.activeMenu === menuKey}
          handleSubnav={handleSubnav}
          handleClose={handleClose}
          multiColourText={multiColourText}
          title={title}
          activeSearch={state?.activeSearch}
          isMobile={isMobile}
          handleMobileMenu={handleMobileMenu}
          isLast={isLast}
        />
      ),
      [primary, bounds, handleSubnav, multiColourText, title, menuKey, handleClose, state, isMobile, handleMobileMenu, isLast]
    )
  })
