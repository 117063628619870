import React, { memo, useCallback, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCore } from "../../../hooks/useCore"
import { useTemplate } from "../../../hooks/useTemplate"

export const withQuizInfo = Component =>
  memo(({ name = "QuizInfo" }: any) => {
    const { activeQuizInfo: type, headerBounds, setActiveQuizInfo: setActive } = useApp()
    const { global, quiz } = useTemplate()
    const {
      helpers: { sanityContent },
    } = useCore()

    const active = type !== null

    const locales = useMemo(
      () => ({
        ...global,
        ...quiz,
        additionalSummarySensitivityFilterPopupContent: sanityContent(quiz?.additionalSummarySensitivityFilterPopupContent),
      }),
      [global, quiz, sanityContent]
    )

    const handleActive = useCallback(() => setActive(null), [setActive])

    Component.displayName = name
    return useMemo(
      () => <Component active={active} bounds={headerBounds} handleActive={handleActive} locales={locales} />,
      [active, headerBounds, handleActive, locales]
    )
  })
