import React, { useCallback, useEffect, useState } from "react"

import { useApp } from "../hooks/useApp"
import { useCore } from "../hooks/useCore"
import { useCheckoutContext } from "../hooks/useCheckout"
import { useShopify } from "../hooks/useShopify"

export const WishlistContext: any = React.createContext({})

export const WishlistProvider = ({ children }): JSX.Element => {
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { decodeBase64, storage },
    graphql: {
      queries: { GET_PRODUCTS_BY_HANDLE },
    },
  } = useCore()
  const { countryCode } = useCheckoutContext()
  const { client, productNormaliser } = useShopify()
  const savedWishlist = storage.get(keys?.wishlist)

  const [added, setAdded] = useState(null)
  const [scroll, setScroll] = useState(null)
  const [wishlist, setWishlist] = useState([])
  const [sharedWishlist, setSharedWishlist] = useState([])

  const getWishlist = useCallback(
    async items => {
      const products = items?.filter(({ handle }) => handle) || []
      const productsWithoutData = products?.filter(({ variants }) => !(variants?.length > 0))

      if (productsWithoutData?.length > 0) {
        const { data: wishlistLiveData } = await client.query({
          query: GET_PRODUCTS_BY_HANDLE(products?.map(product => product?.handle)),
          variables: {
            countryCode,
            firstCollections: 0,
            firstImages: 3,
            firstMedia: 0,
            metafieldIdentifiers: { namespace: "", key: "" },
            firstVariants: 100,
          },
        })

        const items = products?.filter(product => wishlistLiveData[`product${product?.handle?.replace(/-/g, "")}`]?.handle)

        setWishlist([
          ...(items?.map(product => ({
            ...productNormaliser(wishlistLiveData[`product${product?.handle?.replace(/-/g, "")}`]),
          })) || []),
        ])
      }
    },
    [client, GET_PRODUCTS_BY_HANDLE, setWishlist, productNormaliser, countryCode]
  )

  const getSharedWishlist = useCallback(
    async shared => {
      const items = shared
        ? decodeBase64(shared)
            .split("|")
            .map(item => ({
              handle: item.split(":")[0],
              selectedSku: item.split(":")[1],
            }))
        : null

      const { data: wishlistLiveData } = await client.query({
        query: GET_PRODUCTS_BY_HANDLE(items?.map(product => product?.handle)),
        variables: {
          firstCollections: 0,
          firstImages: 3,
          firstMedia: 0,
          metafieldIdentifiers: { namespace: "", key: "" },
          firstVariants: 100,
        },
      })

      setSharedWishlist(
        items?.map(item => ({
          ...item,
          ...productNormaliser(wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`]),
        }))
      )
    },
    [decodeBase64, client, GET_PRODUCTS_BY_HANDLE, setSharedWishlist, productNormaliser]
  )

  // initialize wishlist, only run once when first load
  useEffect(() => {
    setWishlist(savedWishlist)
    if (savedWishlist?.length) {
      getWishlist(savedWishlist)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // intentionally only run once on wishlist contents change
  useEffect(() => {
    storage.set(keys?.wishlist, wishlist || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlist])

  const values = {
    added,
    setAdded,
    scroll,
    setScroll,
    wishlist,
    setWishlist,
    sharedWishlist,
    setSharedWishlist,
    getWishlist,
    getSharedWishlist,
  }

  return <WishlistContext.Provider value={{ ...values }}>{children}</WishlistContext.Provider>
}
