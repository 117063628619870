import React from "react"
import { Sensors } from "@usereactify/search"

import { withSearchForm } from "./withSearchForm"
import { Icon } from "../../Icon/Icon"
import { SearchFormResults } from "./Results/SearchFormResults"
import { StyledContainer } from "../../Styled/Container"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { SearchWrapper, InputWrapper, IconWrapper, Link } from "./styledSearchForm"

export const SearchForm = withSearchForm(
  ({ active, bounds, handleClose, handleSubmit, locales, setSearchTerm, searchTerm }): JSX.Element => (
    <>
      <Sensors />
      <SearchWrapper active={active} height={bounds?.height} onSubmit={handleSubmit}>
        <StyledContainer width={`lg`}>
          <StyledRow gutter={`sm`} items={`center`}>
            <StyledColumn gutter={`sm`} width={`grow`}>
              <InputWrapper>
                <input
                  type="text"
                  name="q"
                  onChange={event => setSearchTerm(event.target.value)}
                  placeholder={locales?.additionalSearchPlaceholder}
                  value={searchTerm}
                />
                <IconWrapper type={`submit`} title={locales?.additionalSearch}>
                  <Icon colour={`grey-darker`} name={`search`} />
                </IconWrapper>
              </InputWrapper>
            </StyledColumn>
            <StyledColumn gutter={`sm`} width={`no-shrink`}>
              <Link colour={`white`} onClick={handleClose} title={locales?.additionalClose}>
                <Icon name={`close`} />
              </Link>
            </StyledColumn>
          </StyledRow>
        </StyledContainer>
      </SearchWrapper>
      <SearchFormResults handleSubmit={handleSubmit} />
    </>
  )
)
