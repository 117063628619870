import { useStaticQuery, graphql } from "gatsby"

import { UseSettings } from "../types/hooks"

export const useRelated = (): UseSettings.Response =>
  useStaticQuery(graphql`
    query {
      products: allSanityProduct(filter: { shopify: { shopifyDeleted: { ne: true }, shopifyPublished: { eq: true } } }) {
        edges {
          node {
            id: _id
            shopify {
              handle: shopifyHandle
              id: shopifyId
            }
            content {
              related: _rawRelated(resolveReferences: { maxDepth: 2 })
            }
          }
        }
      }
    }
  `)
