import React, { memo, useMemo } from "react"

import { useCustomerContext, useCustomerLogout } from "../../../hooks/useCustomer"
import { useRoutes } from "../../../hooks/useRoutes"
import { useTemplate } from "../../../hooks/useTemplate"

export const withAccountWidget = Component =>
  memo(({ name = "AccountWidget", screen = null }: any) => {
    const { customer } = useCustomerContext()
    const { handleLogout } = useCustomerLogout()
    const { routeResolver } = useRoutes()
    const { account, global } = useTemplate()

    const locales = useMemo(
      () => ({
        ...account,
        ...global,
      }),
      [account, global]
    )

    const link = customer ? routeResolver({ type: `dashboard` }) : routeResolver({ type: `login` })

    Component.displayName = name
    return useMemo(
      () => <Component customer={customer} handleLogout={handleLogout} link={link} locales={locales} screen={screen} />,
      [customer, handleLogout, link, locales, screen]
    )
  })
