import { useState, useEffect, useCallback, useMemo, useRef } from "react"
import { navigate } from "gatsby"
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"

import * as Shopify from "../types/shopify"
import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useCheckoutContext } from "./useCheckout"
import { useLocation } from "./useLocation"
import { useRoutes } from "./useRoutes"
import { useShopContext } from "./useShop"

export const useShopify = () => {
  const {
    helpers: { edgeNormaliser },
  } = useApp()
  const client = useApolloClient()
  const { checkout, countryCode } = useCheckoutContext()
  const {
    helpers: { decodeShopifyId, encodeShopifyId, handleize },
  } = useCore()
  const { storeConfig } = useLocation()
  const { routeResolver } = useRoutes()
  const { shop } = useShopContext()

  const productNormaliser = (product: Shopify.Product): Shopify.Product => {
    const result = {
      ...product,
      colour:
        product?.tags
          ?.find((tag: string) => tag?.includes("colour:"))
          ?.replace("colour:", "")
          ?.toLowerCase()
          ?.trim()
          ?.replace(" ", "-") || `purple-dark`,
      collections:
        edgeNormaliser(product?.collections)?.map(collection => ({
          ...collection,
          image: imageNormaliser(collection?.image),
        })) || [],
      description: product?.descriptionHtml?.replace(/(<([^>]+)>)/gi, ""),
      giftcard: product?.productType?.toLowerCase()?.includes(`gift card`),
      handle: product?.handle || product?.shopify?.handle,
      images:
        edgeNormaliser(product?.images)?.length > 0
          ? edgeNormaliser(product?.images)?.map(image => imageNormaliser(image))
          : edgeNormaliser(product?.media)
              ?.filter(({ mediaContentType }) => mediaContentType === "IMAGE")
              ?.map(media => imageNormaliser(media?.image)),
      legacyId: product?.legacyId || product?.shopify?.id,
      media: edgeNormaliser(product?.media)?.map(media =>
        media?.mediaContentType === "VIDEO" ? videoNormaliser(media) : media?.mediaContentType === "IMAGE" ? imageNormaliser(media?.image) : null
      ),
      metafields: edgeNormaliser(product?.metafields),
      models: edgeNormaliser(product?.media)
        ?.filter(({ mediaContentType }) => mediaContentType === "MODEL_3D")
        ?.map(media => modelNormaliser(media)),
      options: [
        ...(edgeNormaliser(product?.grouped)?.reduce(
          (options, grouped) => [
            ...(options?.filter(({ name }) => !grouped?.options?.find(option => option?.name === name)) || []),
            ...(grouped?.options?.filter(({ name }) => !options?.find(option => option?.name === name)) || []),
            ...(options
              ?.filter(({ name }) => grouped?.options?.find(option => option?.name === name))
              ?.map(({ name, values, ...option }) => ({
                ...option,
                name,
                values: [...values, ...(grouped?.options?.find(option => name === option?.name)?.values || [])],
              })) || []),
          ],
          product?.options
        ) ||
          product?.options ||
          []),
      ],
      type:
        product?.tags
          ?.find((tag: string) => tag?.includes("subtitle:"))
          ?.replace("subtitle:", "")
          ?.trim() || null,
      url: routeResolver({
        item: product,
        type: `product`,
      }),
      variants:
        [
          ...edgeNormaliser(product?.variants),
          ...(edgeNormaliser(product?.grouped)?.reduce((variants, grouped) => [...variants, ...edgeNormaliser(grouped?.variants)], []) || []),
        ]?.map(variant => ({
          ...variant,
          image: imageNormaliser(variant?.image),
          legacyId: variant?.legacyId || decodeShopifyId(variant?.id, "ProductVariant"),
          metafields: edgeNormaliser(variant?.metafields),
          presentmentPrices: edgeNormaliser(variant?.presentmentPrices),
          priceV2: priceNormaliser(variant?.presentmentPrices, "price"),
          price: variant?.priceV2,
          compareAtPriceV2: priceNormaliser(variant?.presentmentPrices, "compareAtPrice"),
          compareAtPrice: variant?.compareAtPriceV2,
        })) || [],
      videos: edgeNormaliser(product?.media)
        ?.filter(({ mediaContentType }) => mediaContentType === "VIDEO" || mediaContentType === "EXTERNAL_VIDEO")
        ?.map(media => videoNormaliser(media)),
    }

    return result
  }

  const adminProductNormaliser = product => ({
    ...product,
    availableForSale: product?.variants?.filter(({ available }) => available)?.length > 0,
    id: encodeShopifyId(product?.id, "Product"),
    images: product?.images || [],
    legacyId: product?.id,
    productType: product?.product_type,
    presentmentPriceRanges: {
      minVariantPrice: priceNormaliser(
        product?.presentment_price_ranges?.min_variant_price?.map(({ amount, currency_code }) => ({ amount, currencyCode: currency_code }))
      ),
      maxVariantPrice: priceNormaliser(
        product?.presentment_price_ranges?.max_variant_price?.map(({ amount, currency_code }) => ({ amount, currencyCode: currency_code }))
      ),
    },
    variants: product?.variants?.map(variant => ({
      ...variant,
      availableForSale: variant?.available,
      id: encodeShopifyId(variant?.id, "ProductVariant"),
      legacyId: variant?.id,
      presentmentPrices: adminPresentmentPriceNormaliser(variant?.presentment_prices),
      priceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "price"),
      compareAtPriceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "compareAtPrice"),
    })),
  })

  const adminPresentmentPriceNormaliser = presentment_prices =>
    presentment_prices?.map(presentmentPrice => ({
      compareAtPrice: {
        amount: presentmentPrice?.compare_at_price?.amount,
        currencyCode: presentmentPrice?.compare_at_price?.currency_code,
      },
      price: {
        amount: presentmentPrice?.price?.amount,
        currencyCode: presentmentPrice?.price?.currency_code,
      },
    }))

  const collectionNormaliser = collection => ({
    ...collection,
    image: imageNormaliser(collection?.image),
    metafields: edgeNormaliser(collection?.metafields),
    products: edgeNormaliser(collection?.products).map(product => productNormaliser(product)),
  })

  const imageNormaliser = (image, size = null) => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: imageUrl(image?.originalSrc || image?.src || image?.asset?.url || ``, size),
    srcSet: imageSrcSets(image?.originalSrc || image?.src || image?.asset?.url, size),
  })

  const videoNormaliser = (video, size = null) => ({
    alt: video?.alt || "",
    preview: imageNormaliser(video?.previewImage || ``, size),
    src: video?.sources?.filter(({ format }) => format === `mp4`)?.sort((a, b) => (a.height < b.height ? 1 : -1))?.[0],
  })

  const modelNormaliser = (model, size = null) => ({
    alt: model?.alt || "",
    preview: imageNormaliser(model?.previewImage || ``, size),
    sources: model?.sources,
  })

  const priceNormaliser = (presentmentPrices, field = null) =>
    Object.assign(
      {},
      ...(edgeNormaliser(presentmentPrices)
        ?.filter(
          item =>
            (field ? item[field] : item)?.currencyCode ===
            (checkout?.currencyCode || shop?.paymentSettings?.currencyCode || storeConfig?.siteCurrency)
        )
        .map(item => ({
          ...(field ? item[field] : item),
          amount: Number((field ? item[field] : item)?.amount),
          afterpay: formatMoney((field ? item[field] : item)?.amount / 4),
          local: formatMoney((field ? item[field] : item)?.amount),
        })) || {})
    )

  const checkoutNormaliser = checkout => ({
    ...checkout,
    discountApplications: edgeNormaliser(checkout?.discountApplications),
    lineItems: edgeNormaliser(checkout?.lineItems)
      ?.filter(({ variant }) => variant?.product)
      ?.map((lineItem: any) => ({
        ...lineItem,
        product: productNormaliser({ ...lineItem?.variant?.product, variants: [lineItem?.variant] }),
      })),
  })

  const customerNormaliser = customer => ({
    ...customer,
    addresses: edgeNormaliser(customer?.addresses),
    legacyId: decodeShopifyId(customer?.id, `Customer`),
  })

  const addressNormaliser = ({ id, ...address }, customer = null) => ({
    ...address,
    id,
    legacyId: decodeShopifyId(id, `Address`)?.split(`?`)[0],
    default: id === customer?.defaultAddress?.id,
    url: `${routeResolver({ type: `addresses` })}/${decodeShopifyId(id, `Address`)?.split(`?`)[0]}`,
  })

  const orderNormaliser = ({
    canceledAt,
    currencyCode,
    financialStatus,
    fulfillmentStatus,
    id,
    lineItems,
    name,
    processedAt,
    shippingAddress,
    statusUrl,
    totalPriceV2,
    totalShippingPriceV2,
  }) => ({
    cancelled: canceledAt !== null,
    canceledAt: canceledAt ? new Intl.DateTimeFormat("en-AU", { day: "numeric", month: "long", year: "numeric" }).format(new Date(canceledAt)) : null,
    currencyCode,
    id,
    name: name?.replace(`#`, ``),
    fulfilled: fulfillmentStatus === `FULFILLED`,
    invoice: storeConfig?.orderPrinterToken
      ? `//orderprinterpro.com/download/orders/${storeConfig?.orderPrinterToken}/${
          Number(decodeShopifyId(id, `Order`)?.split(`?key`)?.[0]) * (storeConfig?.orderPrinterIndex || 1)
        }/${handleize(name)}.pdf`
      : null,
    lineItems: edgeNormaliser(lineItems)
      ?.filter(({ variant }) => variant?.product)
      ?.map(({ variant: { product, ...variant }, ...lineItem }) => ({
        ...lineItem,
        ...productNormaliser({ ...product, variants: [variant] }),
        image: imageNormaliser(variant?.image),
      })),
    paid: financialStatus === `PAID`,
    processedAt: processedAt
      ? new Intl.DateTimeFormat("en-AU", { day: "numeric", month: "long", year: "numeric" }).format(new Date(processedAt))
      : null,
    shippingAddress,
    statusUrl,
    subtotal: Number(totalPriceV2?.amount) ? formatMoney(Number(totalPriceV2?.amount) - Number(totalShippingPriceV2?.amount)) : null,
    shipping: Number(totalShippingPriceV2?.amount) ? formatMoney(totalShippingPriceV2?.amount) : null,
    totalAmount: Number(totalPriceV2?.amount) || 0,
    total: Number(totalPriceV2?.amount) ? formatMoney(totalPriceV2?.amount) : null,
    url: `${routeResolver({ type: `orders` })}/${decodeShopifyId(id, `Order`)}`,
  })

  const formatMoney = (amount: number) =>
    `${new Intl.NumberFormat(`en-${checkout?.currencyCode?.slice(0, 2) || shop?.paymentSettings?.countryCode || "AU"}`, {
      style: "currency",
      currency: checkout?.currencyCode || shop?.paymentSettings?.currencyCode || storeConfig?.siteCurrency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(amount)}${checkout?.currencyCode !== shop?.paymentSettings?.currencyCode ? ` ${checkout?.currencyCode}` : ``}`

  const imageUrl = (src, size = null): any => {
    const dimensions = `${size}x${size}`
    const match = src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i)

    return match && src?.includes(`shopify.com`) && size && size !== `master`
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, ``)
      : src
  }

  const imageSrcSets = (src, size) =>
    src?.includes(`shopify.com`) &&
    [1, 500, 1000, 1500, 2000]
      .filter(set => !size || (size && size >= set))
      .map(set => `${imageUrl(src, set)} ${set}w`)
      .join(`,`)

  const onSale = (price, compareAtPrice) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = item => item?.handle || item?.shopify?.shopifyHandle

  const useCollections = ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = { namespace: "", key: "" },
    firstProducts = 0,
    firstVariants = 0,
    handles = [],
  }): Shopify.Collection[] => {
    const {
      graphql: {
        queries: { GET_COLLECTIONS_BY_HANDLE },
      },
    } = useCore()
    const [items, setItems] = useState(null)

    const fetchItems = useCallback(
      async handles => {
        if (handles?.length > 0 && items === null) {
          const { data } = await client.query({
            query: GET_COLLECTIONS_BY_HANDLE(handles),
            variables: {
              countryCode,
              firstCollections,
              firstImages,
              firstMedia,
              metafieldIdentifiers,
              firstProducts,
              firstVariants,
              handles,
            },
          })

          setItems(handles?.map(handle => collectionNormaliser(data[`collection${handle?.replace(/-/g, "")}`])))
        }
      },
      [GET_COLLECTIONS_BY_HANDLE, firstCollections, firstImages, firstMedia, metafieldIdentifiers, firstProducts, firstVariants, items]
    )

    useEffect(() => {
      fetchItems(handles)
    }, [fetchItems, handles])

    return items
  }

  const useProducts = ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = { namespace: "", key: "" },
    firstVariants = 0,
    handles = [],
  }) => {
    const {
      graphql: {
        queries: { GET_PRODUCTS_BY_HANDLE },
      },
    } = useCore()
    const [items, setItems] = useState(null)

    const fetchItems = useCallback(
      async (handles: Array<string>) => {
        if (handles?.length > 0 && items === null) {
          const { data } = await client.query({
            query: GET_PRODUCTS_BY_HANDLE(handles),
            variables: {
              countryCode,
              firstCollections,
              firstImages,
              firstMedia,
              metafieldIdentifiers,
              firstVariants,
            },
          })

          setItems(
            handles
              ?.filter(handle => data[`product${handle?.replace(/-/g, "")}`])
              ?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
          )
        }
      },
      [GET_PRODUCTS_BY_HANDLE, items, firstCollections, firstImages, firstMedia, metafieldIdentifiers, firstVariants]
    )

    useEffect(() => {
      fetchItems(handles)
    }, [fetchItems, handles])

    return items
  }

  const useCartRecommendations = ({
    products,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = { namespace: "", key: "" },
    firstCollections = 0,
    firstVariants = 0,
  }) => {
    const {
      graphql: {
        queries: { GET_PRODUCT_RECOMMENDATIONS },
      },
    } = useCore()
    // const recommendations = { primary: [], secondary: [], tertiary: [] }
    const [recommendations, setRecommendations] = useState(null)
    const fetchItems = useCallback(async () => {
      if (products && recommendations === null) {
        const lineItemRecommendations = []
        products.forEach(async lineItem => {
          const id = lineItem.product.id
          const { data } = await client.query({
            query: GET_PRODUCT_RECOMMENDATIONS,
            variables: {
              countryCode,
              id,
              firstCollections,
              firstImages,
              firstMedia,
              metafieldIdentifiers,
              firstVariants,
            },
          })

          lineItemRecommendations.push(edgeNormaliser(data?.recommendations)?.map(item => productNormaliser(item)))
        })
        setRecommendations(lineItemRecommendations)
      }
    }, [GET_PRODUCT_RECOMMENDATIONS, recommendations, products])

    useEffect(() => {
      fetchItems()
    }, [fetchItems])

    return recommendations
  }

  const useProductRecommendations = ({
    id,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = { namespace: "", key: "" },
    firstCollections = 0,
    firstVariants = 0,
  }) => {
    const {
      graphql: {
        queries: { GET_PRODUCT_RECOMMENDATIONS },
      },
    } = useCore()
    const [items, setItems] = useState(null)

    const fetchItems = useCallback(async () => {
      if (id && items === null) {
        const { data } = await client.query({
          query: GET_PRODUCT_RECOMMENDATIONS,
          variables: {
            countryCode,
            id,
            firstCollections,
            firstImages,
            firstMedia,
            metafieldIdentifiers,
            firstVariants,
          },
        })

        setItems(edgeNormaliser(data?.recommendations)?.map(item => productNormaliser(item)))
      }
    }, [GET_PRODUCT_RECOMMENDATIONS, items, id, firstCollections, firstImages, firstMedia, metafieldIdentifiers, firstVariants])

    useEffect(() => {
      fetchItems()
    }, [fetchItems])

    return items
  }

  const usePopularProducts = ({
    first = 6,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = { namespace: "", key: "" },
    firstCollections = 0,
    firstVariants = 0,
  }) => {
    const {
      graphql: {
        queries: { GET_POPULAR_PRODUCTS },
      },
    } = useCore()
    const [items, setItems] = useState(null)

    const fetchItems = useCallback(async () => {
      if (items === null) {
        const { data } = await client.query({
          query: GET_POPULAR_PRODUCTS,
          variables: {
            countryCode,
            first,
            firstCollections,
            firstImages,
            firstMedia,
            metafieldIdentifiers,
            firstVariants,
          },
        })

        setItems(edgeNormaliser(data?.products)?.map(item => productNormaliser(item)))
      }
    }, [GET_POPULAR_PRODUCTS, items, first, firstCollections, firstImages, firstMedia, metafieldIdentifiers, firstVariants])

    useEffect(() => {
      fetchItems()
    }, [fetchItems])

    return items
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    onSale,
    imageUrl,
    imageSrcSets,
    formatMoney,
    imageNormaliser,
    checkoutNormaliser,
    priceNormaliser,
    productNormaliser,
    adminProductNormaliser,
    collectionNormaliser,
    customerNormaliser,
    addressNormaliser,
    orderNormaliser,
    getHandle,
    useCollections,
    useProducts,
    useCartRecommendations,
    useProductRecommendations,
    usePopularProducts,
  }
}

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct } = useApp()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes("products")) {
        if (!activeProduct) setActiveProduct(product)
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct]
  )

  return { activeProduct, selectProduct }
}

export const useShopifyVariants = ({ firstAvailable = true, useParameter = false, loading = false, product }) => {
  const { trackViewItem } = useAnalytics()
  const {
    activeProduct,
    activeVariant,
    setActiveVariant,
    shop,
    config: {
      settings: { params },
    },
  } = useApp()
  const { checkout } = useCheckoutContext()
  const {
    helpers: { encodeShopifyId, decodeShopifyId },
  } = useCore()
  const { getUrlParameter, setUrlParameter } = useRoutes()
  const { id, variants } = product || {}

  const currentVariant = getUrlParameter(params?.variant)
  const defaultVariant =
    (useParameter && variants?.find(({ id }) => id.includes(currentVariant))) ||
    (firstAvailable && variants?.find(({ availableForSale }) => availableForSale)) ||
    variants[0]

  const [selectedOptions, setSelectedOptions] = useState([])
  const prevVariant = useRef({ variant: activeVariant }).current

  useEffect(() => {
    if (activeVariant && activeVariant.id !== prevVariant?.variant?.id) {
      trackViewItem(activeProduct, activeVariant)
    }
    return () => {
      prevVariant.variant = activeVariant
    }
  }, [activeVariant])

  const handleVariant = useCallback(
    option => {
      setSelectedOptions(selectedOptions.map(selectedOption => (selectedOption.name === option.name ? option : selectedOption)))
    },
    [selectedOptions, setSelectedOptions]
  )

  useEffect(() => {
    if ((checkout?.currencyCode || shop?.paymentSettings?.currencyCode) && !loading && selectedOptions?.length === 0) {
      setSelectedOptions(defaultVariant?.selectedOptions)
    }
  }, [checkout, id, loading, shop, variants, defaultVariant, selectedOptions])

  // intentionally exclude variants from dependencies
  useEffect(() => {
    if (selectedOptions?.length > 0) {
      setActiveVariant(
        variants.find(
          ({ selectedOptions: variantOptions }) =>
            variantOptions?.filter(
              variantOption => variantOption.value === selectedOptions.find(selectedOption => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  useEffect(() => {
    if (activeVariant?.id && activeVariant?.priceV2?.currencyCode && activeVariant?.priceV2?.currencyCode !== checkout?.currencyCode) {
      setActiveVariant((prevState: any) => variants.find(({ id }) => id === prevState?.id) || null)
    }
  }, [activeVariant, checkout, setActiveVariant, variants])

  useEffect(() => {
    if (useParameter && activeVariant?.id && !loading && selectedOptions?.length > 0) {
      if (currentVariant !== encodeShopifyId(activeVariant.id, "ProductVariant") && activeVariant.id !== defaultVariant.id) {
        navigate(setUrlParameter(params?.variant, decodeShopifyId(activeVariant.id, "ProductVariant")), { replace: true })
      }
    }
  }, [
    activeVariant,
    defaultVariant,
    currentVariant,
    decodeShopifyId,
    encodeShopifyId,
    loading,
    params,
    selectedOptions,
    setUrlParameter,
    useParameter,
    trackViewItem,
    activeProduct,
  ])

  return { activeVariant, handleVariant, selectedOptions }
}

export const useShopifyPrice = (variant: any, giftcard: boolean) => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()

  return useMemo(() => {
    if (!variant || !checkout?.currencyCode) {
      return {
        onSale: false,
        price: undefined,
        currencyCode: "AUD",
        formattedPrice: undefined,
        compareAtPrice: undefined,
        installmentPrice: undefined,
        formattedCompareAtPrice: undefined,
        formattedInstallmentPrice: undefined,
      }
    }

    const currencyCode = checkout?.currencyCode ?? "AUD"
    const price = variant?.price?.amount ? Number(variant?.price?.amount) : 0
    const compareAtPrice =
      variant?.compareAtPrice?.amount || variant?.compareAtPriceV2?.amount
        ? Number(variant?.compareAtPrice?.amount || variant?.compareAtPriceV2?.amount)
        : 0
    const origCompareAtPrice = variant?.compareAtPrice?.amount || variant?.compareAtPriceV2?.amount
    const installmentPrice = price / 4
    const onSale = !!compareAtPrice && !!price && (giftcard ? price > compareAtPrice : compareAtPrice > price)
    const formattedPrice = formatMoney(price)
    const formattedCompareAtPrice = formatMoney(compareAtPrice)
    const formattedInstallmentPrice = formatMoney(installmentPrice)

    return {
      price,
      onSale,
      currencyCode,
      compareAtPrice,
      installmentPrice,
      formattedPrice,
      formattedCompareAtPrice,
      formattedInstallmentPrice,
    }
  }, [variant, checkout?.currencyCode, formatMoney, giftcard])
}

export const useShopifyPriceRange = (product: any, separator = " to ") => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()
  const {
    helpers: { formatPrice },
  } = useCore()

  return useMemo(() => {
    if (!product || !checkout?.currencyCode) {
      return {
        priceMin: undefined,
        priceMax: undefined,
        currencyCode: "AUD",
        formattedPriceRange: undefined,
      }
    }

    const currencyCode = checkout?.currencyCode ?? "AUD"
    const priceRangeMin = product?.priceRange?.minVariantPrice || 0
    const priceRangeMax = product?.priceRange?.maxVariantPrice || 0
    const priceMin = priceRangeMin?.amount ? Number(priceRangeMin?.amount) : 0
    const priceMax = priceRangeMax?.amount ? Number(priceRangeMax?.amount) : 0
    const formattedPriceRange =
      priceMin < priceMax && priceMin > 0 ? `${formatMoney(priceMin)}${separator}${formatPrice(`${priceMax}`)}` : formatMoney(priceMax)

    return {
      priceMin,
      priceMax,
      currencyCode,
      formattedPriceRange,
    }
  }, [product, checkout?.currencyCode, separator, formatMoney, formatPrice])
}
