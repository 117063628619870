import React from "react"

import { withFooter } from "./withFooter"
import { Icon } from "../Icon/Icon"
import { FooterAcknowledgement } from "./Acknowledgement/FooterAcknowledgement"
import { FooterNavigation } from "./Navigation/FooterNavigation"
import { FooterPayments } from "./Payments/FooterPayments"
import { FooterSocial } from "./Social/FooterSocial"
import { FooterSubscribe } from "./Subscribe/FooterSubscribe"
import { StyledContainer } from "../Styled/Container"
import { StyledColumn } from "../Styled/Column"
import { StyledRow } from "../Styled/Row"
import { Small } from "../Styled/Text"
import { StyledFooter, Upper, Lower, Logo, Row, Column } from "./styledFooter"

export const Footer = withFooter(
  ({ title, year }): JSX.Element => (
    <StyledFooter>
      <Upper>
        <StyledContainer width={`xl`}>
          <Logo>
            <Icon name={`logo`} width={190} withSpacing={`md`} />
          </Logo>
          <StyledRow gutter={`lg`} items={`stretch`} withWrap>
            <StyledColumn gutter={`lg`} width={`lg:3/4`}>
              <FooterNavigation />
            </StyledColumn>
            <StyledColumn gutter={`lg`} items={`start`} justify={`between`} vertical width={`lg:1/4`}>
              <FooterSubscribe />
              <FooterSocial />
            </StyledColumn>
          </StyledRow>
        </StyledContainer>
      </Upper>
      <Lower>
        <StyledContainer width={`xl`}>
          <Row items={`center`} withWrap>
            <StyledColumn width={`md:1/3`}>
              <Small large>
                &copy; {year} {title}
              </Small>
            </StyledColumn>
            <Column items={`center`} justify={`center`} width={`md:2/3`}>
              <FooterPayments />
            </Column>
          </Row>
        </StyledContainer>
        <StyledContainer width={`xl`}>
          <Row items={`center`} withWrap>
            <StyledColumn width={`md:1/3`}>
              <FooterAcknowledgement />
            </StyledColumn>
          </Row>
        </StyledContainer>
      </Lower>
    </StyledFooter>
  )
)
