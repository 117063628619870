import { useContext, useState, useCallback } from "react"
import { LoyaltyContext } from "../providers/loyalty"
import { useApp } from "./useApp"

export const useLoyaltyContext = () => {
  const loyalty: any = useContext(LoyaltyContext)

  return { ...loyalty }
}

export const useLoyalty = () => {
  const { setUserData, getUserData, userData } = useLoyaltyContext()
  const { setActiveAnimationPopupLoyalty }: { setActiveAnimationPopupLoyalty: any } = useApp()
  const handleAnimationPopup = useCallback((yotpoUserData: any) => setActiveAnimationPopupLoyalty(yotpoUserData), [setActiveAnimationPopupLoyalty])

  return {
    handleAnimationPopup,
    userData,
    setUserData,
    getUserData,
  }
}
