import tw, { css, styled } from "twin.macro"

import { StyledButton } from "../../../Styled/Button"
import { H3, RichText } from "../../../Styled/Text"

export const Notify = styled.section`
  ${tw`fixed inset-0 flex items-center justify-center z-20 invisible opacity-0 bg-white bg-opacity-70 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100`}
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
`
export const Popup = styled.div`
  ${tw`relative w-full bg-white text-black rounded-lg shadow px-12 pb-12 pt-16 overflow-x-hidden overflow-y-auto max-h-full invisible transform scale-90 translate-y-6 transition-all duration-200`}
  ${({ active }) => active && tw`visible scale-100 translate-y-0`}
`
export const Inner = tw.div`flex items-center justify-center w-full h-full p-6 w-200 max-w-full`
export const Heading = tw(H3)`text-orange mb-10`
export const Content = tw(RichText)`md:text-2xl md:leading-tighter`
export const Button = tw(StyledButton)`my-10 md:font-bold md:min-w-55 md:w-auto`
