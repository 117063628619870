import tw, { css, styled } from "twin.macro"

const styles = {
  position: {
    mobile: css`
      display: flex;
      flex-direction: row;
      min-width: 300px;
      max-width: 300px;
      height: 83px;
      background-color: white;
      margin-right: 1.5em;
      padding: 0.25em;
    `,
    desktop: tw`p-1.5 pb-3 bg-white mb-10 flex flex-col items-center`,
  },
  image: {
    mobile: tw`pr-3 h-full`,
    desktop: tw`pb-1.5`,
  },
  button: {
    default: tw`bg-white border rounded-3xl text-purple-dark border-purple-dark hover:bg-purple-dark hover:text-white `,
    mobile: css`
      width: 85px;
      padding: 4px;
      font-size: 10px;
    `,
    desktop: tw`px-2.5 py-1 text-xs mt-3`,
  },
  text: {
    smDefault: tw`text-xs font-normal text-center`,
    wrap: tw`whitespace-nowrap`,
  },
}

export const Slide = styled.div`
  ${({ isMobile }) => (isMobile ? styles.position.mobile : styles.position.desktop)}
`
export const StyledImage = styled.img`
  ${({ isMobile }) => (isMobile ? styles.image.mobile : styles.image.desktop)}
`
export const Button = styled.button`
  ${styles.button.default}
  ${({ isMobile }) => (isMobile ? styles.button.mobile : styles.button.desktop)}
`
export const LargeText = tw.p`text-sm font-semibold text-center`
export const SmallText = styled.p`
  ${styles.text.smDefault}
  ${({ isMobile }) => isMobile && styles.text.wrap}
`
export const PrimaryContainer = tw.div`flex flex-col justify-between`
export const SecondaryContainer = tw.div`flex flex-col items-start`
export const Content = tw.div`flex flex-row flex-grow justify-between py-0.5`
export const AnchorWrapper = tw.a`hover:cursor-pointer`
