import React, { memo, useMemo } from "react"

import { useNavigationTertiary } from "../../../../hooks/useNavigationTertiary"

export const withHeaderNavigationTertiary = Component =>
  memo(({ name = "HeaderNavigationTertiary" }: any) => {
    const { tertiary: items } = useNavigationTertiary()

    Component.displayName = name
    return useMemo(() => (items?.length > 0 ? <Component items={items} /> : null), [items])
  })
