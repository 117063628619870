import React, { memo, useCallback, useMemo } from "react"
import { useNavigationHeader } from "../../../../hooks/useNavigationHeader"
import { useNavigationSecondary } from "@app/hooks/useNavigationSecondary"
import { useApp } from "../../../../hooks/useApp"

export const withHeaderNavigationMain = Component =>
  memo(({ name = "HeaderNavigationMain", bounds, showMobileMenu, handleMobileMenu }: any) => {
    const { header: headerItems } = useNavigationHeader()
    const { globalState } = useApp()
    const [state, dispatch] = globalState
    const { secondary } = useNavigationSecondary()

    const handleCloseSearch = useCallback(() => {
      state?.activeSearch && dispatch({ type: "setActiveSearch", payload: false })
    }, [state, dispatch])

    const setActiveMenuKey = useCallback(
      key => {
        dispatch({ type: "setActiveMenu", payload: key })
      },
      [dispatch]
    )

    const handleClose = useCallback(() => {
      state?.activeSearch && dispatch({ type: "setActiveSearch", payload: false })
      state?.activeMenu && dispatch({ type: "setActiveMenu", payload: false })
      state?.activeSubMenu && dispatch({ type: "setActiveSubMenu", payload: false })
      handleMobileMenu(false)
    }, [state, dispatch, handleMobileMenu])

    Component.displayName = name
    return useMemo(
      () =>
        headerItems?.length > 0 ? (
          <Component
            headerItems={headerItems}
            bounds={bounds}
            activeSearch={state?.activeSearch}
            handleCloseSearch={handleCloseSearch}
            handleClose={handleClose}
            isMobile={bounds?.header?.width <= 1023}
            headerHeight={bounds?.header?.height}
            showMobileMenu={showMobileMenu}
            setActiveMenuKey={setActiveMenuKey}
            handleMobileMenu={handleMobileMenu}
            secondary={secondary}
          />
        ) : null,
      [headerItems, bounds, state, handleCloseSearch, showMobileMenu, setActiveMenuKey, handleClose, handleMobileMenu, secondary]
    )
  })
