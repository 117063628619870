import { useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useFunctions } from "./useFunctions"
import { useLocation } from "./useLocation"

const INITIAL_STATE = {
  name: "",
  email: "",
  phone: "",
  type: "",
  option: "",
  message: "",
}

export const useForm = () => {
  const { userData } = useLocation()
  const { callFunction } = useFunctions()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState(INITIAL_STATE)
  const [success, setSuccess] = useState(false)

  const { forms } = useStaticQuery(graphql`
    query {
      forms: allSanitySettingForms {
        edges {
          node {
            id: _id
            title
            slug {
              current
            }
            subject
            types {
              title
              recipients
              options
            }
          }
        }
      }
    }
  `)

  const submitForm = useCallback(
    async (form, data) => {
      setLoading(true)
      setErrors([])

      const { ipData, userAgent } = await userData()
      const id: string = form?.id
      const type: string = form?.types[data?.type?.toLowerCase()]?.title.toLowerCase()
      const subject: string = form?.subject || `Form submission from ${form?.title}`
      const { status, body } = await callFunction("form-submission", { id, type, subject, data, ipData, userAgent })

      if (status === "error") setErrors([{ code: status, message: body }])
      if (status === "success") {
        setData(INITIAL_STATE)
        setSuccess(true)
      }

      setLoading(false)
    },
    [setLoading, setErrors, setData, setSuccess, userData, callFunction]
  )

  const getForm = slug => {
    const item =
      forms?.edges
        ?.filter(({ node }) => node?.slug?.current === slug)
        .map(({ node }) => ({
          ...node,
          slug: node?.slug?.current,
          types: node?.types.map(type => ({ ...type, id: type?.title?.toLowerCase() })),
        }))[0] || false

    if (item && !data?.type)
      setData((prevState: any) => ({
        ...prevState,
        type: item?.types?.[0]?.id,
      }))

    return item
  }

  return { getForm, submitForm, data, setData, loading, errors, success }
}
