import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withCartListItem } from "./withCartListItem"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import { StyledImage } from "../../../Styled/Image"
import { StyledRow } from "../../../Styled/Row"
import { Small, P, H5, H6, Notification } from "../../../Styled/Text"
import { Item, ImageContainer, AspectRatio, Link, Button, BundleText, Text, Remove, Title, ComparedPrice } from "./styledCartListItem"

export const CartListItem = withCartListItem(
  ({
    colour,
    drawer,
    gift,
    handleQuantity,
    handleRemove,
    image,
    item,
    link,
    loading,
    locales,
    message,
    compareAtPrice,
    price,
    quantity,
    variant,
    pickGiftBundleName,
    pickGiftBundle,
    pickGiftGroupType,
    pickGiftReviewRoute,
    pickMixBundleName,
    pickMixBundle,
    pickMixReviewRoute,
    groupedInfo,
    index,
  }): JSX.Element => (
    <Item disabled={loading && !quantity} index={index}>
      {drawer ? (
        <StyledRow gutter={`xs`} items={`stretch`}>
          <StyledColumn gutter={`xs`} width={`1/3`}>
            <ImageContainer
              colour={`${colour}-pastel`}
              title={item?.title}
              to={pickMixBundle && !pickGiftGroupType ? pickMixReviewRoute : pickGiftBundle && pickGiftGroupType ? pickGiftReviewRoute : link}
            >
              {image ? (
                <StyledImage alt={item?.variant?.image?.altText || item?.variant?.title} fluid={{ ...image, aspectRatio: 1 / 1 }} />
              ) : (
                <AspectRatio />
              )}
            </ImageContainer>
          </StyledColumn>
          <StyledColumn justify={`between`} vertical gutter={`xs`} width={`2/3`}>
            <StyledRow gutter={`xs`} items={`start`}>
              <StyledColumn gutter={`xs`} width={`grow`}>
                {pickMixBundle && !pickGiftGroupType ? (
                  <Link as={GatsbyLink} title={item?.title} to={pickMixReviewRoute}>
                    <Title large>{item.title}</Title>
                  </Link>
                ) : pickGiftBundle && pickGiftGroupType ? (
                  <Link as={GatsbyLink} title={item?.title} to={pickGiftReviewRoute}>
                    <Title large>{item.title}</Title>
                  </Link>
                ) : (
                  <Link as={GatsbyLink} title={item?.title} to={link}>
                    <Title large>{item.title}</Title>
                  </Link>
                )}

                {(item?.product?.type || item?.variant?.title) && (
                  <>
                    <Small large withSpacing={`xxs`}>
                      {item?.product?.type && <span>{item?.product?.type}</span>}
                      {item?.product?.type && item?.variant?.title && item?.variant?.title?.toLowerCase() !== "default title" && <span>{`, `}</span>}
                      {item?.variant?.title && <span>{item?.variant?.title?.replace("Default Title", "")}</span>}
                    </Small>
                    {groupedInfo.map((info, index) => (
                      <BundleText key={index} withSpacing={`none`}>
                        {info}
                      </BundleText>
                    ))}
                    <Small large withSpacing={`xs`}></Small>
                  </>
                )}
              </StyledColumn>
              {!gift && !pickMixBundleName && !pickGiftBundleName && (
                <StyledColumn gutter={`xs`} justify={`end`} width={`no-shrink`}>
                  <Remove colour={`grey-dark`} disabled={loading} drawer onClick={handleRemove} title={locales?.additionalRemove}>
                    <Icon name={`delete`} width={14} />
                  </Remove>
                </StyledColumn>
              )}
            </StyledRow>
            <StyledRow gutter={`xs`} items={`end`}>
              <StyledColumn gutter={`xs`} width={`1/2`}>
                {!gift && !pickMixBundle && !pickGiftBundleName && (
                  <StyledRow gutter={`xxs`} justify={`start`} items={`center`}>
                    <StyledColumn gutter={`xxs`} width={`no-shrink`}>
                      <Button
                        colour={`grey-dark`}
                        disabled={loading || !quantity}
                        onClick={() => handleQuantity(quantity - 1)}
                        title={locales?.additionalDecrease}
                        hidden={pickMixBundleName}
                      >
                        <Icon name={`minus`} width={13} />
                      </Button>
                    </StyledColumn>
                    <StyledColumn gutter={`xxs`} width={`no-shrink`}>
                      <Text colour={`purple-dark`} align={`center`} qty>
                        <strong>{quantity}</strong>
                      </Text>
                    </StyledColumn>
                    <StyledColumn gutter={`xxs`} width={`no-shrink`}>
                      <Button
                        colour={`grey-dark`}
                        disabled={loading}
                        onClick={() => handleQuantity(quantity + 1)}
                        title={locales?.additionalIncrease}
                        hidden={pickMixBundleName}
                      >
                        <Icon name={`plus`} width={13} />
                      </Button>
                    </StyledColumn>
                  </StyledRow>
                )}
                {pickMixBundle && !pickGiftGroupType && (
                  <StyledRow gutter={`xxs`} justify={`center`} items={`center`}>
                    <Link as={GatsbyLink} title={locales?.additionalPickMixEditButton} to={pickMixReviewRoute}>
                      <Text underline>{locales?.additionalPickMixEditButton}</Text>
                    </Link>
                  </StyledRow>
                )}
                {pickGiftBundle && pickGiftGroupType && (
                  <StyledRow gutter={`xxs`} justify={`center`} items={`center`}>
                    <Link as={GatsbyLink} title={locales?.additionalPickGiftEditButton} to={pickGiftReviewRoute}>
                      <Text underline>{locales?.additionalPickGiftEditButton}</Text>
                    </Link>
                  </StyledRow>
                )}
              </StyledColumn>
              <StyledColumn gutter={`xs`} justify={`end`} width={`1/2`}>
                {gift ? (
                  <Text colour={`green`}>{message || locales?.additionalGift}</Text>
                ) : pickMixBundleName ? (
                  <Text colour={`green`}>{locales?.additionalPickMix}</Text>
                ) : pickGiftBundleName ? (
                  <Text colour={`green`}>{locales?.additionalPickGift}</Text>
                ) : (
                  <Text>
                    {item?.product?.giftcard && variant?.compareAtPriceV2?.amount && variant?.priceV2?.amount > variant?.compareAtPriceV2?.amount ? (
                      <>
                        <ComparedPrice>&nbsp;{price}&nbsp;</ComparedPrice>
                        {compareAtPrice}
                      </>
                    ) : (
                      <>
                        {+variant?.compareAtPriceV2?.amount > +variant?.priceV2?.amount ||
                        +variant?.compareAtPrice?.amount > +variant?.price?.amount ? (
                          <ComparedPrice>&nbsp;{compareAtPrice}&nbsp;</ComparedPrice>
                        ) : null}
                        {price}
                      </>
                    )}
                  </Text>
                )}
              </StyledColumn>
            </StyledRow>
          </StyledColumn>
        </StyledRow>
      ) : (
        <>
          <StyledRow gutter={`sm`} items={`center`}>
            <StyledColumn gutter={`sm`} width={`md:1/2`}>
              <StyledRow gutter={`sm`} items={`center`}>
                <StyledColumn gutter={`sm`} width={`no-shrink`}>
                  <ImageContainer
                    colour={`${colour}-pastel`}
                    title={item?.title}
                    to={pickMixBundle && !pickGiftGroupType ? pickMixReviewRoute : pickGiftBundle && pickGiftGroupType ? pickGiftReviewRoute : link}
                    width={`true`}
                  >
                    {image ? (
                      <StyledImage alt={item?.variant?.image?.altText || item?.variant?.title} fluid={{ ...image, aspectRatio: 1 / 1 }} />
                    ) : (
                      <AspectRatio />
                    )}
                  </ImageContainer>
                </StyledColumn>
                <StyledColumn vertical gutter={`sm`} width={`grow`}>
                  {pickMixBundle && !pickGiftGroupType ? (
                    <Link as={GatsbyLink} title={item?.title} to={pickMixReviewRoute}>
                      <H5 uppercase>{item.title}</H5>
                    </Link>
                  ) : pickGiftBundle && pickGiftGroupType ? (
                    <Link as={GatsbyLink} title={item?.title} to={pickGiftReviewRoute}>
                      <H5 uppercase>{item.title}</H5>
                    </Link>
                  ) : (
                    <Link as={GatsbyLink} title={item?.title} to={link}>
                      <H5 uppercase>{item.title}</H5>
                    </Link>
                  )}

                  {(item?.product?.type || item?.variant?.title) && (
                    <>
                      <P withSpacing={`xxs`}>
                        {item?.product?.type && <span>{item?.product?.type}</span>}
                        {item?.product?.type && item?.variant?.title && item?.variant?.title?.toLowerCase() !== "default title" && (
                          <span>{`, `}</span>
                        )}
                        {item?.variant?.title && <span>{item?.variant?.title?.replace("Default Title", "")}</span>}
                      </P>
                      {groupedInfo.map((info, index) => (
                        <BundleText key={index} withSpacing={`none`}>
                          {info}
                        </BundleText>
                      ))}
                    </>
                  )}
                </StyledColumn>
              </StyledRow>
            </StyledColumn>
            <StyledColumn justify={`end`} gutter={`sm`} width={`md:1/4`}>
              {!gift && !pickMixBundle && (
                <StyledRow gutter={`xxs`} justify={`start`} items={`center`}>
                  <StyledColumn gutter={`xxs`} width={`no-shrink`}>
                    <Button
                      colour={`grey-dark`}
                      disabled={loading}
                      onClick={() => handleQuantity(quantity - 1)}
                      title={locales?.additionalDecrease}
                      hidden={pickMixBundleName}
                    >
                      <Icon name={`minus`} width={13} />
                    </Button>
                  </StyledColumn>
                  <StyledColumn gutter={`xxs`} width={`no-shrink`}>
                    <Text colour={`purple-dark`} align={`center`}>
                      <strong>{quantity}</strong>
                    </Text>
                  </StyledColumn>
                  <StyledColumn gutter={`xxs`} width={`no-shrink`}>
                    <Button
                      colour={`grey-dark`}
                      disabled={loading}
                      onClick={() => handleQuantity(quantity + 1)}
                      title={locales?.additionalIncrease}
                      hidden={pickMixBundleName}
                    >
                      <Icon name={`plus`} width={13} />
                    </Button>
                  </StyledColumn>
                </StyledRow>
              )}
              {pickMixBundle && !pickGiftGroupType && (
                <StyledRow gutter={`xxs`} justify={`start`} items={`center`}>
                  <Link as={GatsbyLink} title={locales?.additionalPickMixEditButton} to={pickMixReviewRoute}>
                    <Text underline>{locales?.additionalPickMixEditButton}</Text>
                  </Link>
                </StyledRow>
              )}
              {pickGiftBundle && pickGiftGroupType && (
                <StyledRow gutter={`xxs`} justify={`start`} items={`center`}>
                  <Link as={GatsbyLink} title={locales?.additionalPickGiftEditButton} to={pickGiftReviewRoute}>
                    <Text underline>{locales?.additionalPickGiftEditButton}</Text>
                  </Link>
                </StyledRow>
              )}
            </StyledColumn>
            <StyledColumn justify={`end`} gutter={`sm`} width={`md:1/4`}>
              {gift ? (
                <Notification colour={`green`}>{message || locales?.additionalGift}</Notification>
              ) : pickMixBundleName && !pickGiftGroupType ? (
                <Notification colour={`green`}>{locales?.additionalPickMix}</Notification>
              ) : pickGiftBundleName && pickGiftGroupType ? (
                <Notification colour={`green`}>{locales?.additionalPickGift}</Notification>
              ) : (
                <H6 uppercase>
                  {item?.product?.giftcard && variant?.compareAtPriceV2?.amount && variant?.priceV2?.amount > variant?.compareAtPriceV2?.amount ? (
                    <>
                      <ComparedPrice>&nbsp;{price}&nbsp;</ComparedPrice>
                      {compareAtPrice}
                    </>
                  ) : (
                    <>
                      {+variant?.compareAtPriceV2?.amount > +variant?.priceV2?.amount ||
                      +variant?.compareAtPrice?.amount > +variant?.price?.amount ? (
                        <ComparedPrice>&nbsp; {compareAtPrice} &nbsp;</ComparedPrice>
                      ) : null}
                      {price}
                    </>
                  )}
                </H6>
              )}
            </StyledColumn>
          </StyledRow>
          {!gift && !pickMixBundleName && !pickGiftBundleName && (
            <Remove disabled={loading} onClick={handleRemove} title={locales?.additionalRemove} withSpacing>
              <Icon name={`delete`} width={14} />
            </Remove>
          )}
        </>
      )}
    </Item>
  )
)
