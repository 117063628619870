import React from "react"

import { withFooterAcknowledgement } from "./withFooterAcknowledgement"
import { StyledColumn } from "../../Styled/Column"
import { Row, Text } from "./styledFooterAcknowledgement"

export const FooterAcknowledgement = withFooterAcknowledgement(({ content }) => (
  <Row items={`center`} withWrap>
    <StyledColumn>
      <Text large>{content}</Text>
    </StyledColumn>
  </Row>
))
