import React, { memo, useMemo } from "react"
import { useNavigationFeatured } from "../../../../hooks/useNavigationFeatured"

export const withNavFeatured = Component =>
  memo(({ name = "NavFeatured", handleClose, item }: any) => {
    const { featured } = useNavigationFeatured()

    Component.displayName = name
    return useMemo(() => <Component featured={item || featured[0]} handleClose={handleClose} />, [item, handleClose, featured])
  })
