import React, { memo, useCallback, useEffect, useMemo } from "react"
import { useSearch } from "@usereactify/search"

import { useApp } from "../../../hooks/useApp"
import { useTemplate } from "../../../hooks/useTemplate"
import { useAnalytics } from "../../../hooks/useAnalytics"

export const withSearchForm = Component =>
  memo(({ name = "SearchForm", bounds }: any) => {
    const { trackSearch } = useAnalytics()
    const {
      globalState,
      searchKey,
      config: {
        services: { reactify },
      },
    } = useApp()
    const { global: locales } = useTemplate()
    const { searchTerm, setSearchTerm, submitSearchTerm } = useSearch()

    const type = searchKey === `search` ? `search` : `collection`

    const [state, dispatch] = globalState

    const handleClose = useCallback(() => {
      if (searchTerm?.length > 0) {
        setSearchTerm("")
      } else {
        dispatch({
          type: "setActiveSearch",
          payload: !state.activeSearch,
        })
      }
    }, [dispatch, setSearchTerm, state, searchTerm])

    const handleSubmit = useCallback(
      (event: React.BaseSyntheticEvent) => {
        event.preventDefault()
        trackSearch(searchTerm)
        submitSearchTerm()
      },
      [submitSearchTerm]
    )

    useEffect(() => {
      if (state?.activeSearch) document.querySelector(reactify?.searchFieldSelector)?.select()
    }, [reactify, state])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          active={state?.activeSearch}
          bounds={bounds}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          locales={locales}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          type={type}
        />
      ),
      [state, bounds, handleClose, handleSubmit, locales, searchTerm, setSearchTerm, type]
    )
  })
