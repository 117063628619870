import React, { memo, useCallback, useMemo, useState } from "react"

import * as Components from "../../../types/components"
import { useCheckoutContext, useCheckout } from "../../../hooks/useCheckout"
import { useApp } from "../../../hooks/useApp"
import { useCustomerDetails } from "../../../hooks/useCustomer"
import { useFunctions } from "../../../hooks/useFunctions"
import { useShopify } from "../../../hooks/useShopify"
import { useAnalytics } from "../../../hooks/useAnalytics"

export const withCartTotal = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "CartTotal", page, disabled, handleDisabledClick, mini, drawerlocales }: ExternalProps) => {
    const { trackInitiateCheckout } = useAnalytics()
    const {
      config: {
        settings: { customAttributeKeys },
      },
    } = useApp()
    const { checkout, checkoutUrl, clientId, sessionID, candyReferrer } = useCheckoutContext()
    const { customer } = useCustomerDetails()
    const { callFunction } = useFunctions()
    const { formatMoney } = useShopify()
    const { updateAttributes } = useCheckout()

    const [loading, setLoading] = useState(false)

    const giftcards = checkout?.appliedGiftCards?.length > 0 ? formatMoney(-checkout?.appliedGiftCards?.[0]?.amountUsedV2?.amount) : null
    const discountApplication = checkout?.discountApplications?.[0]
    const discount = useMemo(
      () =>
        discountApplication?.targetType === `SHIPPING_LINE`
          ? page?.additionalShippingFree
          : discountApplication?.targetType === `LINE_ITEM`
          ? discountApplication?.value?.percentage
            ? `-${discountApplication?.value?.percentage}%`
            : formatMoney(-discountApplication?.value?.amount)
          : null,
      [
        discountApplication?.targetType,
        discountApplication?.value?.amount,
        discountApplication?.value?.percentage,
        formatMoney,
        page?.additionalShippingFree,
      ]
    )

    const shipping = discountApplication?.targetType === `SHIPPING_LINE` ? formatMoney(0) : page?.additionalShippingCalculated
    const total = formatMoney(checkout?.totalPriceV2?.amount)
    const handleOrderAttributes = useCallback(async () => {
      if (!checkout?.lineItems?.length) {
        return {}
      }
      const excludedAttributes = ["authority to leave", "instructions"]
      const bundleTitleMappings: { [key: string]: string } = {}
      const bundleItemCount: { [key: string]: number } = {}
      const bundleItems: { [key: string]: string[] } = {}
      const bundleIndex: { [key: string]: string[] } = {}

      for (const lineItem of checkout.lineItems) {
        const pickMixBundleId = lineItem?.customAttributes?.find(({ key }) => key === customAttributeKeys.grouped)?.value
        const pickMixBundleVariantId = lineItem?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedVariantId)?.value
        if (!pickMixBundleId || !pickMixBundleVariantId) {
          continue
        }
        if (!bundleIndex[pickMixBundleVariantId]) {
          bundleIndex[pickMixBundleVariantId] = []
        }
        if (pickMixBundleId) {
          bundleIndex[pickMixBundleVariantId].push(pickMixBundleId)
        }
      }

      let pickGiftCustMessage
      let custMessageChecker = false

      for (const lineItem of checkout.lineItems) {
        const pickMixBundleId = lineItem?.customAttributes?.find(({ key }) => key === customAttributeKeys.grouped)?.value
        const pickMixBundleVariantId = lineItem?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedVariantId)?.value
        pickGiftCustMessage = lineItem?.customAttributes?.find(({ key }) => key === "_customer_card_text")?.value
        if (!pickMixBundleId || !pickMixBundleVariantId) {
          continue
        }
        const pickMixBundleName = lineItem?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedName)?.value
        const isBundleItem = !!pickMixBundleName
        const index = bundleIndex[pickMixBundleVariantId].indexOf(pickMixBundleId)
        if (!bundleTitleMappings[pickMixBundleId] && isBundleItem) {
          bundleTitleMappings[pickMixBundleId] = `${pickMixBundleName.toUpperCase()}${index ? ` (${index + 1})` : ""}`
        }
        if (bundleTitleMappings[pickMixBundleId] && !isBundleItem) {
          const title = lineItem?.variant?.product?.title?.toUpperCase()
          bundleItemCount[title] = bundleItemCount[title] ? bundleItemCount[title]++ : 1
        }

        bundleItems[pickMixBundleId] = bundleItems[pickMixBundleId] || []

        if (isBundleItem) {
          bundleItems[pickMixBundleId].push(`${lineItem.title} x ${lineItem.quantity}`)

          if (pickGiftCustMessage && custMessageChecker == false) {
            bundleItems[pickMixBundleId].push(`Customers card message x ${pickGiftCustMessage}`)
            custMessageChecker = true
          }
        }
      }

      const newCustomAttributes = Object.keys(bundleItems).map(key => {
        const name = bundleTitleMappings[key]
        const items = bundleItems[key]?.join("; ")

        return {
          key: name,
          value: items,
        }
      })

      if (clientId && sessionID) {
        newCustomAttributes.push({ key: "google-clientID", value: clientId }, { key: "ga4session-clientID", value: sessionID })
      }

      if (candyReferrer && candyReferrer !== "") {
        newCustomAttributes.push({ key: "ref-candy", value: candyReferrer })
      }

      // merge customer attributes
      if (newCustomAttributes) {
        const currentAttributes = checkout?.customAttributes?.filter(attr => excludedAttributes.includes(attr.key))
        await updateAttributes({ customAttributes: [...currentAttributes, ...newCustomAttributes] })
      }
    }, [
      checkout?.customAttributes,
      checkout.lineItems,
      customAttributeKeys.grouped,
      customAttributeKeys.groupedName,
      customAttributeKeys.groupedVariantId,
      updateAttributes,
    ])

    const handleClick = useCallback(async () => {
      trackInitiateCheckout()
      setLoading(true)
      await handleOrderAttributes()
      try {
        const response = await callFunction("checkout-multipass", { customerEmail: customer?.email, checkoutId: checkout.id, webUrl: checkoutUrl })
        setLoading(false)

        const url = response.status !== "error" && response.body.includes("https://") ? response.body : checkoutUrl

        window.location.replace(url)
      } catch (e) {
        setLoading(false)
        window.location.replace(checkoutUrl)
      }
    }, [checkout?.id, callFunction, checkoutUrl, customer?.email, handleOrderAttributes])

    const props = {
      discount,
      giftcards,
      handleClick,
      loading,
      locales: page,
      shipping,
      total,
      disabled,
      handleDisabledClick,
      mini,
      drawerlocales,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

// TODO: Replace "any" with appropriate types
type ExternalProps = Components.ComponentProps & {
  page: any
  disabled: boolean
  handleDisabledClick: (status: boolean) => void
  mini: boolean
  drawerlocales: string
}

type InjectedProps = {
  discount: any
  giftcards: string
  loading: boolean
  total: string
  disabled: boolean
  locales: any
  shipping: any
  handleClick: () => Promise<void>
  handleDisabledClick: (status: boolean) => void
  mini: boolean
  drawerLocales: string
}
