import { useCallback } from "react"

import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"

export const useNavigation = (location?: any) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { linkResolver } = useRoutes()

  const itemIsActive = useCallback(
    (item: any) => (isBrowser ? item?.url === location?.pathname?.split(`/`)?.slice(0, 3)?.join(`/`) || false : false),
    [isBrowser, location]
  )

  const mapItems = useCallback(
    (items, name: string) =>
      items?.map((navItem: any) => {
        const linkKey =
          navItem?._type === "navigationFeatured"
            ? navItem?.link?.document?._type
            : navItem[navItem?._type?.replace(`navigation`, ``)?.toLowerCase()]?._type
            ? navItem[navItem?._type?.replace(`navigation`, ``)?.toLowerCase()]?._type
            : navItem?._type

        return {
          key: `${name}-${navItem?._key}`,
          active: itemIsActive(linkResolver(navItem, linkKey)),
          collections: navItem?.collections || null,
          content: navItem?.content || null,
          item: navItem[navItem?._type?.replace(`navigation`, ``)?.toLowerCase()] || null,
          items: navItem?.items?.length > 0 ? mapItems(navItem.items, name) : [],
          multiColourText: navItem?.multiColourText,
          ...linkResolver(navItem, linkKey),
          buttonLabel: navItem?.buttonLabel || null,
          image: navItem?.image || null,
          buttonColour: navItem?.button || null,
          buttonTextColour: navItem?.text || null,
          isFeatured: navItem?._type === "navigationFeatured",
        }
      }),
    [itemIsActive, linkResolver]
  )

  return { mapItems }
}
