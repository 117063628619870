import React, { memo, useMemo, useEffect } from "react"
import { useTransition, TransitionFn } from "react-spring"

import * as Components from "../../types/components"
import * as Shopify from "../../types/shopify"
import { UseAnimation } from "../../types/hooks"
import { useApp } from "../../hooks/useApp"
import { useImage } from "../../hooks/useImage"
import { useAnimationPickGift } from "../../hooks/useAnimationPickGift"

export const withAnimationPopupPickGift = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "AnimationPopupPickGift" }: Components.ComponentProps) => {
    const { activeAnimationPopupPickGift = [], headerBounds, setActiveAnimationPopupPickGift } = useApp()

    const { baseImageTransPickGift, getSpringConfigPickGift } = useAnimationPickGift()
    const { convertSwatchImageUrl } = useImage()

    const active = useMemo(() => activeAnimationPopupPickGift.length, [activeAnimationPopupPickGift.length])
    const bagImage = useMemo(() => activeAnimationPopupPickGift[activeAnimationPopupPickGift.length - 1], [activeAnimationPopupPickGift])
    const productImages = useMemo(
      () => activeAnimationPopupPickGift.slice(1, activeAnimationPopupPickGift.length - 1),
      [activeAnimationPopupPickGift]
    )

    const imageTrans = useMemo(
      () =>
        baseImageTransPickGift?.map((tran, index) => ({
          ...tran,
          src: productImages?.[index]?.src ? convertSwatchImageUrl(productImages?.[index]?.src) : "",
        })),
      [baseImageTransPickGift, convertSwatchImageUrl, productImages]
    )

    const transitions = useTransition(imageTrans, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      delay: 100,
      pause: !active,
      config: { mess: 1, tension: 280, friction: 320 },
    })

    // Used to adjust the set tinme out timer based on how many products have been selected
    let timeout = 2000
    for (let index = 0; index < imageTrans.length; index++) {
      if (imageTrans[index]?.src != "" && timeout < 7850) {
        timeout += 585
      }
    }

    useEffect(() => {
      if (active) {
        setTimeout(() => {
          setActiveAnimationPopupPickGift([])
        }, timeout)
      }
    }, [active, timeout, setActiveAnimationPopupPickGift])

    const props = useMemo(
      () => ({
        active,
        bounds: headerBounds,
        bagImage,
        transitions,
        getSpringConfigPickGift,
      }),
      [active, bagImage, headerBounds, getSpringConfigPickGift, transitions]
    )

    Component.displayName = name
    return useMemo(() => <Component {...(props as P)} />, [props])
  })

type InjectedProps = {
  active: boolean
  bounds: any
  bagImage: Shopify.ShopifyImage
  transitions: TransitionFn<
    {
      src: any
      fig: number
      op: UseAnimation.TransConfig
      trans: UseAnimation.TransConfig
    },
    {
      opacity: number
    }
  >
  getSpringConfigPickGift: (input: UseAnimation.GetSpringConfigInput) => UseAnimation.GetSpringConfigResponse
}
