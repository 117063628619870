exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-activate-tsx": () => import("./../../../src/pages/account/activate.tsx" /* webpackChunkName: "component---src-pages-account-activate-tsx" */),
  "component---src-pages-account-addresses-tsx": () => import("./../../../src/pages/account/addresses.tsx" /* webpackChunkName: "component---src-pages-account-addresses-tsx" */),
  "component---src-pages-account-forgot-tsx": () => import("./../../../src/pages/account/forgot.tsx" /* webpackChunkName: "component---src-pages-account-forgot-tsx" */),
  "component---src-pages-account-invalid-token-tsx": () => import("./../../../src/pages/account/invalid_token.tsx" /* webpackChunkName: "component---src-pages-account-invalid-token-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-account-reset-tsx": () => import("./../../../src/pages/account/reset.tsx" /* webpackChunkName: "component---src-pages-account-reset-tsx" */),
  "component---src-pages-account-rewards-tsx": () => import("./../../../src/pages/account/rewards.tsx" /* webpackChunkName: "component---src-pages-account-rewards-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-account-wishlist-tsx": () => import("./../../../src/pages/account/wishlist.tsx" /* webpackChunkName: "component---src-pages-account-wishlist-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-giftcard-tsx": () => import("./../../../src/pages/giftcard.tsx" /* webpackChunkName: "component---src-pages-giftcard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loyalty-tsx": () => import("./../../../src/pages/loyalty.tsx" /* webpackChunkName: "component---src-pages-loyalty-tsx" */),
  "component---src-pages-password-tsx": () => import("./../../../src/pages/password.tsx" /* webpackChunkName: "component---src-pages-password-tsx" */),
  "component---src-pages-pickgift-tsx": () => import("./../../../src/pages/pickgift.tsx" /* webpackChunkName: "component---src-pages-pickgift-tsx" */),
  "component---src-pages-pickmix-tsx": () => import("./../../../src/pages/pickmix.tsx" /* webpackChunkName: "component---src-pages-pickmix-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-stores-tsx": () => import("./../../../src/pages/stores.tsx" /* webpackChunkName: "component---src-pages-stores-tsx" */),
  "component---src-pages-styleguide-tsx": () => import("./../../../src/pages/styleguide.tsx" /* webpackChunkName: "component---src-pages-styleguide-tsx" */),
  "component---src-pages-virtual-skin-assistant-tsx": () => import("./../../../src/pages/virtual-skin-assistant.tsx" /* webpackChunkName: "component---src-pages-virtual-skin-assistant-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-flexible-tsx": () => import("./../../../src/templates/flexible.tsx" /* webpackChunkName: "component---src-templates-flexible-tsx" */),
  "component---src-templates-generic-tsx": () => import("./../../../src/templates/generic.tsx" /* webpackChunkName: "component---src-templates-generic-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

