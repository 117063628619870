import React, { memo, useMemo } from "react"

import { useTemplate } from "@app/hooks/useTemplate"

export const withUpsellSlide = Component =>
  memo(({ name = "UpsellSlide", global, related, clientId, sessionID, handleAdd, handleNotify, loading, isMobile }: any) => {
    const { product: locales } = useTemplate()

    Component.displayName = name
    return useMemo(
      () =>
        related ? (
          <Component
            related={related}
            clientId={clientId}
            sessionID={sessionID}
            handleAdd={handleAdd}
            handleNotify={handleNotify}
            loading={loading}
            locales={locales}
            isMobile={isMobile}
          />
        ) : null,
      [related, clientId, sessionID, handleAdd, handleNotify, loading, locales, isMobile]
    )
  })
