import React from "react"
import { Results } from "@usereactify/search"

import { withSearchFormResults } from "./withSearchFormResults"
import { Icon } from "../../../Icon/Icon"
import { SearchFormResultsItem } from "./Item/SearchFormResultsItem"
import { StyledLink } from "../../../Styled/Button"
import { StyledContainer } from "../../../Styled/Container"
import { Wrapper, Row, Title } from "./styledSearchFormResults"

export const SearchFormResults = withSearchFormResults(
  ({ handleSubmit, isMobile, locales, results }): JSX.Element => (
    <Wrapper visible={results.length > 0}>
      <StyledContainer width={`lg`}>
        <Title colour={`grey-darker`}>{locales?.additionalSearchResults}</Title>
        <Results
          pageSize={isMobile ? 2 : 3}
          listStyle={{}}
          listClassName="grid grid-cols-2 md:grid-cols-3 gap-6"
          renderLoading={() => null}
          renderNoResults={() => null}
          renderResultCardProduct={renderProps => <SearchFormResultsItem product={renderProps.product} />}
        />
        <Row justify={`center`}>
          <StyledLink colour={`purple-dark`} onClick={handleSubmit} title={locales?.additionalSearchView} underline>
            <span />
            <span>{locales?.additionalSearchView}</span>
            <Icon name={`right`} width={18} />
          </StyledLink>
        </Row>
      </StyledContainer>
    </Wrapper>
  )
)
