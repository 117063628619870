import React, { memo, useCallback, useEffect, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCheckout, useCheckoutSession } from "../../../hooks/useCheckout"
import { useGift } from "../../../hooks/useGift"
import { useTemplate } from "../../../hooks/useTemplate"

export const withCartWidget = Component =>
  memo(({ name = "CartWidget", location, screen }: any) => {
    const { globalState } = useApp()
    const { count } = useCheckout()
    const { importSession } = useCheckoutSession()
    const { applyGifts } = useGift()
    const { global: locales } = useTemplate()

    const [state, dispatch] = globalState

    const handleClick = useCallback(() => {
      dispatch({
        type: "setActiveCart",
        payload: !state.activeCart,
      })
    }, [dispatch, state])

    // intentionally only once at first render
    // import any migrated sessions
    // check for any saved sessions
    // create a new checkout session if no previous checkouts exist
    useEffect(() => {
      importSession(location)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // intentionally only on count update
    useEffect(() => {
      applyGifts()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count])

    Component.displayName = name
    return useMemo(
      () => <Component count={count} handleClick={handleClick} locales={locales} screen={screen} />,
      [count, handleClick, locales, screen]
    )
  })
