import tw, { css } from "twin.macro"
import styled from "@emotion/styled/macro"

import { StyledImage } from "../Styled/Image"

export const Notify = styled.section<NotifyProps>`
  ${tw`fixed inset-0 flex items-center justify-center z-20 invisible opacity-0 bg-white bg-opacity-70 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100`}
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
`
export const Popup = styled.div<GenericProps>`
  ${tw`relative w-full h-full bg-white text-black rounded-lg shadow px-2 sm:px-30 md:px-40 pb-2 pt-16 overflow-x-hidden overflow-y-auto max-h-full invisible transform scale-90 translate-y-6 transition-all duration-200`}
  ${({ active }) => active && tw`visible scale-100 translate-y-0`}
`
export const Inner = tw.div`flex items-center justify-center w-full h-full p-6 w-200 h-129.5 max-w-full`

export const Wrapper = tw.div`relative flex w-full h-full items-center justify-center`
export const Content = tw.div`px-2 justify-center z-0`
export const ItemGroup = tw.div`flex`

export const ImageContent = tw.div`relative self-center w-full max-w-85 h-full z-10`
export const ImageWrapper = tw.div`
  absolute bottom-12 right-0 left-0 transition-all duration-200
`
export const Image = tw(StyledImage)``

interface GenericProps {
  active?: boolean
}
interface NotifyProps extends GenericProps {
  top: number
}
