import tw, { styled } from "twin.macro"

import { GlobalStyles } from "./Global"

const styles = {
  default: tw`flex`,
  wrap: {
    default: tw`flex-wrap`,
    "<sm": tw`flex-wrap md:flex-nowrap`,
  },
  gutter: {
    default: tw`-mx-6 md:-mx-7.5`,
    xxs: tw`-mx-1.25`,
    xs: tw`-mx-1.5 md:-mx-2.5`,
    sm: tw`-mx-1.875 md:-mx-3.75`,
    lg: tw`-mx-6 lg:-mx-12`,
    xl: tw`-mx-6 lg:-mx-18.75`,
  },
  items: {
    none: tw``,
    default: tw`items-start`,
    end: tw`items-end`,
    center: tw`items-center`,
    stretch: tw`items-stretch`,
  },
  justify: {
    none: tw``,
    default: tw`justify-between`,
    end: tw`justify-end`,
    center: tw`justify-center`,
    start: tw`justify-start`,
    evenly: tw`justify-evenly`,
    prototype: tw`justify-center lg:justify-end`,
  },
}

export const StyledRow = styled.div`
  ${styles.default}
  ${({ withWrap }) => (withWrap && styles.wrap[withWrap]) || (withWrap && styles.wrap[`default`])}
  ${({ gutter }) => (gutter && styles.gutter[gutter]) || (gutter && styles.gutter[`default`])}
  ${({ items }) => (items && styles.items[items]) || styles.items[`default`]}
  ${({ justify }) => (justify && styles.justify[justify]) || styles.justify[`default`]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${({ prototype }) => prototype && tw`lg:max-w-[385px]`}
`
