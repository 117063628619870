import React, { useCallback } from "react"

export const useContent = () => {
  const getLineBreaks = useCallback(
    (content: string) =>
      content?.split("\n").map((item: string, key: number) => (
        <span key={key}>
          {item}
          <br />
        </span>
      )),
    []
  )

  const capitalise = (content: string) => `${content?.charAt(0)?.toUpperCase() || ``}${content?.substr(1)?.toLowerCase() || ``}`

  return { capitalise, getLineBreaks }
}
