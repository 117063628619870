import React, { memo, useMemo } from "react"

import { useCustomerContext, useCustomerLogout } from "../../../hooks/useCustomer"
import { useNavigationFooter } from "../../../hooks/useNavigationFooter"
import { useRoutes } from "../../../hooks/useRoutes"
import { useTemplate } from "../../../hooks/useTemplate"

export const withFooterNavigation = Component =>
  memo(({ name = "FooterNavigation" }: any) => {
    const { footer: items } = useNavigationFooter()
    const { customer } = useCustomerContext()
    const { handleLogout } = useCustomerLogout()
    const { routeResolver } = useRoutes()
    const { global: locales } = useTemplate()

    const account = useMemo(
      () => [
        { title: locales?.additionalAccount, url: routeResolver({ type: `dashboard` }) },
        { title: locales?.additionalLogout, onClick: handleLogout },
      ],
      [handleLogout, locales, routeResolver]
    )

    Component.displayName = name
    return useMemo(() => (items?.length > 0 ? <Component account={account} customer={customer} items={items} /> : null), [account, customer, items])
  })
