import React from "react"

import { withAnimationPopupLoyalty } from "./withAnimationPopupLoyalty"
import { Notify, Inner, Popup, Wrapper, Content, Table } from "./styledAnimationLoyalty"
import { H3 } from "../Styled/Text"
import { Icon } from "../Icon/Icon"

export const AnimationPopupLoyalty = withAnimationPopupLoyalty(({ active, bounds, handleClick, activeAnimationPopupLoyalty }): JSX.Element => {
  return (
    <Notify active={active} top={bounds?.height}>
      <Inner>
        <Popup active={active}>
          <button className="absolute w-4 right-4 top-4 z-10" onClick={handleClick}>
            <Icon name={`close`} />
          </button>
          <Wrapper>
            <Content>
              <H3>Rewards History</H3>
              <div className="grid grid-cols-4">
                <div className="p-2 sm:p-4">Date</div>
                <div className="p-2 sm:p-4">Action</div>
                <div className="p-2 sm:p-4">Points</div>
                <div className="p-2 sm:p-4">Status</div>
              </div>
              <Table>
                {activeAnimationPopupLoyalty?.history_items?.map((history, i) => (
                  <div key={i} className="grid grid-cols-4 bg-orange text-white mb-4">
                    <div className="p-2 sm:p-4">{history?.date}</div>
                    <div className="p-2 sm:p-4">{history?.action}</div>
                    <div className="p-2 sm:p-4">{history?.points}</div>
                    <div className="p-2 sm:p-4">{history?.status}</div>
                  </div>
                ))}
              </Table>
            </Content>
          </Wrapper>
        </Popup>
      </Inner>
    </Notify>
  )
})
