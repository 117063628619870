import React from "react"

import { withNewsletter } from "./withNewsletter"
import { Icon } from "../Icon/Icon"
import { NewsletterForm } from "./Form/NewsletterForm"
import { H5, RichText } from "../Styled/Text"
import { Popup, Overlay, Inner, Content, Button, Close, Success, Title, Image } from "./styledNewsletter"

export const Newsletter = withNewsletter(
  ({ active, data, errors, handleClose, handleSubmit, image, loading, locales, setData, success }): JSX.Element => (
    <Popup active={active}>
      <Overlay onClick={handleClose} />

      <Inner active={active}>
        <Image alt={image?.alt} fluid={{ ...image, aspectRatio: 1 / 1 }} />
        <Content active={active}>
          <Close colour={`white`} onClick={handleClose} title={locales?.additionalClose}>
            <Icon name={`cross`} title={locales?.additionalClose} width={20} />
          </Close>

          {success ? (
            <>
              <Title colour={`white`}>{locales?.successTitle?.replace(`{name}`, data?.firstName || ``)}</Title>
              <H5 colour={`white`} withSpacing={`sm`}>
                {locales?.successSubtitle}
              </H5>
              <Success colour={`white`} withSpacing={`xl`}>
                {locales?.successContent}
              </Success>
              <Button size={`secondary`} colour={`purple`} onClick={handleClose} title={locales?.additionalDone}>
                {locales?.additionalDone}
              </Button>
            </>
          ) : (
            <>
              <Title colour={`white`} withSpacing={`xxs`}>
                {locales?.title}
              </Title>
              <H5 colour={`white`} withSpacing={`xxs`}>
                {locales?.subtitle}
              </H5>
              <RichText colour={`white`} withSpacing={`xxs`}>
                {locales?.content}
              </RichText>
              <NewsletterForm data={data} loading={loading} errors={errors} setData={setData} handleSubmit={handleSubmit} locales={locales} />
            </>
          )}
        </Content>
      </Inner>
    </Popup>
  )
)
