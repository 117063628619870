import React from "react"

import { withQuizSubscribePopup } from "./withQuizSubscribePopup"
import { FormInput } from "../../Form/Input/FormInput"
import { Icon } from "../../Icon/Icon"
import { H4, P } from "../../Styled/Text"
import {
  Notify,
  PopupInner,
  Popup,
  PopupForm,
  PopupClose,
  PopupButton,
  SuccessPopupHeading,
  SuccessPopupText,
  SuccessTextSpan,
} from "./styledQuizSubscribe"

export const QuizSubscribePopup = withQuizSubscribePopup(
  ({ active, bounds, data, onClosePopup, handleChange, handleSubmit, loading, locales, success, successContent }): JSX.Element => (
    <Notify active={active} top={bounds?.height}>
      <PopupInner>
        <Popup active={active}>
          {success ? (
            <>
              {locales?.additionalSubscribeSuccess && (
                <SuccessPopupHeading align={"left"} colour={"white"} withSpacing={"sm"}>
                  {locales?.additionalSubscribeSuccess}
                </SuccessPopupHeading>
              )}
              {successContent && (
                <SuccessPopupText align={"left"} colour={"white"} withSpacing={"sm"}>
                  {successContent.map(content =>
                    content.highlight ? <SuccessTextSpan key={content.text.toString()}>{`${content.text} `}</SuccessTextSpan> : `${content.text} `
                  )}
                </SuccessPopupText>
              )}
            </>
          ) : (
            <PopupForm onSubmit={handleSubmit}>
              <H4 align={"center"} withSpacing={"xs"}>
                {locales?.additionalSubscribeTitle}
              </H4>
              <FormInput
                type={"email"}
                name={"email"}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalSubscribeEmail}
                required
                value={data?.email}
                withSpacing
              />
              <P align={"center"} withSpacing={"sm"}>
                {locales?.additionalSubscribeLabel}
              </P>
              <PopupButton type={"submit"} disabled={loading} colour={"purple"} size={"secondary"} title={locales?.additionalShareSubmit}>
                {locales?.additionalShareSubmit}
              </PopupButton>
            </PopupForm>
          )}
          <PopupClose colour={"white"} onClick={onClosePopup} title={locales?.additionalClose}>
            <Icon name={"close"} />
          </PopupClose>
        </Popup>
      </PopupInner>
    </Notify>
  )
)
