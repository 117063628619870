import { useState, useEffect, useCallback, useContext, useMemo } from "react"
import { navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCheckout, useCheckoutContext } from "./useCheckout"
import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"
import { useFunctions } from "./useFunctions"

import { CustomerContext } from "../providers/customer"
import { Buffer } from "buffer"

export const useCustomerContext = () => {
  const customer: any = useContext(CustomerContext)

  return { ...customer }
}

export const useCustomerAccessToken = () => {
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_ACCESS_TOKEN_CREATE, CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { updateCustomer } = useCheckout()
  const { countryCode } = useCheckoutContext()
  const { setCustomer: saveCustomer } = useCustomerContext()
  const { customerNormaliser, useMutation, useQuery } = useShopify()

  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE)
  const [customerAccessTokenCreateWithMultipass] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS)
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, { fetchPolicy: "no-cache", skip: true })

  const setCustomer = useCallback(
    (customer, { accessToken, expiresAt }) => {
      try {
        saveCustomer(customerNormaliser(customer))
        storage.set(keys?.customer, { accessToken, expiresAt })
      } catch (error) {
        console.error(error)
      }
    },
    [customerNormaliser, saveCustomer, keys, storage]
  )

  const getCustomer = useCallback(
    async (tokens = null) => {
      const customerTokens = tokens || storage.get(keys?.customer)

      if (customerTokens?.accessToken) {
        try {
          const {
            data: { customer, customerUserErrors },
          } = await getCustomerQuery({
            countryCode,
            customerAccessToken: customerTokens?.accessToken,
          })

          if (!customerUserErrors?.length) {
            setCustomer(customer, customerTokens)
          }
          if (customerUserErrors?.length) storage.remove(keys?.customer)
        } catch (err) {
          console.error(err)
        }
      } else {
        saveCustomer(null)
      }
    },
    [getCustomerQuery, saveCustomer, setCustomer, keys, storage, countryCode]
  )

  const createAccessToken = useCallback(
    async (email, password) => {
      try {
        const {
          data: {
            customerAccessTokenCreate: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreate({
          variables: { input: { email, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          storage.set(keys?.customer, { accessToken, expiresAt })
          updateCustomer(accessToken)
          getCustomer()
        }

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreate, updateCustomer, getCustomer, keys, storage]
  )

  const createAccessTokenWithMultipass = useCallback(
    async multipassToken => {
      try {
        const {
          data: {
            customerAccessTokenCreateWithMultipass: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreateWithMultipass({
          variables: { multipassToken },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          storage.set(keys?.customer, { accessToken, expiresAt })
          updateCustomer(accessToken)
          getCustomer()
        }

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreateWithMultipass, updateCustomer, getCustomer, keys, storage]
  )

  return { createAccessToken, createAccessTokenWithMultipass, getCustomer }
}

export const useCustomerRegister = () => {
  const {
    helpers: { isBrowser },
    graphql: {
      mutations: { CUSTOMER_CREATE },
    },
  } = useCore()
  const { createAccessToken } = useCustomerAccessToken()
  const { routeResolver } = useRoutes()
  const { useMutation } = useShopify()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "", firstName: "", lastName: "", phone: "", acceptsMarketing: false })

  const [customerCreate] = useMutation(CUSTOMER_CREATE)

  const createCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      const route = routeResolver({ type: `dashboard` })

      try {
        const {
          data: {
            customerCreate: { customerUserErrors: errors },
          },
        } = await customerCreate({
          variables: { input: { ...userData } },
        })

        if (errors?.length) {
          setErrors(errors)
          setLoading(false)
        } else {
          const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

          if (!customerUserErrors?.length) {
            if (isBrowser) navigate(route, { replace: true })
          } else {
            setErrors(customerUserErrors)
            setLoading(false)
          }
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerCreate, createAccessToken, routeResolver, isBrowser]
  )

  return { createCustomer, data, setData, loading, errors }
}

export const useCustomerLogin = () => {
  const {
    config: {
      settings: { params, routes },
    },
  } = useApp()
  const { checkout, checkoutUrl } = useCheckoutContext()
  const { callFunction } = useFunctions()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { createAccessToken } = useCustomerAccessToken()
  const { getUrlParameter } = useRoutes()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "" })

  const loginCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      try {
        const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

        if (!customerUserErrors?.length) {
          if (checkout?.id && checkoutUrl && getUrlParameter(params?.checkout)) {
            try {
              const response = await callFunction("checkout-multipass", { customerEmail: data?.email, checkoutId: checkout.id, webUrl: checkoutUrl })
              setLoading(false)

              const url = response.status !== "error" && response.body.includes("https://") ? response.body : checkoutUrl

              window.location.replace(url)
            } catch (e) {
              setLoading(false)
              window.location.replace(checkoutUrl)
            }
          } else if (isBrowser) {
            navigate(getUrlParameter(params?.continue) ? decodeURIComponent(getUrlParameter(params?.continue)) : routes.DASHBOARD, { replace: true })
          }
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, createAccessToken, routes, isBrowser, getUrlParameter, params, callFunction, checkout, checkoutUrl, data]
  )

  return { loginCustomer, data, setData, loading, errors }
}

export const useCustomerLogout = () => {
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser, storage },
  } = useCore()
  const { setCustomer } = useCustomerContext()

  const handleLogout = useCallback(async () => {
    storage.remove(keys?.customer)
    if (isBrowser) navigate(routes?.DASHBOARD, { replace: true })
    setCustomer(null)
  }, [setCustomer, keys, storage, routes, isBrowser])

  return { handleLogout }
}

export const useCustomerRecover = () => {
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_RECOVER },
    },
  } = useCore()
  const { useMutation } = useShopify()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "" })

  const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  const recoverCustomer = useCallback(
    async email => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerRecover: { customerUserErrors },
          },
        } = await customerRecover({
          variables: { email },
        })

        if (!customerUserErrors?.length) {
          storage.remove(keys?.customer)
          setSuccess(true)
          setLoading(false)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, setSuccess, customerRecover, storage, keys]
  )

  return { recoverCustomer, data, setData, loading, errors, success }
}

export const useCustomerAccount = () => {
  const {
    helpers: { isBrowser, storage },
    graphql: {
      mutations: { CUSTOMER_RESET, CUSTOMER_ACTIVATE },
    },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { useMutation } = useShopify()
  const { updateCustomer } = useCheckout()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ password: "", passwordConfirm: "" })

  const [customerReset] = useMutation(CUSTOMER_RESET)
  const [customerActivate] = useMutation(CUSTOMER_ACTIVATE)

  const resetCustomer = useCallback(
    async (customerId, resetToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = Buffer.from(`gid://shopify/Customer/${customerId}`, "utf8").toString("base64")

        const {
          data: {
            customerReset: { customerAccessToken, customerUserErrors },
          },
        } = await customerReset({
          variables: { id, input: { resetToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          if (isBrowser) navigate(routes?.DASHBOARD, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerReset, updateCustomer, storage, keys, routes, isBrowser]
  )

  const activateCustomer = useCallback(
    async (customerId, activationToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = Buffer.from(`gid://shopify/Customer/${customerId}`, "utf8").toString("base64")

        const {
          data: {
            customerActivate: { customerAccessToken, customerUserErrors },
          },
        } = await customerActivate({
          variables: { id, input: { activationToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          if (isBrowser) navigate(routes?.DASHBOARD, { replace: true })
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerActivate, updateCustomer, storage, keys, routes, isBrowser]
  )

  return { resetCustomer, activateCustomer, data, setData, loading, errors, setErrors }
}

export const useCustomerOrders = (first: number, options: { reverse?: boolean } = {}) => {
  const {
    config: {
      settings: { keys },
    },
    helpers: { edgeNormaliser },
  } = useApp()
  const {
    helpers: { storage },
    graphql: {
      queries: { GET_CUSTOMER_ORDERS },
    },
  } = useCore()
  const { countryCode } = useCheckoutContext()
  const { orderNormaliser, useQuery } = useShopify()
  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken

  const { data, loading, error } = useQuery(GET_CUSTOMER_ORDERS, {
    variables: {
      countryCode,
      customerAccessToken,
      first,
      ...options,
    },
  })

  const orders = edgeNormaliser(data?.customer?.orders)?.map(order => orderNormaliser(order))

  return { orders, loading, error }
}

export const useCustomerOrder = (orderId: string, key: string) => {
  const {
    helpers: { encodeBase64 },
    graphql: {
      queries: { GET_ORDER },
    },
  } = useCore()
  const { countryCode } = useCheckoutContext()
  const { orderNormaliser, useQuery } = useShopify()
  const id = encodeBase64(`gid://shopify/Order/${orderId}${key}`)

  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: {
      countryCode,
      id,
    },
  })

  const order = data?.node ? orderNormaliser(data?.node) : null

  return { order, loading, error }
}

export const useCustomerAddress = () => {
  const {
    setActiveAddress,
    helpers: { edgeNormaliser },
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { decodeShopifyId, storage },
    graphql: {
      mutations: { CUSTOMER_ADDRESS_CREATE, CUSTOMER_ADDRESS_UPDATE, CUSTOMER_ADDRESS_DELETE, CUSTOMER_DEFAULT_ADDRESS_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { countryCode } = useCheckoutContext()
  const { routeResolver } = useRoutes()
  const { addressNormaliser, useMutation, useLazyQuery } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = useMemo(
    () => ({
      address1: "",
      address2: "",
      city: "",
      company: "",
      country: "",
      firstName: "",
      lastName: "",
      phone: "",
      province: "",
      zip: "",
    }),
    []
  )
  const [address, setAddress] = useState({ ...initialData, id: "", action: "" })
  const [addresses, setAddresses] = useState([])
  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken

  const [customerAddressCreate] = useMutation(CUSTOMER_ADDRESS_CREATE)
  const [customerAddressUpdate] = useMutation(CUSTOMER_ADDRESS_UPDATE)
  const [customerAddressDelete] = useMutation(CUSTOMER_ADDRESS_DELETE)
  const [customerDefaultAddressUpdate] = useMutation(CUSTOMER_DEFAULT_ADDRESS_UPDATE)

  const filterData = useCallback(
    address =>
      Object.keys(address)
        .filter(key => Object.keys(initialData).includes(key))
        .reduce((obj, key) => ({ ...obj, [key]: address[key] }), {}),
    [initialData]
  )

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      countryCode,
      customerAccessToken,
    },
  })

  useEffect(() => {
    getAll()
  }, [saving, getAll])

  // intentionally only run on data update
  useEffect(() => {
    if (data?.customer) setAddresses(edgeNormaliser(data?.customer?.addresses)?.map(address => addressNormaliser(address, data?.customer)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const defaultAddress = useCallback(
    async addressId => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerDefaultAddressUpdate: { customerUserErrors },
          },
        } = await customerDefaultAddressUpdate({
          variables: { addressId, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerDefaultAddressUpdate, customerAccessToken]
  )

  const createAddress = useCallback(
    async address => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressCreate: { customerAddress, customerUserErrors },
          },
        } = await customerAddressCreate({
          variables: { customerAccessToken, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          if (address?.default && customerAddress?.id) {
            await defaultAddress(customerAddress?.id)
          }
          setAddress({ ...initialData, id: "", action: "" })
          setActiveAddress(decodeShopifyId(customerAddress?.id, `Address`)?.split(`?`)[0])
          setSaving(false)
          navigate(routeResolver({ type: `addresses` }))
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [
      setSaving,
      setErrors,
      setAddress,
      decodeShopifyId,
      setActiveAddress,
      customerAddressCreate,
      defaultAddress,
      filterData,
      initialData,
      customerAccessToken,
      routeResolver,
    ]
  )

  const updateAddress = useCallback(
    async (id, address) => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressUpdate: { customerUserErrors },
          },
        } = await customerAddressUpdate({
          variables: { customerAccessToken, id, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          if (address?.default && address?.id) await defaultAddress(address?.id)
          setAddress({ ...initialData, id: "", action: "" })
          setActiveAddress(decodeShopifyId(id, `Address`)?.split(`?`)[0])
          setSaving(false)
          navigate(routeResolver({ type: `addresses` }))
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [
      setSaving,
      setErrors,
      setAddress,
      decodeShopifyId,
      setActiveAddress,
      customerAddressUpdate,
      defaultAddress,
      filterData,
      initialData,
      customerAccessToken,
      routeResolver,
    ]
  )

  const deleteAddress = useCallback(
    async id => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressDelete: { customerUserErrors },
          },
        } = await customerAddressDelete({
          variables: { id, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerAddressDelete, customerAccessToken]
  )

  return { addresses, setAddress, address, createAddress, updateAddress, defaultAddress, deleteAddress, initialData, loading, saving, errors }
}

export const useCustomerDetails = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { countryCode } = useCheckoutContext()
  const { useLazyQuery, useMutation } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      acceptsMarketing: false,
    }),
    []
  )

  const [customerUpdate] = useMutation(CUSTOMER_UPDATE)
  const [customer, setCustomer]: any = useState(initialData)
  const [fetchedCustomer, setFetchedCustomer] = useState(initialData)
  const customerTokens = storage.get(keys?.customer)
  const customerAccessToken = customerTokens?.accessToken

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: { countryCode, customerAccessToken },
  })

  const filterData = useCallback(
    (data, ignoreBlank = false) =>
      Object.entries(data)
        .filter(([key, value]) => Object.keys(initialData).includes(key) && (value || !ignoreBlank))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value || null }), {}),
    [initialData]
  )

  const changed = useMemo(
    () =>
      customer && fetchedCustomer
        ? Object.entries(filterData(customer, true)).filter(([key, value]) => fetchedCustomer?.[key] !== value)?.length > 0 ||
          Object.keys(filterData(customer, true))?.length !== Object.keys(filterData(fetchedCustomer, true))?.length
        : false,
    [customer, fetchedCustomer, filterData]
  )

  // intentionally only run once at first render
  useEffect(() => {
    if (customerAccessToken?.length) getAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.customer) {
      setCustomer(data.customer)
      setFetchedCustomer(data.customer)
    }
  }, [data])

  const resetForm = useCallback(() => {
    setCustomer(fetchedCustomer)
  }, [fetchedCustomer, setCustomer])

  const updateCustomer = useCallback(
    async customer => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerUpdate: { customerUserErrors },
          },
        } = await customerUpdate({
          variables: { customerAccessToken, customer: filterData(customer) },
        })

        if (!customerUserErrors?.length) {
          setFetchedCustomer(customer)
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerUpdate, filterData, customerAccessToken]
  )

  return { customer, setCustomer, changed, resetForm, updateCustomer, loading, saving, errors }
}
