module.exports = {
  store: require(`./config.${process.env.GATSBY_ACTIVE_STORE}.js`),
  stores: {
    "raw-earth-retail": require("./config.raw-earth-retail.js"),
    "skin-juice-stage": require("./config.skin-juice-stage.js"),
  },
  theme: {
    color: "#000000",
    background: "#FFFF04",
    display: "minimal-ui",
    icon: "static/images/icon.png",
    logo: "static/images/logo.svg",
  },
  services: {
    location: {
      serviceUrl: "https://ipapi.co/json/",
      countryFieldKey: "country",
      forceRegion: true,
    },
    reactify: {
      searchUrl: "https://api.search.reactify.app/",
      searchFieldSelector: `input[name="q"]`,
    },
    googleMaps: {
      apiKey: "AIzaSyBix1gDfy_golxfyDitQ9i8SyAJ-gABeiM",
      mapId: "2c72bb55ad68ca03",
    },
    mailchimp: {
      listId: "a69177cc3b",
      server: "us7",
    },
    stamped: {
      apiKey: "pubkey-l77aWij89Ea5ixD02l1bln08oTSz23",
      storeUrl: "skinjuice.com.au",
    },
    sharing: {
      facebook: "https://www.facebook.com/sharer/sharer.php?u=",
      pinterest: "https://pinterest.com/pin/create/link/?url=",
    },
  },
  queries: {
    products: {
      query: `allSanityProduct(filter: { shopify: { shopifyDeleted: { ne: true }, shopifyPublished: { eq: true } } }) { edges { node { tags, shopify { handle: shopifyHandle, shopifyRaw } } } }`,
      template: "templates/product.tsx",
      hiddenTag: "hidden",
      parentTagPrefix: "parent",
      siblingTagPrefix: "grouped",
      devPageLimit: 0,
    },
    collections: {
      query: `allSanityCollection(filter: { shopify: { shopifyDeleted: { ne: true }, shopifyPublished: { eq: true } } }) { edges { node { shopify { handle: shopifyHandle } parent: parentCollection { shopify { handle: shopifyHandle } parent: parentCollection { shopify { handle: shopifyHandle } } } } } }`,
      template: "templates/collection.tsx",
      hiddenTag: "hidden",
      devPageLimit: 0,
    },
    articles: {
      query: `allSanityArticle { edges { node { _id handle: slug { current } } } }`,
      template: "templates/article.tsx",
    },
    flexible: {
      query: `allSanityPageFlexible { edges { node { _id handle: slug { current } } } }`,
      template: "templates/flexible.tsx",
    },
    generic: {
      query: `allSanityPageGeneric { edges { node { _id handle: slug { current } } } }`,
      template: "templates/generic.tsx",
    },
    redirects: {
      query: `allSanityRedirect { edges { node { source type destination } } }`,
    },
    robots: {
      query: `allSanitySettingRobots { nodes { content { code } } }`,
    },
  },
  settings: {
    keys: {
      announcement: "skin-juice:announcement",
      birthday: "birthday:",
      checkout: "skin-juice:checkout",
      country: "skin-juice:country",
      customer: "skin-juice:customer",
      discounts: "skin-juice:discounts",
      location: "skin-juice:location",
      maintenance: "skin-juice:maintenance",
      market: "skin-juice:market",
      newsletter: "skin-juice:newsletter",
      password: "skin-juice:password",
      quiz: "skin-juice:quiz",
      reference: "reference:",
      shopify: "skin-juice:shopify",
      wishlist: "skin-juice:wishlist",
      refcandy: "rc-ref-id",
    },
    params: {
      category: "category",
      checkout: "checkout_url",
      continue: "return",
      search: "q",
      session: "session",
      variant: "variant",
      review: "review",
    },
    routes: {
      HOMEPAGE: "/",
      PAGE: "",
      CART: "/cart",
      QUIZ: "/virtual-skin-assistant",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/password",
      SAVED: "/wishlist",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      INVALID: "/account/invalid_token",
      ACTIVATE: "/account/activate",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      WISHLIST: "/account/wishlist",
      REWARDS: "/account/rewards",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      ARTICLE: "/blogs",
      STORE: "/stores",
      PICK_MIX: "/pickmix",
      LOYALTY: "/loyalty",
      PICK_GIFT: "/pickgift",
    },
    defaultRedirects: [
      { source: "/collection", destination: "/collections", type: 301 },
      { source: "/checkout", destination: "/cart", type: 301 },
      { source: "/home", destination: "/", type: 301 },
    ],
    clientPaths: [
      "/giftcard/*",
      "/virtual-skin-assistant/*",
      "/wishlist/*",
      "/account/reset/*",
      "/account/activate/*",
      "/account/addresses/*",
      "/account/orders/*",
    ],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/account/wishlist",
      "/account/rewards",
      "/search",
      "/cart",
      "/cart/login",
    ],
    productTags: {
      AGE_OVER_35: "Age: 35+",
      AGE_UNDER_16: "Age: -16",
      CONCERN_AGING: "Concern: Ageing",
      CONCERN_BREAKOUTS: "Concern: Breakouts",
      CONCERN_CONGESTION: "Concern: Congestion",
      CONCERN_DEHYDRATION: "Concern: Dehydration",
      CONCERN_DULLNESS: "Concern: Dullness",
      CONCERN_PIGMENTATION: "Concern: Pigmentation",
      CONCERN_SENSITIVITY: "Concern: Sensitivity",
      PREGNANT: "Pregnant: Yes",
      SKIN_COMBINATION: "Feels: Combination",
      SKIN_SENSITIVITY: "Concern: Sensitivity",
      SKIN_OILY: "Feels: Oily",
      SPF_TINTED: "SPF: Tinted",
      SPF_UNTINTED: "SPF: Untinted",
      CLEANSER: "Product: Cleanser",
      TONER: "Product: Toner",
      OILS_OR_BALM: "Product: Oil or Balm",
      FACE_CREAM: "Product: Face Cream",
      EYES: "Product: Eyes",
      MASK_OR_TREATMENT: "Product: Mask or Treatment",
      EXFOLIANT: "Product: Exfoliant",
      SPF: "Product: SPF",
    },
    quizSlugs: {
      AGE: "age",
      CONCERNS: "concerns",
      SKIN: "skin",
      SENSITIVITY: "sensitivity",
      SPF: "spf",
      PREGNANCY: "pregnancy",
      LOOKING_FOR: "looking-for",
      FULL_ROUTINE: "full-routine",
      NONE: "none",
    },
    customAttributeKeys: {
      giftItem: "_gift_item",
      giftMessage: "_gift_message",
      grouped: "_grouped",
      groupType: "_group_type",
      groupedName: "_grouped_name",
      groupedVariantId: "_grouped_variant_id",
    },
  },
}
