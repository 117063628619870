import tw, { css, styled } from "twin.macro"

import { StyledButton, StyledLink } from "../../Styled/Button"
import { StyledRow } from "../../Styled/Row"
import { P } from "../../Styled/Text"

const styles = {
  content: {
    default: tw`w-full flex-shrink-0 px-4`,
    border: tw`border-b border-grey-light`,
  },
  cartBg: tw`bg-white`,
  cartBody: tw`overflow-auto py-10`,
  cartFooter: tw`sticky bottom-0 border-t border-purple-accent pt-4`,
}

export const Drawer = styled.aside`
  ${styles.cartBg}
  ${tw`h-screen fixed z-20 md:w-121.5 pb-10 inset-y-0 top-0 right-0 flex flex-col justify-between transition-all duration-200`}
  ${({ active }) =>
    active
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(100%);
        `}
  width: 94%;
  max-width: 460px;
`
export const Overlay = styled.div`
  ${tw`fixed z-20 inset-0 bg-black bg-opacity-60 cursor-pointer invisible opacity-0 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100`}
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
`
export const Content = styled.div`
  ${styles.content.default}
`
export const ContentBorder = styled.div`
  ${styles.content.default}
  ${styles.content.border}
`
export const Link = tw(StyledLink)`p-2 pr-0`
export const Button = tw(StyledButton)`block md:w-full`
export const Row = tw(StyledRow)`mb-4`
export const Text = tw(P)`text-2xl tracking-wider`
export const Extras = tw.div`flex items-start justify-between`
export const ShippingStatement = tw(P)`text-xs py-1.5`
export const KeepShoppingWrapper = tw.button`flex items-center justify-between`
export const KeepShopping = tw.div`text-xs underline py-1.5 mr-1.5 whitespace-nowrap`
export const BaseRow = tw.div``
export const CartBody = styled.div`
  ${styles.cartBody}
`
export const CartFooter = styled.div`
  ${styles.cartBg}
  ${styles.content.default}
  ${styles.cartFooter}
`
