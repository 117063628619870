import React, { memo, useCallback, useEffect, useMemo, useState } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useInstagram, useInstgramContext } from "../../../../hooks/useInstagram"
import { useTemplate } from "../../../../hooks/useTemplate"

export const withInstagramPopup = Component =>
  memo(({ name = "InstagramPopup" }: any) => {
    const { headerBounds } = useApp()
    const { items } = useInstagram(true)
    const { active, index, setActive, setIndex } = useInstgramContext()
    const { global: locales } = useTemplate()
    const [imageBounds, setImageBounds] = useState({})
    const [activePin, setActivePin] = useState(null)

    const item = items[index] || items[0]

    const handleActive = useCallback(() => setActive((prevState: boolean) => !prevState), [setActive])
    const handleClick = useCallback(
      (event, id) => {
        event.stopPropagation()
        setActivePin((prevState: string) => (prevState === id ? null : id))
      },
      [setActivePin]
    )
    const handleChange = useCallback((index = null) => setIndex(index !== null ? index : null), [setIndex])
    const handleResize = useCallback(
      (contentRect: any) =>
        setImageBounds(
          !active
            ? {
                height: contentRect?.bounds?.height + (contentRect?.bounds?.height / 100) * 10,
                width: contentRect?.bounds?.width + (contentRect?.bounds?.width / 100) * 10,
              }
            : { height: contentRect?.bounds?.height, width: contentRect?.bounds?.width }
        ),
      [active, setImageBounds]
    )

    // Intentionally only run on active or index change
    useEffect(() => {
      if (activePin) setActivePin(null)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, index])

    Component.displayName = name
    return useMemo(
      () =>
        item ? (
          <Component
            active={active}
            activePin={activePin}
            bounds={headerBounds}
            handleActive={handleActive}
            handleChange={handleChange}
            handleClick={handleClick}
            handleResize={handleResize}
            imageBounds={imageBounds}
            index={index}
            item={item}
            items={items}
            locales={locales}
          />
        ) : null,
      [active, activePin, headerBounds, handleActive, handleChange, handleClick, handleResize, imageBounds, index, item, items, locales]
    )
  })
