import React, { memo, useEffect, useMemo } from "react"

import { useAnalytics } from "../../hooks/useAnalytics"
import { useCheckoutContext } from "../../hooks/useCheckout"
import { useMaintenance } from "../../hooks/useMaintenance"
import { useSettings } from "../../hooks/useSettings"
import { useShopifyProduct } from "../../hooks/useShopify"
import { Meta } from "../Meta/Meta"

export const withLayout = Component =>
  memo(({ name = "Layout", location, children, data, settings: { settings } }: any) => {
    const {
      checkout: { currencyCode },
    } = useCheckoutContext()
    const { organisation } = useSettings()
    const { active, authorised } = useMaintenance(location)
    const { selectProduct } = useShopifyProduct()
    const { trackEvent } = useAnalytics()

    const routes = settings?.routes

    useEffect(() => {
      selectProduct(data?.page, location?.pathname)
      trackEvent()
    }, [location, currencyCode, data, selectProduct, trackEvent])

    Component.displayName = name
    return useMemo(
      () => (
        <>
          <Meta data={data} routes={routes} url={location?.pathname} />
          {active ? (
            <>{children}</>
          ) : (
            authorised && (
              <Component location={location} title={organisation?.title}>
                {children}
              </Component>
            )
          )}
        </>
      ),
      [active, authorised, children, data, location, routes, organisation]
    )
  })
