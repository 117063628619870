import React, { memo, useMemo } from "react"

import { useCheckout } from "../../../hooks/useCheckout"
import { useCart } from "../../../hooks/useCart"
import { useApp } from "../../../hooks/useApp"

export const withCartList = Component =>
  memo(({ name = "CartList", bounds = null, drawer = false, index = null }: any) => {
    const {
      config: {
        settings: { customAttributeKeys },
      },
    } = useApp()
    const { checkout, count } = useCheckout()
    const { getGroupedItems } = useCart()

    const items = useMemo(() => checkout?.lineItems || [], [checkout?.lineItems])

    // For "Pick + Mix" line items, hide the individual Mini product lines entirely
    // and only show the line with the bag and description under it which summarises the products and quantities
    const filteredItems = useMemo(
      () => items.filter(item => !item?.customAttributes?.find(({ key }) => key == customAttributeKeys.groupedName)),
      [customAttributeKeys.groupedName, items]
    )

    Component.displayName = name
    return useMemo(
      () =>
        count > 0 ? (
          <Component bounds={bounds} drawer={drawer} items={items} filteredItems={filteredItems} getGroupedItems={getGroupedItems} index={index} />
        ) : null,
      [bounds, count, drawer, items, filteredItems, getGroupedItems, index]
    )
  })
