import React from "react"

import { withNewsletterForm } from "./withNewsletterForm"
import { FormInput } from "../../Form/Input/FormInput"
import { StyledErrors } from "../../Styled/Form"
import { Form, Button } from "./styledNewsletterForm"

export const NewsletterForm = withNewsletterForm(
  ({ data, errors, handleChange, handleSubmit, locales, loading }): JSX.Element => (
    <Form full onSubmit={handleSubmit}>
      <FormInput
        name={`firstName`}
        inverted
        disabled={loading}
        full
        onChange={handleChange}
        placeholder={locales?.additionalPlaceholderName}
        required
        value={data?.firstName}
        withSpacing={`xxs`}
      />
      <FormInput
        type={`email`}
        name={`email`}
        inverted
        disabled={loading}
        full
        onChange={handleChange}
        placeholder={locales?.additionalPlaceholderEmail}
        required
        value={data?.email}
        withSpacing={`lg`}
      />
      {errors?.length > 0 && (
        <>
          {errors?.map(({ code, message }) => (
            <StyledErrors key={code?.toString()}>{message?.toString()}</StyledErrors>
          ))}
        </>
      )}
      <Button type={`submit`} colour={`purple`} disabled={loading} size={`secondary`} title={locales?.additionalSubmit}>
        {locales?.additionalSubmit}
      </Button>
    </Form>
  )
)
