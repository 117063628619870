import React, { memo, useCallback, useEffect, useMemo, useState } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useCore } from "../../../../hooks/useCore"
import { useCart } from "../../../../hooks/useCart"
import { useDebounce } from "../../../../hooks/useDebounce"
import { useImage } from "../../../../hooks/useImage"
import { useRoutes } from "../../../../hooks/useRoutes"
import { useShopify } from "../../../../hooks/useShopify"
import { useTemplate } from "../../../../hooks/useTemplate"

export const withCartListItem = Component =>
  memo(({ name = "CartListItem", drawer = false, item, grouped, index }: any) => {
    const {
      config: {
        settings: { routes, customAttributeKeys },
      },
    } = useApp()
    const {
      helpers: { decodeShopifyId },
    } = useCore()
    const { loading, removeFromCart, removeMultipleFromCart, updateQuantity } = useCart()
    const { getStaticImage } = useImage()
    const { routeResolver } = useRoutes()
    const { formatMoney } = useShopify()
    const { cart: locales } = useTemplate()
    const [quantity, setQuantity] = useState(item?.quantity)
    const [totalPrice, setTotalPrice] = useState(0)

    const colour = item?.product?.colour
    const variant = item?.variant
    const image = getStaticImage(variant?.image, { placeholder: true })
    const link = routeResolver({ item: variant?.product, type: `product` })
    const gift = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.giftItem) && item?.discountAllocations?.length > 0
    const message = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.giftMessage)?.value
    const pickMixBundleId = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.grouped)?.value
    const pickMixBundleVariantId = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedVariantId)?.value
    const pickMixBundleName = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedName)?.value
    const pickMixBundle = pickMixBundleVariantId === decodeShopifyId(variant?.id, "Variant")
    const pickMixReviewRoute = `${routes.PICK_MIX}?review=${pickMixBundleId}`

    const pickGiftBundleId = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.grouped)?.value
    const pickGiftBundleVariantId = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedVariantId)?.value
    const pickGiftBundleName = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedName)?.value
    const pickGiftGroupType = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupType)?.value
    const pickGiftBundle = pickGiftBundleVariantId === decodeShopifyId(variant?.id, "Variant")
    const pickGiftReviewRoute = `${routes.PICK_GIFT}?review=${pickGiftBundleId}`

    const groupedInfo = useMemo(() => {
      let totalPriceAddUp = 0
      const array = []
      if (pickMixBundle && grouped?.length > 0 && !pickGiftGroupType) {
        for (const groupedItem of grouped) {
          if (decodeShopifyId(groupedItem?.variant?.id, "Variant") !== pickMixBundleVariantId) {
            array.push(`${groupedItem.title} x ${groupedItem.quantity}`)
          }
        }
      } else if (pickGiftBundle && grouped?.length > 0 && pickGiftGroupType) {
        for (const groupedItem of grouped) {
          if (decodeShopifyId(groupedItem?.variant?.id, "Variant") !== pickMixBundleVariantId) {
            array.push(`${groupedItem.title} x ${groupedItem.quantity}`)
            totalPriceAddUp += Number(groupedItem?.variant?.priceV2?.amount * groupedItem.quantity)
            // totalPrice = Number(totalPrice.toFixed(2))
          }

          if (groupedItem?.variant?.product?.tags?.includes("Pick+Gift:Box")) {
            totalPriceAddUp += Number(groupedItem?.variant?.priceV2?.amount)
          }

          if (totalPriceAddUp > 0) {
            setTotalPrice(totalPriceAddUp)
          }
        }
      }

      return array
    }, [decodeShopifyId, grouped, pickMixBundle, pickMixBundleVariantId, pickGiftBundle, pickGiftGroupType])

    // Use this to update the price
    const price = formatMoney(gift ? 0 : totalPrice > 0 ? totalPrice : Number(variant?.priceV2?.amount) * item.quantity || 1)
    const compareAtPrice = formatMoney(gift ? 0 : totalPrice > 0 ? totalPrice : Number(variant?.compareAtPriceV2?.amount) * item.quantity || 1)

    const handleQuantity = useCallback(quantity => setQuantity(quantity), [setQuantity])

    const handleRemove = useCallback(() => {
      if (grouped?.length > 0) {
        const input: Array<{ variantId: string; qty?: number; groupedId?: string }> = [{ variantId: variant.id, groupedId: pickMixBundleId }]
        for (const item of grouped) {
          input.push({ variantId: item.variant?.id, groupedId: pickMixBundleId })
        }
        return removeMultipleFromCart(input)
      } else {
        return removeFromCart(item?.id, variant?.id)
      }
    }, [grouped, item?.id, pickMixBundleId, removeFromCart, removeMultipleFromCart, variant.id])

    useDebounce(
      () => {
        if (quantity !== item?.quantity) {
          if (quantity) {
            updateQuantity(item?.id, variant?.id, quantity)
          } else {
            removeFromCart(item?.id, variant?.id)
          }
        }
      },
      500,
      [item, quantity]
    )

    // intentionally only run on external qty update
    useEffect(() => {
      if (item?.quantity !== quantity) {
        setQuantity(item.quantity)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, setQuantity])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          colour={colour}
          drawer={drawer}
          gift={gift}
          image={image}
          item={item}
          handleQuantity={handleQuantity}
          handleRemove={handleRemove}
          link={link}
          locales={locales}
          loading={loading}
          message={message}
          compareAtPrice={compareAtPrice}
          price={price}
          quantity={quantity}
          variant={variant}
          totalPrice={totalPrice}
          pickGiftBundleName={pickGiftBundleName}
          pickGiftBundle={pickGiftBundle}
          pickGiftReviewRoute={pickGiftReviewRoute}
          pickMixBundleName={pickMixBundleName}
          pickGiftGroupType={pickGiftGroupType}
          pickMixBundle={pickMixBundle}
          pickMixReviewRoute={pickMixReviewRoute}
          groupedInfo={groupedInfo}
          index={index}
        />
      ),
      [
        colour,
        drawer,
        gift,
        totalPrice,
        image,
        item,
        handleQuantity,
        handleRemove,
        link,
        loading,
        locales,
        message,
        compareAtPrice,
        price,
        quantity,
        variant,
        pickGiftBundle,
        pickGiftBundleName,
        pickGiftReviewRoute,
        pickMixBundleName,
        pickGiftGroupType,
        pickMixBundle,
        pickMixReviewRoute,
        groupedInfo,
        index,
      ]
    )
  })
