import React, { memo, useCallback, useMemo } from "react"

export const withNewsletterForm = Component =>
  memo(({ name = "NewsletterForm", data, loading, errors, handleSubmit: submit, locales, setData }: any) => {
    const handleSubmit = useCallback(
      async (event: any) => {
        event.preventDefault()
        submit()
      },
      [submit]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    Component.displayName = name
    return useMemo(
      () => <Component data={data} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} locales={locales} loading={loading} />,
      [data, errors, handleChange, handleSubmit, locales, loading]
    )
  })
