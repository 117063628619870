import React from "react"
import { animated } from "react-spring"

import { withAnimationPopup } from "./withAnimationPopup"
import { Notify, Inner, Popup, Wrapper, Content, ItemGroup, ImageContent, ImageWrapper, Image } from "./styledAnimation"

export const AnimationPopup = withAnimationPopup(({ active, bounds, bagImage, transitions, getSpringConfig }): JSX.Element => {
  return (
    <Notify active={active} top={bounds?.height}>
      <Inner>
        <Popup active={active}>
          <Wrapper>
            <Content>
              <ItemGroup>
                {transitions(({ opacity }, item) => {
                  return <animated.img style={{ ...(getSpringConfig({ opacity, item }) as unknown as any) }} src={item.src}></animated.img>
                })}
              </ItemGroup>
            </Content>
            <ImageContent>
              <ImageWrapper>
                <Image alt={"bag"} fluid={{ ...bagImage, aspectRatio: 4 / 3 }} />
              </ImageWrapper>
            </ImageContent>
          </Wrapper>
        </Popup>
      </Inner>
    </Notify>
  )
})
