import { useCallback, useContext, useMemo, useState } from "react"
import { navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCart } from "./useCart"
import { useCore } from "./useCore"
import { useCustomerContext } from "./useCustomer"
import { useRoutes } from "./useRoutes"
import { WishlistContext } from "../providers/wishlist"

export const useWishlistContext = () => {
  const wishlist: any = useContext(WishlistContext)

  return { ...wishlist }
}

export const useWishlist = (location = null) => {
  const {
    config: {
      services: { sharing },
    },
  } = useApp()
  const {
    helpers: { encodeBase64, isBrowser },
  } = useCore()
  const { customer } = useCustomerContext()
  const { getWishlist, getSharedWishlist, wishlist, setWishlist, sharedWishlist, setSharedWishlist, setAdded } = useWishlistContext()
  const { routeResolver } = useRoutes()
  const { addToCart, removeFromCart } = useCart()
  const [loading, setLoading] = useState(false)

  const allowed = useMemo(() => ["id", "selectedSku", "selectedTitle", "handle", "title", "vendor", "image", "images", "tags"], [])

  const filterData = useCallback(
    (allowed, data) =>
      Object.keys(data)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => ({ ...obj, [key]: data[key] }), {}),
    []
  )

  const formatData = useCallback(
    data => ({
      ...filterData(allowed, data),
    }),
    [filterData, allowed]
  )

  const addToWishlist = useCallback(
    async (data, context = null, scroll = null) => {
      const mappedData = formatData(data)

      if (isBrowser) {
        setAdded({ product: mappedData, scroll, url: context || window?.location?.pathname })
      }
      if (customer?.id) {
        setLoading(true)
        await setWishlist(prevState => (wishlist?.length ? [...prevState, mappedData] : [mappedData]))
        setLoading(false)
      }
      navigate(routeResolver({ type: `wishlist` }))
    },
    [customer, formatData, isBrowser, routeResolver, setAdded, setLoading, setWishlist, wishlist]
  )

  const deleteFromWishlist = useCallback(
    async id => {
      setLoading(true)
      await setWishlist(prevState => prevState.filter(item => item?.id !== id))
      setLoading(false)
    },
    [setLoading, setWishlist]
  )

  const moveToCart = useCallback(
    async (id, variantId) => {
      setLoading(true)
      await addToCart(variantId, 1)
      await setWishlist(prevState => prevState.filter(item => item?.id !== id))
      if (wishlist?.length > 1) await getWishlist(wishlist.filter(item => item?.id !== id))
      setLoading(false)
    },
    [setLoading, setWishlist, getWishlist, addToCart, wishlist]
  )

  const moveToWishlist = useCallback(
    async (data, id, variantId) => {
      setLoading(true)
      const mappedData = formatData(data)
      await removeFromCart(id, variantId)
      await setWishlist(prevState => (wishlist?.length ? [...prevState, mappedData] : [mappedData]))
      setLoading(false)
    },
    [setLoading, setWishlist, wishlist, removeFromCart, formatData]
  )

  const existsInWishlist = useCallback(id => customer?.id && wishlist?.filter(item => item?.id === id).length, [customer, wishlist])

  const updateWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      await setWishlist(prevState => [...prevState.filter(item => item?.id !== data?.id), mappedData])
      setLoading(false)
    },
    [setLoading, setWishlist, formatData]
  )

  const shareWishlist = useCallback(
    (network, title) => {
      const shareLinks = (url: string) => ({
        email: `mailto:?subject=${title}&body=${url}`,
        facebook: `${sharing?.facebook}${encodeURIComponent(url)}`,
        pinterest: `${sharing?.pinterest}${encodeURIComponent(url)}`,
      })

      const string = wishlist?.map(item => `${item.handle}:${item.selectedSku}`).join("|")
      const url = `${location?.origin}${routeResolver({ type: `saved` })}/${encodeBase64(string)}`
      window.open(shareLinks(url)?.[network], `_blank`)
    },
    [encodeBase64, location, routeResolver, sharing, wishlist]
  )

  const saveWishlist = useCallback(() => {
    setLoading(true)
    setWishlist(sharedWishlist)
    setSharedWishlist([])
    navigate(`${routeResolver({ type: `saved` })}`, { replace: true })
    setLoading(false)
  }, [setLoading, setWishlist, setSharedWishlist, sharedWishlist, routeResolver])

  const count = wishlist?.length || 0

  return {
    addToWishlist,
    count,
    deleteFromWishlist,
    existsInWishlist,
    getSharedWishlist,
    loading,
    moveToCart,
    moveToWishlist,
    shareWishlist,
    saveWishlist,
    updateWishlist,
  }
}
