import React from "react"

import { withIcon } from "./withIcon"
import { Svg } from "./styledIcon"

export const Icon = withIcon(({ colour, height, icon, id, title, width, withSpacing, fill }) => (
  <>
    {icon ? (
      <Svg
        id={id}
        colour={colour}
        height={height === `auto` ? `100%` : height}
        width={width === `auto` ? `100%` : width}
        withSpacing={withSpacing}
        viewBox={icon.viewBox}
        fill={fill}
      >
        <title id={`${id}_title`}>{title}</title>
        <use xlinkHref={icon.url} />
      </Svg>
    ) : (
      <p>[{title}]</p>
    )}
  </>
))
