import React from "react"

import { withCartNotes } from "./withCartNotes"
import { Accordion } from "../../Accordion/Accordion"
import { FormInput } from "../../Form/Input/FormInput"
import { StyledForm, StyledErrorCentered } from "../../Styled/Form"
import { StyledButton } from "../../Styled/Button"
import { Wrapper } from "./styledCartNotes"

export const CartNotes = withCartNotes(
  ({ note, locales, loading, canSave, showNotesError, handleNoteChange, handleSave, setHeight, show }): JSX.Element => (
    <Wrapper setHeight={setHeight} show={show}>
      <Accordion
        active={note && note !== "" ? 0 : undefined}
        freeze={canSave}
        noBorder={true}
        together={true}
        items={[
          {
            children: (
              <StyledForm onSubmit={handleSave}>
                <FormInput
                  name={`discount`}
                  onChange={handleNoteChange}
                  value={note}
                  required={!canSave || undefined}
                  withSpacing={`xs`}
                  srOnly={true}
                  drawerInput={true}
                  full={true}
                />
                <StyledButton type={`submit`} colour={`purple`} disabled={loading || !canSave} size={`quaternary`} title={`Save`}>
                  SAVE
                </StyledButton>
                {showNotesError && <StyledErrorCentered>{locales?.additionalCustomerNoteError || "Please submit to proceed"}</StyledErrorCentered>}
              </StyledForm>
            ),
            title: locales?.additionalCustomerNotePlaceholder,
          },
        ]}
      />
    </Wrapper>
  )
)
