import React, { memo, useMemo } from "react"

import { useContent } from "../../hooks/useContent"

import Account from "../../../static/icons/account.svg"
import Afterpay from "../../../static/icons/afterpay.svg"
import Bag from "../../../static/icons/bag.svg"
import Check from "../../../static/icons/check.svg"
import Close from "../../../static/icons/close.svg"
import Cross from "../../../static/icons/cross.svg"
import Delete from "../../../static/icons/delete.svg"
import Down from "../../../static/icons/down.svg"
import DownArrow from "../../../static/icons/down-arrow.svg"
import Download from "../../../static/icons/download.svg"
import Drop from "../../../static/icons/drop.svg"
import Edit from "../../../static/icons/edit.svg"
import Email from "../../../static/icons/email.svg"
import Eye from "../../../static/icons/eye.svg"
import EyeClosed from "../../../static/icons/eye-closed.svg"
import Facebook from "../../../static/icons/facebook.svg"
import Heart from "../../../static/icons/heart.svg"
import HeartOutline from "../../../static/icons/heart-outline.svg"
import Left from "../../../static/icons/left.svg"
import Location from "../../../static/icons/location.svg"
import Logo from "../../../static/icons/logo.svg"
import Link from "../../../static/icons/link.svg"
import Menu from "../../../static/icons/menu.svg"
import Messenger from "../../../static/icons/messenger.svg"
import Minus from "../../../static/icons/minus.svg"
import Mute from "../../../static/icons/mute.svg"
import Pause from "../../../static/icons/pause.svg"
import PauseCta from "../../../static/icons/pause-cta.svg"
import Play from "../../../static/icons/play.svg"
import PlayCta from "../../../static/icons/play-cta.svg"
import Plus from "../../../static/icons/plus.svg"
import Right from "../../../static/icons/right.svg"
import Search from "../../../static/icons/search.svg"
import Star from "../../../static/icons/star.svg"
import Tag from "../../../static/icons/tag.svg"
import Tick from "../../../static/icons/tick.svg"
import Unmute from "../../../static/icons/unmute.svg"
import Up from "../../../static/icons/up.svg"
import RightCaret from "../../../static/icons/rightCaret.svg"
import LeftCaret from "../../../static/icons/leftCaret.svg"

import BrandAlfalfa from "../../../static/icons/brand/brand-alfalfa.svg"
import BrandAllantoin from "../../../static/icons/brand/brand-allantoin.svg"
import BrandAloeVera from "../../../static/icons/brand/brand-aloe-vera.svg"
import BrandApricotKernal from "../../../static/icons/brand/brand-apricot-kernal.svg"
import BrandAustralia from "../../../static/icons/brand/brand-australia.svg"
import BrandAvocado from "../../../static/icons/brand/brand-avocado.svg"
import BrandBanana from "../../../static/icons/brand/brand-banana.svg"
import BrandBisabolol from "../../../static/icons/brand/brand-bisabolol.svg"
import BrandBlackcurrant from "../../../static/icons/brand/brand-blackcurrant.svg"
import BrandBlueberry from "../../../static/icons/brand/brand-blueberry.svg"
import BrandBrownAlgae from "../../../static/icons/brand/brand-brown-algae.svg"
import BrandCamellia from "../../../static/icons/brand/brand-camellia.svg"
import BrandChamomile from "../../../static/icons/brand/brand-chamomile.svg"
import BrandCherry from "../../../static/icons/brand/brand-cherry.svg"
import BrandChia from "../../../static/icons/brand/brand-chia.svg"
import BrandCobiolift from "../../../static/icons/brand/brand-cobiolift.svg"
import BrandCocoaButter from "../../../static/icons/brand/brand-cocoa-butter.svg"
import BrandCoconut from "../../../static/icons/brand/brand-coconut.svg"
import BrandColloidalOatmeal from "../../../static/icons/brand/brand-colloidal-oatmeal.svg"
import BrandCommiphoraMukul from "../../../static/icons/brand/brand-commiphora-mukul.svg"
import BrandCucumber from "../../../static/icons/brand/brand-cucumber.svg"
import BrandDragonFruit from "../../../static/icons/brand/brand-dragon-fruit.svg"
import BrandDavidsonPlum from "../../../static/icons/brand/brand-davidson-plum.svg"
import BrandEarthMinerals from "../../../static/icons/brand/brand-earth-minerals.svg"
import BrandFucogel from "../../../static/icons/brand/brand-fucogel.svg"
import BrandGrapefruit from "../../../static/icons/brand/brand-grapefruit.svg"
import BrandGreenTea from "../../../static/icons/brand/brand-green-tea.svg"
import BrandGuava from "../../../static/icons/brand/brand-guava.svg"
import BrandHoney from "../../../static/icons/brand/brand-honey.svg"
import BrandHyaloronicAcid from "../../../static/icons/brand/brand-hyaloronic-acid.svg"
import BrandJojoba from "../../../static/icons/brand/brand-jojoba.svg"
import BrandKakaduPlum from "../../../static/icons/brand/brand-kakadu-plum.svg"
import BrandKale from "../../../static/icons/brand/brand-kale.svg"
import BrandKaolinClay from "../../../static/icons/brand/brand-kaolin-clay.svg"
import BrandKiwiFruit from "../../../static/icons/brand/brand-kiwi-fruit.svg"
import BrandLAscorbicAcid from "../../../static/icons/brand/brand-l-ascorbic-acid.svg"
import BrandLacticAcid from "../../../static/icons/brand/brand-lactic-acid.svg"
import BrandLavendar from "../../../static/icons/brand/brand-lavendar.svg"
import BrandLeaf from "../../../static/icons/brand/brand-leaf.svg"
import BrandLemonMyrtle from "../../../static/icons/brand/brand-lemon-myrtle.svg"
import BrandLemon from "../../../static/icons/brand/brand-lemon.svg"
import BrandLime from "../../../static/icons/brand/brand-lime.svg"
import BrandMacadamia from "../../../static/icons/brand/brand-macadamia.svg"
import BrandMandarin from "../../../static/icons/brand/brand-mandarin.svg"
import BrandMandelicAcid from "../../../static/icons/brand/brand-mandelic-acid.svg"
import BrandMangoButter from "../../../static/icons/brand/brand-mango-butter.svg"
import BrandMatilook from "../../../static/icons/brand/brand-matilook.svg"
import BrandMicronizedAlgae from "../../../static/icons/brand/brand-micronized-algae.svg"
import BrandMultiFruit from "../../../static/icons/brand/brand-multi-fruit.svg"
import BrandOlive from "../../../static/icons/brand/brand-olive.svg"
import BrandPapayaEnzymes from "../../../static/icons/brand/brand-papaya-enzymes.svg"
import BrandPassionfruit from "../../../static/icons/brand/brand-passionfruit.svg"
import BrandPaw from "../../../static/icons/brand/brand-paw.svg"
import BrandPineappleEnzymes from "../../../static/icons/brand/brand-pineapple-enzymes.svg"
import BrandPineapple from "../../../static/icons/brand/brand-pineapple.svg"
import BrandPomegranate from "../../../static/icons/brand/brand-pomegranate.svg"
import BrandPricklyPear from "../../../static/icons/brand/brand-prickly-pear.svg"
import BrandProbiobalance from "../../../static/icons/brand/brand-probiobalance.svg"
import BrandPumpkin from "../../../static/icons/brand/brand-pumpkin.svg"
import BrandRaspberry from "../../../static/icons/brand/brand-raspberry.svg"
import BrandRaspberryLeaf from "../../../static/icons/brand/brand-raspberry-leaf.svg"
import BrandResveratrol from "../../../static/icons/brand/brand-resveratrol.svg"
import BrandRoseGeranium from "../../../static/icons/brand/brand-rose-geranium.svg"
import BrandRose from "../../../static/icons/brand/brand-rose.svg"
import BrandRosemary from "../../../static/icons/brand/brand-rosemary.svg"
import BrandSafflower from "../../../static/icons/brand/brand-safflower.svg"
import BrandSeaBuckthorn from "../../../static/icons/brand/brand-sea-buckthorn.svg"
import BrandSheaButter from "../../../static/icons/brand/brand-shea-butter.svg"
import BrandStarFruit from "../../../static/icons/brand/brand-star-fruit.svg"
import BrandSunflower from "../../../static/icons/brand/brand-sunflower.svg"
import BrandSweetFennel from "../../../static/icons/brand/brand-sweet-fennel.svg"
import BrandTomato from "../../../static/icons/brand/brand-tomato.svg"
import BrandTotarol from "../../../static/icons/brand/brand-totarol.svg"
import BrandTurmeric from "../../../static/icons/brand/brand-turmeric.svg"
import BrandVanilla from "../../../static/icons/brand/brand-vanilla.svg"
import BrandVitALike from "../../../static/icons/brand/brand-vit-a-like.svg"
import BrandWatermelon from "../../../static/icons/brand/brand-watermelon.svg"
import BrandWattle from "../../../static/icons/brand/brand-wattle.svg"
import BrandYlangYlang from "../../../static/icons/brand/brand-ylang-ylang.svg"
import BrandZinc from "../../../static/icons/brand/brand-zinc.svg"

import FaceBalanced from "../../../static/icons/face/face-balanced.svg"
import FaceBalancing from "../../../static/icons/face/face-balancing.svg"
import FaceBooster from "../../../static/icons/face/face-booster.svg"
import FaceCalmer from "../../../static/icons/face/face-calmer.svg"
import FaceCleanser from "../../../static/icons/face/face-cleanser.svg"
import FaceClearer from "../../../static/icons/face/face-clearer.svg"
import FaceCombination from "../../../static/icons/face/face-combination.svg"
import FaceDay from "../../../static/icons/face/face-day.svg"
import FaceDayCircle from "../../../static/icons/face/face-day-circle.svg"
import FaceDry from "../../../static/icons/face/face-dry.svg"
import FaceExfoliants from "../../../static/icons/face/face-exfoliants.svg"
import FaceEye from "../../../static/icons/face/face-eye.svg"
import FaceFirmer from "../../../static/icons/face/face-firmer.svg"
import FaceGlowing from "../../../static/icons/face/face-glowing.svg"
import FaceHeart from "../../../static/icons/face/face-heart.svg"
import FaceIrritated from "../../../static/icons/face/face-irritated.svg"
import FaceMakeup from "../../../static/icons/face/face-makeup.svg"
import FaceMasks from "../../../static/icons/face/face-masks.svg"
import FaceMoisturisers from "../../../static/icons/face/face-moisturisers.svg"
import FaceNight from "../../../static/icons/face/face-night.svg"
import FaceNightCircle from "../../../static/icons/face/face-night-circle.svg"
import FaceNotPregnant from "../../../static/icons/face/face-not-pregnant.svg"
import FaceOils from "../../../static/icons/face/face-oils.svg"
import FaceOily from "../../../static/icons/face/face-oily.svg"
import FacePoppy from "../../../static/icons/face/face-poppy.svg"
import FacePregnant from "../../../static/icons/face/face-pregnant.svg"
import FaceSensitive from "../../../static/icons/face/face-sensitive.svg"
import FaceSun from "../../../static/icons/face/face-sun.svg"
import FaceToners from "../../../static/icons/face/face-toners.svg"
import FaceUnbalanced from "../../../static/icons/face/face-unbalanced.svg"

import PaymentsAfterpay from "../../../static/icons/payments/payments-afterpay.svg"
import PaymentsApplepay from "../../../static/icons/payments/payments-applepay.svg"
import PaymentsMastercard from "../../../static/icons/payments/payments-mastercard.svg"
import PaymentsPaypal from "../../../static/icons/payments/payments-paypal.svg"
import PaymentsVisa from "../../../static/icons/payments/payments-visa.svg"

import SocialFacebook from "../../../static/icons/social/social-facebook.svg"
import SocialInstagram from "../../../static/icons/social/social-instagram.svg"
import SocialPinterest from "../../../static/icons/social/social-pinterest.svg"
import SocialYoutube from "../../../static/icons/social/social-youtube.svg"

const icons = {
  account: Account,
  afterpay: Afterpay,
  bag: Bag,
  check: Check,
  close: Close,
  cross: Cross,
  delete: Delete,
  down: Down,
  "down-arrow": DownArrow,
  download: Download,
  drop: Drop,
  edit: Edit,
  email: Email,
  eye: Eye,
  "eye-closed": EyeClosed,
  Facebook: Facebook,
  heart: Heart,
  "heart-outline": HeartOutline,
  left: Left,
  location: Location,
  logo: Logo,
  rightCaret: RightCaret,
  leftCaret: LeftCaret,
  "Copy link": Link,
  menu: Menu,
  Messenger: Messenger,
  minus: Minus,
  mute: Mute,
  pause: Pause,
  "pause-cta": PauseCta,
  play: Play,
  "play-cta": PlayCta,
  plus: Plus,
  right: Right,
  search: Search,
  star: Star,
  tag: Tag,
  tick: Tick,
  up: Up,
  unmute: Unmute,
  "brand-alfalfa": BrandAlfalfa,
  "brand-allantoin": BrandAllantoin,
  "brand-aloe-vera": BrandAloeVera,
  "brand-apricot-kernal": BrandApricotKernal,
  "brand-australia": BrandAustralia,
  "brand-avocado": BrandAvocado,
  "brand-banana": BrandBanana,
  "brand-bisabolol": BrandBisabolol,
  "brand-blackcurrant": BrandBlackcurrant,
  "brand-blueberry": BrandBlueberry,
  "brand-brown-algae": BrandBrownAlgae,
  "brand-camellia": BrandCamellia,
  "brand-chamomile": BrandChamomile,
  "brand-cherry": BrandCherry,
  "brand-chia": BrandChia,
  "brand-cobiolift": BrandCobiolift,
  "brand-cocoa-butter": BrandCocoaButter,
  "brand-coconut": BrandCoconut,
  "brand-colloidal-oatmeal": BrandColloidalOatmeal,
  "brand-commiphora-mukul": BrandCommiphoraMukul,
  "brand-cucumber": BrandCucumber,
  "brand-dragon-fruit": BrandDragonFruit,
  "brand-davidson-plum": BrandDavidsonPlum,
  "brand-earth-minerals": BrandEarthMinerals,
  "brand-fucogel": BrandFucogel,
  "brand-grapefruit": BrandGrapefruit,
  "brand-green-tea": BrandGreenTea,
  "brand-guava": BrandGuava,
  "brand-honey": BrandHoney,
  "brand-hyaloronic-acid": BrandHyaloronicAcid,
  "brand-jojoba": BrandJojoba,
  "brand-kakadu-plum": BrandKakaduPlum,
  "brand-kale": BrandKale,
  "brand-kaolin-clay": BrandKaolinClay,
  "brand-kiwi-fruit": BrandKiwiFruit,
  "brand-l-ascorbic-acid": BrandLAscorbicAcid,
  "brand-lactic-acid": BrandLacticAcid,
  "brand-lavendar": BrandLavendar,
  "brand-leaf": BrandLeaf,
  "brand-lemon-myrtle": BrandLemonMyrtle,
  "brand-lemon": BrandLemon,
  "brand-lime": BrandLime,
  "brand-macadamia": BrandMacadamia,
  "brand-mandarin": BrandMandarin,
  "brand-mandelic-acid": BrandMandelicAcid,
  "brand-mango-butter": BrandMangoButter,
  "brand-matilook": BrandMatilook,
  "brand-micronized-algae": BrandMicronizedAlgae,
  "brand-multi-fruit": BrandMultiFruit,
  "brand-olive": BrandOlive,
  "brand-papaya-enzymes": BrandPapayaEnzymes,
  "brand-passionfruit": BrandPassionfruit,
  "brand-paw": BrandPaw,
  "brand-pineapple-enzymes": BrandPineappleEnzymes,
  "brand-pineapple": BrandPineapple,
  "brand-pomegranate": BrandPomegranate,
  "brand-prickly-pear": BrandPricklyPear,
  "brand-probiobalance": BrandProbiobalance,
  "brand-pumpkin": BrandPumpkin,
  "brand-raspberry": BrandRaspberry,
  "brand-raspberry-leaf": BrandRaspberryLeaf,
  "brand-resveratrol": BrandResveratrol,
  "brand-rose-geranium": BrandRoseGeranium,
  "brand-rose": BrandRose,
  "brand-rosemary": BrandRosemary,
  "brand-safflower": BrandSafflower,
  "brand-sea-buckthorn": BrandSeaBuckthorn,
  "brand-shea-butter": BrandSheaButter,
  "brand-star-fruit": BrandStarFruit,
  "brand-sunflower": BrandSunflower,
  "brand-sweet-fennel": BrandSweetFennel,
  "brand-tomato": BrandTomato,
  "brand-totarol": BrandTotarol,
  "brand-turmeric": BrandTurmeric,
  "brand-vanilla": BrandVanilla,
  "brand-vit-a-like": BrandVitALike,
  "brand-watermelon": BrandWatermelon,
  "brand-wattle": BrandWattle,
  "brand-ylang-ylang": BrandYlangYlang,
  "brand-zinc": BrandZinc,
  "face-balanced": FaceBalanced,
  "face-balancing": FaceBalancing,
  "face-booster": FaceBooster,
  "face-calmer": FaceCalmer,
  "face-cleanser": FaceCleanser,
  "face-clearer": FaceClearer,
  "face-combination": FaceCombination,
  "face-day": FaceDay,
  "face-day-circle": FaceDayCircle,
  "face-dry": FaceDry,
  "face-exfoliants": FaceExfoliants,
  "face-eye": FaceEye,
  "face-firmer": FaceFirmer,
  "face-glowing": FaceGlowing,
  "face-heart": FaceHeart,
  "face-irritated": FaceIrritated,
  "face-makeup": FaceMakeup,
  "face-masks": FaceMasks,
  "face-moisturisers": FaceMoisturisers,
  "face-night": FaceNight,
  "face-night-circle": FaceNightCircle,
  "face-not-pregnant": FaceNotPregnant,
  "face-oils": FaceOils,
  "face-oily": FaceOily,
  "face-poppy": FacePoppy,
  "face-pregnant": FacePregnant,
  "face-sensitive": FaceSensitive,
  "face-sun": FaceSun,
  "face-toners": FaceToners,
  "face-unbalanced": FaceUnbalanced,
  "payments-afterpay": PaymentsAfterpay,
  "payments-applepay": PaymentsApplepay,
  "payments-mastercard": PaymentsMastercard,
  "payments-paypal": PaymentsPaypal,
  "payments-visa": PaymentsVisa,
  "social-facebook": SocialFacebook,
  "social-instagram": SocialInstagram,
  "social-pinterest": SocialPinterest,
  "social-youtube": SocialYoutube,
}

export const withIcon = Component =>
  memo(({ componentName = "Icon", colour = null, name, title, height = null, width = `auto`, withSpacing = null, fill }: any) => {
    const { capitalise } = useContent()

    const icon = icons[name]
    const id = `${icon?.id}_${Math.random().toString(36).substr(2, 9)}`
    const types = [`brand-`, `face-`, `payments-`, `social-`]
    const regex = new RegExp(types.map(type => type).join(`|`), `gi`)
    const formattedTitle = title || capitalise(name?.replace(regex, ``)?.replace(/-/g, ` `))

    Component.displayName = componentName
    return useMemo(
      () => (
        <Component colour={colour} height={height} icon={icon} id={id} title={formattedTitle} width={width} withSpacing={withSpacing} fill={fill} />
      ),
      [colour, height, icon, id, formattedTitle, width, withSpacing, fill]
    )
  })
