import { useCallback } from "react"
import { navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useSettings } from "./useSettings"

export const useSearchSwatches = () => {
  const {
    helpers: { edgeNormaliser },
  } = useApp()
  const {
    helpers: { handleize },
  } = useCore()
  const { swatches } = useSettings()

  const getSwatches = () =>
    edgeNormaliser(swatches)?.map(({ colour, icon, linked, name }) => ({
      colour: colour?.toLowerCase()?.replace(/ /g, `-`) || `purple-dark`,
      icon: icon?.toLowerCase()?.replace(/ /g, `-`),
      linked,
      name,
    })) || []

  const getSwatch = (value: string) =>
    getSwatches().find(
      (swatch: any) =>
        handleize(swatch?.name) === handleize(value) || swatch?.linked?.map((item: string) => handleize(item)).includes(handleize(value))
    ) || false

  return { getSwatch, getSwatches }
}

export const useSearchRedirect = () => {
  const handleRedirect = useCallback((type, url) => ["search", "redirect"].includes(type) && navigate(url), [])

  return { handleRedirect }
}
