import tw, { styled } from "twin.macro"

import { GlobalStyles } from "./Global"

const styles = {
  default: tw`w-full max-w-full mx-auto px-6 md:px-12 lg:px-18.75 print:px-0`,
  condensed: {
    default: tw`px-2 md:px-4 lg:px-4`,
    "md>": tw`md:px-4 lg:px-4`,
  },
  collapse: {
    default: tw`px-0 md:px-0 lg:px-0`,
    "<sm": tw`px-0 md:px-12 lg:px-18.75`,
    "<md": tw`px-0 md:px-0 lg:px-18.75`,
    "<xl": tw`px-0 md:px-0 lg:px-2`,
  },
  width: {
    sm: tw`max-w-screen-sm`,
    md: tw`max-w-screen-md`,
    lg: tw`max-w-screen-lg`,
    xlg: tw`max-w-300`,
    xl: tw`max-w-screen-xl`,
    default: tw`max-w-full`,
  },
}

export const StyledContainer = styled.div`
  ${styles.default}
  ${({ condensed }) => styles.condensed[condensed] || (condensed && styles.condensed[`default`])}
  ${({ collapse }) => styles.collapse[collapse] || (collapse && styles.collapse[`default`])}
  ${({ width }) => styles.width[width] || styles.width[`default`]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${({ prototype }) => prototype && tw`px-0 lg:px-0`}
`
