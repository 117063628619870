import React from "react"

import { withProductCard } from "./withProductCard"
import { Icon } from "../../Icon/Icon"
import { StyledImage } from "../../Styled/Image"
import { P, H5 } from "../../Styled/Text"
import { Card, Button, Content, Image, ImageContainer, Wishlist, ComparedPrice } from "./styledProductCard"

export const ProductCard = withProductCard(
  ({
    adding,
    added,
    card,
    colour,
    condensed,
    giftcard,
    handleAdd,
    handleClick,
    handleHover,
    handleNotify,
    handleWishlist,
    hover,
    images,
    locales,
    price,
    priceVaries,
    title,
    type,
    variant,
    wishlist,
    withSpacing,
    hideTitle,
    clientId,
    prototype,
  }) => (
    <Card onClick={handleClick} onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} ref={card} withSpacing={withSpacing}>
      <Content>
        <ImageContainer colour={`${colour}-pastel`}>
          <Image hover={images[1] && hover}>
            <StyledImage alt={images[0]?.alt || title} fluid={{ ...images[0], aspectRatio: 3 / 4 }} />
          </Image>
          {images[1] && (
            <Image hover={!hover}>
              <StyledImage alt={images[1]?.alt || title} fluid={{ ...images[1], aspectRatio: 3 / 4 }} />
            </Image>
          )}
          <Wishlist
            active={wishlist}
            colour={prototype ? `white` : wishlist ? `orange-dark` : `default`}
            onClick={handleWishlist}
            title={wishlist ? locales?.additionalRemoveFromWishlist : locales?.additionalAddToWishlist}
          >
            <Icon fill={wishlist ? `auto` : `none`} name={wishlist ? `heart` : `heart-outline`} />
          </Wishlist>
        </ImageContainer>
        {!hideTitle && (
          <>
            <H5 uppercase>{title}</H5>
            {type ? <P withSpacing={`xs`}>{type}</P> : <P dangerouslySetInnerHtml={{ __html: `&nbsp;` }} withSpacing={`xs`} />}
          </>
        )}
      </Content>
      {variant && (
        <Button
          colour={priceVaries || variant?.availableForSale ? `orange` : `orange-outline`}
          disabled={adding}
          onClick={priceVaries ? handleClick : variant?.availableForSale ? handleAdd : handleNotify}
          size={condensed ? `tertiary` : `secondary`}
          title={
            priceVaries
              ? locales?.additionalPriceVaries?.replace(`{price}`, price || `—`)
              : variant?.availableForSale
              ? added
                ? locales?.additionalAddedToCart
                : locales?.additionalAddToCart
              : locales?.additionalNotify
          }
          data-clientid={clientId ? clientId : null}
          prototype={prototype}
        >
          {giftcard && variant?.compareAtPriceV2?.amount && variant?.priceV2?.amount > variant?.compareAtPriceV2?.amount ? (
            <>
              <ComparedPrice>&nbsp;{price}&nbsp;</ComparedPrice>
              {priceVaries
                ? locales?.additionalPriceVaries?.replace(`{price}`, variant?.compareAtPriceV2?.local || `—`)
                : `${variant?.compareAtPriceV2?.local ? `${variant?.compareAtPriceV2?.local} — ` : ``}${
                    variant?.availableForSale ? (added ? locales?.additionalAddedToCart : locales?.additionalAddToCart) : locales?.additionalNotify
                  }`}
            </>
          ) : (
            <>
              {variant?.compareAtPriceV2?.amount > variant?.priceV2?.amount ? (
                <ComparedPrice>&nbsp;{variant?.compareAtPriceV2?.local}&nbsp;</ComparedPrice>
              ) : null}
              {priceVaries
                ? locales?.additionalPriceVaries?.replace(`{price}`, price || `—`)
                : `${price ? `${price} — ` : ``}${
                    variant?.availableForSale ? (added ? locales?.additionalAddedToCart : locales?.additionalAddToCart) : locales?.additionalNotify
                  }`}
            </>
          )}
        </Button>
      )}
    </Card>
  )
)
