import React, { memo, useCallback, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useTemplate } from "../../../hooks/useTemplate"

export const withSearchWidget = Component =>
  memo(({ name = "SearchWidget", screen = null, handleMobileMenu }: any) => {
    const {
      globalState,
      config: {
        services: { reactify },
      },
    } = useApp()
    const { global: locales } = useTemplate()

    const [state, dispatch] = globalState

    const handleClick = useCallback(() => {
      screen === "sm" && handleMobileMenu(false)
      dispatch({
        type: "setActiveSearch",
        payload: !state.activeSearch,
      })

      if (!state?.activeSearch) {
        setTimeout(() => document.querySelector(reactify?.searchFieldSelector)?.select(), 1)
      }
    }, [dispatch, reactify, state, handleMobileMenu, screen])

    Component.displayName = name
    return useMemo(() => <Component handleClick={handleClick} locales={locales} screen={screen} />, [handleClick, locales, screen])
  })
