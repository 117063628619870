import tw, { css } from "twin.macro"
import styled from "@emotion/styled/macro"

import { GlobalStyles } from "./Global"

const styles = {
  vertical: tw`flex flex-col`,
  self: {
    start: tw`self-start`,
    center: tw`self-center`,
    stretch: tw`self-stretch`,
    end: tw`self-end`,
  },
  items: {
    start: tw`items-start`,
    center: tw`items-center`,
    stretch: tw`items-stretch`,
    end: tw`items-end`,
  },
  justify: {
    start: tw`flex justify-start`,
    center: tw`flex justify-center`,
    end: tw`flex justify-end`,
    between: tw`flex justify-between`,
  },
  gutter: {
    none: tw`px-0 md:px-0`,
    default: tw`px-6 md:px-7.5`,
    xxs: tw`px-1.25`,
    xs: tw`px-1.5 md:px-2.5`,
    sm: tw`px-1.875 md:px-3.75`,
    lg: tw`px-6 lg:px-12`,
    xl: tw`px-6 lg:px-18.75`,
  },
  width: {
    default: tw`w-full`,
    grow: tw`flex-grow`,
    fit: tw`w-fit`,
    "no-shrink": tw`flex-shrink-0`,
    "md:no-shrink": tw`w-full md:w-auto md:flex-shrink-0`,
    "1/6": tw`w-1/6`,
    "2/5": tw`w-2/5`,
    "1/4": tw`w-1/4`,
    "3/4": tw`w-3/4`,
    "1/3": tw`w-1/3`,
    "2/3": tw`w-2/3`,
    "1/2": tw`w-1/2`,
    "1/6 md:full": tw`w-1/6 md:w-full`,
    "1/2 md:full": tw`w-1/2 md:w-full`,
    "1/2 md:1/3": tw`w-1/2 md:w-1/3`,
    "1/2 md:1/4": tw`w-1/2 md:w-1/4`,
    "1/3 md:full": tw`w-1/3 md:w-full`,
    "2/3 md:full": tw`w-2/3 md:w-full`,
    "1/3 md:1/4": tw`w-1/3 md:w-1/4`,
    "1/2 lg:1/4": tw`w-1/2 md:w-1/3 lg:w-1/4`,
    "md:1/2 lg:1/4": tw`w-full md:w-1/2 lg:w-1/4`,
    "md:1/2 lg:1/3": tw`w-full md:w-1/2 lg:w-1/3`,
    "md:1/3 lg:1/4": tw`w-full md:w-1/3 lg:w-1/4`,
    "md:2/3 lg:3/4": tw`w-full md:w-2/3 lg:w-3/4`,
    "md:1/2": tw`w-full md:w-1/2`,
    "md:1/3": tw`w-full md:w-1/3`,
    "md:2/3": tw`w-full md:w-2/3`,
    "md:1/4": tw`w-full md:w-1/4`,
    "md:3/4": tw`w-full md:w-3/4`,
    "md:1/6": tw`w-full md:w-1/6`,
    "md:1/8": tw`w-full md:w-1/8`,
    "lg:1/2": tw`w-full lg:w-1/2`,
    "lg:1/3": tw`w-full lg:w-1/3`,
    "lg:2/3": tw`w-full lg:w-2/3`,
    "lg:1/4": tw`w-full lg:w-1/4`,
    "lg:3/4": tw`w-full lg:w-3/4`,
    "lg:2/5 xl:7/20": tw`w-full lg:w-2/5 xl:w-7/20`,
  },
  padding: {
    "lg:pb-2.5": tw`lg:pb-2.5`,
  },
}

export const StyledColumn = styled.div<StyledColumnProps>`
  ${({ gutter }) => (gutter && styles.gutter[gutter]) || (gutter && styles.gutter[`default`])}
  ${({ items }) => items && styles.items[items]}
  ${({ self }) => self && styles.self[self]}
  ${({ justify }) => justify && styles.justify[justify]}
  ${({ vertical }) => vertical && styles.vertical}
  ${({ screen }) => GlobalStyles.screen[screen]}
  ${({ width }) => styles.width[width] || styles.width[`default`]}
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
  ${({ padding }) => padding && styles.padding[padding]}
`

type StyledColumnProps = {
  gutter?: "none" | "default" | "xxs" | "xs" | "sm" | "lg" | "xl"
  items?: "start" | "center" | "stretch" | "end"
  self?: "start" | "center" | "stretch" | "end"
  justify?: "start" | "center" | "stretch" | "end"
  vertical?: boolean
  screen?: string
  width?:
    | "default"
    | "grow"
    | "fit"
    | "no-shrink"
    | "md:no-shrink"
    | "1/6"
    | "2/5"
    | "1/4"
    | "3/4"
    | "1/3"
    | "2/3"
    | "1/2"
    | "1/6 md:full"
    | "1/2 md:full"
    | "1/2 md:1/3"
    | "1/2 md:1/4"
    | "1/3 md:full"
    | "2/3 md:full"
    | "1/3 md:1/4"
    | "1/2 lg:1/4"
    | "md:1/2 lg:1/4"
    | "md:1/2 lg:1/3"
    | "md:1/3 lg:1/4"
    | "md:2/3 lg:3/4"
    | "md:1/2"
    | "md:1/3"
    | "md:2/3"
    | "md:1/4"
    | "md:3/4"
    | "md:1/6"
    | "md:1/8"
    | "lg:1/2"
    | "lg:1/3"
    | "lg:2/3"
    | "lg:1/4"
    | "lg:3/4"
    | "lg:2/5 xl:7/20"
}
