import React from "react"

import { withQuizShare } from "./withQuizShare"
import { FormInput } from "../../Form/Input/FormInput"
import { FormTextarea } from "../../Form/Textarea/FormTextarea"
import { Icon } from "../../Icon/Icon"
import { H4, P } from "../../Styled/Text"
import { Notify, Inner, Popup, Form, Close, Button } from "./styledQuizShare"

export const QuizShare = withQuizShare(
  ({ active, bounds, data, errors, field, handleActive, handleChange, handleSubmit, loading, locales, success }): JSX.Element => (
    <Notify active={active} top={bounds?.height}>
      <Inner>
        <Popup active={active}>
          {success ? (
            <H4 align={`center`} withSpacing={`xs`}>
              {locales?.additionalShareSuccess}
            </H4>
          ) : (
            <Form onSubmit={handleSubmit}>
              <H4 align={`center`} withSpacing={`xs`}>
                {locales?.additionalShareTitle}
              </H4>
              <P align={`center`} withSpacing>
                {locales?.additionalShareContent}
              </P>
              <FormInput
                type={`text`}
                name={`name`}
                disabled={loading}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalShareName}
                required
                reference={field}
                value={data?.name}
                withSpacing
              />
              <FormInput
                type={`email`}
                name={`email`}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalShareEmail}
                required
                value={data?.email}
                withSpacing
              />
              <FormTextarea
                name={`message`}
                disabled={loading}
                errors={errors}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalShareMessage}
                value={data?.message}
                withSpacing
              />
              <Button type={`submit`} disabled={loading} colour={`purple`} size={`secondary`} title={locales?.additionalShareSubmit}>
                {locales?.additionalShareSubmit}
              </Button>
            </Form>
          )}
          <Close colour={`white`} onClick={handleActive} title={locales?.additionalClose}>
            <Icon name={`close`} />
          </Close>
        </Popup>
      </Inner>
    </Notify>
  )
)
