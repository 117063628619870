module.exports = {
  id: "raw-earth-retail",
  title: "Skin Juice",
  description: "Zero-gimmick skincare that will make your skin smile",
  url: "https://skinjuice.com.au",

  siteLocation: "AU",
  siteCountries: "AU",
  siteCurrency: "AUD",
  shopName: "raw-earth-retail",
  shopDomain: "checkout.skinjuice.com.au",
  shopifyApiVersion: "2024-01",
  accessToken: "f9105bef3bb59d00d5bb1379f96d10d2",
  adminAccessToken: "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",
  functionsEndpoint: "https://api.skinjuice.com.au",
  shopifyPlus: true,
  emailSender: "hello@skinjuice.com.au",

  searchIndex: "skin-juice-search-v2",
  orderPrinterToken: "92bfe23b4f87da100d2b",
  orderPrinterIndex: 5176,
  searchConfig: `https://config.search.reactify.app/?shop=raw-earth-retail.myshopify.com`,

  sanityProjectId: "aktntfbz",
  sanityDataset: "production",
  sanityApiVersion: "2022-02-24",
}
