import { useCallback, useContext } from "react"

import { TrackingContext } from "../providers/tracking"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"
import { useCheckoutContext } from "./useCheckout"

export const useAnalytics = () => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { activeVariant } = useApp()
  const { checkout, clientId, sessionID } = useCheckoutContext()
  const { productNormaliser } = useShopify()
  const { tracked, gtm } = useContext(TrackingContext) || {}

  const formatPrice = price => parseFloat(typeof price === `string` ? price?.replace(/,/g, ".") : price)?.toFixed(2) || 0

  const getVariantOptionValue = (options, selectedName) => options?.find(({ name }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value

  const isDevelopment = process.env.NODE_ENV === "development"

  const log = useCallback((message: string) => isDevelopment && console.log(`[TRACKING]:`, message), [isDevelopment])

  const trackPageView = useCallback(() => {
    setTimeout(() => {
      log(`pageView (${document.title})`)
      gtm.dataLayer({
        dataLayer: {
          event: "Pageview",
          pagePath: `${document?.location?.pathname}${document?.location?.search ? document.location.search : ""}`,
          pageTitle: document?.title,
          originalLocation: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
        },
      })
    }, 200)
  }, [gtm, log])

  const trackViewItemList = useCallback(
    collection => {
      if (document?.location?.pathname?.includes("/collection")) {
        log(`view_item_list (${document.location.pathname})`)
        const items =
          collection?.products?.map(product => {
            const variant = product?.variants?.find(({ available }) => available) || product?.variants?.[0]
            const { id, title, vendor, product_type } = productNormaliser(product)
            return {
              item_id: id || "",
              item_name: title || "",
              item_brand: vendor || "",
              item_category: product_type || "",
              item_list_name: collection?.title || "",
              price: formatPrice(variant?.price || 0),
              quantity: 1,
            }
          }) || []

        const data = {
          item_list_id: collection?.shopify?.id || "",
          item_list_name: collection?.title || "",
          items: items,
        }
        if (window.gtag) {
          window.gtag("event", "view_item_list", data)
        }
      }
    },
    [gtm, log]
  )

  const trackProductImpression = useCallback(
    async (product, position, list = null) => {
      const { collections, id, title, vendor, priceRange, price_min, product_type } = productNormaliser(product)
      if (title) {
        log(`productImpression (${title} ${list})`)
        gtm.dataLayer({
          dataLayer: {
            event: "productImpression",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              impressions: [
                {
                  id: decodeShopifyId(id, "Product"),
                  name: title,
                  brand: vendor,
                  category: collections?.[0]?.title || product_type,
                  price: formatPrice(priceRange?.minVariantPrice?.amount || price_min),
                  list: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                  position,
                },
              ],
            },
          },
        })
      }
    },
    [gtm, log, checkout, productNormaliser, decodeShopifyId]
  )

  const trackProductClick = useCallback(
    async (product, variant, position, list = null) => {
      const { collections, id, priceRange, price_min, title, vendor, product_type } = productNormaliser(product)
      if (title) {
        log(`productClick (${title} – ${variant?.title})`)
        gtm.dataLayer({
          dataLayer: {
            event: "productClick",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              click: {
                actionField: { list: list || "Collection Results" }, // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                products: [
                  {
                    id: decodeShopifyId(id, "Product"),
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || product_type,
                    price: formatPrice(priceRange?.minVariantPrice?.amount || price_min),
                    position,
                    variant: getVariantOptionValue(variant?.selectedOptions, `Colour`),
                    //  dimension2: getVariantOptionValue(variant?.selectedOptions, `Size`),
                    // dimension3: variant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, log, checkout, productNormaliser, decodeShopifyId]
  )

  const trackViewItem = useCallback(
    async (product, selectedVariant, list = null) => {
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      if (title) {
        const name = `${title} – ${selectedVariant?.title}`
        log(`view_item (${name})`)
        const variant = selectedVariant || product?.variants?.[0]
        const value = formatPrice(variant?.priceV2?.amount || variant?.price?.amount)
        const data = {
          currency: checkout?.currencyCode,
          value: value,
          items: [
            {
              item_id: variant?.sku || "",
              item_name: name,
              item_brand: vendor,
              item_category: productType || "",
              item_list_name: collections?.[0]?.title || "",
              item_variant: variant?.title || "",
              price: value,
              quantity: 1,
            },
          ],
        }

        if (window.gtag) {
          window.gtag("event", "view_item", data)
        }
        if (window.ttq) {
          log(`ViewContent:ttq:${title}`)
          window.ttq.track("ViewContent", {
            description: title,
          })
        }
      }
    },
    [gtm, log, activeVariant, checkout, productNormaliser, decodeShopifyId]
  )

  const trackSelectContent = useCallback(
    async product => {
      if (product) {
        const { id, title } = productNormaliser(product)
        if (id) {
          log(`select_content (Product: ${title})`)
          const data = {
            content_type: "product",
            content_id: decodeShopifyId(id, "Product"),
          }

          if (window.gtag) {
            window.gtag("event", "select_content", data)
          }
        }
      }
    },
    [gtm, log, productNormaliser, decodeShopifyId]
  )

  const trackProductView = useCallback(
    async (product, list = null) => {
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      if (title) {
        log(`productView (${title} – ${activeVariant?.title})`)
        gtm.dataLayer({
          dataLayer: {
            event: "productDetail",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              detail: {
                actionField: { list: list || "Product Page" }, // Product Page, Quick View
                products: [
                  {
                    id: decodeShopifyId(id, "Product"),
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || productType,
                    price: formatPrice(activeVariant?.priceV2?.amount || (product?.variants && product?.variants[0]?.priceV2?.amount)),
                    variant: getVariantOptionValue(activeVariant?.selectedOptions, `Colour`),
                    // dimension2: getVariantOptionValue(activeVariant?.selectedOptions, `Size`),
                    // dimension3: activeVariant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
        if (window.ttq) {
          log(`ViewContent:ttq:${title}`)
          window.ttq.track("ViewContent", {
            description: title,
          })
        }
      }
    },
    [gtm, log, activeVariant, checkout, productNormaliser, decodeShopifyId]
  )

  const trackCartUpdate = useCallback(
    async (type, variantId, quantity, lineitems) => {
      const selectedLineItem = lineitems?.filter(({ variant }) => variant?.id === variantId)[0]

      if (selectedLineItem?.title) {
        log(`cartUpdate (${type} – ${selectedLineItem?.title})`)
        if (window.ttq && type === "add") {
          log(`AddToCart:ttq:${decodeShopifyId(variantId, "ProductVariant")}`)
          window.ttq.track("AddToCart", {
            content_id: decodeShopifyId(variantId, "ProductVariant"),
            quantity,
            price: formatPrice(selectedLineItem?.variant?.priceV2?.amount),
            content_name: selectedLineItem?.title,
            currency: checkout?.currencyCode,
          })
        }

        if (window.gtag && type === "add") {
          const value = formatPrice(selectedLineItem?.variant?.priceV2?.amount || selectedLineItem?.variant?.price?.amount)
          const data = {
            currency: checkout?.currencyCode,
            value: value,
            items: [
              {
                item_id: decodeShopifyId(variantId, "ProductVariant") || "",
                item_name: selectedLineItem?.title,
                item_brand: selectedLineItem?.variant?.product?.vendor,
                item_category: selectedLineItem?.variant?.product?.productType,
                item_variant: selectedLineItem?.variant?.title || "",
                price: value,
                quantity,
              },
            ],
          }
          window.gtag("event", "add_to_cart", data)
        }
      }
    },
    [gtm, log, checkout, decodeShopifyId]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId: id, name, creative, position }) => {
      if (name) {
        log(`promoImpression (${name})`)
        gtm.dataLayer({
          dataLayer: {
            event: "promotionView",
            ecommerce: {
              promoView: {
                promotions: [{ id, name, creative, position }],
              },
            },
          },
        })
      }
    },
    [gtm, log]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId: id, name, creative, position }) => {
      if (name) {
        log(`promoClick (${name})`)
        gtm.dataLayer({
          dataLayer: {
            event: "promotionClick",
            ecommerce: {
              promoClick: {
                promotions: [{ id, name, creative, position }],
              },
            },
          },
        })
      }
    },
    [gtm, log]
  )

  const trackQuizAnswer = useCallback(
    async ({ title, answer, tag }) => {
      if (answer) {
        log(`quizAnswer (${title})`)
        gtm.dataLayer({
          dataLayer: {
            event: "quizAnswer",
            ecommerce: {
              quizAnswer: { title, answer, tag },
            },
          },
        })
      }
    },
    [gtm, log]
  )

  const trackEvent = useCallback(async () => {
    if (tracked && checkout?.currencyCode) {
      trackPageView()
    }
  }, [checkout, tracked, trackPageView])

  const trackSearch = searchTerm => {
    if (window.ttq) {
      log(`Search:ttq:${searchTerm}`)
      window.ttq.track("Search", {
        query: searchTerm,
      })
    }
  }

  const trackInitiateCheckout = () => {
    if (window.ttq) {
      log(`InitiateCheckout:ttq`)
      window.ttq.track("InitiateCheckout")
    }
  }

  const retrieveClientID = useCallback(() => {
    if (window?.ga && !clientId) {
      const tracker = window?.ga?.getAll ? window?.ga?.getAll()[0] : null
      const retrievedClientId = tracker && tracker?.get("clientId")
      return retrievedClientId
      // setClientId(retrievedClientId)
    }
  }, [clientId])

  const retrieveReferralCandyReferrer = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const refID = urlParams.get("aic")
    const refIDInStorage = window.localStorage.getItem("rc-ref-id")
    return refID ? refID : refIDInStorage ? refIDInStorage : ""
  }

  return {
    tracked,
    trackEvent,
    trackPageView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackQuizAnswer,
    trackSearch,
    trackInitiateCheckout,
    retrieveClientID,
    retrieveReferralCandyReferrer,
    trackViewItemList,
    trackViewItem,
    trackSelectContent,
  }
}
