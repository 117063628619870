import React from "react"
import { Link as GatsbyLink } from "gatsby"
import Measure from "react-measure"

import { withInstagramPopup } from "./withInstagramPopup"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import { StyledImage } from "../../../Styled/Image"
import { StyledRow } from "../../../Styled/Row"
import { P } from "../../../Styled/Text"
import {
  Overlay,
  Inner,
  Popup,
  Content,
  Button,
  Close,
  Text,
  Navigation,
  Image,
  Pin,
  Circle,
  Popover,
  Link,
  LinkText,
  LinkType,
  LinkIcon,
} from "./styledInstagramPopup"

export const InstagramPopup = withInstagramPopup(
  ({ active, activePin, bounds, index, item, items, handleActive, handleClick, handleChange, handleResize, imageBounds, locales }): JSX.Element => (
    <Overlay active={active} top={bounds?.height}>
      <Inner>
        <Popup active={active}>
          <StyledRow items={`stretch`} withWrap>
            <StyledColumn width={`md:2/3`}>
              <Measure bounds onResize={handleResize}>
                {({ measureRef }) => (
                  <Image onClick={event => handleClick(event, null)} ref={measureRef}>
                    <StyledImage alt={item?.image?.alt} fluid={item?.image} />
                    {item?.pins?.map(({ id, position, product }) => (
                      <Pin key={id} height={imageBounds?.height} width={imageBounds?.width} left={position?.x} top={position?.y}>
                        <Circle active={activePin === id} onClick={event => handleClick(event, id)}>
                          <Icon name={`tag`} colour={`white`} width={12} />
                        </Circle>
                        <Popover active={activePin === id} key={id} left={position?.x > 80} right={position?.x < 20} top={position?.y > 80}>
                          <Link as={GatsbyLink} colour={`white`} title={product?.title} to={product?.url}>
                            <LinkText>
                              <P>{product?.title}</P>
                              <LinkType screen={`md>`}>{product?.type}</LinkType>
                            </LinkText>
                            <LinkIcon>
                              <Icon name={`right`} width={12} />
                            </LinkIcon>
                          </Link>
                        </Popover>
                      </Pin>
                    ))}
                  </Image>
                )}
              </Measure>
            </StyledColumn>
            <StyledColumn width={`md:1/3`}>
              <Content height={imageBounds?.height}>
                <Navigation>
                  <StyledRow gutter={`sm`} withSpacing withWrap>
                    <StyledColumn gutter={`sm`} width={`md:1/2`}>
                      <StyledRow>
                        <StyledColumn width={`1/2`}>
                          <Button
                            colour={`grey-darker`}
                            disabled={!index}
                            onClick={() => handleChange(index - 1)}
                            title={locales?.additionalPrevious}
                          >
                            <Icon name={`left`} />
                          </Button>
                        </StyledColumn>
                        <StyledColumn justify={`end`} width={`1/2`}>
                          <Button
                            colour={`grey-darker`}
                            disabled={index === items?.length - 1}
                            onClick={() => handleChange(index + 1)}
                            title={locales?.additionalNext}
                          >
                            <Icon name={`right`} />
                          </Button>
                        </StyledColumn>
                      </StyledRow>
                    </StyledColumn>
                    <StyledColumn gutter={`sm`} justify={`end`} width={`md:1/2`}>
                      <Close colour={`grey-darker`} onClick={handleActive} title={locales?.additionalClose}>
                        <Icon name={`close`} />
                      </Close>
                    </StyledColumn>
                  </StyledRow>
                </Navigation>
                <Text dangerouslySetInnerHTML={{ __html: item?.title }} withSpacing={item?.products?.length > 0 ? `default` : null} />
              </Content>
            </StyledColumn>
          </StyledRow>
        </Popup>
      </Inner>
    </Overlay>
  )
)
