import React from "react"

import { withFormTextarea } from "./withFormTextarea"
import { Icon } from "../../Icon/Icon"
import { StyledErrors, StyledInputWrapper, StyledTextarea, StyledLabel, StyledSrLabel } from "../../Styled/Form"

export const FormTextarea = withFormTextarea(
  ({ errors, field, full, id, label, placeholder, withSpacing }): JSX.Element => (
    <>
      <StyledInputWrapper full={full} withSpacing={!(errors?.length > 0) && withSpacing}>
        <StyledSrLabel htmlFor={id}>{placeholder}</StyledSrLabel>
        <StyledTextarea {...field} errors={errors} id={id} name={label} placeholder={placeholder} />
        <div>
          <Icon name={`tick`} />
          {placeholder && <StyledLabel>{placeholder}</StyledLabel>}
        </div>
      </StyledInputWrapper>
      {errors?.length > 0 && (
        <>
          {errors?.map(({ code, message }) => (
            <StyledErrors key={code?.toString()} withSpacing={withSpacing}>
              {message?.toString()}
            </StyledErrors>
          ))}
        </>
      )}
    </>
  )
)
