import tw, { css, styled } from "twin.macro"

import { StyledLink } from "../../Styled/Button"

export const SearchWrapper = styled.form`
  ${tw`flex flex-col justify-center absolute inset-x-0 top-0 bg-orange text-white border-t border-white border-opacity-25`}
  ${({ active }) => !active && tw`hidden`}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`
export const InputWrapper = styled.div`
  ${tw`w-full max-w-full relative flex items-center justify-between`}

  > div {
    ${tw`w-full`}
  }

  input {
    ${tw`bg-white text-grey-darker text-base md:text-2xl max-w-full w-full h-auto border-none font-sans rounded-6xl pl-13 md:pl-18 py-3 pr-4 focus:outline-none flex-grow`}
  }
`
export const IconWrapper = styled(StyledLink)`
  ${tw`absolute left-0 ml-4 md:ml-8`}
  svg {
    ${tw`w-4 md:w-5`}
  }
`
export const Link = styled(StyledLink)`
  svg {
    ${tw`w-3 md:w-4.5`}
  }
`
