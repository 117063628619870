import { useMemo, useCallback } from "react"

import { UseAnimation } from "../types/hooks"

export const useAnimationPickGift = (): UseAnimation.ResponsePickGift => {
  const baseImageTransPickGift = useMemo(
    () => [
      {
        fig: 1,
        op: {
          range: [0.1, 1],
          output: [1, 1],
        },
        trans: {
          range: [0, 1],
          output: [-40, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 2,
        op: {
          range: [0.2, 1],
          output: [1, 1],
        },
        trans: {
          range: [0.1, 1],
          output: [-60, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 3,
        op: {
          range: [0.2, 1],
          output: [1, 1],
        },
        trans: {
          range: [0.2, 1],
          output: [-80, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 4,
        op: {
          range: [0.3, 1],
          output: [1, 1],
        },
        trans: {
          range: [0.3, 1],
          output: [-100, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 5,
        op: {
          range: [0.4, 1],
          output: [1, 1],
        },
        trans: {
          range: [0.4, 1],
          output: [-120, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 6,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [0.5, 1],
          output: [-140, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 7,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [0.6, 1],
          output: [-160, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 8,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [0.7, 1],
          output: [-180, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 9,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [0.8, 1],
          output: [-200, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 10,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [0.9, 1],
          output: [-220, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 11,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [1, 1],
          output: [-240, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 12,
        op: {
          range: [0, 1],
          output: [1, 1],
        },
        trans: {
          range: [1, 1],
          output: [-260, 1],
          extrapolate: "clamp",
        },
      },
      {
        fig: 13,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [1, 1],
          output: [-280, 0],
          extrapolate: "clamp",
        },
      },
      {
        fig: 14,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [1, 1],
          output: [-300, 0],
          extrapolate: "clamp",
        },
      },
      {
        fig: 15,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [1, 1],
          output: [-320, 0],
          extrapolate: "clamp",
        },
      },
    ],
    []
  )

  const getSpringConfigPickGift = useCallback(({ opacity, item }: UseAnimation.GetSpringConfigInput): UseAnimation.GetSpringConfigResponse => {
    const distance = 15

    return {
      position: "absolute",
      left: item.fig % 2 ? "calc(50% - 105px)" : "calc(50% - 85px)",
      bottom: "50px",
      opacity: opacity.to(item.op),
      transform: opacity.to(item.trans).to(y => `translate3d(0, ${y * distance}px, 0)`),
      width: "240px",
      height: "auto",
      rotateZ: item.fig % 2 ? -10 - item.fig * 4 : 10 + item.fig * 4,
    }
  }, [])

  return {
    baseImageTransPickGift,
    getSpringConfigPickGift,
  }
}
