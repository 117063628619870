import React, { memo, useCallback } from "react"

import * as Components from "../../../../types/components"
import { useApp } from "../../../../hooks/useApp"
import { usePickMix } from "../../../../hooks/usePickMix"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withPickMixPopup = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickMixPopup" }: Components.ComponentProps) => {
    const { activePickMixPopup: active, headerBounds, setActivePickMixPopup: setActive } = useApp()
    const { pickMix } = usePickMix()
    const { linkResolver } = useRoutes()

    const { links: rawLinks } = pickMix
    const links = rawLinks.map(rawLink => linkResolver(rawLink))

    const handleActive = useCallback(() => setActive(false), [setActive])

    const props = {
      active,
      handleActive,
      bounds: headerBounds,
      links,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type InjectedProps = {
  active: boolean
  handleActive: () => void
  bounds: any
  links: any
}
