import React from "react"

import { Icon } from "../../Icon/Icon"
import { Counter, Link } from "./styledCartWidget"
import { withCartWidget } from "./withCartWidget"

export const CartWidget = withCartWidget(
  ({ count, handleClick, locales, screen }): JSX.Element => (
    <Link onClick={handleClick} title={locales?.additionalCart}>
      <Icon colour={count > 0 && `orange-dark`} name={`bag`} width={screen !== "sm" ? 22 : 23} fill={`none`} />
      {<Counter>{count || ""}</Counter>}
    </Link>
  )
)
