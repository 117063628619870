import React, { useEffect, useState } from "react"
import { globalHistory } from "@reach/router"

export const InstagramContext: any = React.createContext({})

export const InstagramProvider = ({ children }): JSX.Element => {
  const [active, setActive] = useState(null)
  const [index, setIndex] = useState(null)

  const values = {
    active,
    setActive,
    index,
    setIndex,
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH" && active) setActive(false)
    })
  }, [active, setActive])

  return <InstagramContext.Provider value={{ ...values }}>{children}</InstagramContext.Provider>
}
