import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, EffectFade } from "swiper"

import { withHeaderAnnouncement } from "./withHeaderAnnouncement"
import { Items, Item, Text, Link } from "./styledHeaderAnnouncement"

SwiperCore.use([Autoplay, EffectFade])

export const HeaderAnnouncement = withHeaderAnnouncement(
  ({ autoplay, items }): JSX.Element => (
    <Items>
      <Swiper
        allowTouchMove={false}
        autoplay={autoplay ? { delay: autoplay, disableOnInteraction: true } : false}
        effect={`fade`}
        loop
        slidesPerView={1}
        spaceBetween={0}
      >
        {items?.map(({ id, link, title }) => (
          <SwiperSlide key={id}>
            <Item>
              <Text align={`center`}>
                {link?.url ? (
                  <Link title={link?.title} to={link?.url}>
                    {title}
                  </Link>
                ) : (
                  title
                )}
              </Text>
            </Item>
          </SwiperSlide>
        ))}
      </Swiper>
    </Items>
  )
)
