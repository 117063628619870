import React, { memo, useMemo } from "react"

import { useAcknowledgement } from "../../../hooks/useAcknowledgement"
import { useApp } from "../../../hooks/useApp"
import { useCore } from "../../../hooks/useCore"

export const withFooterAcknowledgement = Component =>
  memo(({ name = "FooterAcknowledgement" }) => {
    const {
      config: {
        settings: { keys },
      },
    } = useApp()
    const {
      helpers: { storage, sanityContent },
    } = useCore()
    const { enabled, content: rawContent, alternativeItems } = useAcknowledgement()

    const content = useMemo(() => {
      const country = storage.get(keys?.country) || "AU"
      const defaultContent = sanityContent(rawContent)
      const alternativeContent =
        alternativeItems?.reduce((result, item) => {
          const countryCode = item?.countryCode
          const content = sanityContent(item?.content)
          if (countryCode && content) {
            result[countryCode] = content
          }
          return result
        }, {}) || {}

      return alternativeContent[country] || defaultContent
    }, [alternativeItems, keys?.country, rawContent, sanityContent, storage])

    Component.displayName = name
    return useMemo(() => (enabled ? <Component content={content} /> : null), [content, enabled])
  })
