import tw, { styled } from "twin.macro"

import { StyledRow } from "../../../Styled/Row"
import { H5 } from "../../../Styled/Text"

export const Title = tw(H5)`mb-4 md:mb-6`
export const Row = tw(StyledRow)`mt-8 md:mt-12`
export const Wrapper = styled.div`
  ${tw`invisible absolute pt-4 md:pt-5 pb-8 md:pb-12 inset-x-0 bg-white shadow`}
  ${({ visible }) => visible && tw`visible z-20`}
`
