import React, { memo, useState, useEffect, useCallback } from "react"

import * as Components from "../../../types/components"
import { UseTemplate } from "../../../types/hooks"
import { useCheckout, useCheckoutContext } from "../../../hooks/useCheckout"

export const withCartNotes = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "CartNotes", page, canSaveNotes, showNotesError, handleUpdateNoteStatus, handleNotesError, setHeight, show }: ExternalProps) => {
    const { checkout } = useCheckoutContext()
    const { updateAttributes } = useCheckout()

    const checkoutNote = checkout?.note || ""

    const [note, setNote] = useState<string>(checkoutNote)
    const [loading, setLoading] = useState<boolean>(false)

    const handleNoteChange: React.ChangeEventHandler<HTMLInputElement> = e => {
      setNote(e.target.value)
      handleUpdateNoteStatus(note !== checkoutNote)
    }

    const handleSave = useCallback(
      (e: React.SyntheticEvent) => {
        e.preventDefault()
        if (canSaveNotes) {
          handleNotesError(false)
          setLoading(true)
          updateAttributes({
            note,
          })
        }
      },
      [canSaveNotes, note, updateAttributes, handleNotesError]
    )

    useEffect(() => {
      handleUpdateNoteStatus(note !== checkoutNote)
    }, [checkoutNote, handleUpdateNoteStatus, note])

    useEffect(() => {
      if (checkout) {
        setTimeout(() => {
          setLoading(false)
          handleUpdateNoteStatus(false)
        }, 1500)
      }
    }, [checkout, handleUpdateNoteStatus])

    const props = {
      note,
      locales: page,
      loading,
      canSave: canSaveNotes,
      showNotesError,
      handleNoteChange,
      handleSave,
      setHeight,
      show,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  page: UseTemplate.Cart
  canSaveNotes: boolean
  showNotesError: boolean
  handleNotesError: (status: boolean) => void
  handleUpdateNoteStatus: (status: boolean) => void
  setHeight: string
  show: string
}

type InjectedProps = {
  note: string
  locales: UseTemplate.Cart
  loading: boolean
  canSave: boolean
  showNotesError: boolean
  handleNoteChange: React.ChangeEventHandler<HTMLInputElement>
  handleSave: (event: React.SyntheticEvent) => void
  setHeight: string
  show: string
}
