import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withCartDrawer } from "./withCartDrawer"
import { CartList } from "../List/CartList"
import { CartNotes } from "../Notes/CartNotes"
import { Icon } from "../../Icon/Icon"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { H4 } from "../../Styled/Text"
import { CartTotal } from "../../../components/Cart/Total/CartTotal"
import { CartBar } from "@app/components/CartBar/CartBar"
import { CartDrawerUpsell } from "./Upsell/cartDrawerUpsell"
import {
  Drawer,
  Overlay,
  ContentBorder,
  Button,
  Text,
  Link,
  Row,
  Extras,
  ShippingStatement,
  KeepShoppingWrapper,
  KeepShopping,
  BaseRow,
  CartBody,
  CartFooter,
} from "./styledCartDrawer"

export const CartDrawer = withCartDrawer(
  ({
    active,
    bounds,
    count,
    empty,
    handleCart,
    handleClose,
    link,
    locales,
    subtotal,
    page,
    canSaveNotes,
    handleUpdateNoteStatus,
    showNotesError,
    handleNotesError,
    showCartBar,
  }): JSX.Element => (
    <>
      <Overlay active={active} onClick={handleClose} top={bounds?.height} />
      <Drawer active={active} top={bounds?.height}>
        {bounds?.width > 767 ? <CartDrawerUpsell global={global} active={active} isMobile={false} locales={locales}></CartDrawerUpsell> : null}
        <CartBody>
          <ContentBorder>
            <StyledRow gutter={`xs`} items={`center`} withSpacing={`xxsmd`}>
              <StyledColumn gutter={`xs`} width={`grow`}>
                <Text>
                  <strong>
                    {empty ? locales?.additionalEmpty : locales?.additionalCartTitle}
                    {count ? ` (${count})` : null}
                  </strong>
                </Text>
              </StyledColumn>
              <StyledColumn gutter={`xs`} width={`no-shrink`}>
                <Link colour={`grey-dark`} onClick={handleClose} title={locales?.additionalClose}>
                  <Icon name={`close`} width={20} />
                </Link>
              </StyledColumn>
            </StyledRow>
          </ContentBorder>
          {!empty && showCartBar ? (
            <>
              <ContentBorder>
                <BaseRow>
                  <CartBar></CartBar>
                </BaseRow>
              </ContentBorder>
            </>
          ) : null}
          <CartList drawer />
          {bounds?.width < 768 ? <CartDrawerUpsell global={global} active={active} isMobile={true} locales={locales}></CartDrawerUpsell> : null}
        </CartBody>
        <CartFooter>
          {count > 0 ? (
            <>
              <CartNotes
                page={locales}
                canSaveNotes={canSaveNotes}
                showNotesError={showNotesError}
                handleNotesError={handleNotesError}
                handleUpdateNoteStatus={handleUpdateNoteStatus}
                setHeight={`fit`}
                show={active ? `visible` : `invisible`}
              />
              <Row items={`center`}>
                <StyledColumn width={`grow`}>
                  <Text>
                    <strong>{locales?.additionalSubtotal}</strong>
                  </Text>
                </StyledColumn>
                <StyledColumn width={`no-shrink`}>
                  <Text>
                    <strong>{subtotal}</strong>
                  </Text>
                </StyledColumn>
              </Row>
              <CartTotal
                page={page}
                disabled={canSaveNotes}
                handleDisabledClick={handleNotesError}
                mini={true}
                drawerlocales={locales?.additionalCartView}
              ></CartTotal>
              <Extras>
                <ShippingStatement>Shipping & taxes are calculated at checkout</ShippingStatement>
                <KeepShoppingWrapper type={`button`} onClick={handleClose}>
                  <KeepShopping>Keep Shopping</KeepShopping>
                  <Icon name={`right`} width={12} />
                </KeepShoppingWrapper>
              </Extras>
            </>
          ) : empty ? (
            <Button as={GatsbyLink} colour={`purple`} size={`primary`} to={link?.url} title={link?.title}>
              {link?.title}
            </Button>
          ) : null}
        </CartFooter>
      </Drawer>
    </>
  )
)
