import React, { useState, useRef, useEffect } from "react"
import { useGlobal } from "../hooks/useGlobal"

export const CustomerContext: any = React.createContext({})

export const CustomerProvider = ({ children }): JSX.Element => {
  const { handleGlobalGroupReady } = useGlobal()
  const [customer, setCustomer] = useState(false)

  const prevCustomer = useRef(customer)

  useEffect(() => {
    if (!prevCustomer.current) {
      prevCustomer.current = customer
    }
  }, [customer])

  if (prevCustomer.current === false && customer) {
    handleGlobalGroupReady("customer")
  }

  const values = {
    customer,
    setCustomer,
  }

  return <CustomerContext.Provider value={{ ...values }}>{children}</CustomerContext.Provider>
}
