import tw, { css } from "twin.macro"
import styled from "@emotion/styled/macro"

export const Notify = styled.section<NotifyProps>`
  ${tw`fixed inset-0 flex items-center justify-center z-20 invisible opacity-0 bg-white bg-opacity-70 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100`}
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
`
export const Popup = styled.div<GenericProps>`
  ${tw`relative w-full h-full bg-white text-black rounded-lg shadow p-1 sm:p-4 max-h-full invisible transform scale-90 translate-y-6 transition-all duration-200`}
  ${({ active }) => active && tw`visible scale-100 translate-y-0`}
`
export const Inner = tw.div`flex items-center justify-center w-full h-full p-4 sm:p-6 w-200 h-129.5 max-w-full`

export const Wrapper = tw.div`relative flex w-full h-full items-center justify-center`
export const Content = tw.div`px-2 justify-center z-0 h-full`
export const Table = tw.div`overflow-x-hidden overflow-y-auto h-3/4 text-sm sm:text-base`

interface GenericProps {
  active?: boolean
}
interface NotifyProps extends GenericProps {
  top: number
}
