import React from "react"

import config from "./config.js"
import "./static/index.css"

import { Providers } from "./src/providers/providers"
import { Layout } from "./src/components/Layout/Layout"

export const wrapRootElement = ({ element }) => <Providers>{element}</Providers>

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props} settings={config.settings}>
    {element}
  </Layout>
)
