import { useMemo, useCallback } from "react"

import { UseAnimation } from "../types/hooks"

export const useAnimation = (): UseAnimation.Response => {
  const baseImageTrans = useMemo(
    () => [
      {
        fig: 1,
        op: {
          range: [0.75, 1],
          output: [1, 1],
        },
        trans: {
          range: [0, 1],
          output: [-40, 0],
          extrapolate: "clamp",
        },
      },
      {
        fig: 2,
        op: {
          range: [0.25, 0.5],
          output: [1, 1],
        },
        trans: {
          range: [0.25, 1],
          output: [-40, 0],
          extrapolate: "clamp",
        },
      },
      {
        fig: 3,
        op: {
          range: [0, 0.25],
          output: [1, 1],
        },
        trans: {
          range: [0.5, 1],
          output: [-40, 0],
          extrapolate: "clamp",
        },
      },
      {
        fig: 4,
        op: {
          range: [0.5, 0.75],
          output: [1, 1],
        },
        trans: {
          range: [0.75, 1],
          output: [-40, 0],
          extrapolate: "clamp",
        },
      },
    ],
    []
  )

  const getSpringConfig = useCallback(({ opacity, item }: UseAnimation.GetSpringConfigInput): UseAnimation.GetSpringConfigResponse => {
    const distance = 15

    return {
      position: "absolute",
      left: item.fig % 2 ? "calc(50% - 105px)" : "calc(50% - 85px)",
      bottom: "0px",
      opacity: opacity.to(item.op),
      transform: opacity.to(item.trans).to(y => `translate3d(0, ${y * distance}px, 0)`),
      width: "240px",
      height: "auto",
      rotateZ: item.fig % 2 ? -10 - item.fig * 4 : 10 + item.fig * 4,
    }
  }, [])

  return {
    baseImageTrans,
    getSpringConfig,
  }
}
