import tw, { styled } from "twin.macro"
import { Link as GatsbyLink } from "gatsby"

import { GlobalStyles } from "../../../Styled/Global"
import { StyledLink } from "../../../Styled/Button"
import { Overline, P } from "../../../Styled/Text"

export const Item = styled.div`
  ${tw`block relative py-9 border-t border-grey-light transition-all duration-200`}
  ${({ disabled }) => disabled && tw`pointer-events-none opacity-50`}
  ${({ index }) => index === 0 && tw`border-0`}
`
export const Link = tw(StyledLink)`block`
export const Edit = tw(StyledLink)`block cursor-pointer`
export const Button = styled(StyledLink)`
  ${tw`block p-2`}
  ${({ hidden }) => hidden && tw`invisible`}
`
export const ComparedPrice = tw.span`line-through mr-2`
export const Text = styled(P)`
  ${tw`text-base font-bold select-none`}
  ${({ underline }) => (underline ? tw`underline` : "")}
  ${({ qty }) => qty && tw`text-lg mx-2`}
`
export const Title = tw(Overline)`tracking-wide mb-1.5`
export const AspectRatio = tw.div`w-full pb-full`
export const Remove = styled(StyledLink)`
  ${tw`absolute top-0 -right-2 mt-2.5 pl-2 pb-2`}
  ${({ drawer }) => drawer && tw`mt-7 p-2`}
`
export const ImageContainer = styled(GatsbyLink)`
  ${tw`block`}
  ${({ colour }) => colour && GlobalStyles.background[colour]}
  ${({ width }) => width && tw`w-20`}
`

export const BundleText = tw(P)`text-sm`
