import tw, { styled } from "twin.macro"

const styles = {
  default: tw`relative w-full`,
  visibility: {
    visible: tw`opacity-100`,
    invisible: tw`opacity-0`,
    default: tw`opacity-0`,
  },
  height: {
    full: tw`h-full`,
    fit: tw`h-fit`,
    default: tw`h-fit`,
  },
}

export const Wrapper = styled.div`
  ${styles.default}
  ${({ show }) => styles.visibility[show] || (show && styles.visibility[`default`])}
  ${({ setHeight }) => styles.height[setHeight] || (setHeight && styles.height[`default`])}
`
