import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withFooterNavigation } from "./withFooterNavigation"
import { Accordion } from "../../Accordion/Accordion"
import { Overline } from "../../Styled/Text"
import { Nav, Items, Item, Link } from "./styledFooterNavigation"

export const FooterNavigation = withFooterNavigation(
  ({ account, customer, items }): JSX.Element => (
    <>
      <Nav screen={`sm`}>
        <Accordion
          condensed
          items={items?.map(({ key, items, title }) => ({
            id: key,
            title,
            children: (
              <Items>
                {title?.toLowerCase()?.includes(`account`) && customer
                  ? account?.map(({ onClick, title, type, url }, key: number) => (
                      <Item key={key?.toString()}>
                        <Link as={url && GatsbyLink} onClick={onClick} title={title} to={type !== `link` ? url : null}>
                          {title}
                        </Link>
                      </Item>
                    ))
                  : items?.map(({ key, external, title, type, url }) => (
                      <Item key={key}>
                        <Link
                          as={url && type === `link` ? `a` : GatsbyLink}
                          href={type === `link` && url}
                          rel={type === `link` ? `noopener` : null}
                          target={external ? `_blank` : null}
                          title={title}
                          to={type !== `link` ? url : null}
                        >
                          {title}
                        </Link>
                      </Item>
                    ))}
              </Items>
            ),
          }))}
          withSpacing={`lg`}
        />
      </Nav>
      <Nav screen={`md`}>
        {items?.map(({ key, items, title }, index: number) => (
          <Items key={key} small={index > 2}>
            <Item>
              <Overline large withSpacing={`xs`}>
                {title}
              </Overline>
            </Item>
            {title?.toLowerCase()?.includes(`account`) && customer
              ? account?.map(({ onClick, title, type, url }, key: number) => (
                  <Item key={key?.toString()}>
                    <Link as={url && GatsbyLink} onClick={onClick} title={title} to={type !== `link` ? url : null}>
                      {title}
                    </Link>
                  </Item>
                ))
              : items?.map(({ key, external, title, type, url }) => (
                  <Item key={key}>
                    <Link
                      as={url && type === `link` ? `a` : GatsbyLink}
                      href={type === `link` && url}
                      rel={type === `link` ? `noopener` : null}
                      target={external ? `_blank` : null}
                      title={title}
                      to={type !== `link` ? url : null}
                    >
                      {title}
                    </Link>
                  </Item>
                ))}
          </Items>
        ))}
      </Nav>
    </>
  )
)
