import React, { useEffect, useState } from "react"
import { useCustomerContext } from "../hooks/useCustomer"
import { useFunctions } from "../hooks/useFunctions"

export const LoyaltyContext: any = React.createContext({})
export const CustomerContext: any = React.createContext({})

export const LoyaltyProvider = ({ children }): JSX.Element => {
  // const { yotpoCustomerDetails } = useFunctions()
  const { callFunction, errors, loading } = useFunctions()
  const { customer } = useCustomerContext()

  const [userData, setUserData] = useState([])
  const [userEmail, setUserEmail] = useState(null)

  const getUserData = async email => {
    // const response = await yotpoCustomerDetails(email)
    const response = await callFunction("yotpo-customer-details", { email: email })

    setUserData(response)
  }

  useEffect(() => {
    if (customer?.id) {
      getUserData(customer.email)
    }
  }, [customer])

  const values = {
    userData,
    setUserData,
    userEmail,
    setUserEmail,
  }

  return <LoyaltyContext.Provider value={{ ...values }}>{children}</LoyaltyContext.Provider>
}
