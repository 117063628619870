import { useCallback, useContext } from "react"

import { useCore } from "./useCore"
import { useShopify } from "./useShopify"
import { ShopContext } from "../providers/shop"

export const useShopContext = () => {
  const ShopData: any = useContext(ShopContext)
  return { ...ShopData }
}

export const useShop = () => {
  const {
    graphql: {
      queries: { GET_SHOP_SETTINGS },
    },
  } = useCore()
  const { setShop } = useShopContext()
  const { useQuery } = useShopify()
  const { refetch: getQueryShop } = useQuery(GET_SHOP_SETTINGS, { skip: true })

  const getShop = useCallback(async () => {
    const {
      data: { shop },
      loading,
    } = await getQueryShop()
    if (!loading) setShop(shop)
  }, [getQueryShop, setShop])

  return {
    getShop,
  }
}
