import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withHeaderNavigationTertiary } from "./withHeaderNavigationTertiary"
import { Nav, Items, Item, Link } from "./styledHeaderNavigationTertiary"

export const HeaderNavigationTertiary = withHeaderNavigationTertiary(
  ({ items }): JSX.Element => (
    <Nav>
      <Items>
        {items?.map(({ key, external, title, type, url }) => (
          <Item key={key}>
            <Link
              as={url && type === `link` ? `a` : GatsbyLink}
              href={type === `link` && url}
              rel={type === `link` ? `noopener` : null}
              target={external ? `_blank` : null}
              to={type !== `link` && url}
            >
              {title}
            </Link>
          </Item>
        ))}
      </Items>
    </Nav>
  )
)
