import tw, { styled } from "twin.macro"
import { Link as GatsbyLink } from "gatsby"

import { P } from "../../Styled/Text"

export const Items = styled.section`
  .swiper-slide {
    ${tw`h-auto`}
  }
`
export const Item = tw.div`flex flex-col items-center justify-center w-full h-full bg-orange py-0.5 md:py-0`
export const Text = tw(P)`text-xs lg:text-sm`
export const Link = tw(GatsbyLink)`block no-underline hover:underline`
