import React, { useEffect, useReducer, useState, createContext } from "react"
import { globalHistory } from "@reach/router"

import * as Shopify from "../types/shopify"
import * as Headless from "../types/headless"
import * as QuizTypes from "../types/quiz"
import { useCore } from "../hooks/useCore"

export const AppContext: any = createContext({})

export const AppProvider = ({ children, config: siteConfig }): JSX.Element => {
  const [config] = useState<Headless.Config>(siteConfig || {})
  const [headerBounds, setHeaderBounds] = useState({})
  const [searchKey, setSearchKey] = useState(null)
  const [activeAddress, setActiveAddress] = useState(null)
  const [activeAnimationPopup, setActiveAnimationPopup] = useState<Shopify.ShopifyImage[]>([])
  const [activeAnimationPopupLoyalty, setActiveAnimationPopupLoyalty] = useState({})
  const [activeAnimationPopupPickGift, setActiveAnimationPopupPickGift] = useState<Shopify.ShopifyImage[]>([])
  const [activeBoxCardAnimationPopup, setActiveBoxCardAnimationPopup] = useState<Shopify.ShopifyImage[]>([])
  const [activeArticlePage, setActiveArticlePage] = useState(0)
  const [activeArticleCategory, setActiveArticleCategory] = useState(null)
  const [activeCollection, setActiveCollection] = useState(false)
  const [activeProduct, setActiveProduct] = useState(false)
  const [activeQuiz, setActiveQuiz] = useState(null)
  const [activeQuizInfo, setActiveQuizInfo] = useState(null)
  const [activeQuizSubscribePanel, setQuizSubscribeActivePanel] = useState(false)
  const [activeQuizSubscribe, setActiveQuizSubscribe] = useState<QuizTypes.QuizSubscribe.Type>(null)
  const [activeSubscribe, setActiveSubscribe] = useState(false)
  const [activeVariant, setActiveVariant] = useState(false)
  const [activeProductGiftcard, setActiveProductGiftcard] = useState(null)
  const [activeProductNotify, setActiveProductNotify] = useState(null)
  const [activePickMixPopup, setActivePickMixPopup] = useState(false)
  const [activePickGiftPopup, setActivePickGiftPopup] = useState(false)

  const {
    helpers: { isBrowser },
  } = useCore()

  const values = {
    config,
    activeAddress,
    setActiveAddress,
    activeAnimationPopup,
    setActiveAnimationPopup,
    activeAnimationPopupLoyalty,
    setActiveAnimationPopupLoyalty,
    activeAnimationPopupPickGift,
    setActiveAnimationPopupPickGift,
    activeBoxCardAnimationPopup,
    setActiveBoxCardAnimationPopup,
    activeArticlePage,
    setActiveArticlePage,
    activeArticleCategory,
    setActiveArticleCategory,
    activeVariant,
    setActiveVariant,
    activeProduct,
    setActiveProduct,
    activeProductGiftcard,
    setActiveProductGiftcard,
    activeProductNotify,
    setActiveProductNotify,
    activeCollection,
    setActiveCollection,
    activeQuiz,
    setActiveQuiz,
    activeQuizInfo,
    setActiveQuizInfo,
    activeQuizSubscribe,
    setActiveQuizSubscribe,
    activeQuizSubscribePanel,
    setQuizSubscribeActivePanel,
    activeSubscribe,
    setActiveSubscribe,
    headerBounds,
    setHeaderBounds,
    searchKey,
    setSearchKey,
    activePickMixPopup,
    setActivePickMixPopup,
    activePickGiftPopup,
    setActivePickGiftPopup,
  }

  const initialState = {
    activeCart: false,
    activeMenu: false,
    activeSubMenu: false,
    activeSearch: false,
  }

  const reducer = (state: any, action: any) => {
    const actions = {
      setActiveCart: { ...state, activeCart: action.payload, activeMenu: false, activeSearch: false },
      setActiveMenu: { ...state, activeMenu: action.payload, activeCart: false, activeSearch: false },
      setActiveSubMenu: { ...state, activeSubMenu: action.payload, activeCart: false, activeSearch: false },
      setActiveSearch: { ...state, activeSearch: action.payload, activeCart: false, activeMenu: false },
      default: { ...state, activeCart: false, activeMenu: false, activeSearch: false },
    }

    return actions[action?.type] || actions.default
  }

  const globalState = useReducer(reducer, initialState)

  useEffect(() => {
    const [state] = globalState

    if (isBrowser && state) {
      const body = document.querySelector(`body`)

      if (Object.values(state)?.filter(value => value)?.length > 0) {
        body.classList.add(`overflow-hidden`)
        body.classList.add(`fixed`)
        body.classList.add(`md:static`)
        body.classList.add(`inset-0`)
        body.classList.add(`md:inset-auto`)
      } else {
        body.classList.remove(`overflow-hidden`)
        body.classList.remove(`fixed`)
        body.classList.remove(`md:static`)
        body.classList.remove(`inset-0`)
        body.classList.remove(`md:inset-auto`)
      }
    }
  }, [globalState, isBrowser])

  useEffect(() => {
    return globalHistory.listen(({ action, location }) => {
      const [state, dispatch] = globalState

      if (action === "PUSH") {
        if (state?.activeCart || state?.activeMenu || state?.activeSearch || state?.activeSubMenu) {
          dispatch({
            type: "default",
          })
        }

        if (activeQuiz) setActiveQuiz(null)

        if (!location?.pathname?.includes(siteConfig?.settings?.routes?.PRODUCT)) {
          if (activeProduct) {
            setActiveProduct(null)
          }
          if (activeVariant) {
            setActiveVariant(null)
          }
        }
      }
    })
  }, [activeProduct, activeQuiz, activeVariant, globalState, setActiveProduct, setActiveQuiz, setActiveVariant, siteConfig])

  return <AppContext.Provider value={{ ...values, globalState }}>{children}</AppContext.Provider>
}

export const withApp =
  Component =>
  (props: any): JSX.Element =>
    <AppContext.Consumer>{(values: any) => <Component {...props} layout={values} />}</AppContext.Consumer>
