import { useState, useCallback } from "react"

import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCheckoutContext } from "./useCheckout"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"

export const useCart = () => {
  const {
    helpers: { storage, decodeShopifyId },
    graphql: {
      mutations: { CHECKOUT_LINE_ITEMS_REPLACE },
    },
  } = useCore()
  const {
    globalState,
    config: {
      settings: { keys, customAttributeKeys },
    },
  } = useApp()
  const { checkout, countryCode, setCheckout } = useCheckoutContext()
  const { useMutation, checkoutNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const checkoutId = checkout?.id || storage.get(keys?.checkout)

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)

  const prepareCustomAttributes = useCallback(
    attributes =>
      attributes?.map(({ key, value }) => ({
        key,
        value,
      })) || [],
    []
  )

  const sortPickMixLineItems = useCallback(
    lineItems => {
      return lineItems.sort((a, b) => {
        // Always put the Pick Mix products after any other else
        if (a?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedName)?.value) {
          return 1
        } else if (b?.customAttributes?.find(({ key }) => key === customAttributeKeys.groupedName)?.value) {
          return -1
        } else {
          return 0
        }
      })
    },
    [customAttributeKeys.groupedName]
  )

  const addToCart = useCallback(
    async (variantId, quantity = 1, customAttributes = [], drawer = true) => {
      setLoading(true)
      let alreadyInCart = false
      let shouldAdd = true

      if (customAttributes.filter((attr: any) => attr.key === "_gift_item").length) {
        const checkGift = attributes => attributes.filter((attr: any) => attr.key === "_gift_item")
        const inCart = checkout?.lineItems?.filter((lineItem: any) => {
          const id = `gid://shopify/ProductVariant/${decodeShopifyId(variantId, `ProductVariant`)}`
          return lineItem?.variant?.id == id && checkGift(lineItem?.customAttributes).length
        })

        if (inCart.length) {
          shouldAdd = false
          const item = inCart[0]
          if (item) {
            updateQuantity(item?.id, variantId, 1)
          }
        }
      }

      if (shouldAdd) {
        const lineItems =
          checkout?.lineItems?.map((lineItem: any) => {
            if (
              lineItem?.variant?.id === variantId &&
              JSON.stringify(prepareCustomAttributes(lineItem?.customAttributes)) === JSON.stringify(customAttributes)
            ) {
              alreadyInCart = true
              return {
                customAttributes: [...prepareCustomAttributes(lineItem?.customAttributes), ...(customAttributes || [])],
                quantity: lineItem?.quantity + quantity,
                variantId,
              }
            }
            return {
              customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
                key,
                value,
              })),
              quantity: lineItem?.quantity,
              variantId: lineItem?.variant?.id,
            }
          }) || []

        const {
          data: { checkoutLineItemsReplace: data, userErrors: errors },
        } = await lineItemsReplace({
          variables: {
            countryCode,
            checkoutId,
            lineItems: sortPickMixLineItems([...(alreadyInCart ? lineItems : [...lineItems, { quantity, variantId, customAttributes }])]),
          },
        })

        if (errors?.length) setErrors(errors)
        if (data) await setCheckout(checkoutNormaliser(data?.checkout))

        setLoading(false)

        const [state, dispatch] = globalState

        if (drawer && !state.activeCart) {
          dispatch({
            type: "setActiveCart",
            payload: !state.activeCart,
          })
        }

        if (shouldAdd) {
          trackCartUpdate("add", variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
        }
      } else {
        setLoading(false)
      }
    },
    [
      checkout?.lineItems,
      lineItemsReplace,
      countryCode,
      checkoutId,
      sortPickMixLineItems,
      setCheckout,
      checkoutNormaliser,
      globalState,
      trackCartUpdate,
      prepareCustomAttributes,
    ]
  )

  const addMultipleToCart = useCallback(
    async (input: Array<{ variantId: string; quantity: number; customAttributes: Array<{ [key: string]: string }> }>, groupedId: string) => {
      setLoading(true)
      const lineItems = checkout?.lineItems ? [...checkout.lineItems] : []
      const convertedLineItems = lineItems
        .filter(item => item?.customAttributes?.find(attribute => attribute.key === customAttributeKeys.grouped)?.value !== groupedId)
        .map(lineItem => {
          return {
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            variantId: lineItem?.variant?.id || lineItem?.variantId,
          }
        })

      for (const item of input) {
        convertedLineItems.push({ quantity: item.quantity || 1, variantId: item.variantId, customAttributes: item.customAttributes || [] })
      }

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: sortPickMixLineItems(convertedLineItems),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        await setCheckout(checkoutNormaliser(data?.checkout))
      }
      setLoading(false)

      for (const item of input) {
        trackCartUpdate("add", item.variantId, item.quantity, checkoutNormaliser(data?.checkout)?.lineItems)
      }
    },
    [
      checkout.lineItems,
      countryCode,
      lineItemsReplace,
      checkoutId,
      sortPickMixLineItems,
      customAttributeKeys.grouped,
      setCheckout,
      checkoutNormaliser,
      trackCartUpdate,
    ]
  )

  const removeFromCart = useCallback(
    async (id, variantId) => {
      setLoading(true)
      const quantity = checkout?.lineItems.filter(lineItem => lineItem.id === id).map(({ quantity }) => quantity)[0] || 1
      trackCartUpdate("remove", variantId, quantity, checkout?.lineItems)

      const lineItems = checkout?.lineItems
        .filter(lineItem => lineItem.id !== id)
        .map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: sortPickMixLineItems(lineItems),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) await setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)
    },
    [checkout?.lineItems, trackCartUpdate, countryCode, lineItemsReplace, checkoutId, sortPickMixLineItems, setCheckout, checkoutNormaliser]
  )

  const removeMultipleFromCart = useCallback(
    async (input: Array<{ variantId: string; qty?: number; groupedId?: string }>) => {
      setLoading(true)

      // Normalize lineItems
      let lineItems: Array<{ variantId: string; quantity: number; customAttributes?: Array<{ key: string; value: string }> }> =
        checkout?.lineItems?.map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant?.id || lineItem.variantId,
        })) || []

      // Replace quantity
      for (const item of input) {
        const quantity =
          item.qty || checkout?.lineItems.filter(({ variant }) => variant.id === item.variantId).map(({ quantity }) => quantity)[0] || 1
        trackCartUpdate("remove", item.variantId, quantity, checkout?.lineItems)

        lineItems = lineItems.map(lineItem => {
          if (
            (lineItem.variantId === item.variantId || lineItem.variantId === item.variantId) &&
            (!item.groupedId ||
              (item.groupedId &&
                lineItem.customAttributes?.find(attribute => attribute.key === customAttributeKeys.grouped)?.value === item.groupedId))
          ) {
            return {
              ...(lineItem.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
              }),
              quantity: item.qty ? lineItem.quantity - item.qty : 0,
              variantId: lineItem.variantId || lineItem.variantId,
            }
          }

          return lineItem
        })
      }

      // Filter deleted items
      lineItems = lineItems.filter(lineItem => lineItem.quantity)

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: sortPickMixLineItems(lineItems),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) await setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)
    },
    [
      checkout?.lineItems,
      lineItemsReplace,
      countryCode,
      checkoutId,
      sortPickMixLineItems,
      setCheckout,
      checkoutNormaliser,
      trackCartUpdate,
      customAttributeKeys.grouped,
    ]
  )

  const updateQuantity = useCallback(
    async (id, variantId, quantity, action = "add") => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.id === id ? quantity : lineItem.quantity,
        variantId: lineItem.variant.id,
      }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: sortPickMixLineItems(lineItems),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) await setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)

      trackCartUpdate(action, variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [checkout?.lineItems, lineItemsReplace, countryCode, checkoutId, sortPickMixLineItems, setCheckout, checkoutNormaliser, trackCartUpdate]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
      }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: sortPickMixLineItems(lineItems),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)
    },
    [checkout?.lineItems, lineItemsReplace, countryCode, checkoutId, sortPickMixLineItems, setCheckout, checkoutNormaliser]
  )

  const updateItem = useCallback(
    async (id, variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.id === id
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...prepareCustomAttributes(lineItem?.customAttributes),
                    ...(Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })) || []),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: sortPickMixLineItems(lineItems),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(checkoutNormaliser(data?.checkout))
      setLoading(false)
    },
    [checkout?.lineItems, lineItemsReplace, countryCode, checkoutId, sortPickMixLineItems, setCheckout, checkoutNormaliser, prepareCustomAttributes]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)
    checkout?.lineItems?.map(({ variant, quantity }) => trackCartUpdate("remove", variant?.id, quantity, checkout?.lineItems))

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: {
        countryCode,
        checkoutId,
        lineItems: [],
      },
    })

    if (errors?.length) setErrors(errors)
    if (data) setCheckout(checkoutNormaliser(data?.checkout))

    setLoading(false)
  }, [lineItemsReplace, countryCode, setErrors, setCheckout, setLoading, trackCartUpdate, checkout, checkoutId, checkoutNormaliser])

  const getGroupedItems = useCallback(
    (lineItem, lineItems) =>
      lineItems.filter(
        item =>
          item.customAttributes &&
          item.customAttributes.find(
            ({ key, value }) =>
              key === customAttributeKeys.grouped &&
              value === lineItem.customAttributes?.find(({ key }) => key === customAttributeKeys.grouped)?.value
          )
      ),
    [customAttributeKeys.grouped]
  )

  return {
    addToCart,
    addMultipleToCart,
    removeFromCart,
    removeMultipleFromCart,
    updateQuantity,
    updateVariant,
    updateItem,
    clearCart,
    getGroupedItems,
    loading,
    errors,
  }
}
