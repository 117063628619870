import React, { memo, useMemo } from "react"

import { useTemplate } from "../../../hooks/useTemplate"
import { useShopContext } from "../../../hooks/useShop"

export const withFooterPayments = Component =>
  memo(({ name = "FooterPayments" }: any) => {
    const { shop } = useShopContext()
    const { global: locales } = useTemplate()

    const capitalise = (title: string) =>
      title
        ?.split(`_`)
        ?.map(title => title.charAt(0).toUpperCase() + title.slice(1)?.toLowerCase())
        ?.join(` `)

    const allowed = [`afterpay`, `applepay`, `mastercard`, `paypal`, `visa`]
    const types = [
      ...(shop?.paymentSettings?.acceptedCardBrands || []),
      `PayPal`,
      `AfterPay`,
      ...(shop?.paymentSettings?.supportedDigitalWallets || []),
    ]?.map((item: string) => ({
      id: item?.replace(`_`, ``)?.toLowerCase(),
      title: capitalise(item),
    }))
    const items = types?.filter(item => allowed?.includes(item?.id))

    Component.displayName = name
    return useMemo(() => <Component items={items} locales={locales} />, [items, locales])
  })
