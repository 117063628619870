import tw, { css, styled } from "twin.macro"
import { Link as GatsbyLink } from "gatsby"

import { StyledLink } from "../Styled/Button"

export const StyledHeader = tw.header`fixed top-0 inset-x-0 bg-white z-10 print:hidden`
export const Upper = tw.div`bg-orange text-white`
export const Middle = styled.div`
  ${tw`pt-3.5 pb-[1.125rem] lg:py-0 border-b border-grey-lighter`}
  ${css`
    @media (min-width: 1024px) {
      .hbc-mobile {
        display: none;
      }
    }
    @media (max-width: 1023px) {
      .hbc-desktop {
        display: none;
      }
    }
  `}
`
export const Lower = tw.div`py-1.5 md:pt-1.75 md:pb-0 pl-10 overflow-hidden w-full`
export const Logo = tw(GatsbyLink)`block max-w-full w-36 lg:w-48 lg:ml-0 lg:pb-2.5`
export const SearchWrapper = tw.div`relative`
export const Link = styled(StyledLink)`
  ${css`
    padding-left: 0;
  `}
  ${tw`hover:text-black lg:hidden`}
`
export const Buffer = styled.div`
  ${tw`pointer-events-none`}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`

export const HeaderBarContainer = styled.div`
  ${tw`px-3 lg:px-10 lg:pt-6`}
`

export const UpperItems = styled.div`
  ${tw`flex flex-col-reverse md:flex-row items-center justify-between lg:min-h-[40px]`}
`
