import React from "react"

import { withFooterSubscribe } from "./withFooterSubscribe"
import { FormInput } from "../../Form/Input/FormInput"
import { StyledButton } from "../../Styled/Button"
import { StyledForm } from "../../Styled/Form"
import { H5 } from "../../Styled/Text"
import { Title } from "./styledFooterSubscribe"

export const FooterSubscribe = withFooterSubscribe(
  ({ data, errors, handleChange, handleSubmit, locales, loading, success }): JSX.Element => (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <Title large withSpacing={`xxs`}>
          {locales?.additionalSubscribe}
        </Title>
        {success ? (
          <H5 colour={`purple-dark`}>{locales?.additionalSubscribeSuccess?.replace(`{name}`, data?.firstName || ``)}</H5>
        ) : (
          <>
            <FormInput
              name={`firstName`}
              onChange={handleChange}
              placeholder={locales?.additionalSubscribeName}
              required
              disabled={loading}
              value={data?.firstName}
              withSpacing={`xxs`}
            />
            <FormInput
              type={`email`}
              name={`email`}
              errors={errors}
              onChange={handleChange}
              placeholder={locales?.additionalSubscribeEmail}
              required
              disabled={loading}
              value={data?.email}
              withSpacing={`sm`}
            />
            <StyledButton type={`submit`} colour={`orange`} disabled={loading} size={`secondary`} title={locales?.additionalSubscribeSubmit}>
              {locales?.additionalSubscribeSubmit}
            </StyledButton>
          </>
        )}
      </StyledForm>
    </>
  )
)
