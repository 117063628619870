import { useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { PickGift, PickGiftHelper } from "../types/pickGift"
import * as Sanity from "../types/sanity"
import * as Shopify from "../types/shopify"
import { useApp } from "./useApp"

export const usePickGift = (): Response => {
  const {
    setActiveAnimationPopupPickGift,
    setActiveBoxCardAnimationPopup,
  }: { setActiveAnimationPopupPickGift: any; setActiveBoxCardAnimationPopup: any } = useApp()

  const { pickMix } = useStaticQuery(graphql`
    query {
      pickMix: sanityPagePickGift {
        links: _rawLinks(resolveReferences: { maxDepth: 4 })
      }
    }
  `)

  const handleAnimationPopup = useCallback(
    (images: Shopify.ShopifyImage[]) => setActiveAnimationPopupPickGift(images),
    [setActiveAnimationPopupPickGift]
  )

  const handleBoxCardAnimationPopup = useCallback(
    (images: Shopify.ShopifyImage[]) => {
      setActiveBoxCardAnimationPopup(images)
    },
    [setActiveBoxCardAnimationPopup]
  )

  const mergeProducts = (shopifyProducts: Shopify.Product[], sanityProducts: Sanity.Product[]): Array<Sanity.Product & Shopify.Product> => {
    if (!Array.isArray(shopifyProducts) || !Array.isArray(sanityProducts)) {
      return []
    }

    return shopifyProducts.map((item: Shopify.Product) => {
      const sanityProduct = sanityProducts?.find(product => product?.shopify?.shopifyHandle === item?.handle)

      return {
        ...sanityProduct,
        ...item,
      }
    })
  }

  const getSelectableProducts = (input: PickGiftHelper.getSelectableProductsInput): PickGiftHelper.getSelectableProductsResponse => {
    const { filters, shopifyProducts, sanityProducts } = input

    // Filter items by tag
    return filters.reduce(
      (result, filter) => {
        if (filter.productTag) {
          const key = filter.title
          const relatedShopifyProducts = shopifyProducts?.filter(product => product.tags?.includes(filter.productTag)) || []
          const relatedProducts = mergeProducts(relatedShopifyProducts, sanityProducts)
          result[key] = relatedProducts
          result["All products"] = shopifyProducts
        }
        return result
      },
      { ["All products"]: [] }
    )
  }

  const getSelectableProductsRefine = (input: PickGiftHelper.getSelectableProductsInputRefine): PickGiftHelper.getSelectableProductsResponse => {
    const { refine, shopifyProducts, sanityProducts } = input

    // Filter items by tag
    return refine.reduce(
      (result, refine) => {
        if (refine.productTag) {
          const key = refine.title
          const relatedShopifyProducts = shopifyProducts?.filter(product => product.tags?.includes(refine.productTag)) || []
          const relatedProducts = mergeProducts(relatedShopifyProducts, sanityProducts)
          result[key] = relatedProducts
          result["All products"] = shopifyProducts
        }
        return result
      },
      { ["All products"]: [] }
    )
  }

  const convertFilter = (items: PickGift.Item[]) => {
    const newItems = [...items]
    newItems.unshift({
      title: "All products",
      productTag: null,
    })
    return newItems
  }

  return {
    pickMix,
    handleAnimationPopup,
    handleBoxCardAnimationPopup,
    getSelectableProducts,
    getSelectableProductsRefine,
    mergeProducts,
    convertFilter,
  }
}

type Link = {
  document: any
  title: string
  _key: string
  _type: string
}
type PickMixResponse = {
  links: Link[]
}
type Response = {
  pickMix: PickMixResponse
  handleAnimationPopup: (images: Shopify.ShopifyImage[]) => void
  handleBoxCardAnimationPopup: (images: Shopify.ShopifyImage[]) => void
  getSelectableProducts: (input: PickGiftHelper.getSelectableProductsInput) => PickGiftHelper.getSelectableProductsResponse
  getSelectableProductsRefine: (input: PickGiftHelper.getSelectableProductsInputRefine) => PickGiftHelper.getSelectableProductsResponse
  mergeProducts: (shopifyProducts: Shopify.Product[], sanityProducts: Sanity.Product[]) => Array<Sanity.Product & Shopify.Product>
  convertFilter: (items: PickGift.Item[]) => PickGift.Item[]
}
