module.exports = {
  id: "skin-juice-stage",
  title: "Skin Juice Staging",
  description: "Zero-gimmick skincare that will make your skin smile",
  url: "https://skin-juice-website-stage.web.app",

  siteLocation: "AU",
  siteCountries: "AU",
  siteCurrency: "AUD",
  shopName: "skin-juice-stage",
  shopDomain: "skin-juice-stage.myshopify.com",
  shopifyApiVersion: "2024-01",
  accessToken: "5d3c6018b8bcff47825de13b7fcae797",
  adminAccessToken: "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",
  functionsEndpoint: "https://api-skin-juice-website-stage.web.app",
  shopifyPlus: true,
  emailSender: "hello@skinjuice.com.au",

  searchIndex: "skin-juice-search-stage-v2",
  orderPrinterToken: "b42e4ff9dda2cc77b6c8",
  orderPrinterIndex: 5176,
  searchConfig: `https://config.search.reactify.app/?shop=skin-juice-stage.myshopify.com`,

  sanityProjectId: "aktntfbz",
  sanityDataset: "staging",
  sanityApiVersion: "2022-02-24",
}
