import { useState, useCallback, useEffect } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"

const INITIAL_STATE = {
  password: "",
}

export const useMaintenance = (location: any) => {
  const {
    config: {
      settings: { keys, params, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser, decodeBase64, encodeBase64, storage },
  } = useCore()
  const { getUrlParameter } = useRoutes()
  const [error, setError] = useState(false)
  const [data, setData] = useState(INITIAL_STATE)

  const { maintenance } = useStaticQuery(graphql`
    query {
      maintenance: sanitySettingMaintenance {
        title
        password
        enabled
        backgroundImage {
          asset {
            url
          }
        }
        additionalError
        additionalPassword
        additionalSubmit
      }
    }
  `)

  const saved = storage.get(keys?.password)
  const authorised = !maintenance?.enabled || (saved && maintenance?.password === decodeBase64(saved))
  const active = location.pathname.startsWith(routes.PASSWORD)
  const navigateToSite = useCallback(
    () => navigate(`${getUrlParameter(params?.continue) || routes.HOMEPAGE}`, { replace: true }),
    [getUrlParameter, params, routes.HOMEPAGE]
  )

  useEffect(() => {
    if (isBrowser) {
      if (active) {
        document.body.classList.add(`overflow-x-hidden`)
        document.body.classList.add(`fixed`)
        document.body.classList.add(`inset-0`)
        document.body.classList.add(`bg-pink-dark`)
      } else {
        document.body.classList.remove(`overflow-x-hidden`)
        document.body.classList.remove(`fixed`)
        document.body.classList.remove(`inset-0`)
        document.body.classList.remove(`bg-pink-dark`)
      }
    }
  }, [active, isBrowser])

  if (isBrowser) {
    if (maintenance?.enabled) {
      storage.set(keys?.maintenance, maintenance?.enabled)
      if (!authorised && !active)
        navigate(`${routes.PASSWORD}${location.pathname !== routes.HOMEPAGE ? `?${params?.continue}=${location.pathname}` : ``}`, {
          replace: true,
        })
    }

    if ((active && !maintenance?.enabled) || (active && maintenance?.enabled && authorised)) {
      storage.remove(keys?.maintenance)
      navigateToSite()
    }
  }

  const validatePassword = useCallback(() => {
    if (maintenance?.password === data?.password) {
      storage.set(keys?.password, encodeBase64(data?.password))
      setData(INITIAL_STATE)
      setError(false)
      navigateToSite()
    } else {
      storage.remove(keys?.password)
      setData(INITIAL_STATE)
      setError(true)
    }
  }, [keys, navigateToSite, storage, encodeBase64, setData, setError, maintenance, data])

  return { active, authorised, error, data, locales: maintenance, setData, setError, validatePassword }
}
