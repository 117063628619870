import React from "react"
import { withSubMenuNavigation } from "./withSubMenuNavigation"
import { HeaderNavigationPrimary } from "../../Primary/HeaderNavigationPrimary"
import { MultiColourText } from "../../Main/styledHeaderNavigationMain"
import { Overlay, Link } from "../../Primary/styledHeaderNavigationPrimary"
import { Icon } from "../../../../Icon/Icon"
import { SubMenuContainer, IconContainer } from "../Submenu/styledSubMenuNavigation"

export const SubMenuNavigation = withSubMenuNavigation(
  ({ showSubMenu, primary, bounds, handleSubnav, multiColourText, title, handleClose, isMobile, handleMobileMenu, isLast }): JSX.Element => (
    <>
      {!isMobile ? (
        <SubMenuContainer>
          <Overlay active={showSubMenu} onMouseEnter={handleClose} headerHeight={bounds?.header?.height} />
          <Link className={`animate-underline`} title={title} isMobile={isMobile}>
            {multiColourText ? <MultiColourText>{title}</MultiColourText> : title}
            <span className={`line`} />
          </Link>
          <HeaderNavigationPrimary
            bounds={bounds?.header}
            primary={primary}
            active={showSubMenu}
            handleSubnav={handleSubnav}
            handleClose={handleClose}
            handleMobileMenu={handleMobileMenu}
          />
        </SubMenuContainer>
      ) : (
        <SubMenuContainer isMobile={isMobile} isLast={isLast}>
          <Link title={title} onClick={handleSubnav} isMobile={isMobile} isLast={isLast}>
            {multiColourText ? <MultiColourText>{title}</MultiColourText> : title}
            <IconContainer active={showSubMenu}>
              <Icon name={`rightCaret`} width={25} />
            </IconContainer>
          </Link>
          <HeaderNavigationPrimary
            bounds={bounds?.header}
            primary={primary}
            active={showSubMenu}
            handleSubnav={handleSubnav}
            handleClose={handleClose}
            isMobile={isMobile}
          />
        </SubMenuContainer>
      )}
    </>
  )
)

export default SubMenuNavigation
