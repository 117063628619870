import React from "react"

import { useAnnouncement } from "../../../hooks/useAnnouncement"

export const withHeaderAnnouncement =
  Component =>
  ({ name = "HeaderAnnouncement" }: any) => {
    const { autoplay: rawAutoplay, autoplaySeconds, items } = useAnnouncement()

    const autoplay = rawAutoplay && autoplaySeconds ? autoplaySeconds * 1000 : null

    Component.displayName = name
    return items?.length > 0 ? <Component autoplay={autoplay} items={items} /> : null
  }
