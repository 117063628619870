import tw, { styled, css } from "twin.macro"

export const SubMenuContainer = styled.div`
  ${tw`relative transition-all duration-700`}
  ${({ isMobile }) => isMobile && tw`flex flex-col`}
`

export const IconContainer = styled.div`
  ${css`
    transform: rotate(90deg);
  `}
  ${({ active }) =>
    active &&
    css`
      transform: rotate(-90deg);
    `}
`
