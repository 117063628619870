import tw, { styled } from "twin.macro"

import { StyledLink } from "../../Styled/Button"

export const Nav = tw.nav`w-full mt-8`
export const Items = styled.ul`
  ${tw`flex items-center justify-center md:justify-start`}
  ${({ small }) => small && tw`row-span-1`}
`
export const Item = tw.li`mr-4 last:mr-0`
export const Link = tw(StyledLink)`block`
