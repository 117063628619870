import { useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { PickMix, PickMixHelper } from "../types/pickMix"
import * as Sanity from "../types/sanity"
import * as Shopify from "../types/shopify"
import { useApp } from "./useApp"

export const usePickMix = (): Response => {
  const { setActiveAnimationPopup }: { setActiveAnimationPopup: any } = useApp()

  const { pickMix } = useStaticQuery(graphql`
    query {
      pickMix: sanityPagePickMix {
        links: _rawLinks(resolveReferences: { maxDepth: 4 })
      }
    }
  `)

  const handleAnimationPopup = useCallback(
    (images: Shopify.ShopifyImage[]) => {
      setActiveAnimationPopup(images)
    },
    [setActiveAnimationPopup]
  )

  const mergeProducts = (shopifyProducts: Shopify.Product[], sanityProducts: Sanity.Product[]): Array<Sanity.Product & Shopify.Product> => {
    if (!Array.isArray(shopifyProducts) || !Array.isArray(sanityProducts)) {
      return []
    }

    return shopifyProducts.map((item: Shopify.Product) => {
      const sanityProduct = sanityProducts?.find(product => product?.shopify?.shopifyHandle === item?.handle)

      return {
        ...sanityProduct,
        ...item,
      }
    })
  }

  const getSelectableProducts = (input: PickMixHelper.getSelectableProductsInput): PickMixHelper.getSelectableProductsResponse => {
    const { filters, shopifyProducts, sanityProducts } = input

    // Filter items by tag
    return filters.reduce(
      (result, filter) => {
        if (filter.productTag) {
          const key = filter.title
          const relatedShopifyProducts = shopifyProducts?.filter(product => product.tags?.includes(filter.productTag)) || []
          const relatedProducts = mergeProducts(relatedShopifyProducts, sanityProducts)
          result[key] = relatedProducts
          result["All products"] = result["All products"].concat(relatedProducts)
        }
        return result
      },
      { ["All products"]: [] }
    )
  }

  const convertFilter = (items: PickMix.Item[]) => {
    const newItems = [...items]
    newItems.unshift({
      title: "All products",
      productTag: null,
    })
    return newItems
  }

  return {
    pickMix,
    handleAnimationPopup,
    getSelectableProducts,
    mergeProducts,
    convertFilter,
  }
}

type Link = {
  document: any
  title: string
  _key: string
  _type: string
}
type PickMixResponse = {
  links: Link[]
}
type Response = {
  pickMix: PickMixResponse
  handleAnimationPopup: (images: Shopify.ShopifyImage[]) => void
  getSelectableProducts: (input: PickMixHelper.getSelectableProductsInput) => PickMixHelper.getSelectableProductsResponse
  mergeProducts: (shopifyProducts: Shopify.Product[], sanityProducts: Sanity.Product[]) => Array<Sanity.Product & Shopify.Product>
  convertFilter: (items: PickMix.Item[]) => PickMix.Item[]
}
