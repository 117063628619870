import { useStaticQuery, graphql } from "gatsby"

import { useRoutes } from "./useRoutes"

export const useAnnouncement = () => {
  const { linkResolver } = useRoutes()

  const { announcements } = useStaticQuery(graphql`
    query {
      announcements: sanitySettingAnnouncement {
        autoplay
        autoplaySeconds
        items {
          id: _key
          link: _rawDocument(resolveReferences: { maxDepth: 6 })
          title
        }
      }
    }
  `)

  const items = announcements?.items?.map(({ id, link, title }) => ({
    id,
    link: linkResolver(link),
    title,
  }))

  return {
    ...announcements,
    items,
  }
}
