import React, { memo, useMemo } from "react"

import { useShopify } from "../../../../../hooks/useShopify"

export const withSearchFormResultsItem = Component =>
  memo(({ name = "SearchFormResultsItem", product }: any) => {
    const { adminProductNormaliser } = useShopify()

    const item = adminProductNormaliser(product)

    Component.displayName = name
    return useMemo(() => <Component product={item} />, [item])
  })
