import React from "react"
import Measure from "react-measure"
import { ReactifySearchProvider } from "@usereactify/search"

import { withHeader } from "./withHeader"
import { HeaderAnnouncement } from "./Announcement/HeaderAnnouncement"
import { HeaderNavigationMain } from "./Navigation/Main/HeaderNavigationMain"
import { HeaderNavigationTertiary } from "./Navigation/Tertiary/HeaderNavigationTertiary"
import { SearchForm } from "../Search/Form/SearchForm"
import { CartDrawer } from "../Cart/Drawer/CartDrawer"
import { Icon } from "../Icon/Icon"
import { HeaderCurrency } from "./Currency/HeaderCurrency"
import { SearchWidget } from "../Search/Widget/SearchWidget"
import { AccountWidget } from "../Account/Widget/AccountWidget"
import { CartWidget } from "../Cart/Widget/CartWidget"
import { StyledColumn } from "../Styled/Column"
import { StyledContainer } from "../Styled/Container"
import { StyledRow } from "../Styled/Row"
import { StyledHeader, Upper, UpperItems, Middle, Lower, Logo, Buffer, SearchWrapper, Link, HeaderBarContainer } from "./styledHeader"

export const Header = withHeader(
  ({ bounds, handleResize, handleRedirect, locales, location, shopifyPermanentDomain, title, showMobileMenu, handleMobileMenu }): JSX.Element => (
    <>
      <Measure bounds onResize={(contentRect: any) => handleResize(contentRect, `header`)}>
        {({ measureRef }) => (
          <StyledHeader ref={measureRef}>
            <Upper>
              <StyledContainer collapse={`<sm`} width={`xl`}>
                <UpperItems>
                  <StyledColumn width={`md:1/3`} />
                  <StyledColumn screen={`md>`} width={`md:1/3`}>
                    <HeaderAnnouncement />
                  </StyledColumn>
                  <StyledColumn width={`md:1/3`}>
                    <StyledRow justify={`end`} items={`center`}>
                      <StyledColumn screen={`<sm`} width={`1/6`} />
                      <StyledColumn screen={`<sm`} width={`2/3`}>
                        <HeaderAnnouncement />
                      </StyledColumn>
                      <StyledColumn justify={`end`} items={`center`} width={`1/6 md:full`}>
                        <HeaderNavigationTertiary />
                        <HeaderCurrency />
                      </StyledColumn>
                    </StyledRow>
                  </StyledColumn>
                </UpperItems>
              </StyledContainer>
            </Upper>

            <Measure bounds onResize={(contentRect: any) => handleResize(contentRect, `lower`)}>
              {({ measureRef }) => (
                <SearchWrapper ref={measureRef}>
                  <Middle>
                    <HeaderBarContainer className="hbc-desktop">
                      <StyledRow items={`center`}>
                        <StyledColumn justify={`start`} width={`fit`}>
                          <Logo title={title} to={`/`}>
                            <Icon name={`logo`} title={title} width={190} />
                          </Logo>
                        </StyledColumn>
                        <Lower>
                          <StyledColumn justify={`center`}>
                            <HeaderNavigationMain bounds={bounds} handleMobileMenu={handleMobileMenu} />
                          </StyledColumn>
                        </Lower>
                        <StyledColumn justify={`end`} items={`center`} width={`fit`} minWidth={190} padding={`lg:pb-2.5`}>
                          <SearchWidget screen={`lg`} />
                          <AccountWidget screen={`lg`} />
                          <CartWidget location={location} screen={`lg`} />
                        </StyledColumn>
                      </StyledRow>
                    </HeaderBarContainer>
                    <HeaderBarContainer className="hbc-mobile">
                      <StyledRow items={`center`}>
                        <StyledColumn width={`md:1/6`}>
                          <Link title={locales?.additionalMenu} onClick={() => handleMobileMenu(!showMobileMenu)}>
                            <Icon name={showMobileMenu ? `cross` : `menu`} title={title} width={showMobileMenu ? 14 : 25} />
                          </Link>
                        </StyledColumn>
                        <StyledColumn justify={`center`}>
                          <Logo title={title} to={`/`} onClick={() => showMobileMenu && handleMobileMenu(!showMobileMenu)}>
                            <Icon name={`logo`} title={title} width={190} />
                          </Logo>
                        </StyledColumn>
                        <StyledColumn justify={`end`} items={`center`} width={`md:1/6`}>
                          <SearchWidget screen={`sm`} handleMobileMenu={handleMobileMenu} />
                          <CartWidget location={location} screen={`sm`} />
                        </StyledColumn>
                      </StyledRow>
                      <HeaderNavigationMain bounds={bounds} showMobileMenu={showMobileMenu} handleMobileMenu={handleMobileMenu} />
                    </HeaderBarContainer>
                  </Middle>
                  <ReactifySearchProvider mode="instant-search" onRedirect={handleRedirect} shopifyPermanentDomain={shopifyPermanentDomain}>
                    <SearchForm bounds={bounds?.lower} />
                  </ReactifySearchProvider>
                </SearchWrapper>
              )}
            </Measure>
          </StyledHeader>
        )}
      </Measure>
      <Buffer height={bounds?.header?.height} />
      <CartDrawer bounds={bounds?.header} />
    </>
  )
)
