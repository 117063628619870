import React, { memo, useCallback, useMemo } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useNavigationTertiary } from "../../../../hooks/useNavigationTertiary"
import { useTemplate } from "../../../../hooks/useTemplate"

export const withHeaderNavigationPrimary = Component =>
  memo(({ name = "HeaderNavigationPrimary", bounds, primary, active, handleClose, isMobile }: any) => {
    const { globalState } = useApp()
    const { tertiary } = useNavigationTertiary()
    const { global: locales } = useTemplate()
    const [state, dispatch] = globalState

    const handleSubMenuItems = useCallback(
      key => {
        dispatch({ type: "setActiveSubMenu", payload: key !== state?.activeSubMenu ? key : false })
      },
      [state, dispatch]
    )

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          active={active}
          activeSearch={state?.activeSearch}
          bounds={bounds}
          handleClose={handleClose}
          locales={locales}
          primary={primary}
          tertiary={tertiary}
          headerHeight={bounds?.height}
          isMobile={isMobile}
          activeSubMenu={state?.activeSubMenu}
          handleSubMenuItems={handleSubMenuItems}
        />
      ),
      [active, bounds, handleClose, locales, primary, state, tertiary, isMobile, handleSubMenuItems]
    )
  })
