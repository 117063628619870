import { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"
import { useImage } from "./useImage"
import { useShopify } from "./useShopify"
import { InstagramContext } from "../providers/instagram"

export const useInstgramContext = () => {
  const instagram: any = useContext(InstagramContext)

  return { ...instagram }
}

export const useInstagram = (large = false) => {
  const {
    helpers: { edgeNormaliser },
  } = useApp()
  const { getFluidImage } = useImage()
  const { productNormaliser } = useShopify()

  const { images } = useStaticQuery(graphql`
    query {
      images: allSanityInstagram(
        filter: { settingsHide: { ne: true }, timestamp: { ne: "" } }
        limit: 24
        sort: { fields: _createdAt, order: DESC }
      ) {
        edges {
          node {
            ...GatsbyInstagramFragment
          }
        }
      }
    }
  `)

  const items = edgeNormaliser(images)
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    .map(item => ({
      id: item?.id,
      image: getFluidImage(item?.image, { maxWidth: large ? 2000 : 800 }),
      pins: item?.pins?.map(({ _key, position_x, position_y, product }) => ({
        id: _key,
        position: {
          x: position_x || 0,
          y: position_y || 0,
        },
        product: productNormaliser(product),
      })),
      title: item?.title?.replace(/(?:\r\n|\r|\n)/g, "<br>")?.trim(),
    }))

  return {
    items,
  }
}
