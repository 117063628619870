import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useApp } from "../../../hooks/useApp"
import { useFunctions } from "../../../hooks/useFunctions"
import { useTemplate } from "../../../hooks/useTemplate"

const INITIAL_STATE = { email: "", firstName: "", lastName: "" }

export const withProductNotify = Component =>
  memo(({ name = "ProductNotify" }: any) => {
    const { activeProductNotify: id, headerBounds, setActiveProductNotify } = useApp()
    const { callFunction, errors, loading } = useFunctions()
    const { global, product } = useTemplate()
    const [data, setData] = useState(INITIAL_STATE)
    const [success, setSuccess] = useState(false)
    const field = useRef()

    const active = id !== null
    const locales = useMemo(
      () => ({
        ...global,
        ...product,
      }),
      [global, product]
    )

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()

        const { status } = await callFunction("product-notify", { ...data, product: id })

        if (status === "success") {
          setData(INITIAL_STATE)
          setSuccess(true)
        }
      },
      [callFunction, data, id]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) =>
        setData((prevState: any) => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        })),
      []
    )

    const handleActive = useCallback(() => setActiveProductNotify(null), [setActiveProductNotify])

    useEffect(() => {
      setSuccess(false)
    }, [id, setSuccess])

    useEffect(() => {
      active && field?.current ? setTimeout(() => field.current.select(), 200) : null
    }, [active, field])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          active={active}
          bounds={headerBounds}
          data={data}
          errors={errors}
          field={field}
          handleActive={handleActive}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          locales={locales}
          success={success}
        />
      ),
      [active, headerBounds, data, errors, field, handleActive, handleChange, handleSubmit, loading, locales, success]
    )
  })
