import React from "react"

import { withHeaderCurrency } from "./withHeaderCurrency"
import { Icon } from "../../Icon/Icon"
import { StyledSelectOption } from "../../Styled/Form"
import { Select, SelectWrapper } from "./styledHeaderCurrency"

export const HeaderCurrency = withHeaderCurrency(
  ({ currency, currencies = [], handleChange }): JSX.Element => (
    <SelectWrapper full withSpacing>
      <Select disabled={!currency} title={currency} onChange={handleChange} required value={currency}>
        {currencies?.map(item => (
          <StyledSelectOption key={item?.toString()}>{item}</StyledSelectOption>
        ))}
      </Select>
      <Icon name={`down`} />
    </SelectWrapper>
  )
)
