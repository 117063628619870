import tw from "twin.macro"

import { StyledColumn } from "../Styled/Column"
import { StyledRow } from "../Styled/Row"

export const StyledFooter = tw.footer`relative z-1 print:hidden`
export const Logo = tw.div`flex items-center justify-center md:justify-start`
export const Upper = tw.div`bg-orange-pastel pb-8.5 md:pb-16 pt-10.5 md:pt-17.5`
export const Lower = tw.div`bg-orange text-white pt-5.5 pb-24 text-center md:text-left`
export const Row = tw(StyledRow)`flex-col-reverse md:flex-row`
export const Column = tw(StyledColumn)`md:justify-end`
