import React, { memo, useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useSettings } from "../../../hooks/useSettings"

export const withFooterSocial = Component =>
  memo(({ name = "FooterSocial" }: any) => {
    const {
      helpers: { handleize },
    } = useCore()
    const { social } = useSettings()

    const allowed = [`facebook`, `instagram`, `pinterest`, `youtube`]
    const channels = social?.channels?.map(({ title, url }) => ({
      title,
      id: handleize(title),
      url,
    }))
    const items = channels?.filter((item: any) => allowed?.includes(item?.id))

    Component.displayName = name
    return useMemo(() => (items?.length > 0 ? <Component items={items} /> : null), [items])
  })
