import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { navigate } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCart } from "../../../hooks/useCart"
import { useCheckoutContext } from "../../../hooks/useCheckout"
import { useImage } from "../../../hooks/useImage"
import { useShopify, useShopifyPriceRange } from "../../../hooks/useShopify"
import { useTemplate } from "../../../hooks/useTemplate"
import { useWishlist, useWishlistContext } from "../../../hooks/useWishlist"
import { useCheckout } from "../../../hooks/useCheckout"
import { useAnalytics } from "../../../hooks/useAnalytics"

export const withProductCard = Component =>
  memo(
    ({
      name = "ProductCard",
      condensed = false,
      external = false,
      hoverImage = null,
      product,
      withScrollPosition = false,
      withSpacing = null,
      hideTitle = false,
      prototype = false,
    }: any) => {
      const { clientId } = useCheckout()
      const { setActiveProductNotify } = useApp()
      const { addToCart, loading: adding } = useCart()
      const { checkout } = useCheckoutContext()
      const { getFluidImage, getStaticImage } = useImage()
      const { productNormaliser } = useShopify()
      const { product: locales } = useTemplate()
      const { addToWishlist, deleteFromWishlist, existsInWishlist } = useWishlist()
      const { scroll, setScroll } = useWishlistContext()
      const [hover, setHover] = useState(false)
      const card = useRef()
      const { trackSelectContent } = useAnalytics()

      const item = productNormaliser(product)
      const { formattedPriceRange } = useShopifyPriceRange(item)
      const images = [
        getStaticImage(item?.images?.[0], { maxWidth: 1000 }),
        hoverImage ? getFluidImage(hoverImage, { maxWidth: 1000 }) : item?.images?.[1] ? getStaticImage(item?.images?.[1], { maxWidth: 1000 }) : null,
      ]?.filter(Boolean)
      const variant = item?.variants?.find(({ available }) => available) || item?.variants?.[0]
      const priceVaries = item?.variants?.length > 1
      const added = checkout?.lineItems?.find(lineItem => lineItem?.variant?.id === variant?.id)
      const wishlist = existsInWishlist(item?.id)

      const handleAdd = useCallback(
        async event => {
          event.stopPropagation()
          await addToCart(variant?.id)
        },
        [addToCart, variant]
      )

      const handleClick = useCallback(() => {
        if (!hover) {
          setHover(true)
        } else {
          trackSelectContent(item)
          external ? window.open(item?.url, `_blank`) : navigate(item?.url)
        }
      }, [external, hover, item, setHover])

      const handleHover = useCallback((state: boolean) => setHover(state), [setHover])

      const handleNotify = useCallback(
        event => {
          event.stopPropagation()
          setActiveProductNotify(item?.handle)
        },
        [item, setActiveProductNotify]
      )

      const handleWishlist = useCallback(
        event => {
          event.stopPropagation()

          if (wishlist) {
            deleteFromWishlist(item?.id)
          } else {
            addToWishlist(
              {
                ...item,
              },
              null,
              withScrollPosition ? item?.handle : null
            )
          }
        },
        [addToWishlist, deleteFromWishlist, item, wishlist, withScrollPosition]
      )

      useEffect(() => {
        if (withScrollPosition && scroll && scroll === item?.handle && card?.current) {
          card.current.scrollIntoView()
          setTimeout(() => window.scroll(0, window.scrollY - 200), 10)
          setScroll(null)
        }
      }, [card, item, setScroll, scroll, withScrollPosition])

      const price = variant?.priceV2?.amount ? variant?.priceV2?.local : formattedPriceRange

      Component.displayName = name
      return useMemo(
        () => (
          <Component
            {...item}
            added={added}
            adding={adding}
            card={card}
            condensed={condensed}
            handleAdd={handleAdd}
            handleClick={handleClick}
            handleHover={handleHover}
            handleNotify={handleNotify}
            handleWishlist={handleWishlist}
            hover={hover}
            images={images}
            locales={locales}
            price={price}
            priceVaries={priceVaries}
            variant={variant}
            wishlist={wishlist}
            withSpacing={withSpacing}
            hideTitle={hideTitle}
            clientId={clientId}
            prototype={prototype}
          />
        ),
        [
          item,
          added,
          adding,
          card,
          condensed,
          handleAdd,
          handleClick,
          handleHover,
          handleNotify,
          handleWishlist,
          hover,
          images,
          locales,
          price,
          priceVaries,
          variant,
          wishlist,
          withSpacing,
          hideTitle,
          clientId,
          prototype,
        ]
      )
    }
  )
