import React from "react"

import { withSearchWidget } from "./withSearchWidget"
import { Icon } from "../../Icon/Icon"
import { Link } from "./styledSearchWidget"

export const SearchWidget = withSearchWidget(
  ({ handleClick, locales, screen }): JSX.Element => (
    <Link onClick={handleClick} screen={screen} title={locales?.additionalSearch}>
      <Icon name={`search`} width={screen !== "sm" ? 20 : 23} />
    </Link>
  )
)
