import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountWidget } from "./withAccountWidget"
import { Icon } from "../../Icon/Icon"
import { Links, Link, Border, Dropdown, DropdownInner, DropdownLink, Name } from "./styledAccountWidget"

export const AccountWidget = withAccountWidget(
  ({ customer, handleLogout, link, locales, screen }): JSX.Element => (
    <>
      {customer && screen ? (
        <Links className={`group`}>
          <Link as={GatsbyLink} screen={screen} title={locales?.additionalAccount} to={link}>
            <Icon name={`account`} width={screen !== "sm" ? 20 : 23} />
          </Link>
          <Border />
          <Dropdown>
            <DropdownInner>
              <Name large>{locales?.bannerTitle?.replace(`{name}`, customer?.firstName || ``)}</Name>
              <DropdownLink onClick={handleLogout} title={locales?.additionalLogout}>
                {locales?.additionalLogout}
              </DropdownLink>
            </DropdownInner>
          </Dropdown>
        </Links>
      ) : (
        <Link as={GatsbyLink} screen={screen} title={locales?.additionalAccount} to={link}>
          <Icon name={`account`} width={screen !== "sm" ? 20 : 23} />
        </Link>
      )}
    </>
  )
)
