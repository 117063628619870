import React from "react"

import { withCartList } from "./withCartList"
import { CartListItem } from "./Item/CartListItem"
import { Items } from "./styledCartList"

export const CartList = withCartList(
  ({ bounds, drawer, items, filteredItems, getGroupedItems }): JSX.Element => (
    <Items height={bounds?.height} drawer={drawer}>
      {filteredItems?.map((item, idx) => (
        <CartListItem key={item?.id} drawer={drawer} item={item} grouped={getGroupedItems(item, items)} index={idx} />
      ))}
    </Items>
  )
)
