import { useCallback, useMemo } from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"

import { UseImage } from "../types/hooks"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useSettings } from "./useSettings"
import { useShopify } from "./useShopify"

export const useImage = (): UseImage.Response => {
  const {
    config: { store },
  } = useApp()
  const {
    helpers: { handleize },
  } = useCore()
  const { placeholder } = useSettings()
  const { imageNormaliser } = useShopify()

  const sanityConfig = useMemo(
    () => ({
      projectId: store.sanityProjectId,
      dataset: store.sanityDataset,
    }),
    [store]
  )

  const filename = useCallback(({ alt, asset }) => `${handleize(asset?.originalFilename?.split(`.`)?.[0] || alt)}.${asset?.extension}`, [handleize])

  const webPImage = useCallback(src => src?.split(`fm=webp`).join(`auto=format`), [])

  const withFilename = useCallback(
    (fluidImage = {}, name = `image.jpg`, properties = [`src`, `srcWebp`, `srcSet`, `srcSetWebp`]) => ({
      ...fluidImage,
      ...properties?.reduce(
        (o, key) => ({
          ...o,
          [key]: webPImage(fluidImage.images.fallback[key]?.split(`?`)?.join(`/${name}?`)),
        }),
        {}
      ),
    }),
    [webPImage]
  )

  const getFluidImage = useCallback(
    (image: UseImage.FluidImageInput, options: UseImage.ImageOptions = {}) =>
      image?.asset?.id
        ? {
            id: image?.asset?.id,
            alt: image?.alt,
            ...withFilename(getGatsbyImageData(image?.asset?.id as unknown as any, options, sanityConfig), filename(image)),
          }
        : options?.placeholder
        ? { ...(placeholder?.childImageSharp?.gatsbyImageData || {}) }
        : null,
    [filename, placeholder, sanityConfig, withFilename]
  )

  const getResponsiveImage = useCallback(
    (image: UseImage.ResponsiveImageInput, options: UseImage.ResponsiveImageOptionInput = {}) => ({
      desktop: getFluidImage(image?.desktop, options?.desktop || options || {}),
      mobile: getFluidImage(image?.mobile?.asset ? image?.mobile : image?.desktop, options?.mobile || options?.desktop || options || {}),
    }),
    [getFluidImage]
  )

  const getStaticImage = useCallback(
    (image: UseImage.StaticImageInput, options: UseImage.ImageOptions = {}) =>
      image ? imageNormaliser(image) : options?.placeholder ? { ...(placeholder?.childImageSharp?.gatsbyImageData || {}) } : {},
    [imageNormaliser, placeholder]
  )

  const convertSwatchImageUrl = useCallback((url: string): string => (url.includes("?") ? `${url}&w=100` : `${url}?w=100`), [])

  return { getFluidImage, getResponsiveImage, getStaticImage, convertSwatchImageUrl }
}
