import React, { memo, useCallback, useMemo, useState, useEffect } from "react"
import { navigate } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCheckout, useCheckoutContext } from "../../../hooks/useCheckout"
import { useRoutes } from "../../../hooks/useRoutes"
import { useShopify } from "../../../hooks/useShopify"
import { useTemplate } from "../../../hooks/useTemplate"
import { useSettings } from "@app/hooks/useSettings"
import { useGift } from "@app/hooks/useGift"
import { useLocation } from "@app/hooks/useLocation"

export const withCartDrawer = Component =>
  memo(({ name = "CartDrawer", bounds }: any) => {
    const { globalState } = useApp()
    const { checkout } = useCheckoutContext()
    const { count, empty } = useCheckout()
    const { linkResolver, routeResolver } = useRoutes()
    const { formatMoney } = useShopify()
    const { global, cart } = useTemplate()
    const { shipping } = useSettings()
    const { applyGifts, gifts } = useGift()
    const { location } = useLocation()
    const [canSaveNotes, setCanSaveNotes] = useState<boolean>(false)
    const [showNotesError, setNotesError] = useState<boolean>(false)
    const [showCartBar, setShowCartBar] = useState<boolean>(false)

    const locales = useMemo(
      () => ({
        ...global,
        ...cart,
      }),
      [global, cart]
    )

    const link = linkResolver(locales?.link)
    const subtotal = formatMoney(checkout?.totalPriceV2?.amount || 0)

    const [state, dispatch] = globalState

    const handleUpdateNoteStatus = useCallback((status: boolean) => setCanSaveNotes(status), [])

    const handleNotesError = useCallback((status: boolean) => setNotesError(status), [])

    const handleCart = useCallback(() => navigate(routeResolver({ type: `cart` })), [routeResolver])

    const handleClose = useCallback(() => {
      dispatch({
        type: "setActiveCart",
        payload: !state.activeCart,
      })
    }, [dispatch, state])

    useEffect(() => {
      let isValidCountry = []
      if (shipping && shipping?.shippingCountries?.length) {
        isValidCountry = shipping.shippingCountries?.filter(oneCountry => oneCountry.country === location)
      }
      setShowCartBar(isValidCountry.length || gifts.length)
    }, [shipping, gifts, location])

    useEffect(() => {
      state?.activeCart && applyGifts()
    }, [state?.activeCart, applyGifts])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          active={state?.activeCart}
          bounds={bounds}
          count={count}
          empty={empty}
          handleClose={handleClose}
          handleCart={handleCart}
          link={link}
          locales={locales}
          subtotal={subtotal}
          canSaveNotes={canSaveNotes}
          showNotesError={showNotesError}
          handleUpdateNoteStatus={handleUpdateNoteStatus}
          handleNotesError={handleNotesError}
          showCartBar={showCartBar}
        />
      ),
      [
        state,
        bounds,
        count,
        empty,
        handleClose,
        handleCart,
        link,
        locales,
        subtotal,
        canSaveNotes,
        showNotesError,
        handleUpdateNoteStatus,
        handleNotesError,
        showCartBar,
      ]
    )
  })
