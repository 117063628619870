import React from "react"

import { withFooterPayments } from "./withFooterPayments"
import { Icon } from "../../Icon/Icon"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { Payment, Text } from "./styledFooterPayments"

export const FooterPayments = withFooterPayments(
  ({ items, locales }): JSX.Element => (
    <StyledRow items={`center`} withWrap={`<sm`}>
      <StyledColumn width={`md:no-shrink`}>
        <Text large>{locales?.additionalPayments}</Text>
      </StyledColumn>
      <StyledColumn width={`grow`}>
        <StyledRow items={`center`}>
          {items?.map(({ id, title }) => (
            <Payment key={id}>
              <Icon name={`payments-${id}`} title={title} width={38} />
            </Payment>
          ))}
        </StyledRow>
      </StyledColumn>
    </StyledRow>
  )
)
