import React, { memo, useMemo, useEffect, useState, useCallback } from "react"

import { useApolloClient } from "@apollo/client"
import { useApp } from "@app/hooks/useApp"
import { useCheckoutContext } from "@app/hooks/useCheckout"
import { useShopify } from "@app/hooks/useShopify"
import { useCart } from "@app/hooks/useCart"
import { useCore } from "@app/hooks/useCore"

export const withCartDrawerUpsell = Component =>
  memo(({ name = "CartDrawerUpsell", id = `cart`, global, page, active, isMobile, locales }: any) => {
    const { headerBounds, activeVariant: selectedVariant, setActiveProductNotify } = useApp()
    const client = useApolloClient()
    const { currentUpsellHandles, clientId, sessionID, countryCode } = useCheckoutContext()
    const { productNormaliser } = useShopify()
    const { addToCart, loading: adding } = useCart()
    const [items, setItems] = useState([])
    const {
      graphql: {
        queries: { GET_PRODUCTS_BY_HANDLE },
      },
    } = useCore()

    const updateRelated = handles => {
      return client.query({
        query: GET_PRODUCTS_BY_HANDLE(handles),
        variables: {
          countryCode,
          firstCollections: 0,
          firstImages: 5,
          firstMedia: 0,
          metafieldIdentifiers: { namespace: "", key: "" },
          firstVariants: 1,
        },
      })
    }

    useEffect(() => {
      const getRelated = async () => {
        let relatedItems = []
        if (currentUpsellHandles.length) {
          const response = await updateRelated(currentUpsellHandles).catch(e => console.log(e))
          if (response) {
            const { data } = response
            relatedItems = currentUpsellHandles
              ?.filter(handle => data[`product${handle?.replace(/-/g, "")}`])
              ?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
            relatedItems.forEach(product => {
              product.variant = product.variants[0]
            })
          }
        }
        setItems(relatedItems)
      }

      getRelated()
    }, [currentUpsellHandles])

    const handleAdd = useCallback(variantId => addToCart(variantId, 1), [addToCart])

    const handleNotify = useCallback(handle => setActiveProductNotify(handle), [setActiveProductNotify])

    Component.displayName = name
    return useMemo(
      () =>
        items?.length > 0 ? (
          <Component
            bounds={headerBounds}
            id={id}
            items={items.slice(0, 6)}
            locales={locales}
            active={active}
            isMobile={isMobile}
            clientId={clientId}
            sessionID={sessionID}
            handleAdd={handleAdd}
            handleNotify={handleNotify}
            loading={adding}
          />
        ) : null,
      [headerBounds, id, items, locales, active, isMobile, clientId, sessionID, handleAdd, handleNotify, currentUpsellHandles]
    )
  })
