import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withHeaderNavigationMain } from "./withHeaderNavigationMain"
import { Nav, Items, Item, SecondaryContainer, IconContainer } from "./styledHeaderNavigationMain"
import { SubMenuNavigation } from "../Main/Submenu/SubMenuNavigation"
import { Overlay, Link } from "../Primary/styledHeaderNavigationPrimary"
import { NavFeatured } from "../Featured/NavFeatured"
import { Icon } from "../../../Icon/Icon"

export const HeaderNavigationMain = withHeaderNavigationMain(
  ({
    headerItems,
    bounds,
    activeSearch,
    handleCloseSearch,
    handleClose,
    isMobile,
    headerHeight,
    showMobileMenu,
    setActiveMenuKey,
    handleMobileMenu,
    secondary,
  }): JSX.Element => (
    <>
      {isMobile && <Overlay active={showMobileMenu || activeSearch} onClick={handleClose} onMouseEnter={handleClose} headerHeight={headerHeight} />}
      <Nav isMobile={isMobile} headerHeight={headerHeight} isActive={showMobileMenu}>
        <Overlay active={activeSearch} onClick={handleCloseSearch} headerHeight={headerHeight} />
        <Items isMobile={isMobile} padForMouseOver>
          {headerItems?.map((item, index) => {
            const { key, items, title, type, url, multiColourText } = item
            return (
              <Item
                key={key}
                isMobile={isMobile}
                isLast={index === headerItems.length - 1}
                onMouseEnter={() => !isMobile && setActiveMenuKey(key)}
                onMouseLeave={() => !isMobile && setActiveMenuKey(null)}
              >
                {type === `sub` ? (
                  Array.isArray(items) ? (
                    <>
                      <SubMenuNavigation
                        primary={items}
                        bounds={bounds}
                        multiColourText={multiColourText}
                        title={title}
                        menuKey={key}
                        isMobile={isMobile}
                        handleMobileMenu={handleMobileMenu}
                      />
                    </>
                  ) : null
                ) : (
                  <>
                    <Link
                      as={url && GatsbyLink}
                      title={title}
                      to={url}
                      className={`${!isMobile ? `animate-underline` : null}`}
                      onClick={() => handleMobileMenu(false)}
                      isMobile={isMobile}
                      isLast={index === headerItems.length - 1}
                    >
                      {title}
                      {!isMobile && <span className={`line`} />}
                    </Link>
                  </>
                )}
              </Item>
            )
          })}
        </Items>
        {isMobile ? (
          <SecondaryContainer>
            {secondary?.length > 0 && (
              <Items secondary>
                {secondary?.map(({ key, external, title, type, url }) => (
                  <Item key={key} secondary>
                    <Link
                      as={url && type === `link` ? `a` : GatsbyLink}
                      className={`animate-underline`}
                      href={type === `link` && url}
                      rel={type === `link` ? `noopener` : null}
                      target={external ? `_blank` : null}
                      title={title}
                      to={type !== `link` && url}
                      onClick={() => handleMobileMenu(false)}
                      secondary
                    >
                      {title}
                      <span className={`line`} />
                      {url === "/account/login" && (
                        <IconContainer>
                          <Icon name={`account`} width={18} className="inline ml-2" />
                        </IconContainer>
                      )}
                    </Link>
                  </Item>
                ))}
              </Items>
            )}
            <NavFeatured handleClose={handleClose} />
          </SecondaryContainer>
        ) : null}
      </Nav>
    </>
  )
)

export default HeaderNavigationMain
