import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useNavigation } from "./useNavigation"

export const useNavigationHeader = (location?: any) => {
  const { mapItems } = useNavigation(location)

  const { menus } = useStaticQuery<GatsbyTypes.UseNavigationHeaderQuery>(graphql`
    query UseNavigationHeader {
      menus: sanitySettingMenus {
        header {
          slug {
            current
          }
          items {
            ... on SanityLink {
              _key
              _type
              title
              url: link
              external
            }
            ... on SanityNavigationArticle {
              _key
              title
              article {
                title
                slug {
                  current
                }
                _type
              }
              _type
            }
            ... on SanityNavigationSub {
              title
              _key
              _type
              content: _rawContent(resolveReferences: { maxDepth: 2 })
              collections: _rawCollections(resolveReferences: { maxDepth: 6 })
              link: _rawLink(resolveReferences: { maxDepth: 4 })
              items: _rawItems(resolveReferences: { maxDepth: 4 })
              multiColourText
            }
            ... on SanityNavigationProduct {
              _key
              _type
              title
              product {
                shopify {
                  shopifyHandle
                }
                title
              }
            }
            ... on SanityNavigationStore {
              _key
              _type
              title
              store {
                title
              }
            }
            ... on SanityNavigationPage {
              _key
              _type
              title
              page {
                ... on SanityPageAccountAddresses {
                  id
                  title
                  _type
                }
                ... on SanityPageStores {
                  id
                  title
                  _type
                }
                ... on SanityPageSearch {
                  id
                  title
                  _type
                }
                ... on SanityPageLoyalty {
                  id
                  title
                  _type
                }
                ... on SanityPageQuizV2 {
                  id
                  _type
                  title
                  additionalName
                }
                ... on SanityPageQuiz {
                  id
                  title
                  _type
                }
                ... on SanityPagePickMix {
                  id
                  _type
                  title
                }
                ... on SanityPagePickGift {
                  id
                  _type
                  title
                }
                ... on SanityPageHomepage {
                  id
                  title
                  _type
                }
                ... on SanityPageGeneric {
                  id
                  slug {
                    current
                  }
                  title
                  _type
                }
                ... on SanityPageFlexible {
                  id
                  title
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityPageContact {
                  id
                  title
                  _type
                }
                ... on SanityPageCollections {
                  id
                  title
                  _type
                }
                ... on SanityPageArticles {
                  id
                  title
                  _type
                }
                ... on SanityPageCart {
                  id
                  title
                  _type
                }
                ... on SanityPageAccountWishlist {
                  id
                  _type
                  title
                }
                ... on SanityPageAccountRegister {
                  id
                  title
                  _type
                }
                ... on SanityPageAccountOrders {
                  id
                  title
                  _type
                }
                ... on SanityPageAccountLogin {
                  id
                  title
                  _type
                }
                ... on SanityPageAccountForgot {
                  id
                  title
                  _type
                }
                ... on SanityPageAccountDashboard {
                  id
                  title
                  _type
                }
              }
            }
            ... on SanityNavigationCollection {
              _key
              _type
              title
              collection: _rawCollection(resolveReferences: { maxDepth: 6 })
            }
          }
        }
      }
    }
  `)

  const header = useMemo(() => mapItems(menus?.header?.items, "header"), [menus, mapItems])

  return {
    header,
  }
}
