import { useStaticQuery, graphql } from "gatsby"

import { UseTemplate } from "../types/hooks"

export const useTemplate = (): UseTemplate.Response =>
  useStaticQuery(graphql`
    query {
      account: sanityTemplateAccount {
        bannerTitle
      }
      cart: sanityPageCart {
        additionalEmpty
        additionalSubtotal
        additionalPickMix
        additionalPickMixEditButton
        additionalPickGift
        additionalPickGiftEditButton
        additionalGift
        additionalRemove
        additionalIncrease
        additionalDecrease
        additionalRelatedView
        additionalCustomerNotePlaceholder
        additionalCustomerNoteSubmit
        link: _rawLink(resolveReferences: { maxDepth: 6 })
      }
      global: sanityTemplateGlobal {
        title
        description
        additionalClose
        additionalNext
        additionalPrevious
        additionalMenu
        additionalShow
        additionalHide
        additionalZoom
        additionalCart
        additionalCartTitle
        additionalCartView
        additionalAccount
        additionalLogout
        additionalSearch
        additionalSearchView
        additionalSearchPlaceholder
        additionalSearchResults
        additionalEmail
        additionalFacebook
        additionalPinterest
        additionalPayments
        additionalSubscribe
        additionalSubscribeSuccess
        additionalSubscribeName
        additionalSubscribeEmail
        additionalSubscribeSubmit
      }
      product: sanityTemplateProduct {
        additionalAddToCart
        additionalAddedToCart
        additionalPriceVaries
        additionalGiftcard
        additionalGiftcardContent
        additionalGiftcardSender
        additionalGiftcardRecipient
        additionalGiftcardEmail
        additionalGiftcardDate
        additionalGiftcardMessage
        additionalGiftcardSubmit
        additionalNotify
        additionalNotifyEmail
        additionalNotifyFirstName
        additionalNotifyLastName
        additionalNotifyContent
        additionalNotifySubmit
        additionalNotifySuccess
        additionalNotifyTitle
        additionalAddToWishlist
        additionalRemoveFromWishlist
        additionalSelect
        additionalRelated
      }
      quiz: sanityPageQuizV2 {
        additionalShareEmail
        additionalShareName
        additionalShareContent
        additionalShareMessage
        additionalShareSubmit
        additionalShareSuccess
        additionalShareTitle
        additionalSubscribeTitle
        additionalSubscribeEmail
        additionalSubscribeLabel
        additionalSubscribeButton
        additionalSubscribeSubmit
        additionalSubscribeSuccess
        additionalSubscribeSuccessContentRoute
        additionalSubscribeSuccessContentResult
        additionalSummarySensitivityFilterPopupTitle
        additionalSummarySensitivityFilterPopupContent: _rawAdditionalSummarySensitivityFilterPopupContent(resolveReferences: { maxDepth: 2 })
      }
    }
  `)
