import { UseRoutes } from "../types/hooks"
import { useCallback } from "react"
import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useRoutes = (): UseRoutes.Response => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()

  const findRoute = useCallback(
    (type: string) => {
      const route = Object.entries(routes)?.find(([key]) =>
        type
          ?.toLowerCase()
          ?.replace(`pageaccount`, ``)
          ?.replace(`pagearticles`, `articles`)
          ?.replace(`pagequiz`, `quiz`)
          ?.replace(`pageloyalty`, `loyalty`)
          ?.includes(key?.toLowerCase())
      ) || [`page`, routes[`page`]]

      return {
        type: route[0]?.toLowerCase(),
        url: route[1],
      }
    },
    [routes]
  )

  const traverseParents = useCallback((item: any) => {
    const handles = []

    if (item?.parentCollection?.shopify?.shopifyHandle) handles.push(...traverseParents(item?.parentCollection))

    const handle =
      item?.meta?.metaCanonicalURL?.current ||
      item?.slug?.current ||
      item?.shopify?.shopifyHandle ||
      item?.handle ||
      item?._id?.replace(`drafts.`, ``).replace(`page`, ``).toLowerCase()
    if (handle) handles.push(handle)

    return handles?.length > 0 ? handles : null
  }, [])

  const nestedHandles = useCallback(
    item =>
      traverseParents(item)
        ?.map(item => item)
        ?.join(`/`) || traverseParents(item),
    [traverseParents]
  )

  const routeResolver = useCallback(
    ({ item, type }: any) => {
      const dynamicTypes = [`product`, `collection`, `page`, `article`]
      const itemType = type || item?.type || item?._type
      const route = findRoute(itemType)
      const handleRaw = item?._type !== "navigationFeatured" ? item : item?.link?.document || {}
      const handle = nestedHandles(handleRaw)
      const path = dynamicTypes?.includes(route?.type?.toLowerCase()) && handle ? `/${nestedHandles(handleRaw)}` : ``

      return `${route?.url}${path}`
    },
    [findRoute, nestedHandles]
  )

  const linkResolver = useCallback(
    (item: any, itemType: string = null): UseRoutes.Link => {
      const document = item?.document || item
      const type = (itemType || document?._type || document?.__typename || document?.type)?.replace(`navigation`, ``)?.toLowerCase()
      const title = item?.title || null

      if (!document) return {}
      if (type === `sub`) return { link: linkResolver(item?.link?.[0]), title, type }
      if (type === `link`)
        return {
          external: document?.external || document?.link?.includes(`mailto:`) || false,
          title,
          type,
          url: document?.link,
        }

      const route = findRoute(type)
      return {
        title,
        type: route?.type || null,
        url: routeResolver({ item: document[route?.type?.replace(`flexible`, ``)?.replace(`generic`, ``)] || document, type: route?.type }),
      }
    },
    [findRoute, routeResolver]
  )

  const parentCollections = useCallback(
    (collection, includeSelf = false) => {
      const collections = []

      if (collection?.parentCollection?.parentCollection) collections.push(linkResolver(collection?.parentCollection?.parentCollection))
      if (collection?.parentCollection) collections.push(linkResolver(collection?.parentCollection))
      if (collection && includeSelf) collections.push(linkResolver(collection))

      return collections
    },
    [linkResolver]
  )

  const getUrlParameter = useCallback(
    (name, location = (isBrowser && window?.location) || null) => new URLSearchParams(location?.search)?.get(name),
    [isBrowser]
  )

  const setUrlParameter = useCallback(
    (name = ``, value = ``, location = (isBrowser && window?.location) || null) => {
      const url = new URLSearchParams(location?.search)
      name && value ? url?.set(name, value) : name && url?.delete(name)

      return `${location?.pathname}${url?.toString() ? `?${url?.toString()}` : ``}`
    },
    [isBrowser]
  )

  return {
    getUrlParameter,
    linkResolver,
    parentCollections,
    routeResolver,
    setUrlParameter,
  }
}
