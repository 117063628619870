import React, { memo, useMemo, useState, useEffect } from "react"

import { useCheckoutContext } from "../../hooks/useCheckout"
import { useLocation } from "@app/hooks/useLocation"
import { useSettings } from "@app/hooks/useSettings"
import { useGift } from "../../hooks/useGift"

export const withCartBar = Component =>
  memo(({ name = "withCartBar" }: any) => {
    const { checkout } = useCheckoutContext()
    const { country, location } = useLocation()
    const { shipping } = useSettings()
    const { gifts } = useGift()
    const [sortedBarItems, setSortedBarItems] = useState([{}])
    const [toNextThreshold, setToNextThreshold] = useState(0)
    const [nextPerk, setNextPerk] = useState("")
    const [achieved, setAchieved] = useState("")
    // subtotal amount without gift cards included
    const [amount, setAmount] = useState(0)

    useEffect(() => {
      if (checkout?.lineItems) {
        const newAmount = checkout.lineItems.reduce((acc: number, item: any): number => {
          const isGiftItem = item?.customAttributes?.filter((attr: any) => attr.key === "_gift_item").length
          if (!item.product.giftcard && !isGiftItem) {
            acc += item?.variant?.priceV2?.amount * item?.quantity
          }
          return acc
        }, 0)
        setAmount(newAmount)
      }
    }, [checkout?.lineItems])

    useEffect(() => {
      const items = []
      let shippingCountry = {}
      if (shipping && shipping.shippingCountries.length) {
        shipping.shippingCountries?.forEach(oneCountry => {
          if (oneCountry.country === location) {
            shippingCountry = oneCountry
          }
        })
      }
      if (shippingCountry && amount > 0) {
        if (shippingCountry.descriptionOne && shippingCountry.priceOne) {
          items.push({
            title: shippingCountry.descriptionOne,
            threshold: shippingCountry.priceOne,
            met: amount > shippingCountry.priceOne ? true : false,
          })
        }
        if (shippingCountry.descriptionTwo && shippingCountry.priceTwo) {
          items.push({
            title: shippingCountry.descriptionTwo,
            threshold: shippingCountry.priceTwo,
            met: amount > shippingCountry.priceTwo ? true : false,
          })
        }
      }
      if (gifts) {
        gifts.forEach(gift => {
          const giftPrice = +gift.price / 100
          items.push({ title: "Free Gift", threshold: giftPrice, met: +amount > giftPrice ? true : false })
        })
      }
      items.sort((a, b) => (a.threshold > b.threshold ? 1 : -1))
      setSortedBarItems(items)
    }, [shipping, amount])

    useEffect(() => {
      if (sortedBarItems.length) {
        setAchieved("")
        for (let i = 0; i < sortedBarItems.length; i++) {
          if (!sortedBarItems[i].met) {
            let thresholdPrice = sortedBarItems[i]?.threshold - amount
            if (thresholdPrice && sortedBarItems[i]?.title) {
              thresholdPrice = thresholdPrice.toFixed(2)
              setToNextThreshold(thresholdPrice)
              setNextPerk(sortedBarItems[i]?.title)
            }
            break
          } else if (sortedBarItems[i].met) {
            setAchieved(sortedBarItems[i].title)
          }
        }
      }
    }, [sortedBarItems, amount, achieved])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          amount={amount}
          country={country}
          location={location}
          sortedBarItems={sortedBarItems}
          toNextThreshold={toNextThreshold}
          achieved={achieved}
          nextPerk={nextPerk}
        />
      ),
      [amount, sortedBarItems, country, location, toNextThreshold, nextPerk, achieved]
    )
  })
