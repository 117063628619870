import { useCallback, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useFunctions } from "./useFunctions"

const INITIAL_STATE = { email: "", firstName: "" }

export const useNewsletter = (location = null) => {
  const [data, setData] = useState(INITIAL_STATE)
  const [success, setSuccess] = useState(false)
  const {
    activeSubscribe,
    setActiveSubscribe,
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const { callFunction, errors, loading } = useFunctions()

  const { newsletter } = useStaticQuery(graphql`
    query {
      newsletter: sanitySettingNewsletter {
        enabled
        delay
        expiry
        title
        subtitle
        content: _rawContent(resolveReferences: { maxDepth: 2 })
        successTitle
        successSubtitle
        successContent: _rawSuccessContent(resolveReferences: { maxDepth: 2 })
        image: _rawImage(resolveReferences: { maxDepth: 2 })
        templateHomepage
        templateCollection
        templateProduct
        templateArticle
        additionalPlaceholderEmail
        additionalPlaceholderName
        additionalSubmit
        additionalDone
      }
    }
  `)

  const route =
    Object.entries(routes)?.find(([route, url]) => !route?.toLowerCase()?.includes(`page`) && url && location?.pathname?.includes(url))?.[0] ||
    (location?.pathname === routes?.HOMEPAGE && `HOMEPAGE`)
  const active = newsletter?.enabled && route && newsletter[`template${route?.charAt(0)?.toUpperCase() + route?.slice(1)?.toLowerCase()}`]

  const handleSubmit = useCallback(async () => {
    await callFunction("customer-subscribe", data)
    setSuccess(true)
  }, [callFunction, data, setSuccess])

  const handleClose = useCallback(() => {
    storage.set(keys?.newsletter, `hidden`, newsletter?.expiry)
    setActiveSubscribe(false)
  }, [keys, newsletter, setActiveSubscribe, storage])

  useEffect(() => {
    if (active && !storage.get(keys?.newsletter) && !activeSubscribe) {
      setTimeout(() => setActiveSubscribe(true), (newsletter?.delay || 0) * 1000)
    }
  }, [active, activeSubscribe, keys, newsletter, setActiveSubscribe, storage])

  return {
    activeSubscribe,
    handleClose,
    handleSubmit,
    data,
    errors,
    loading,
    newsletter,
    setActiveSubscribe,
    setData,
    success,
  }
}
