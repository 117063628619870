import React, { memo, useCallback, useEffect, useMemo, useRef } from "react"

import { useApp } from "../../../hooks/useApp"
import { useForm } from "../../../hooks/useForm"
import { useTemplate } from "../../../hooks/useTemplate"

export const withQuizShare = Component =>
  memo(({ name = "QuizShare" }: any) => {
    const { activeQuiz: url, headerBounds, setActiveQuiz: setActive } = useApp()
    const { getForm, submitForm, data, setData, loading, errors, success } = useForm()
    const { global, quiz } = useTemplate()
    const field = useRef()

    const active = url !== null
    const form = getForm(`skin-assistant`)

    const locales = useMemo(
      () => ({
        ...global,
        ...quiz,
      }),
      [global, quiz]
    )

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()

        const submission = {
          ...data,
          message: data?.message ? `${data?.message}\n\n${url}` : url,
        }

        await submitForm(form, submission)
      },
      [data, form, submitForm, url]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    const handleActive = useCallback(() => setActive(null), [setActive])

    useEffect(() => {
      active && field?.current ? setTimeout(() => field.current.select(), 200) : null
    }, [active, field])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          active={active}
          bounds={headerBounds}
          data={data}
          errors={errors}
          field={field}
          handleActive={handleActive}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          locales={locales}
          success={success}
        />
      ),
      [active, headerBounds, data, errors, field, handleActive, handleChange, handleSubmit, loading, locales, success]
    )
  })
