import React from "react"

import { withCartBar } from "./withCartBar"
import { First, Middle, Last, Circle, BarContainer, Checkmark, SMText, LGText, HeaderContainer } from "./styledCartBar"

export const CartBar = withCartBar(
  ({ subtotal, sortedBarItems, toNextThreshold, achieved, nextPerk }): JSX.Element => (
    <>
      <HeaderContainer>
        {achieved ? (
          <LGText>
            Congrats! You&#39;ve earned <strong>{achieved}!</strong>
          </LGText>
        ) : null}
        {toNextThreshold && nextPerk && toNextThreshold > 0 ? (
          <LGText>
            You&#39;re ${toNextThreshold} away from <strong>{nextPerk}!</strong>
          </LGText>
        ) : null}
      </HeaderContainer>
      <BarContainer colNum={sortedBarItems.length ? sortedBarItems.length + 1 : 0}>
        {sortedBarItems.length
          ? sortedBarItems?.map((item, index) => (
              <>
                {index === 0 ? (
                  <First key={`${item.title}-first`} met={true}>
                    <Circle met={true} position={`start`}>
                      <Checkmark met={true}>&#10003;</Checkmark>
                    </Circle>
                  </First>
                ) : null}

                {index === sortedBarItems.length - 1 ? (
                  <Last key={`${item.title}-last`} met={item.met}>
                    <Circle met={item.met} position={`end`}>
                      {item.met ? <Checkmark>&#10003;</Checkmark> : null}
                    </Circle>
                    <SMText position={`end`}>{item.title}</SMText>
                  </Last>
                ) : null}
                {(index === 0 && sortedBarItems.length !== 1) || (index !== 0 && index !== sortedBarItems.length - 1) ? (
                  <Middle key={`${item.title}-middle`} met={item.met}>
                    <Circle met={item.met} position={`middle`}>
                      {item.met ? <Checkmark>&#10003;</Checkmark> : null}
                    </Circle>
                    <SMText position={`middle`}>{item.title}</SMText>
                  </Middle>
                ) : null}
              </>
            ))
          : null}
      </BarContainer>
    </>
  )
)
