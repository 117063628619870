import React, { memo, useCallback, useMemo, useState } from "react"

import * as Components from "../../types/components"
import { GlobalStylesTypes } from "../Styled/Global"

export const withAccordion = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = `Accordion`,
      active = null,
      colour = `orange`,
      condensed = false,
      items,
      withSpacing = null,
      noBorder = false,
      freeze = false,
      together = false,
      prototype = false,
    }: ExternalProps) => {
      const [isActive, setActive] = useState<number>(active)

      const list = useMemo(
        () =>
          items?.map((item: any, index: number) => ({
            ...item,
            active: index === isActive,
            id: item?.id || item?.title,
          })),
        [isActive, items]
      )

      const handleActive = useCallback(
        (item: number) => () =>
          !freeze &&
          setActive((active: number) => {
            return active !== item ? item : null
          }),
        [freeze]
      )

      const props = {
        colour,
        condensed,
        handleActive,
        items: list,
        withSpacing,
        noBorder,
        together,
        prototype,
      }
      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

export type AccordionItem = {
  children?: JSX.Element
  id?: string
  title: string
}

type ExternalProps = Components.ComponentProps & {
  data: any
  active?: number | null
  colour?: string
  condensed?: boolean
  items: AccordionItem[]
  withSpacing?: GlobalStylesTypes["spacing"]
  noBorder?: boolean
  freeze?: boolean
  together?: boolean
  prototype?: boolean
}

type InjectedProps = {
  data: any
  colour: string
  condensed: boolean
  items: Array<
    AccordionItem & {
      active: boolean
      id: string | number
    }
  >
  withSpacing: GlobalStylesTypes["spacing"] | null
  handleActive: (item: any) => () => void
  noBorder: boolean
  together: boolean
  prototype: boolean
}
