import React from "react"
import { animated } from "react-spring"

import { withAnimationPopupPickGift } from "./withAnimationPopupPickGift"
import { Notify, Inner, Popup, Wrapper, Content, ItemGroup, ImageContent, ImageWrapper, Image } from "./styledAnimationPickGift"

export const AnimationPopupPickGift = withAnimationPopupPickGift(
  ({ active, bounds, bagImage, transitions, getSpringConfigPickGift }): JSX.Element => {
    return (
      <Notify active={active} top={bounds?.height}>
        <Inner>
          <Popup active={active}>
            <Wrapper>
              <Content>
                <ItemGroup>
                  {transitions(({ opacity }, item) => {
                    return (
                      <animated.img
                        className="animationImage"
                        style={{ ...(getSpringConfigPickGift({ opacity, item }) as unknown as any) }}
                        src={item.src}
                      ></animated.img>
                    )
                  })}
                </ItemGroup>
              </Content>
              <ImageContent>
                <ImageWrapper>
                  <Image className="animationOneBag" alt={"bag"} fluid={{ ...bagImage, aspectRatio: 4 / 3 }} />
                </ImageWrapper>
              </ImageContent>
            </Wrapper>
          </Popup>
        </Inner>
      </Notify>
    )
  }
)
