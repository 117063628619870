import React from "react"

import { StyledButton } from "../../Styled/Button"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { P } from "../../Styled/Text"
import { withCartTotal } from "./withCartTotal"

export const CartTotal = withCartTotal(
  ({ discount, giftcards, handleClick, loading, locales, shipping, total, disabled, handleDisabledClick, mini, drawerlocales }): JSX.Element => (
    <>
      {!mini ? (
        <StyledRow withWrap withSpacing>
          {discount && (
            <>
              <StyledColumn width={`1/2`}>
                <P withSpacing={`xs`}>{locales?.additionalDiscount}</P>
              </StyledColumn>
              <StyledColumn width={`1/2`}>
                <P align={`right`} withSpacing={`xs`}>
                  {discount}
                </P>
              </StyledColumn>
            </>
          )}
          {giftcards && (
            <>
              <StyledColumn width={`1/2`}>
                <P withSpacing={`xs`}>{locales?.additionalGiftcard}</P>
              </StyledColumn>
              <StyledColumn width={`1/2`}>
                <P align={`right`} withSpacing={`xs`}>
                  {giftcards}
                </P>
              </StyledColumn>
            </>
          )}
          <StyledColumn width={`1/2`}>
            <P withSpacing={`xs`}>{locales?.additionalShipping}</P>
          </StyledColumn>
          <StyledColumn width={`1/2`}>
            <P align={`right`} withSpacing={`xs`}>
              {shipping}
            </P>
          </StyledColumn>
          <StyledColumn width={`1/2`}>
            <P>
              <strong>{locales?.additionalSubtotal}</strong>
            </P>
          </StyledColumn>
          <StyledColumn width={`1/2`}>
            <P align={`right`}>
              <strong>{total}</strong>
            </P>
          </StyledColumn>
        </StyledRow>
      ) : null}
      <a onClick={() => handleDisabledClick(disabled)}>
        <StyledButton
          colour={`purple`}
          disabled={loading || disabled}
          full
          onClick={handleClick}
          size={mini ? `secondary` : `primary`}
          title={drawerlocales ? drawerlocales : locales?.additionalCheckout}
          mini={mini}
          withSpacing={mini ? null : true}
        >
          {drawerlocales ? drawerlocales : locales?.additionalCheckout}
        </StyledButton>
      </a>
    </>
  )
)
