import React from "react"
import { withUpsellSlide } from "./withUpsellSlide"
import { Button, LargeText, SmallText, Slide, StyledImage, PrimaryContainer, SecondaryContainer, Content, AnchorWrapper } from "./styledUpsellSlide"

export const UpsellSlide = withUpsellSlide(
  ({ related, clientId, sessionID, handleAdd, handleNotify, loading, locales, isMobile }): JSX.Element => (
    <>
      {isMobile ? (
        <Slide isMobile={isMobile}>
          <AnchorWrapper href={related.url}>
            <StyledImage src={related?.images?.[0]?.src} isMobile={isMobile}></StyledImage>
          </AnchorWrapper>
          <Content>
            <PrimaryContainer>
              <AnchorWrapper href={related.url}>
                <SecondaryContainer>
                  <LargeText>{related.title}</LargeText>
                  {related?.type && (
                    <SmallText isMobile={isMobile}>
                      {related?.type}
                      {related?.type && related?.variant?.title && related?.variant?.title?.toLowerCase() !== "default title" && ", "}
                      {related?.variant?.title && related?.variant?.title?.replace("Default Title", "")}
                    </SmallText>
                  )}
                </SecondaryContainer>
              </AnchorWrapper>
              <Button
                colour={`purple`}
                disabled={loading}
                onClick={
                  loading || related?.variants?.[0]?.availableForSale
                    ? () => handleAdd(related?.variants?.[0]?.id)
                    : () => handleNotify(related?.handle)
                }
                title={locales?.additionalNotify}
                data-clientid={clientId ? clientId : null}
                notify={!loading || !related?.variants?.[0]?.availableForSale ? `sm` : null}
                isMobile={isMobile}
              >
                {loading || related?.variants?.[0]?.availableForSale
                  ? locales?.additionalAddToCart.toUpperCase()
                  : locales?.additionalNotify.toUpperCase()}
              </Button>
            </PrimaryContainer>
            <LargeText>{`$${Number(related?.variants?.[0]?.price?.amount).toFixed(2)}`}</LargeText>
          </Content>
        </Slide>
      ) : (
        <Slide isMobile={isMobile}>
          <AnchorWrapper href={related.url}>
            <StyledImage src={related?.images?.[0]?.src} isMobile={isMobile}></StyledImage>
          </AnchorWrapper>
          <AnchorWrapper href={related.url}>
            <LargeText>{related.title}</LargeText>
            <LargeText>{`$${Number(related?.variants?.[0]?.price?.amount).toFixed(2)}`}</LargeText>
            {related?.type && (
              <SmallText>
                {related?.type}
                {related?.type && related?.variant?.title && related?.variant?.title?.toLowerCase() !== "default title" && ", "}
                {related?.variant?.title && related?.variant?.title?.replace("Default Title", "")}
              </SmallText>
            )}
            {/* {related?.type && related?.variant?.title && related?.variant?.title?.toLowerCase() !== "default title" && <SmallText>{`, `}</SmallText>} */}
            {/* {related?.variant?.title && <SmallText>{related?.variant?.title?.replace("Default Title", "")}</SmallText>} */}
          </AnchorWrapper>
          <Button
            colour={`purple`}
            disabled={loading}
            onClick={
              loading || related?.variants?.[0]?.availableForSale ? () => handleAdd(related?.variants?.[0]?.id) : () => handleNotify(related?.handle)
            }
            title={locales?.additionalNotify}
            data-clientid={clientId ? clientId : null}
            notify={!loading || !related?.variants?.[0]?.availableForSale ? `sm` : null}
            isMobile={isMobile}
          >
            {loading || related?.variants?.[0]?.availableForSale
              ? locales?.additionalAddToCart.toUpperCase()
              : locales?.additionalNotify.toUpperCase()}
          </Button>
        </Slide>
      )}
    </>
  )
)
