import tw, { styled } from "twin.macro"

import { Small } from "../../Styled/Text"
import { StyledLink } from "../../Styled/Button"

export const Links = tw.div`relative border-l border-r border-transparent md:hover:border-grey-lighter`
export const Border = tw.div`absolute hidden group-hover:block -right-0.25 pt-0.25 pointer-events-none w-full inset-x-0 -bottom-0.25 h-0.25 bg-white`
export const Dropdown = tw.div`absolute hidden group-hover:block -right-0.25 pt-0.25`
export const DropdownInner = tw.div`bg-white py-3 pl-3 pr-12 overflow-hidden border-l border-r border-b border-grey-lighter whitespace-nowrap`
export const Name = tw(Small)`font-light`
export const DropdownLink = tw(StyledLink)`block font-bold leading-none mt-1`
export const Link = styled(StyledLink)`
  ${tw`block p-1 md:py-4.75 md:px-2 lg:px-4 hover:text-black hover:cursor-pointer`}
  ${({ screen }) => screen === `lg` && tw`hidden md:block`}
`
