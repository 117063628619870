import React, { memo, useMemo } from "react"

import { useCore } from "../../hooks/useCore"
import { useImage } from "../../hooks/useImage"
import { useNewsletter } from "../../hooks/useNewsletter"
import { useTemplate } from "../../hooks/useTemplate"

export const withNewsletter = Component =>
  memo(({ name = "Newsletter", location }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { getFluidImage } = useImage()
    const { activeSubscribe, data, errors, handleClose, handleSubmit, loading, newsletter, setData, success } = useNewsletter(location)
    const { global } = useTemplate()

    const image = getFluidImage(newsletter?.image, { maxWidth: 1000 })

    const locales = useMemo(
      () => ({
        ...global,
        ...newsletter,
        content: sanityContent(newsletter?.content),
        successContent: sanityContent(newsletter?.successContent),
      }),
      [global, newsletter, sanityContent]
    )

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          active={activeSubscribe}
          data={data}
          errors={errors}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          image={image}
          loading={loading}
          locales={locales}
          setData={setData}
          success={success}
        />
      ),
      [activeSubscribe, data, errors, handleClose, handleSubmit, image, loading, locales, setData, success]
    )
  })
