import React, { memo, useMemo } from "react"

export const withFormTextarea = Component =>
  memo(({ name = "FormTextarea", errors = null, full = false, id: formId = null, name: label, placeholder, withSpacing = null, ...field }: any) => {
    const id = formId || `${label}_${Math.random().toString(36).substr(2, 9)}`

    Component.displayName = name
    return useMemo(
      () => <Component errors={errors} field={field} full={full} id={id} label={label} placeholder={placeholder} withSpacing={withSpacing} />,
      [errors, field, full, id, label, placeholder, withSpacing]
    )
  })
