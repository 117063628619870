import React, { memo, useCallback, useMemo } from "react"

import { useCheckout, useCheckoutContext } from "../../../hooks/useCheckout"
import { useShopContext } from "../../../hooks/useShop"

export const withHeaderCurrency = Component =>
  memo(({ name = "HeaderCurrency" }: any) => {
    const { checkout } = useCheckoutContext()
    const { shop } = useShopContext()
    const { updateCurrency } = useCheckout()

    const handleChange = useCallback(({ target: { value } }) => updateCurrency(value.slice(0, 2)), [updateCurrency])

    Component.displayName = name
    return useMemo(
      () => (
        <Component currency={checkout?.currencyCode} currencies={shop?.paymentSettings?.enabledPresentmentCurrencies} handleChange={handleChange} />
      ),
      [checkout, handleChange, shop]
    )
  })
