import tw, { css, styled } from "twin.macro"

import { GlobalStyles, GlobalStylesTypes } from "../Styled/Global"
import { H5 } from "../Styled/Text"

const styles = {
  default: tw`block`,
  item: tw`border-t border-grey-light`,
  button: {
    default: tw`w-full flex py-7.75 justify-between items-center cursor-pointer select-none focus:outline-none`,
    condensed: tw`py-4`,
    together: tw`justify-start pt-0 pb-3`,
  },
  title: {
    default: tw`text-left font-medium flex-grow pr-6`,
    condensed: tw`font-bold uppercase text-base tracking-widest`,
    together: tw`text-left font-normal text-base md:text-base pr-6`,
  },
  content: {
    default: tw`pb-7.75 pr-10`,
    condensed: tw`pb-5 pr-6`,
  },
  icon: {
    default: tw`flex-shrink-0 transform rotate-0 transition-transform duration-200`,
    active: tw`rotate-180`,
  },
}

export const Items = styled.ul`
  ${styles.default}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
export const Item = styled.li<ItemProps>`
  ${({ noBorder }) => (noBorder !== "true" ? styles.item : "")}
  ${({ colour }) => colour && GlobalStyles.border[colour]}
  ${({ prototype }) => prototype && tw`last:border-b border-grey-light`}
  ${({ rotate }) =>
    rotate &&
    css`
      button svg {
        transform: rotate(${rotate}deg);
      }
    `}
`
export const Button = styled.button`
  ${styles.button.default}
  ${({ condensed }) => condensed && styles.button.condensed}
  ${({ together }) => together && styles.button.together}

  svg {
    ${styles.icon.default}
  }

  ${({ active }) =>
    active &&
    css`
      svg {
        ${styles.icon.active}
      }
    `}
`
export const Title = styled(H5)`
  ${({ condensed }) => condensed && styles.title.condensed}
  ${({ together }) => (together ? styles.title.together : styles.title.default)}
`
export const Content = styled.div`
  ${styles.content.default}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ condensed }) => condensed && styles.content.condensed}
  ${({ together }) => together && tw`pr-0`}
`

interface ItemProps {
  noBorder?: "true" | undefined
  colour: GlobalStylesTypes["colour"]
}
