import tw, { styled } from "twin.macro"

import { GlobalStyles } from "../../Styled/Global"
import { StyledButton, StyledLink } from "../../Styled/Button"

export const Card = styled.div`
  ${tw`w-full flex flex-col justify-between items-start flex-grow cursor-pointer`}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
export const Content = tw.div`w-full`
export const ImageContainer = styled.div`
  ${tw`relative mb-5.5 w-full`}
  ${({ colour }) => GlobalStyles.background[colour]}
`
export const ComparedPrice = tw.span`line-through mr-2`
export const Button = styled(StyledButton)`
  ${tw`w-full md:w-auto`}
  ${({ prototype }) => prototype && tw`text-black border-black bg-white`}
`
export const Wishlist = styled(StyledLink)`
  ${tw`absolute top-0 right-0 p-3`}

  svg {
    ${tw`w-4 md:w-5`}
  }
`
export const Image = styled.div`
  ${tw`absolute inset-0 first:static first:inset-auto transition-all duration-200`}
  ${({ hover }) => hover && tw`opacity-0 invisible`}
`
