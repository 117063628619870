import tw, { css, styled } from "twin.macro"

import { StyledLink } from "../../Styled/Button"

export const Link = tw(StyledLink)`relative block p-1 md:pl-2 lg:pl-4 hover:text-black`
export const Counter = styled.p`
  ${tw`absolute mr-0.75 mb-1.25 text-xxxs font-bold text-center w-3.75 hover:text-black`}
  ${css`
    background: #f4bbc7;
    bottom: -3px;
    right: -2px;
    padding: 1.5px;
    border-radius: 50%;
    min-width: 15px;
    min-height: 15px;
  `}
`
