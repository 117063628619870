import { useStaticQuery, graphql } from "gatsby"

import { UseSettings } from "../types/hooks"

export const useSettings = (): UseSettings.Response =>
  useStaticQuery(graphql`
    query {
      languages: sanitySettingLanguages {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
      organisation: sanitySettingOrganisation {
        title
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
      placeholder: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          gatsbyImageData
          # fluid(maxWidth: 800) {
          #   ...GatsbyImageSharpFluid
          # }
        }
      }
      promotions: allSanitySettingPromotion {
        edges {
          node {
            id: _key
            priceMin
            priceMax
            title
            image: _rawImage(resolveReferences: { maxDepth: 2 })
            link: _rawLink(resolveReferences: { maxDepth: 6 })
            text
            background
          }
        }
      }
      scripts: sanitySettingScripts {
        trackingHeader {
          code
        }
        trackingBody {
          code
        }
        trackingFooter {
          code
        }
      }
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
      swatches: allSanitySettingSwatches {
        edges {
          node {
            colour
            icon
            linked
            name
          }
        }
      }
      tracking: sanitySettingTracking {
        googleTagManagerId
        googleSiteVerify
        googleSiteVerifyCustomArray
        googleAnalyticsId
        gaFourId
        facebookPixelId
        facebookAppId
      }
      shipping: sanitySettingFreeShipping {
        shippingCountries {
          country
          descriptionOne
          priceOne
          descriptionTwo
          priceTwo
        }
      }
    }
  `)
