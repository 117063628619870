import { useContext } from "react"

import { AppContext } from "../providers/app"

export const useApp = () => {
  const app: any = useContext(AppContext)

  const edgeNormaliser = object => object?.edges?.map(({ node }) => node) || object || []

  return { ...app, helpers: { edgeNormaliser } }
}
