import { useCallback, useContext } from "react"

import { GlobalContext } from "../providers/global"

export const useGlobalContext = () => {
  const GlobalData: any = useContext(GlobalContext)

  return { ...GlobalData }
}

export const useGlobal = () => {
  const { setReadyGroup } = useGlobalContext()

  const handleGlobalGroupReady = useCallback(
    groupName => {
      setReadyGroup(prevState => ({
        ...prevState,
        [groupName]: true,
      }))
    },
    [setReadyGroup]
  )

  return { handleGlobalGroupReady }
}
