import React from "react"

import { withAccordion } from "./withAccordion"
import { Icon } from "../Icon/Icon"
import { Button, Content, Item, Items, Title } from "./styledAccordion"

export const Accordion = withAccordion(({ colour, condensed, handleActive, items, withSpacing, noBorder, together, prototype = false }) => (
  <Items withSpacing={withSpacing}>
    {items?.map(({ active, children, id, title, content }, index: number) => (
      <Item
        key={id?.toString()}
        colour={colour === `white` ? `white` : null}
        noBorder={noBorder ? "true" : undefined}
        prototype={prototype}
        rotate={!active && prototype ? 45 : 0}
      >
        <Button active={active} condensed={condensed} onClick={handleActive(index)} together={together}>
          <Title colour={colour === `white` ? `white` : null} condensed={condensed} together={together}>
            {title}
          </Title>
          <Icon
            name={active ? `minus` : prototype ? `cross` : `plus`}
            colour={prototype ? `black` : colour}
            width={prototype ? 15 : together ? 18 : condensed ? 14 : 20}
          />
        </Button>
        {active && (
          <Content colour={colour === `white` ? `white` : null} condensed={condensed} together={together}>
            {children || content?.[0]?.children?.[0]?.text}
          </Content>
        )}
      </Item>
    ))}
  </Items>
))
