import React from "react"
import { withNavFeatured } from "./withNavFeatured"
import { Title, Container, Content, Button, SubContainer, ButtonText } from "./styledNavFeatured"
import { Item } from "../Primary/styledHeaderNavigationPrimary"

export const NavFeatured = withNavFeatured(
  ({ featured, handleClose, item }): JSX.Element => (
    <Item>
      <Container>
        <img src={featured?.image?.asset?.url || ""} alt="" />
        <SubContainer>
          <Title>{featured?.title || ""}</Title>
          <Content>{featured?.content || ""}</Content>
          <a href={featured?.url || ""} onClick={handleClose}>
            <Button
              colour={featured?.buttonColour?.toLowerCase() || `purple`}
              full
              size={`secondary`}
              title={featured?.buttonLabel || ""}
              withSpacing={`xs`}
            >
              <ButtonText colour={featured?.buttonTextColour?.toLowerCase() || `light`}>{featured?.buttonLabel || ""}</ButtonText>
            </Button>
          </a>
        </SubContainer>
      </Container>
    </Item>
  )
)
