import React, { memo } from "react"
import { useSearch, useResults } from "@usereactify/search"

import { useApp } from "../../../../hooks/useApp"
import { useDom } from "../../../../hooks/useDom"
import { useTemplate } from "../../../../hooks/useTemplate"

export const withSearchFormResults = Component =>
  memo(({ name = "SearchFormResults", ...props }: any) => {
    const { globalState } = useApp()
    const { dom } = useDom()
    const { results } = useResults()
    const { searchTerm } = useSearch()
    const { global: locales } = useTemplate()

    const [state] = globalState

    Component.displayName = name
    return state.activeSearch && searchTerm ? <Component {...props} isMobile={!dom.isMedium} locales={locales} results={results} /> : null
  })
