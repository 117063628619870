import React, { useState, useRef, useEffect } from "react"
import { useGlobal } from "../hooks/useGlobal"

export const ShopContext: any = React.createContext({})

export const ShopProvider = ({ children }): JSX.Element => {
  const { handleGlobalGroupReady } = useGlobal()
  const [shop, setShop] = useState(false)

  const prevShop = useRef(shop)

  useEffect(() => {
    if (!prevShop.current) {
      prevShop.current = shop
    }
  }, [shop])

  useEffect(() => {
    if (prevShop.current === false && shop) {
      handleGlobalGroupReady("shop")
    }
  }, [handleGlobalGroupReady, shop])

  const values = {
    shop,
    setShop,
  }

  return <ShopContext.Provider value={{ ...values }}>{children}</ShopContext.Provider>
}
