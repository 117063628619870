import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Mousewheel, Navigation } from "swiper"

import { withCartDrawerUpsell } from "./withCartDrawerUpsell"
import { UpsellSlide } from "../UpsellSlides/UpsellSlide"
import { StyledContainer, Title, Arrows, ArrowWrapper, Arrow, TitleButtonContainer } from "../Upsell/styledCartDrawerUpsell"
import { Icon } from "@app/components/Icon/Icon"

SwiperCore.use([Autoplay, Mousewheel, Navigation])

export const CartDrawerUpsell = withCartDrawerUpsell(
  ({ autoplay, id, items, locales, active, isMobile, clientId, sessionID, handleAdd, handleNotify, loading }): JSX.Element => (
    <>
      {isMobile ? (
        <>
          <TitleButtonContainer>
            <Title isMobile={isMobile}>You May Like</Title>
            <ArrowWrapper>
              <div>
                <Arrows>
                  <Arrow colour={`purple`} id={`cartDrawerUpsell-left`} position={`left`} title={locales?.additionalPrevious}>
                    <Icon name={`leftCaret`} />
                  </Arrow>
                  <Arrow colour={`purple`} id={`cartDrawerUpsell--right`} position={`right`} title={locales?.additionalNext}>
                    <Icon name={`rightCaret`} />
                  </Arrow>
                </Arrows>
              </div>
            </ArrowWrapper>
          </TitleButtonContainer>
          <StyledContainer active={active} isMobile={isMobile}>
            <Swiper
              autoplay={autoplay ? { delay: autoplay, disableOnInteraction: true } : false}
              lazy
              loop
              mousewheel={{
                forceToAxis: true,
                thresholdTime: 1000,
              }}
              navigation={{ prevEl: `#cartDrawerUpsell-left`, nextEl: `#cartDrawerUpsell--right` }}
              preloadImages={false}
              slidesPerView={1.05}
              spaceBetween={0}
            >
              {items.map(item => (
                <SwiperSlide key={item?.id}>
                  <UpsellSlide
                    key={item.handle}
                    related={item}
                    clientId={clientId}
                    sessionID={sessionID}
                    handleAdd={handleAdd}
                    handleNotify={handleNotify}
                    loading={loading}
                    isMobile={isMobile}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </StyledContainer>
        </>
      ) : (
        <>
          <StyledContainer active={active} isMobile={isMobile}>
            <Title isMobile={isMobile}>You May Like</Title>
            {items.map(item => (
              <UpsellSlide
                key={item.handle}
                related={item}
                clientId={clientId}
                sessionID={sessionID}
                handleAdd={handleAdd}
                handleNotify={handleNotify}
                loading={loading}
                isMobile={isMobile}
              />
            ))}
          </StyledContainer>
        </>
      )}
    </>
  )
)
