import React, { memo, useMemo, useCallback } from "react"

import * as Components from "../../../types/components"
import * as QuizTypes from "../../../types/quiz"
import { UseTemplate } from "../../../types/hooks"
import { useApp } from "../../../hooks/useApp"
import { useTemplate } from "../../../hooks/useTemplate"
import { useQuizSubscribe } from "../../../hooks/useQuizV2"

export const withQuizSubscribePopup = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "QuizSubscribePopup" }: Components.ComponentProps) => {
    const { headerBounds } = useApp()
    const { activeQuizSubscribe, data, errors, loading, success, handleClosePopup, handleSubmit, handleChange, parseSuccessContent } =
      useQuizSubscribe()
    const { global, quiz } = useTemplate()

    const locales = useMemo(
      () => ({
        ...global,
        ...quiz,
      }),
      [global, quiz]
    )

    const onClosePopup = useCallback(() => {
      handleClosePopup()
    }, [handleClosePopup])

    const successRouteContent = parseSuccessContent(locales?.additionalSubscribeSuccessContentRoute)
    const successResultContent = parseSuccessContent(locales?.additionalSubscribeSuccessContentResult)

    const props = {
      locales,
      active: activeQuizSubscribe !== null,
      data,
      loading,
      errors,
      success,
      successContent: activeQuizSubscribe === "RESULTS15" ? successResultContent : successRouteContent,
      bounds: headerBounds,
      handleChange,
      handleSubmit,
      onClosePopup,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type InjectedProps = {
  locales: UseTemplate.Quiz & UseTemplate.Global
  active: boolean
  bounds: any // TODO
  successContent: QuizTypes.QuizSubscribe.SuccessTextItem[]
  onClosePopup: () => void
} & Omit<QuizTypes.QuizSubscribe.Response, keyof QuizTypes.QuizSubscribe.PopupController>
