import React, { useState, useEffect } from "react"

export const GlobalContext: any = React.createContext({})

export const GlobalProvider = ({ children }): JSX.Element => {
  const [readyGroup, setReadyGroup] = useState({
    location: false,
    shopify: false,
    shop: false,
    customer: false,
    checkout: false,
  })

  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!Object.values(readyGroup).some(value => value === false)) {
      setReady(true)
    }
  }, [readyGroup])

  const values = {
    ready,
    setReadyGroup,
  }

  return <GlobalContext.Provider value={{ ...values }}>{children}</GlobalContext.Provider>
}
