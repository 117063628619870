import React from "react"

import { withFormInput } from "./withFormInput"
import { FormTextarea } from "../Textarea/FormTextarea"
import { Icon } from "../../Icon/Icon"
import { StyledErrors, StyledInputWrapper, StyledInput, StyledLabel, StyledSrLabel } from "../../Styled/Form"
import { StyledLink } from "../../Styled/Button"

export const FormInput = withFormInput(
  ({
    errors,
    field,
    full,
    handleChange,
    handleClick,
    iconColour,
    id,
    label,
    locales,
    placeholder,
    reference,
    show,
    type,
    withSpacing,
    srOnly,
    drawerInput,
  }): JSX.Element => (
    <>
      <StyledInputWrapper full={full} withSpacing={!(errors?.length > 0) && withSpacing} drawerInput={drawerInput}>
        <StyledSrLabel htmlFor={id}>{placeholder}</StyledSrLabel>
        {drawerInput ? (
          <FormTextarea
            full={full}
            {...field}
            errors={errors}
            iconColour={iconColour}
            id={id}
            name={label}
            onChange={handleChange}
            placeholder={placeholder}
            ref={reference}
          ></FormTextarea>
        ) : (
          <StyledInput
            {...field}
            errors={errors}
            iconColour={iconColour}
            id={id}
            name={label}
            onChange={handleChange}
            placeholder={placeholder}
            ref={reference}
            type={type === `tel` ? `text` : type === `password` && show ? `text` : type}
            srOnly={srOnly}
          />
        )}
        <div>
          {type === `password` ? (
            <StyledLink
              type={`button`}
              tabIndex={`-1`}
              colour={field?.inverted ? `white` : `purple-dark`}
              onClick={handleClick}
              title={`${show ? locales?.additionalHide : locales?.additionalShow} ${placeholder}`}
            >
              <Icon name={show ? `eye-closed` : `eye`} title={`${show ? locales?.additionalHide : locales?.additionalShow} ${placeholder}`} />
            </StyledLink>
          ) : (
            <Icon name={`tick`} />
          )}
          {placeholder && <StyledLabel srOnly={srOnly}>{placeholder}</StyledLabel>}
        </div>
      </StyledInputWrapper>
      {errors?.length > 0 && (
        <>
          {errors?.map(({ code, message }) => (
            <StyledErrors key={code?.toString()} withSpacing={withSpacing}>
              {message?.toString()}
            </StyledErrors>
          ))}
        </>
      )}
    </>
  )
)
