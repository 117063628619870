import tw, { css, styled } from "twin.macro"

export const Items = styled.div`
  ${tw`overflow-y-auto overflow-x-hidden px-4`}
  ${({ drawer }) => drawer && tw`w-full mb-5 flex-1`}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  &::-webkit-scrollbar {
    ${tw`w-1`}
  }
  &::-webkit-scrollbar-thumb {
    ${tw`bg-grey-light rounded-3xl`}
  }
`
