import { useCallback, useEffect, useMemo, useState } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import * as QuizTypes from "../types/quiz"
import * as Components from "../types/components"
import * as Sanity from "../types/sanity"
import * as Shopify from "../types/shopify"
import * as Headless from "../types/headless"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"
import { useCustomerContext } from "./useCustomer"
import { useRoutes } from "./useRoutes"
import { useFunctions } from "./useFunctions"
import { useAnalytics } from "./useAnalytics"

export const useQuiz = (location: Components.LocationProps): QuizTypes.Quiz.Response => {
  const {
    setActiveQuiz,
    setActiveQuizInfo,
    helpers: { edgeNormaliser },
    config: {
      settings: { keys, productTags, quizSlugs },
    },
  }: { setActiveQuiz: any; setActiveQuizInfo: any; setActiveQuizSubscribe: any; helpers: any; config: Headless.Config } = useApp() // TODO: move headless types to providers
  const {
    helpers: { decodeBase64, encodeBase64, isBrowser, storage },
  } = useCore()
  const { customer } = useCustomerContext()
  const { routeResolver } = useRoutes()
  const { trackQuizAnswer } = useAnalytics()

  const stepTypes: QuizTypes.Quiz.StepTypes = { form: "form", title: "title", custom: "custom", options: "options" }

  const { products: rawProducts, quiz } = useStaticQuery(graphql`
    query {
      quiz: sanityPageQuizV2 {
        additionalStart
        additionalPrevious
        additionalNext
        additionalNextAlternative
        additionalShare
        additionalShareTitle
        additionalSkip
        additionalRestart
        additionalName
        additionalFirstName
        additionalIntroduction
        additionalIntroductionDescription
        additionalSummary
        additionalSummaryGenericContent
        additionalSummarySensitivityFilter
        additionalSummarySensitivityFilterDescription
        additionalSummarySensitivityFilterPopup
        additionalSummarySensitivityFilterPopupTitle
        additionalSummarySensitivityFilterPopupContent: _rawAdditionalSummarySensitivityFilterPopupContent(resolveReferences: { maxDepth: 2 })
        additionalResultsParagraph1
        additionalResultsParagraph2
        additionalFullRoutinePrefix
        additionalSelectedProductsPrefix
        additionalSelectedParagraphGenericSuffix
        additionalPromoLabel
        additionalDailyDietLabel
        additionalHelpLabel
        additionalRoutineHelpLabel
        additionalEmpty
        additionalEmptyDescription
        additionalMoreInfo
        additionalLearnMore
        additionalSubscribeTag
        additionalSubscribeTitle
        additionalSubscribeEmail
        additionalSubscribeLabel
        additionalSubscribeButton
        additionalSubscribeSubmit
        additionalSubscribeSuccess
        additionalNight
        additionalMorning
        additionalBoosters
        additionalStep
        bannerTitle
        bannerSubtitle
        bannerImage: _rawBannerImage(resolveReferences: { maxDepth: 2 })
        bannerText
        bannerBackground
        resultsTitle
        resultsSubtitle
        resultsTitleAlternative
        resultsSubtitleAlternative
        resultsImage: _rawResultsImage(resolveReferences: { maxDepth: 2 })
        resultsText
        resultsBackground
        loadingTitle
        loadingSubtitle
        loadingImage: _rawLoadingImage(resolveReferences: { maxDepth: 2 })
        loadingText
        loadingBackground
        steps: _rawSteps(resolveReferences: { maxDepth: 3 })
        title
      }
      products: allSanityProduct(filter: { shopify: { shopifyDeleted: { ne: true }, shopifyPublished: { eq: true } } }) {
        edges {
          node {
            id: _id
            tags
            shopify {
              shopifyHandle
            }
            content {
              usage: _rawUsage(resolveReferences: { maxDepth: 3 })
              suitability: _rawSuitability(resolveReferences: { maxDepth: 3 })
              displayHeading
            }
          }
        }
      }
    }
  `)

  const getSharedResults = useCallback(
    (shared: string) => {
      try {
        return (shared && JSON.parse(decodeBase64(shared))) || null
      } catch (error) {
        console.error([error?.message])
      }
    },
    [decodeBase64]
  )

  const { shared } = useMemo(() => {
    const parts = location?.pathname?.split(`/`) || []
    const shared = parts?.length > 2 ? parts[parts?.length - 1] : null

    return { shared }
  }, [location])

  const saved = shared ? getSharedResults(shared) : storage.get(keys?.quiz)

  const { steps: rawSteps, ...locales } = quiz
  const products = edgeNormaliser(rawProducts)

  const steps: Array<QuizTypes.Quiz.Step> = rawSteps?.map((item: QuizTypes.Quiz.Step) => ({
    ...item,
    id: item._key,
    options: item.options?.map(item => ({
      ...item,
      id: item._id,
      icon: item.icon?.toLowerCase()?.replace(/ /g, `-`),
      title: item.title?.replace("V2", "").trim(),
    })),
    slug: item.slug || "unknown",
    icon: item.icon?.toLowerCase()?.replace(/ /g, `-`),
  }))

  const [answers, setAnswers] = useState<QuizTypes.Quiz.Answers>(saved || {})
  // const [answers, setAnswers] = useState<QuizTypes.Answers>(answersWithoutSensitivity) // debug
  const [currentStep, setCurrentStep] = useState(saved ? Object.keys(saved)?.length + 3 : 0)

  const complete = useMemo(() => {
    const requiredSteps = steps.filter(step => !step.allowSkip)

    return !!shared || (answers && Object.keys(answers)?.length === requiredSteps?.length + 1 && currentStep === steps?.length)
  }, [answers, currentStep, steps, shared])

  const step = currentStep === 0 ? steps[0] : currentStep <= steps.length ? steps[currentStep - 1 < 0 ? 0 : currentStep - 1] : null

  const participant = useMemo(() => {
    const name = Array.isArray(answers?.participant) ? answers?.participant?.[0].title : answers?.participant
    return typeof name === "string" ? name.trim() : name
  }, [answers?.participant])

  const validateNextStepRule = useCallback(
    (nextStep: number): boolean => {
      const stepSlug = steps[nextStep]?.slug
      let display = true
      if (stepSlug === quizSlugs.SPF) {
        const selectedLookingForOptions = (answers[quizSlugs.LOOKING_FOR] as QuizTypes.Quiz.StepOption[])?.map(option => option.slug) || []
        display = selectedLookingForOptions.includes(quizSlugs.FULL_ROUTINE) || selectedLookingForOptions.includes(quizSlugs.SPF)
      }

      return display
    },
    [answers, steps, quizSlugs]
  )

  const handleBack = useCallback(() => {
    setCurrentStep((prevState: number) => prevState - 1)
  }, [])
  const handleNext = useCallback(() => {
    if (validateNextStepRule(currentStep)) {
      setCurrentStep((prevState: number) => prevState + 1)
    } else {
      setCurrentStep((prevState: number) => prevState + 2)
    }
  }, [currentStep, validateNextStepRule])

  const handleChange = useCallback(
    ({ target: { type, name, value, checked } }) => {
      setAnswers(prevState => ({
        ...(prevState || {}),
        [name]: type === "checkbox" ? checked : value,
      }))
    },
    [setAnswers]
  )

  // eslint-disable-next-line
  const removeKeys = obj => {
    // eslint-disable-next-line
    const keys = ["_key", "_id", "id", "_type", "_createdAt", "_updatedAt", "_rev"]
    obj &&
      Object.keys(obj).forEach(key => {
        if (keys.includes(key)) {
          delete obj[key]
        }
        if (typeof obj[key] === "object") {
          removeKeys(obj[key])
        }
      })
    return obj
  }

  const handleOption = useCallback(
    (slug: string, value: QuizTypes.Quiz.StepOption, multiple: boolean) => {
      const currentAnswer = answers?.[slug] ? answers[slug] : []

      if (Array.isArray(currentAnswer) && multiple) {
        if (currentAnswer.find(answer => answer.slug === value.slug)) {
          setAnswers(prevState => ({
            ...(prevState || {}),
            [slug]: removeKeys(currentAnswer.filter(answer => answer.slug !== value.slug)),
          }))
        } else {
          currentAnswer.push(value)
          setAnswers(prevState => ({
            ...(prevState || {}),
            [slug]: removeKeys(currentAnswer),
          }))
        }

        for (const answer of currentAnswer) {
          trackQuizAnswer({
            title: step?.title,
            stepSlug: slug,
            answer: answer.title,
            answerSlug: answer.slug,
            tag: answer.productTag,
          })
        }
      } else {
        setAnswers(prevState => ({
          ...(prevState || {}),
          [slug]: [removeKeys(value)],
        }))

        trackQuizAnswer({
          title: step?.title,
          stepSlug: slug,
          answer: value.title,
          answerSlug: value.slug,
          tag: value.productTag,
        })
      }

      if (!step?.showContinueButton && !step?.allowCustomInput && !step?.allowChooseMultiple) {
        setTimeout(() => handleNext(), 500)
      }
    },
    [removeKeys, answers, handleNext, step?.allowChooseMultiple, step?.allowCustomInput, step?.showContinueButton, step?.title, trackQuizAnswer]
  )

  const handleReset = useCallback(() => {
    setAnswers(null)
    setCurrentStep(0)
    storage.remove(keys.quiz)
    if (shared) navigate(routeResolver({ type: `quiz` }), { replace: true })
  }, [keys, routeResolver, setAnswers, setCurrentStep, shared, storage])

  const handleShare = useCallback(() => {
    const url = `${location?.origin}${routeResolver({ type: `quiz` })}/${encodeBase64(JSON.stringify(answers))}`
    setActiveQuiz((prevState: string | null) => (!prevState ? url : null))
  }, [answers, encodeBase64, location, routeResolver, setActiveQuiz])

  const handleToggleInfoPopup = useCallback(
    type => {
      setActiveQuizInfo((prevState: string | null) => (!prevState ? type : null))
    },
    [setActiveQuizInfo]
  )

  useEffect(() => {
    if (complete && !shared) {
      storage.set(keys.quiz, answers)
    }
  }, [answers, complete, keys, shared, steps, storage])

  useEffect(() => {
    if (!answers && customer?.firstName) {
      setAnswers({ participant: customer?.firstName })
    }
  }, [answers, customer, setAnswers])

  // intentionally only run at first render
  useEffect(() => {
    isBrowser ? window.scrollTo({ top: 0, behavior: "smooth" }) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  /**
   * This function includes customized/hardcoded logic
   */
  const validateMultipleChoiceRule = (isDisabled: boolean, stepSlug: string, stepOptionSlug: string, answers: QuizTypes.Quiz.Answers): boolean => {
    if (stepSlug === quizSlugs.LOOKING_FOR) {
      const selectedLookingForOptions = (answers[stepSlug] as QuizTypes.Quiz.StepOption[])?.map(option => option.slug) || []
      if (stepOptionSlug === quizSlugs.FULL_ROUTINE) {
        return answers[stepSlug]?.length && !selectedLookingForOptions.includes(quizSlugs.FULL_ROUTINE)
      } else {
        return answers[stepSlug]?.length && selectedLookingForOptions.includes(quizSlugs.FULL_ROUTINE)
      }
    }

    if (stepSlug === quizSlugs.SENSITIVITY) {
      const selectedLookingForOptions = (answers[stepSlug] as QuizTypes.Quiz.StepOption[])?.map(option => option.slug) || []
      if (stepOptionSlug === quizSlugs.NONE) {
        return answers[stepSlug]?.length && !selectedLookingForOptions.includes(quizSlugs.NONE)
      } else {
        return answers[stepSlug]?.length && selectedLookingForOptions.includes(quizSlugs.NONE)
      }
    }

    return isDisabled
  }

  const checkSensitiveSkin = useCallback((): boolean => {
    const skin = Array.isArray(answers?.[quizSlugs.SKIN]) ? (answers[quizSlugs.SKIN]?.[0] as QuizTypes.Quiz.StepOption) : null

    return skin?.productTag === productTags.SKIN_SENSITIVITY
  }, [answers, productTags, quizSlugs])

  const checkSensitivityFilter = useCallback((): boolean => {
    const sensitivity = Array.isArray(answers?.[quizSlugs.SENSITIVITY]) ? (answers[quizSlugs.SENSITIVITY] as QuizTypes.Quiz.StepOption[]) : []
    const sensitivityTags = sensitivity.map(item => item.productTag).filter(tag => tag === productTags.CONCERN_SENSITIVITY)

    return sensitivityTags.length > 0
  }, [answers, productTags, quizSlugs])

  /**
   * This function includes customized/hardcoded logic
   */
  const getResultProducts = useCallback(
    (isFullRoutine: boolean): Sanity.Product[] => {
      const allRelatedTypes = [productTags.CLEANSER, productTags.TONER, productTags.OILS_OR_BALM, productTags.FACE_CREAM]
      const age = Number.isInteger(Number(answers?.[quizSlugs.AGE])) ? Number(answers?.[quizSlugs.AGE]) : 0
      const skin = Array.isArray(answers?.[quizSlugs.SKIN]) ? (answers[quizSlugs.SKIN]?.[0] as QuizTypes.Quiz.StepOption) : null
      const concerns = Array.isArray(answers?.[quizSlugs.CONCERNS]) ? (answers[quizSlugs.CONCERNS] as QuizTypes.Quiz.StepOption[]) : []
      const selectedTypes = Array.isArray(answers?.[quizSlugs.LOOKING_FOR])
        ? (answers[quizSlugs.LOOKING_FOR] as QuizTypes.Quiz.StepOption[]).map(answer => answer.productTag)
        : null
      const typeTags = isFullRoutine ? allRelatedTypes : selectedTypes
      const resultProducts: Sanity.Product[] = []
      const selectedTags = []
      const excludedTags = []
      const sensitivityTag = checkSensitivityFilter() ? productTags.CONCERN_SENSITIVITY : null
      let ageRestrictionTag = null
      let extraHierarchyTag = null

      if (!skin?.productTag) {
        return resultProducts // early exit
      }

      // Check if selected sensitivity option
      selectedTags.push(...concerns.map(concern => concern.productTag))

      if (age && age <= 16) {
        ageRestrictionTag = productTags.AGE_UNDER_16
      }

      if (age && age >= 35) {
        extraHierarchyTag = productTags.AGE_OVER_35
      } else {
        excludedTags.push(productTags.AGE_OVER_35)
      }

      for (const typeTag of typeTags) {
        /**
         * Skin Type/age Hierarchy:
         *
         * 1) Age: -16
         * 2) Concern: Sensitivity
         * 3) Combination & Age: 35+; Oily & Age: 35+
         * 4) Dry; Oily; Combination; Balanced
         */

        let targetProductTag = skin.productTag

        // If selected any sensitivity option, override target tag
        targetProductTag = sensitivityTag ? sensitivityTag : targetProductTag

        // If age <= 16, override all target tags above
        targetProductTag = ageRestrictionTag ? ageRestrictionTag : targetProductTag

        // Remove additional required tag if age <= 16 and/or sensitivity selected
        extraHierarchyTag = sensitivityTag || ageRestrictionTag ? null : extraHierarchyTag

        let filteredProducts = pipeFilterProductByTags({ products, targetTag: typeTag })
        filteredProducts = pipeFilterProductByTags({ products: filteredProducts, targetTag: targetProductTag })
        filteredProducts = pipeFilterProductByTags({ products: filteredProducts, targetTag: extraHierarchyTag, optional: true })
        filteredProducts = pipeFilterProductByTags({ products: filteredProducts, excludedTags, optional: true })

        if (isFullRoutine && filteredProducts.length > 0) {
          // Only return single product if selected "full routine"
          filteredProducts = pipeFilerProductByHandleKeywords({ products: filteredProducts, excludeKeywords: "-mini", optional: true })
          resultProducts.push(filteredProducts[0])
        } else {
          // Return all possible products if selected a single product type
          resultProducts.push(...filteredProducts)
        }
      }

      return resultProducts.sort((a: Sanity.Product, b: Sanity.Product) => {
        const typeA = a.tags.find(tag => tag.startsWith("Product:"))
        const typeB = b.tags.find(tag => tag.startsWith("Product:"))
        return allRelatedTypes.indexOf(typeA) - allRelatedTypes.indexOf(typeB)
      })
    },
    [answers, productTags, quizSlugs, products, checkSensitivityFilter]
  )

  const pipeFilterProductByTags = (input: {
    products: Sanity.Product[]
    targetTag?: string
    excludedTags?: string[]
    optional?: boolean
  }): Sanity.Product[] => {
    const { products, targetTag, excludedTags = [], optional } = input
    const result = targetTag
      ? products.filter(product => product.tags.includes(targetTag))
      : excludedTags
      ? products.filter(product => !product.tags.filter(tag => excludedTags.includes(tag)).length)
      : products
    return !optional ? result : result.length > 0 ? result : products
  }

  const pipeFilerProductByHandleKeywords = (input: {
    products: Sanity.Product[]
    includeKeywords?: string
    excludeKeywords?: string
    optional?: boolean
  }): Sanity.Product[] => {
    const { products, includeKeywords, excludeKeywords, optional } = input
    const result = includeKeywords
      ? products.filter(product => product.shopify.shopifyHandle?.includes(includeKeywords))
      : excludeKeywords
      ? products.filter(product => !product.shopify.shopifyHandle?.includes(excludeKeywords))
      : products

    return !optional ? result : result.length > 0 ? result : products
  }

  /**
   * Skin Concern Hierarchy:
   *
   * 1) Pregnant: Yes	- Overrides everything else
   * 2) Concern: Sensitivity - Overrides everything below
   * 3) Ageing; Pigmentation - here and below only overrides the exfoliant option
   * 4) Dehydration
   * 5) Breakouts
   * 6) Congestion
   * 7) Dullness
   */
  const getBoosterProducts = useCallback(
    (isFullRoutine: boolean): Sanity.Product[] => {
      const allRelatedTypes = [productTags.EYES, productTags.MASK_OR_TREATMENT, productTags.EXFOLIANT, productTags.SPF]
      const exfoliantTagsOrder = {
        [productTags.CONCERN_DULLNESS]: 0,
        [productTags.CONCERN_CONGESTION]: 1,
        [productTags.CONCERN_BREAKOUTS]: 2,
        [productTags.CONCERN_DEHYDRATION]: 3,
        [productTags.CONCERN_PIGMENTATION]: 4,
        [productTags.CONCERN_AGING]: 5,
      }
      const concerns = Array.isArray(answers?.[quizSlugs.CONCERNS]) ? (answers[quizSlugs.CONCERNS] as QuizTypes.Quiz.StepOption[]) : []
      const spf = Array.isArray(answers?.[quizSlugs.SPF]) ? (answers[quizSlugs.SPF]?.[0] as QuizTypes.Quiz.StepOption) : null
      const pregnancy = Array.isArray(answers?.[quizSlugs.PREGNANCY]) ? (answers[quizSlugs.PREGNANCY]?.[0] as QuizTypes.Quiz.StepOption) : null
      const selectedTypes = Array.isArray(answers?.[quizSlugs.LOOKING_FOR])
        ? (answers[quizSlugs.LOOKING_FOR] as QuizTypes.Quiz.StepOption[]).map(answer => answer.productTag)
        : null
      const excludedTags = [productTags.EXFOLIANT] // The exfoliant product will be processed separately due to different hierarchy logic
      const pregnantTag = pregnancy && pregnancy.productTag
      const filteredExfoliantProducts: Sanity.Product[] = []
      let targetProductTags = []
      const sensitivityTag = checkSensitivityFilter() || checkSensitiveSkin() ? productTags.CONCERN_SENSITIVITY : null
      let otherBoosterProducts: Sanity.Product[] = []
      let allBoosterProducts: Sanity.Product[] = []

      targetProductTags.push(...concerns.map(concern => concern.productTag))

      // Only extract one exfoliant option
      let targetExfoliantTag = sensitivityTag
        ? sensitivityTag
        : targetProductTags.reduce((output, tag) => (exfoliantTagsOrder[tag] > exfoliantTagsOrder[output] ? tag : output), targetProductTags?.[0])

      if (spf && spf.productTag) {
        if (spf.productTag === productTags.SPF_TINTED) {
          excludedTags.push(productTags.SPF_UNTINTED)
        } else if (spf.productTag === productTags.SPF_UNTINTED) {
          excludedTags.push(productTags.SPF_TINTED)
        }
      }

      const requiredTypeTags = isFullRoutine ? allRelatedTypes : selectedTypes

      // If selected any sensitivity option, override target tag
      targetProductTags = sensitivityTag ? [sensitivityTag] : targetProductTags

      // If selected pregnant, override all target tags above
      if (pregnantTag) {
        requiredTypeTags.push(productTags.PREGNANT)
        allRelatedTypes.push(productTags.PREGNANT)
        targetProductTags = [pregnantTag]
        targetExfoliantTag = pregnantTag
      }

      // Remove undefined/null values
      const filteredTargetProductTags = targetProductTags.reduce((result, item) => (item ? [...result, item] : [...result]), [])

      // Find Exfoliant product individually due to its specific hierarchy
      if (requiredTypeTags.includes(productTags.EXFOLIANT)) {
        let exfoliantProducts = pipeFilterProductByTags({ products, targetTag: productTags.EXFOLIANT })
        exfoliantProducts = pipeFilterProductByTags({ products: exfoliantProducts, targetTag: targetExfoliantTag })
        exfoliantProducts = pipeFilerProductByHandleKeywords({ products: exfoliantProducts, excludeKeywords: "-mini", optional: true })
        filteredExfoliantProducts.push(exfoliantProducts[0])
      }

      // Find other booster products
      otherBoosterProducts = products.filter(product => {
        const isMiniProduct = product.handle?.includes("-mini")

        const matchProduct = (): boolean =>
          product.tags.find(
            (tag: string): boolean =>
              filteredTargetProductTags.includes(tag) &&
              !product.tags.find(tag => excludedTags.includes(tag)) &&
              (!requiredTypeTags.length || product.tags.find(tag => requiredTypeTags.includes(tag)))
          )

        // Skip "mini" products if selected "full routine"
        // Or return all possible products if selected a single product type
        return isFullRoutine ? !isMiniProduct && matchProduct() : matchProduct()
      })

      allBoosterProducts = [...filteredExfoliantProducts, ...otherBoosterProducts]

      return allBoosterProducts.sort((a: Sanity.Product, b: Sanity.Product) => {
        const typeA = a.tags.find(tag => tag.startsWith("Product:")) || a.tags.find(tag => tag.startsWith("Pregnant:"))
        const typeB = b.tags.find(tag => tag.startsWith("Product:")) || b.tags.find(tag => tag.startsWith("Pregnant:"))

        return allRelatedTypes.indexOf(typeA) - allRelatedTypes.indexOf(typeB)
      })
    },
    [answers, productTags, quizSlugs, products, checkSensitivityFilter, checkSensitiveSkin]
  )

  return {
    answers,
    complete,
    currentStep,
    handleBack,
    handleChange,
    handleNext,
    handleOption,
    handleReset,
    handleShare,
    handleToggleInfoPopup,
    validateMultipleChoiceRule,
    checkSensitivityFilter,
    getResultProducts,
    getBoosterProducts,
    locales,
    participant,
    shared,
    step,
    steps,
    stepTypes,
  }
}

export const useQuizHelper = (): QuizTypes.QuizHelper.Response => {
  const { productNormaliser } = useShopify()
  const {
    setActiveQuizInfo,
    setActiveQuizSubscribe,
    config: {
      settings: { productTags, quizSlugs },
    },
  }: { setActiveQuizInfo: any; setActiveQuizSubscribe: any; config: Headless.Config } = useApp()
  const {
    helpers: { sanityContent },
  } = useCore()

  const handleToggleInfoPopup = useCallback(
    type => {
      setActiveQuizInfo((prevState: string | null) => (!prevState ? type : null))
    },
    [setActiveQuizInfo]
  )

  const handleSubscribePopup = useCallback(
    type => {
      setActiveQuizSubscribe((prevState: string | null) => (!prevState ? type : null))
    },
    [setActiveQuizSubscribe]
  )

  const parseDescriptor = (descriptor: string, rawAnswers: QuizTypes.Quiz.Answers): QuizTypes.QuizHelper.ParsedDescriptor[] => {
    const answers = trimAnswers(rawAnswers)
    const paragraph = typeof descriptor === "string" ? descriptor : ""
    const regex = /{(.*?)}/g
    const placeholders = paragraph.match(regex).map(item => item.replace(regex, "$1"))
    const paragraphFragments = paragraph.split(regex).reduce((result: QuizTypes.QuizHelper.ParsedDescriptor[], fragment) => {
      if (fragment.trim() === "") {
        return result
      }

      // Check if need to replace placeholder content
      if (placeholders.includes(fragment) && answers?.[fragment]) {
        const previousFragment = result.length > 1 ? result[result.length - 1] : null

        // Check if need to add conjunctions in between
        if (previousFragment && previousFragment.highlight) {
          result.push({
            highlight: false,
            text: " and ",
          })
        }

        const answer = answers?.[fragment]
        const convertedText = typeof answer === "string" ? answer.trim() : joinStringArray(answer.map(option => option.title?.toLocaleLowerCase()))

        result.push({
          highlight: true,
          text: convertedText,
        })
      } else if (!placeholders.includes(fragment)) {
        result.push({
          highlight: false,
          text: fragment,
        })
      }
      return result
    }, [])

    return paragraphFragments
  }

  const joinStringArray = (input: string[] = []): string => {
    const array = [...input.map(item => item.trim())]
    let output = ""
    if (array.length === 1) {
      output = array[0]
    } else if (array.length === 2) {
      output = array.join(" and ")
    } else {
      const last = array.pop()
      output = `${array.join(", ")} and ${last}`
    }

    return output
  }

  const checkSelectedAnswer = (answerSlug: string, stepSlug: string, answers: QuizTypes.Quiz.Answers): boolean => {
    if (!Array.isArray(answers?.[stepSlug])) {
      return false // Invalid answer
    }

    for (const option of answers[stepSlug]) {
      if (typeof option !== "string" && option.slug === answerSlug) {
        return true // early exit
      }
    }

    return false // Not found
  }

  const parseResultProducts = (shopifyProducts: Shopify.Product[], sanityProducts: Sanity.Product[]): QuizTypes.Quiz.MergedProduct[] => {
    let result: QuizTypes.Quiz.MergedProduct[] = []

    if (!Array.isArray(shopifyProducts) || !Array.isArray(sanityProducts)) {
      return result
    }

    result = shopifyProducts.map((item: any) => {
      const sanityProduct = sanityProducts?.find(product => product?.shopify?.shopifyHandle === item?.handle)
      const shopifyProduct = productNormaliser(item)

      return {
        ...sanityProduct,
        product: shopifyProduct,
        displayContent: [
          {
            type: "heading",
            label: parseProductHeading(sanityProduct),
          },
          {
            type: "title",
          },
          {
            type: "content",
            label: "What",
            content: shopifyProduct?.description,
          },
          {
            type: "content",
            label: "Suitable for?",
            content: sanityProduct?.content?.suitability?.map((item: any) => item?.title),
          },
          {
            type: "divider",
          },
          {
            type: "content",
            label: "When",
            content: sanityContent(sanityProduct?.content?.usage?.find(item => item.title?.toLowerCase() === "when?")?.content),
          },
        ],
      }
    })

    return result
  }

  /**
   * This function includes customized/hardcoded logic
   */

  // A default mappings for product headings on result page
  const parseProductHeading = (sanityProduct: Sanity.Product): string => {
    let heading = ""
    const typeTag = sanityProduct.tags.find(tag => tag.startsWith("Product:"))

    switch (typeTag) {
      case productTags.OILS_OR_BALM:
        heading = "Face Oil/Balm"
        break
      case productTags.MASK_OR_TREATMENT:
        heading = "Mask/Treatment"
        break
      default:
        heading = typeof typeTag === "string" ? typeTag.replace("Product:", "").trim() : typeTag
    }

    return sanityProduct?.content?.displayHeading || heading
  }

  /**
   * This function includes customized/hardcoded logic
   */

  // Display "age" and/or "pregnancy" status on result page if applicable
  const trimAnswers = (answers: QuizTypes.Quiz.Answers = {}): QuizTypes.Quiz.Answers => {
    const trimmedAnswers: QuizTypes.Quiz.Answers = {}

    for (const [key, value] of Object.entries(answers)) {
      let push = true
      if (key === quizSlugs.AGE && !Number(value)) {
        push = false
      }
      if (key === quizSlugs.PREGNANCY && (!Array.isArray(value) || !value.map(item => item.slug).includes(quizSlugs.PREGNANCY))) {
        push = false
      }
      if (Array.isArray(value) && !value.length) {
        push = false
      }

      if (push) {
        trimmedAnswers[key] = value
      }
    }

    return trimmedAnswers
  }

  return {
    handleToggleInfoPopup,
    handleSubscribePopup,
    parseDescriptor,
    checkSelectedAnswer,
    trimAnswers,
    parseResultProducts,
  }
}

export const useQuizSubscribe = (): QuizTypes.QuizSubscribe.Response => {
  const INITIAL_STATE = useMemo(() => ({ email: "", firstName: "" }), [])
  const customerTagMapping = useMemo(
    () => ({
      ROUTINE15: "Consult Tab",
      RESULTS15: "Consult Results",
    }),
    []
  )

  const { activeQuizSubscribePanel, setQuizSubscribeActivePanel, activeQuizSubscribe, setActiveQuizSubscribe } = useApp()
  const { callFunction, errors, loading } = useFunctions()

  const [data, setData] = useState<QuizTypes.QuizSubscribe.Data>(INITIAL_STATE)
  const [success, setSuccess] = useState<boolean>(true)

  const handleChange = useCallback(
    (input: QuizTypes.QuizSubscribe.Input) => {
      const { target } = input
      setData(prevState => ({
        ...prevState,
        [target?.name]: target?.type === "checkbox" ? target?.checked : target?.value,
      }))
    },
    [setData]
  )

  const parseSuccessContent = (content: string): QuizTypes.QuizSubscribe.SuccessTextItem[] => {
    if (!content) {
      return []
    }

    return content.split(" ").map(item => ({
      highlight: !!customerTagMapping[item],
      text: item,
    }))
  }

  const handleToggleTab = useCallback(() => {
    setQuizSubscribeActivePanel(prev => !prev)
  }, [setQuizSubscribeActivePanel])

  const handleOpenPopup = useCallback(
    (type: QuizTypes.QuizSubscribe.Type) => {
      setActiveQuizSubscribe(type)
    },
    [setActiveQuizSubscribe]
  )

  const handleClosePopup = useCallback(() => {
    setActiveQuizSubscribe(null)
  }, [setActiveQuizSubscribe])

  const handleSubmit = useCallback(
    async (event: Event) => {
      event.preventDefault()
      const type: QuizTypes.QuizSubscribe.Type = activeQuizSubscribe || "ROUTINE15"
      await callFunction("customer-tags-email", { ...data, tags: [type, customerTagMapping[type]] })

      setSuccess(true)
    },
    [activeQuizSubscribe, customerTagMapping, callFunction, data]
  )

  useEffect(() => {
    if (!activeQuizSubscribePanel && !activeQuizSubscribe) {
      setSuccess(false)
      setData(INITIAL_STATE)
    }
  }, [activeQuizSubscribePanel, activeQuizSubscribe, INITIAL_STATE])

  return {
    activeQuizSubscribe,
    activeQuizSubscribePanel,
    data,
    errors,
    loading,
    success,
    handleOpenPopup,
    handleClosePopup,
    handleSubmit,
    handleChange,
    handleToggleTab,
    parseSuccessContent,
  }
}
