import tw, { css, styled } from "twin.macro"
import { StyledLink } from "@app/components/Styled/Button"

const styles = {
  default: tw`bg-purple-light-pastel relative`,
  position: {
    desktop: css`
      transform: translateX(-100%);
      position: fixed;
      top: 0;
      bottom: 0;
      overflow: auto;
      width: 180px;
      padding: 0 1.5em;
      text-align: center;
    `,
    mobile: css`
      display: flex;
      align-items: center;
      overflow: scroll;
      padding: 1em;
    `,
  },
  visibility: {
    show: tw`block`,
    hide: tw`hidden`,
  },
  title: {
    default: tw`font-bold`,
    mobile: tw`p-2`,
    desktop: tw`pt-4 pb-6`,
  },
}

export const StyledContainer = styled.div`
  ${styles.default}
  ${({ active }) => (active ? styles.visibility.show : styles.visibility.hide)}
  ${({ isMobile }) => (isMobile ? styles.position.mobile : styles.position.desktop)}
`
export const Title = styled.h2`
  ${styles.title.default}
  ${({ isMobile }) => (isMobile ? styles.title.mobile : styles.title.desktop)}
`
export const TitleButtonContainer = tw.div`flex justify-between items-center`

export const Arrow = styled(StyledLink)`
  ${tw`z-1 w-6 h-6`}
  ${({ position }) => (position === `right` ? tw`right-0` : tw`left-0`)}
`
export const Arrows = tw.div`flex lg:mt-0`
export const ArrowWrapper = tw.div`flex justify-center pr-1`
