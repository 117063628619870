import { UseSettings } from "../types/hooks"
import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"
import { useSettings } from "./useSettings"
import { useTemplate } from "./useTemplate"

export const useMeta = () => {
  const {
    helpers: {
      isDomReady,
      metaTags,
      renderTrackingScripts,
      schemaArticle,
      schemaBlog,
      schemaBreadcrumbs,
      schemaContent,
      schemaData,
      schemaOrg,
      schemaProduct,
      schemaSearch,
      schemaStore,
      schemaWebSite,
    },
  } = useCore()
  const { routeResolver } = useRoutes()
  const { languages, organisation, scripts, social, tracking } = useSettings()
  const { global } = useTemplate()

  const mapping = {
    page: schemaWebSite,
    blog: schemaBlog,
    article: schemaArticle,
    product: schemaProduct,
    collection: schemaWebSite,
    search: schemaSearch,
    store: schemaStore,
  }

  const eliminateGoogleSiteVerifyFromSchema = (): Omit<UseSettings.Tracking, "googleSiteVerify" | "googleSiteVerifyCustomArray"> => {
    const output = {}
    for (const [key, value] of Object.entries(tracking)) {
      if (key !== "googleSiteVerify" && key !== "googleSiteVerifyCustomArray") {
        output[key] = value
      }
    }

    return output as Omit<UseSettings.Tracking, "googleSiteVerify" | "googleSiteVerifyCustomArray">
  }

  const mergeGoogleSiteVerifyIntoMetaTags = (
    metaTags: Array<{ [key: string]: string | undefined }>
  ): Array<{ [key: string]: string | undefined }> => {
    const cleanedMetaTags = metaTags.reduce((result, tag) => (tag.name === "google-site-verification" ? result : [...result, tag]), [])
    const newMetaTags = tracking.googleSiteVerifyCustomArray?.map(item => ({ name: "google-site-verification", content: item })) || []
    return [...cleanedMetaTags, ...newMetaTags]
  }

  const getData = ({ breadcrumbs, data, language, routes, template, url }) =>
    schemaData(
      { breadcrumbs, data, global, language, organisation, routes, social, template, tracking: eliminateGoogleSiteVerifyFromSchema(), url },
      mapping
    )

  const getTags = data => mergeGoogleSiteVerifyIntoMetaTags(metaTags(data))

  const getLanguages = route =>
    languages?.hrefLangs?.map(({ _key, language, region, url }, index) => ({
      key: _key,
      primary: !index,
      href: `${url || organisation?.url}${route || ``}`,
      hrefLang: `${language}-${region}`,
      lang: language,
      language: `${language}_${region}`,
      rel: `alternate`,
    }))

  const getSchemas = data =>
    [schemaOrg(data), schemaContent(data), schemaBreadcrumbs(data)]?.filter(
      schema => schema[`@type`] !== `Organization` || data?.url === routeResolver({ type: `homepage` })
    )

  const getTracking = () =>
    isDomReady
      ? [
          renderTrackingScripts(scripts?.trackingHeader?.code || "", { id: "tracking", placement: "head" }),
          renderTrackingScripts(scripts?.trackingBody?.code || "", { id: "tracking", placement: "body" }),
          renderTrackingScripts(scripts?.trackingFooter?.code || "", { id: "tracking", placement: "foot" }),
        ]
      : []

  return {
    getData,
    getLanguages,
    getSchemas,
    getTags,
    getTracking,
  }
}
