import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withHeaderNavigationPrimary } from "./withHeaderNavigationPrimary"
import { AccountWidget } from "../../../Account/Widget/AccountWidget"
import { SearchWidget } from "../../../Search/Widget/SearchWidget"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { NavFeatured } from "../Featured/NavFeatured"
import { Icon } from "../../../Icon/Icon"
import { Column, Row, Nav, Items, Item, Link, SubItems, PrimaryItemHeader, PrimaryItem, IconContainer } from "./styledHeaderNavigationPrimary"

export const HeaderNavigationPrimary = withHeaderNavigationPrimary(
  ({ active, bounds, handleClose, primary, isMobile, activeSubMenu, handleSubMenuItems }): JSX.Element => (
    <>
      {!isMobile ? (
        <Nav active={active} top={bounds?.height}>
          {primary?.length > 0 && (
            <Row items={`stretch`} justify={`start`} withWrap={`<sm`}>
              <Column top={bounds?.height}>
                <Row inner items={`stretch`}>
                  <StyledColumn vertical items={`start`} justify={`between`} width={`2/3 md:full`}>
                    <Items>
                      {primary?.map((item, index) => {
                        const { key, external, title, type, url, items, isFeatured } = item
                        return (
                          <Item key={key}>
                            {type === `sub` ? (
                              <>
                                <PrimaryItemHeader title={title} to={url}>
                                  {title}
                                </PrimaryItemHeader>
                                <SubItems key={key} isActive={true}>
                                  {items?.map(({ key, items, title, url, multiColourText, link }) => (
                                    <>
                                      <StyledColumn>
                                        <a href={url || link.url} title={title} onClick={handleClose}>
                                          <PrimaryItem align={`center`} className={`animate-underline`} multiColourText={multiColourText}>
                                            {title}
                                            <span className={`line`} />
                                          </PrimaryItem>
                                        </a>
                                      </StyledColumn>
                                      {items?.length
                                        ? items?.map(({ key, external, title, type, url }) => (
                                            <Item key={key}>
                                              <Link
                                                as={url && type === `link` ? `a` : GatsbyLink}
                                                href={type === `link` && url}
                                                rel={type === `link` ? `noopener` : null}
                                                target={external ? `_blank` : null}
                                                title={title}
                                                to={type !== `link` && url}
                                              >
                                                {title}
                                              </Link>
                                            </Item>
                                          ))
                                        : null}
                                    </>
                                  ))}
                                </SubItems>
                              </>
                            ) : isFeatured ? (
                              <NavFeatured handleClose={handleClose} item={item} />
                            ) : (
                              <Link
                                as={url && type === `link` ? `a` : GatsbyLink}
                                href={type === `link` && url}
                                rel={type === `link` ? `noopener` : null}
                                target={external ? `_blank` : null}
                                title={title}
                                to={type !== `link` && url}
                                onClick={handleClose}
                                className={`animate-underline`}
                              >
                                {title}
                                <span className={`line`} />
                              </Link>
                            )}
                          </Item>
                        )
                      })}
                    </Items>
                  </StyledColumn>
                  <StyledColumn screen={`<sm`} width={`1/3`}>
                    <StyledRow items={`center`} justify={`end`}>
                      <SearchWidget handleClose={handleClose} />
                      <AccountWidget />
                    </StyledRow>
                  </StyledColumn>
                </Row>
              </Column>
            </Row>
          )}
        </Nav>
      ) : (
        <Nav active={active} top={bounds?.height} isMobile={isMobile}>
          {primary?.length > 0 && (
            <Row items={`stretch`} justify={`start`} withWrap={`<sm`}>
              <Column top={bounds?.height}>
                <Row inner items={`stretch`} active={active}>
                  <StyledColumn vertical items={`start`} justify={`between`} width={`full`}>
                    <Items isMobile={isMobile}>
                      {primary?.map(({ key, external, title, type, url, items, isFeatured }, index) => (
                        <Item key={key}>
                          {type === `sub` ? (
                            <>
                              <SubItems key={key} isActive={active} isMobile={isMobile}>
                                {items?.map(({ key, indItems, title, url, multiColourText, link }, indexTwo) => (
                                  <>
                                    <StyledColumn>
                                      <a href={url || link.url} title={title} onClick={handleClose}>
                                        <PrimaryItem
                                          align={`center`}
                                          className={`animate-underline`}
                                          isSubLink={`true`}
                                          multiColourText={multiColourText}
                                          isLast={indexTwo === items.length - 1}
                                          isFirst={indexTwo === 0}
                                          isHeaderLast={index === primary.length - 1}
                                        >
                                          {title}
                                          <span className={`line`} />
                                        </PrimaryItem>
                                      </a>
                                    </StyledColumn>
                                    {indItems?.length
                                      ? indItems?.map(({ key, external, title, type, url }) => (
                                          <Item key={key}>
                                            <Link
                                              as={url && type === `link` ? `a` : GatsbyLink}
                                              href={type === `link` && url}
                                              rel={type === `link` ? `noopener` : null}
                                              target={external ? `_blank` : null}
                                              title={title}
                                              to={type !== `link` && url}
                                            >
                                              {title}
                                            </Link>
                                          </Item>
                                        ))
                                      : null}
                                  </>
                                ))}
                              </SubItems>
                            </>
                          ) : !isFeatured ? (
                            <Link
                              as={url && type === `link` ? `a` : GatsbyLink}
                              href={type === `link` && url}
                              rel={type === `link` ? `noopener` : null}
                              target={external ? `_blank` : null}
                              title={title}
                              to={type !== `link` && url}
                              onClick={handleClose}
                              className={`animate-underline`}
                              isSubLink={true}
                            >
                              {title}
                              <span className={`line`} />
                            </Link>
                          ) : null}
                        </Item>
                      ))}
                    </Items>
                  </StyledColumn>
                </Row>
              </Column>
            </Row>
          )}
        </Nav>
      )}
    </>
  )
)

export default HeaderNavigationPrimary
