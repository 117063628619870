import React, { memo, useCallback, useMemo } from "react"

import { useNewsletter } from "../../../hooks/useNewsletter"
import { useTemplate } from "../../../hooks/useTemplate"

export const withFooterSubscribe = Component =>
  memo(({ name = "FooterSubscribe" }: any) => {
    const { data, loading, errors, setData, handleSubmit: submit, success } = useNewsletter()
    const { global: locales } = useTemplate()

    const handleSubmit = useCallback(
      async (event: any) => {
        event.preventDefault()
        submit()
      },
      [submit]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          data={data}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          locales={locales}
          loading={loading}
          success={success}
        />
      ),
      [data, errors, handleChange, handleSubmit, locales, loading, success]
    )
  })
