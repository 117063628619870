import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withPickMixPopup } from "./withPickMixPopup"
import { Notify, Inner, Popup, Button } from "./styledPickMixPopup"

export const PickMixPopup = withPickMixPopup(
  ({ links, active, bounds, handleActive }): JSX.Element => (
    <Notify active={active} top={bounds?.height}>
      <Inner>
        <Popup active={active}>
          {links.map((link, index) => (
            <Button
              key={index}
              as={link.url ? GatsbyLink : null}
              to={link?.url}
              onClick={handleActive}
              colour={"orange"}
              full={"true"}
              size={"primary"}
              title={link.title}
            >
              {link.title}
            </Button>
          ))}
        </Popup>
      </Inner>
    </Notify>
  )
)
