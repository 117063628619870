import tw, { styled } from "twin.macro"

import { StyledLink } from "../../Styled/Button"

export const Nav = styled.nav`
  ${({ screen }) => (screen === `md` ? tw`hidden md:mb-12 lg:mb-0 md:grid grid-cols-4 grid-rows-2 gap-y-12` : tw`md:hidden`)}
`
export const Items = styled.ul`
  ${tw`row-span-2`}
  ${({ small }) => small && tw`row-span-1`}
`
export const Item = tw.li`mb-3 md:mb-4 last:mb-0 md:last:mb-0`
export const Link = tw(StyledLink)`block text-base whitespace-nowrap`
