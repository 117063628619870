import tw, { css, styled } from "twin.macro"
import { StyledButton } from "../../../../components/Styled/Button"
import { GlobalStyles } from "../../../../components/Styled/Global"

const styles = {
  text: {
    base: tw`whitespace-normal`,
  },
}

export const Title = styled.p`
  ${tw`text-base font-semibold pb-0.5 pt-2`}
`

export const Content = styled.p`
  ${() => styles.text.base}
  ${tw`text-sm pb-1 pt-2`}
`
export const Container = styled.div`
  ${() => styles.text.base}
  ${tw`flex flex-row lg:flex-col items-center justify-start lg:justify-center max-w-full lg:max-w-[15rem] px-5 pb-4 pt-12 lg:p-0`}
  ${css`
    img {
      ${tw`max-w-[45%] lg:max-w-full lg:w-full`}
    }
  `}
`
export const SubContainer = styled.div`
  ${tw`pl-5 max-w-[55%] lg:max-w-full lg:pl-0`}
`

export const Button = tw(StyledButton)`mb-0`
export const ButtonText = styled.span`
  ${({ colour }) => colour && GlobalStyles.colour[`link-${colour}`]}
`
