import React from "react"

import { withQuizInfo } from "./withQuizInfo"
import { Icon } from "../../Icon/Icon"
import { Notify, Inner, Popup, Close, Heading, Content } from "./styledQuizInfo"

export const QuizInfo = withQuizInfo(
  ({ active, bounds, handleActive, locales }): JSX.Element => (
    <Notify active={active} top={bounds?.height}>
      <Inner>
        <Popup active={active}>
          <Heading align={`center`} withSpacing={`xs`}>
            {locales?.additionalSummarySensitivityFilterPopupTitle}
          </Heading>
          <Content align={`center`} withSpacing>
            {locales?.additionalSummarySensitivityFilterPopupContent}
          </Content>
          <Close colour={`black`} onClick={handleActive} title={locales?.additionalClose}>
            <Icon name={`close`} />
          </Close>
        </Popup>
      </Inner>
    </Notify>
  )
)
