import React, { memo, useCallback, useMemo, useState } from "react"

import { useApp } from "../../hooks/useApp"
import { useSearchRedirect } from "../../hooks/useSearch"
import { useTemplate } from "../../hooks/useTemplate"

export const withHeader = Component =>
  memo(({ name = "Header", location, title }: any) => {
    const {
      globalState,
      setHeaderBounds,
      config: {
        store: { shopName },
      },
    } = useApp()
    const { global: locales } = useTemplate()
    const [bounds, setBounds] = useState({})
    const { handleRedirect } = useSearchRedirect()
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const [state, dispatch] = globalState
    const shopifyPermanentDomain = `${shopName}.myshopify.com`

    const handleResize = useCallback(
      (contentRect: any, name) => {
        setBounds((prevState: any) => ({
          ...prevState,
          [name]: contentRect.bounds,
        }))

        if (name === `header`) {
          setHeaderBounds(contentRect.bounds)
        }
      },
      [setBounds, setHeaderBounds]
    )

    const handleMobileMenu = useCallback(
      status => {
        status !== "undefined" ? setShowMobileMenu(status) : setShowMobileMenu((prevState: boolean) => !prevState)
        state?.activeMenu && dispatch({ type: "setActiveMenu", payload: false })
        state?.activeSubMenu && dispatch({ type: "setActiveSubMenu", payload: false })
      },
      [setShowMobileMenu, state, dispatch]
    )

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          activeMenu={state?.activeMenu}
          bounds={bounds}
          handleRedirect={handleRedirect}
          handleResize={handleResize}
          locales={locales}
          location={location}
          shopifyPermanentDomain={shopifyPermanentDomain}
          title={title}
          handleMobileMenu={handleMobileMenu}
          showMobileMenu={showMobileMenu}
        />
      ),
      [bounds, handleRedirect, handleResize, locales, location, shopifyPermanentDomain, state, title, showMobileMenu, handleMobileMenu]
    )
  })
