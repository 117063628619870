import React, { memo, useMemo, useCallback } from "react"

import * as Components from "../../types/components"
import { useApp } from "../../hooks/useApp"
import { useLoyalty } from "../../hooks/useLoyalty"

export const withAnimationPopupLoyalty = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "AnimationPopupLoyalty" }: Components.ComponentProps) => {
    const { activeAnimationPopupLoyalty = {}, headerBounds } = useApp()
    const { handleAnimationPopup } = useLoyalty()

    const handleClick = useCallback(async () => {
      await handleAnimationPopup({})
    }, [handleAnimationPopup])

    const active = useMemo(() => Object.keys(activeAnimationPopupLoyalty).length, [activeAnimationPopupLoyalty])

    const props = useMemo(
      () => ({
        active,
        bounds: headerBounds,
        handleClick,
        activeAnimationPopupLoyalty,
      }),
      [active, headerBounds, handleClick, activeAnimationPopupLoyalty]
    )

    Component.displayName = name
    return useMemo(() => <Component {...(props as P)} />, [props])
  })

type InjectedProps = {
  active: boolean
  bounds: any
  handleClick: () => void
  activeAnimationPopupLoyalty: any
}
