import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useNavigation } from "./useNavigation"

export const useNavigationTertiary = (location?: any) => {
  const { mapItems } = useNavigation(location)

  const { menus } = useStaticQuery<GatsbyTypes.UseNavigationTertiaryQuery>(graphql`
    query UseNavigationTertiary {
      menus: sanitySettingMenus {
        tertiary {
          items: _rawItems(resolveReferences: { maxDepth: 4 })
          slug {
            current
          }
        }
      }
    }
  `)

  return {
    tertiary: useMemo(() => mapItems(menus?.tertiary?.items, "tertiary"), [menus, mapItems]),
  }
}
