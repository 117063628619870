import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useNavigation } from "./useNavigation"

export const useNavigationSecondary = (location?: any) => {
  const { mapItems } = useNavigation(location)

  const { menus } = useStaticQuery<GatsbyTypes.UseNavigationSecondaryQuery>(graphql`
    query UseNavigationSecondary {
      menus: sanitySettingMenus {
        secondary {
          items: _rawItems(resolveReferences: { maxDepth: 4 })
          slug {
            current
          }
        }
      }
    }
  `)

  return {
    secondary: useMemo(() => mapItems(menus?.secondary?.items, "secondary"), [menus, mapItems]),
  }
}
