import tw, { styled } from "twin.macro"

export const SelectWrapper = styled.div`
  ${tw`relative w-auto flex justify-between items-center text-white mr-3.5 md:mr-0 hidden`}

  svg {
    ${tw`absolute pointer-events-none right-0 w-2`}
  }
`
export const Select = styled.select`
  ${tw`bg-transparent cursor-pointer text-base transform scale-75 lg:scale-100 lg:text-sm pr-3 lg:pr-4 border-none appearance-none focus:outline-none text-white`}

  option {
    ${tw`bg-white text-black`}
  }
`
