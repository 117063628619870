import React, { memo, useCallback, useMemo, useRef, useState } from "react"

import { useTemplate } from "../../../hooks/useTemplate"

export const withFormInput = Component =>
  memo(
    ({
      name = "FormInput",
      errors = null,
      full = false,
      iconColour = null,
      id: formId = null,
      name: label,
      onChange,
      placeholder,
      reference = null,
      type = `text`,
      withSpacing = null,
      srOnly = false,
      drawerInput = false,
      ...field
    }: any) => {
      const { global: locales } = useTemplate()
      const [show, setShow] = useState(false)
      const ref = useRef()

      const id = formId || `${label}_${Math.random().toString(36).substr(2, 9)}`

      const handleChange = useCallback(event => onChange(event), [onChange])

      const handleClick = useCallback(() => {
        setShow((prevState: boolean) => !prevState)
        reference?.current ? reference.current?.select() : ref?.current && ref.current?.select()
      }, [ref, reference])

      Component.displayName = name
      return useMemo(
        () => (
          <Component
            errors={errors}
            field={field}
            full={full}
            handleChange={handleChange}
            handleClick={handleClick}
            iconColour={iconColour}
            id={id}
            label={label}
            locales={locales}
            placeholder={placeholder}
            reference={reference || ref}
            show={show}
            type={type}
            withSpacing={withSpacing}
            srOnly={srOnly}
            drawerInput={drawerInput}
          />
        ),
        [
          errors,
          field,
          full,
          handleChange,
          handleClick,
          iconColour,
          id,
          label,
          locales,
          placeholder,
          reference,
          show,
          type,
          withSpacing,
          srOnly,
          drawerInput,
        ]
      )
    }
  )
