import tw from "twin.macro"

export const GlobalStyles = {
  align: {
    left: tw`text-left`,
    center: tw`text-center`,
    right: tw`text-right`,
  },
  background: {
    white: tw`bg-white`,
    "white-pastel": tw`bg-[#fcfcfc]`,
    black: tw`bg-black`,
    grey: tw`bg-grey`,
    "grey-darkest": tw`bg-grey-darkest`,
    "grey-darker": tw`bg-grey-darker`,
    "grey-dark": tw`bg-grey-dark`,
    "grey-light": tw`bg-grey-light`,
    "grey-lighter": tw`bg-grey-lighter`,
    green: tw`bg-green`,
    "green-pastel": tw`bg-green-pastel`,
    "green-dark": tw`bg-green-dark`,
    "green-dark-pastel": tw`bg-green-dark-pastel`,
    "green-light": tw`bg-green-light`,
    "green-light-pastel": tw`bg-green-light-pastel`,
    orange: tw`bg-orange`,
    "orange-pastel": tw`bg-orange-pastel`,
    "orange-dark": tw`bg-orange-dark`,
    "orange-dark-pastel": tw`bg-orange-dark-pastel`,
    "orange-light": tw`bg-orange-light`,
    "orange-light-pastel": tw`bg-orange-light-pastel`,
    "pink-dark": tw`bg-pink-dark`,
    "pink-dark-pastel": tw`bg-pink-dark-pastel`,
    "pink-light": tw`bg-pink-light`,
    "pink-light-pastel": tw`bg-pink-light-pastel`,
    "purple-dark": tw`bg-purple-dark`,
    "purple-dark-pastel": tw`bg-purple-dark-pastel`,
    "purple-light": tw`bg-purple-light`,
    "purple-light-pastel": tw`bg-purple-light-pastel`,
    red: tw`bg-red`,
    "red-pastel": tw`bg-red-pastel`,
    "yellow-dark": tw`bg-yellow-dark`,
    "yellow-dark-pastel": tw`bg-yellow-dark-pastel`,
    "yellow-light": tw`bg-yellow-light`,
    "yellow-light-pastel": tw`bg-yellow-light-pastel`,
    peach: tw`bg-peach`,
    "peach-pastel": tw`bg-peach-pastel`,
  },
  border: {
    white: tw`border-white`,
    black: tw`border-black`,
    grey: tw`border-grey`,
    "grey-darkest": tw`border-grey-darkest`,
    "grey-darker": tw`border-grey-darker`,
    "grey-dark": tw`border-grey-dark`,
    "grey-light": tw`border-grey-light`,
    "grey-lighter": tw`border-grey-lighter`,
    green: tw`border-green`,
    "green-pastel": tw`border-green-pastel`,
    "green-dark": tw`border-green-dark`,
    "green-dark-pastel": tw`border-green-dark-pastel`,
    "green-light": tw`border-green-light`,
    "green-light-pastel": tw`border-green-light-pastel`,
    orange: tw`border-orange`,
    "orange-pastel": tw`border-orange-pastel`,
    "orange-dark": tw`border-orange-dark`,
    "orange-dark-pastel": tw`border-orange-dark-pastel`,
    "orange-light": tw`border-orange-light`,
    "orange-light-pastel": tw`border-orange-light-pastel`,
    "pink-dark": tw`border-pink-dark`,
    "pink-dark-pastel": tw`border-pink-dark-pastel`,
    "pink-light": tw`border-pink-light`,
    "pink-light-pastel": tw`border-pink-light-pastel`,
    "purple-dark": tw`border-purple-dark`,
    "purple-dark-pastel": tw`border-purple-dark-pastel`,
    "purple-light": tw`border-purple-light`,
    "purple-light-pastel": tw`border-purple-light-pastel`,
    red: tw`border-red`,
    "red-pastel": tw`border-red-pastel`,
    "yellow-dark": tw`border-yellow-dark`,
    "yellow-dark-pastel": tw`border-yellow-dark-pastel`,
    "yellow-light": tw`border-yellow-light`,
    "yellow-light-pastel": tw`border-yellow-light-pastel`,
    peach: tw`border-peach`,
    "peach-pastel": tw`border-peach-pastel`,
  },
  colour: {
    white: tw`text-white`,
    black: tw`text-black`,
    grey: tw`text-grey`,
    "grey-darkest": tw`text-grey-darkest`,
    "grey-darker": tw`text-grey-darker`,
    "grey-dark": tw`text-grey-dark`,
    "grey-light": tw`text-grey-light`,
    "grey-lighter": tw`text-grey-lighter`,
    green: tw`text-green`,
    "green-pastel": tw`text-green-pastel`,
    "green-dark": tw`text-green-dark`,
    "green-dark-pastel": tw`text-green-dark-pastel`,
    "green-light": tw`text-green-light`,
    "green-light-pastel": tw`text-green-light-pastel`,
    orange: tw`text-orange`,
    "orange-pastel": tw`text-orange-pastel`,
    "orange-dark": tw`text-orange-dark`,
    "orange-dark-pastel": tw`text-orange-dark-pastel`,
    "orange-light": tw`text-orange-light`,
    "orange-light-pastel": tw`text-orange-light-pastel`,
    "pink-dark": tw`text-pink-dark`,
    "pink-dark-pastel": tw`text-pink-dark-pastel`,
    "pink-light": tw`text-pink-light`,
    "pink-light-pastel": tw`text-pink-light-pastel`,
    "purple-dark": tw`text-purple-dark`,
    "purple-dark-pastel": tw`text-purple-dark-pastel`,
    "purple-light": tw`text-purple-light`,
    "purple-light-pastel": tw`text-purple-light-pastel`,
    red: tw`text-red`,
    "red-pastel": tw`text-red-pastel`,
    "yellow-dark": tw`text-yellow-dark`,
    "yellow-dark-pastel": tw`text-yellow-dark-pastel`,
    "yellow-light": tw`text-yellow-light`,
    "yellow-light-pastel": tw`text-yellow-light-pastel`,
    peach: tw`text-peach`,
    "peach-pastel": tw`text-peach-pastel`,
  },
  screen: {
    default: tw`block`,
    "<sm": tw`block md:hidden`,
    "<md": tw`block lg:hidden`,
    "md>": tw`hidden md:block`,
    "lg>": tw`hidden lg:block`,
  },
  shadow: {
    xs: tw`shadow-xs`,
    sm: tw`shadow-sm`,
    default: tw`shadow`,
    lg: tw`shadow-lg`,
    xl: tw`shadow-xl`,
  },
  spacing: {
    none: tw`mb-0`,
    xxxs: tw`mb-1`,
    xxs: tw`mb-2`,
    xxsmd: tw`mb-3.5`,
    xs: tw`mb-4`,
    sm: tw`mb-6`,
    default: tw`mb-8`,
    md: tw`mb-10`,
    lg: tw`mb-12`,
    xl: tw`mb-16`,
    xxl: tw`mb-20`,
  },
}

export interface GlobalStylesTypes {
  align: "left" | "center" | "right"
  background:
    | "white"
    | "black"
    | "grey"
    | "grey-darkest"
    | "grey-darker"
    | "grey-dark"
    | "grey-light"
    | "grey-lighter"
    | "green"
    | "green-pastel"
    | "green-dark"
    | "green-dark-pastel"
    | "green-light"
    | "green-light-pastel"
    | "orange"
    | "orange-pastel"
    | "orange-dark"
    | "orange-dark-pastel"
    | "orange-light"
    | "orange-light-pastel"
    | "pink-dark"
    | "pink-dark-pastel"
    | "pink-light"
    | "pink-light-pastel"
    | "purple-dark"
    | "purple-dark-pastel"
    | "purple-light"
    | "purple-light-pastel"
    | "red"
    | "red-pastel"
    | "yellow-dark"
    | "yellow-dark-pastel"
    | "yellow-light"
    | "yellow-light-pastel"
    | "peach"
    | "peach-pastel"
  border:
    | "white"
    | "black"
    | "grey"
    | "grey-darkest"
    | "grey-darker"
    | "grey-dark"
    | "grey-light"
    | "grey-lighter"
    | "green"
    | "green-pastel"
    | "green-dark"
    | "green-dark-pastel"
    | "green-light"
    | "green-light-pastel"
    | "orange"
    | "orange-pastel"
    | "orange-dark"
    | "orange-dark-pastel"
    | "orange-light"
    | "orange-light-pastel"
    | "pink-dark"
    | "pink-dark-pastel"
    | "pink-light"
    | "pink-light-pastel"
    | "purple-dark"
    | "purple-dark-pastel"
    | "purple-light"
    | "purple-light-pastel"
    | "red"
    | "red-pastel"
    | "yellow-dark"
    | "yellow-dark-pastel"
    | "yellow-light"
    | "yellow-light-pastel"
    | "peach"
    | "peach-pastel"
  colour:
    | "white"
    | "black"
    | "grey"
    | "grey-darkest"
    | "grey-darker"
    | "grey-dark"
    | "grey-light"
    | "grey-lighter"
    | "green"
    | "green-pastel"
    | "green-dark"
    | "green-dark-pastel"
    | "green-light"
    | "green-light-pastel"
    | "orange"
    | "orange-pastel"
    | "orange-dark"
    | "orange-dark-pastel"
    | "orange-light"
    | "orange-light-pastel"
    | "pink-dark"
    | "pink-dark-pastel"
    | "pink-light"
    | "pink-light-pastel"
    | "purple-dark"
    | "purple-dark-pastel"
    | "purple-light"
    | "purple-light-pastel"
    | "red"
    | "red-pastel"
    | "yellow-dark"
    | "yellow-dark-pastel"
    | "yellow-light"
    | "yellow-light-pastel"
    | "peach"
    | "peach-pastel"

  screen: "default" | "<sm" | "<md" | "md>" | "lg>"
  shadow: "xs" | "sm" | "default" | "lg" | "xl"
  spacing: "none" | "xxxs" | "xxs" | "xs" | "sm" | "default" | "md" | "lg" | "xl" | "xxl"
}
