import React, { useEffect } from "react"
import { ApolloClient, ApolloProvider, ApolloConsumer, HttpLink, InMemoryCache } from "@apollo/client"

import { useLocation } from "../hooks/useLocation"
import { useGlobal } from "../hooks/useGlobal"

export const ShopifyProvider = ({ children, config, allStores }): JSX.Element => {
  const { shopifyStore: currentStore } = useLocation()
  const { handleGlobalGroupReady } = useGlobal()

  const shopifyConfig = {
    ...config,
    ...(allStores[currentStore]?.shopName && { ...allStores[currentStore] }),
  }

  useEffect(() => {
    handleGlobalGroupReady("shopify")
  }, [handleGlobalGroupReady])

  const client = new ApolloClient({
    link: new HttpLink({
      uri: `https://${shopifyConfig?.shopDomain}/api/${shopifyConfig?.shopifyApiVersion}/graphql.json`,
      headers: {
        "X-Shopify-Storefront-Access-Token": shopifyConfig?.accessToken,
      },
    }),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const withShopify =
  Component =>
  (props): JSX.Element =>
    <ApolloConsumer>{client => <Component {...props} shopify={client} />}</ApolloConsumer>
