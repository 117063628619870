import { useEffect, useState } from "react"

import { useCore } from "./useCore"

export const useDomReady = () => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const waitForGlobal = (key, callback) => {
    if (isBrowser) {
      if (window[key]) {
        callback()
      } else {
        setTimeout(() => {
          waitForGlobal(key, callback)
        }, 100)
      }
    }
  }

  return { waitForGlobal }
}

export const useDom = () => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const [dom, setDom] = useState({
    width: undefined,
    height: undefined,
    isSmall: false,
    isSmallMedium: false,
    isMedium: true,
    isMediumLarge: true,
    isLarge: true,
    isExtraLarge: true,
  })

  useEffect(() => {
    const handleResize = () => {
      setDom({
        width: window.innerWidth,
        height: window.innerHeight,
        isSmall: window.innerWidth >= 375,
        isSmallMedium: window.innerWidth >= 480,
        isMedium: window.innerWidth >= 768,
        isMediumLarge: window.innerWidth >= 900,
        isLarge: window.innerWidth >= 1024,
        isExtraLarge: window.innerWidth >= 1200,
      })
    }

    if (isBrowser) {
      window.addEventListener("resize", handleResize)
      handleResize()
    }

    return () => isBrowser && window.removeEventListener("resize", handleResize)
  }, [isBrowser])

  return { dom }
}
